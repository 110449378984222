import React from 'react';
import { BaseButton } from '../Buttons/BaseButton';
import { StepsMenu } from './StepsMenu';

export const WizardDialog = ({
	children,
	open,
	title,
	onClose,
	formik,
	steps,
	activeStep,
	setActiveStep,
	handleNext,
	handleBack,
	onSubmit,
	homeOffice,
	nextButtonText = "Siguiente",
	prevButtonText = "Regresar",
	saveButtonText = "Guardar"
}) => {
	return (
		<div className='grid grid-cols-12 gap-x-1 h-full shadow-sideMenu-box-shadow border border-border relative'>
			<div className='col-span-2'>
				<StepsMenu
					textSave={'Guardar'}
					textCancel={'Cancelar'}
					open={open}
					title={title}
					onClose={onClose}
					formik={formik}
					maxWidth={'lg'}
					steps={steps}
					activeStep={activeStep}
					setActiveStep={setActiveStep}
				/>
			</div>
			<div className='col-span-10 flex flex-col relative'>
				{children}
				<div className='flex justify-end py-1 mr-2  gap-x-1'>
					{activeStep > 0 && (
						<BaseButton
							type='submit'
							onClick={handleBack}
							label={prevButtonText}
							icon='pi pi-angle-left'
						/>
					)}
					{activeStep !== steps?.length - 1 ? (
						<BaseButton
							type='submit'
							onClick={handleNext}
							iconPos='right'
							label={nextButtonText}
							icon='pi pi-angle-right'
						/>
						) : (<></>)
					}
					
					<BaseButton
						className={`${homeOffice ? '!hidden': '!visible'}`}
						type='submit'
						onClick={() => onSubmit()}
						label={saveButtonText}
					/>
				</div>
			</div>
		</div>
	);
};
