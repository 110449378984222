import React, { useContext, useState } from 'react';
import { IntegrationComponent } from '../../components/IntegrationComponent';
import { SidebarCatalogsComponent } from '../../components/SidebarCatalogsComponent';
import { SidebarNames } from '../../namesConstants/names';
import { SendIncidencesComponent } from './SendIncidences';
import { SendIncidencesServiceContext } from '../../Services/Incidences/SendIncidencesServices';
import { FullLoading } from '../../components/FullLoading';
import { StoreContext } from '../../business/Provider';

export const IncidencesScreen = () => {
	const [itemSelected, setItemSelected] = useState(1);
	const [currentTap, setCurrentTap] = useState({ id: 1 });
	const [isOpen, setIsOpen] = useState(true);
	const sendIncidencesService = useContext(SendIncidencesServiceContext);
	const { loadingState, loadingContextState } = useContext(StoreContext);
	const { stateLoadingWithMessage } = loadingContextState;

	const children = [
		{
			name: 'Catálogos',
			icon: 'AiOutlineSchedule',
			children: [
				{
					id: 1,
					name: 'incidences',
					title: SidebarNames.SidebarIncidences,
					tapTitle: SidebarNames.SidebarIncidences,
					icon: 'MdOutlineSyncProblem',
					translate: SidebarNames.SidebarIncidences,
					type: 'item',
				},
			],
		},
	];
	const renderComponent = (valueSelect) => {
		switch (valueSelect) {
			case 1:
				return <SendIncidencesComponent service={sendIncidencesService} />;
			default:
				return null;
		}
	};
	return (
		<IntegrationComponent>
			<div className='flex w-full h-full flex-row relative'>
				<FullLoading
					loadingState={loadingState || stateLoadingWithMessage.loading}
					message={stateLoadingWithMessage.message}
				/>
				<SidebarCatalogsComponent
					isOpen={isOpen}
					setCurrentTap={setCurrentTap}
					currentTap={currentTap}
					setIsOpen={setIsOpen}
					setItemSelected={setItemSelected}
					Data={children}
				/>
				<div className='flex h-full flex-grow'>
					{renderComponent(itemSelected)}
				</div>
			</div>
		</IntegrationComponent>
	);
};
