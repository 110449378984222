import {
	PATCH_WORK_CENTER,
	PATCH_WORK_CENTER_ERROR,
	PATCH_WORK_CENTER_SUCCESS,
} from '../../../constants';

export const patchWorkCenterReducer = (state, actions) => {
	switch (actions.type) {
		case PATCH_WORK_CENTER:
			return {
				...state,
				loading: true,
				error: null,
				errorShow: false,
			};
		case PATCH_WORK_CENTER_ERROR:
			return {
				...state,
				loading: false,
				error: actions.payload,
				errorShow: true,
			};
		case PATCH_WORK_CENTER_SUCCESS:
			return {
				...state,
				loading: false,
				error: null,
				errorShow: false,
				workCenters: actions.payload,
			};
		default:
			return state;
	}
};
