import { useContext, useEffect } from 'react';
import { StoreContext } from '../../business/Provider';
import useApi from './../api';
import {
	GET_CATALOGS_REPORTS_SUCCESS,
	LOADING_OFF,
	LOADING_ON,
} from '../../business/constants';
import { generateUrlBase } from '../../utils/utils';
import { Toast } from '../../components/toast';

export const useReportCatalog = () => {
	const context = useContext(StoreContext);
	const { genericApiCall } = useApi();
	const { getCatalogReportState, dispatchGetCatalogReport, dispatchLoading } =
		context;

	const getCatalogs = async () => {
		const baseUrl = generateUrlBase(`reports/catalogs`);
		dispatchLoading({ type: LOADING_ON });
		//dispatchGetCatalogReport()
		try {
			const result = await genericApiCall(baseUrl, 'GET', {}, {}, {});
			const payload = result.data;
			if (result.status === 200) {
				dispatchGetCatalogReport({
					type: GET_CATALOGS_REPORTS_SUCCESS,
					payload: payload,
				});
				dispatchLoading({ type: LOADING_OFF });
				//setDaysOff(payload);
			}
			return result;
		} catch (error) {
			Toast('warning', error.message, '', error);
			dispatchLoading({ type: LOADING_OFF });
		}
	};

	useEffect(() => {
		if (
			getCatalogReportState.catalogs === null &&
			getCatalogReportState.loading === false &&
			getCatalogReportState.error === null
		) {
			getCatalogs();
		}
	}, [getCatalogReportState]);

	return { getCatalogs };
};
