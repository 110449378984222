import { Dropdown } from 'primereact/dropdown';
import React from 'react';

export const HeaderTable = ({
	typePeriod,
	typePeriods,
	period,
	periods,
	handleTypePeriodSelectChange,
	handlePeriodSelectChange,
}) => {
	const renderDropComponent = (
		label,
		value,
		onChange,
		options,
		optionLabel,
		placeHolder,
		disabled
	) => {
		return (
			<div className='flex w-[30%] h-full '>
				<div className='flex w-1/3 h-full content-center items-center'>
					<label className='ml-2 font-bold text-sm' for=''>
						{label}
					</label>
				</div>
				<div className='flex w-2/3 h-full content-center items-center'>
					<Dropdown
						value={value}
						onChange={onChange}
						options={options}
						optionLabel={optionLabel}
						className='!w-full !h-5 !flex'
						placeholder={placeHolder}
						disabled={disabled}
						emptyMessage={'No se encontraron resultados'}
					/>
				</div>
			</div>
		);
	};
	return (
		<div className='flex h-16 w-full '>
			{renderDropComponent(
				'Tipo de periodo',
				typePeriod,
				(e) => handleTypePeriodSelectChange(e.value),
				typePeriods,
				'name',
				'Selecciona un tipo de periodo',
				false
			)}

			{renderDropComponent(
				'Periodo',
				period,
				(e) => handlePeriodSelectChange(e.value),
				periods,
				'name',
				'Selecciona un periodo',
				periods.length === 0
			)}
		</div>
	);
};
