import React from 'react';

import { ProgressBar } from 'primereact/progressbar';
import { Dialog } from 'primereact/dialog';
export const ModalProgressBarComponent = ({ service }) => {
	return (
		<Dialog
			header='Procesando invitaciones...'
			visible={service.showProgressBarDialog}
			style={{ width: '50vw' }}
			closable={false}
			headerClassName='justify-center content-center items-center flex'
			//footer={renderFooter('displayBasic')}
			//onHide={() => onHide('displayBasic')}
		>
			<ProgressBar value={service.valueProgress}></ProgressBar>
		</Dialog>
	);
};
