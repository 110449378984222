import { actionTypesOperations } from '../../../constans/operation';

export const getDataFiltersOperation_IS = {
	data: null,
	error: null,
	loading: false,
	isLoadData: false,
};
export const getDataFiltersOperationRed = (state, actions) => {
	switch (actions.type) {
		case actionTypesOperations.GET_FILTER_OPERATION.BASE:
			return {
				...state,
				loading: true,
				error: null,
				errorShow: false,
				isLoadData: false,
			};
		case actionTypesOperations.GET_FILTER_OPERATION.ERROR:
			return {
				...state,
				loading: false,
				error: actions.payload,
				errorShow: true,
				data: null,
				isLoadData: false,
			};
		case actionTypesOperations.GET_FILTER_OPERATION.SUCCESS:
			return {
				...state,
				loading: false,
				error: null,
				errorShow: false,
				data: actions.payload,
				isLoadData: true,
			};
		default:
			return state;
	}
};
