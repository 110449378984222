import React, { useState } from 'react';
import CustomDropdown from '../../../../components/Input/Dropdown';
import { Button } from 'primereact/button';

import { HolidaysYears } from '../../../../utils/utils';

export const HeaderComponent = ({
	selectYear,
	setSelectYear,
	setOpenExportDaysOff,
	openExportDaysOff,
	getDaysOff,
}) => {
	const [years] = useState({ yearsFromHolidays: HolidaysYears });

	const handleFilterChange = (e) => {
		const { name, value } = e.target;

		setSelectYear({
			year: value,
		});
		getDaysOff(value);
	};

	return (
		<div className='flex flex-row h-auto w-full space-x-3 p-1'>
			<div className='flex flex-row h-full space-x-1'>
				<p className='flex items-center justify-center'>{'Calendario: '}</p>
				<CustomDropdown
					name='year'
					value={selectYear.year}
					options={years.yearsFromHolidays}
					optionLabel='label'
					handler={handleFilterChange}
					optionValue='value'
					className='!border-r !border-border h-full !w-64'
				/>
			</div>
			<Button
				label='Festivos de ley'
				className='flex !w-auto !bg-secondary-ShadowPCS002 text-white rounded-lg'
				onClick={() => {
					setOpenExportDaysOff(true);
				}}
			/>
		</div>
	);
};
