import React from 'react';

import { IconsPanelControl } from '../IconsPanelControl';
import { SearchComponent } from '../SearchComponent';
export const ToolbarSearch = ({
	placeholder,
	value,
	regexName,
	onChange,
	onClickAction = () => {},
	clearTextLabel = () => {},
	hidden = false,
	iconsTable = [],
}) => {
	return (
		<div className='flex w-full h-full flex-row  '>
			<div className='flex h-full flex-grow p-1'>
				<SearchComponent
					placeholder={placeholder}
					value={value}
					regexName={regexName}
					onChange={onChange}
					clearTextLabel={clearTextLabel}
				/>
			</div>

			<div
				className={`flex w-[10%] lg:w-[25%] xl:w-[20%] 2xl:w-[10%] h-full justify-center content-center items-center pt-1 pb-1 pr-1  ${
					hidden ? 'hidden' : 'visible'
				}`}>
				<div className='flex w-full h-full  rounded-md justify-end items-center content-center'>
					<IconsPanelControl iconsData={iconsTable} />
				</div>
			</div>
		</div>
	);
};
