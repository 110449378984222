import { useReducer } from 'react';
import { loadingWhitMessageRed } from '../../../business/reducers/Loading/LoadingRed';
import {
	LOADING_OFF_MESSAGE,
	LOADING_ON_MESSAGE,
} from '../../../business/constants';
import { loadingWhitMessageIS } from '../../../business/initialState/loading';

export const useLoading = () => {
	const [stateLoadingWithMessage, dispatch] = useReducer(
		loadingWhitMessageRed,
		loadingWhitMessageIS
	);

	const loadingOnDp = (message) => {
		return dispatch({
			type: LOADING_ON_MESSAGE,
			payload: message,
		});
	};

	const loadingOffDp = () => {
		return dispatch({ type: LOADING_OFF_MESSAGE });
	};
	return { stateLoadingWithMessage, loadingOnDp, loadingOffDp };
};
