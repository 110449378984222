import { composeProviders } from './composerProvider';
import { EmployeeManagementFilterProvider } from './queries/EmployeeManagementFilterContext';
import { SendIncidencesFilterProvider } from './queries/SendIncidencesFilterContext';

const Providers = composeProviders(
	SendIncidencesFilterProvider,
	EmployeeManagementFilterProvider
);

export default Providers;
