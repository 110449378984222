import React, { useState, useContext, useEffect } from 'react';
import {
	GeneralNames,
	TableNames,
	CollaboratorScreenNames,
} from '../../../../namesConstants/names';
import { Column } from 'primereact/column';
import { CustomTable } from '../../../../components/Table/CustomTable';
import { headerClassName } from '../../../../assets/customStyles';
import { StoreContext } from '../../../../business/Provider';

export const TableCollaboratorsShift = (props) => {
    const [collaboratorsList, setCollaboratorsList] =useState([])
    const { getOperationCollaboratorsState , loadingState, getOperationWorkCenterLocationCollaboratorsState,getWorkCenterState} = useContext(StoreContext);
	const [collaborators, setCollaborators] = useState([]);
	const [selectedCollaborators, setSelectedCollaborators] = useState([]);
    useEffect(() => {
        setCollaboratorsList(props.filtersList);
      }, [props.filtersList]);
	useEffect(() => {
		if (
			getOperationCollaboratorsState.loading === false &&
			getOperationCollaboratorsState.collaborators !== undefined &&
			getOperationCollaboratorsState.collaborators !== null
		) {
			const _result = getOperationCollaboratorsState.collaborators?.results;
			const _selected = _result.filter((r) => {
				return selectedCollaborators.some((s) => s.id === r.id);
			});
			setCollaborators(_result);
			setSelectedCollaborators(_selected);
		}
	}, [getOperationCollaboratorsState.collaborators]);
    const filteredCollaborators = collaborators.filter(collaborator => collaboratorsList.includes(collaborator.id));
    const [sort, setSort] = useState([{ field: 'name', order: 1 }]);
	const bodyClassName =
		'!border-r-2 !border-b-0 !border-secondary-PCS004 !text-black';
	return (
		<CustomTable
			value={filteredCollaborators}
			sortField='name'
			header={undefined}
			//loading={loading}
			filters={props.filters}
			responsiveLayout='scroll'
			globalFilterFields={['name', 'fullAddress', 'isActive']}
			emptyMessage={GeneralNames.GeneralEmptyMessage}
			currentPageReportTemplate={CollaboratorScreenNames.CurrentPageReportTemplate}
			resizableColumns
			columnResizeMode='fit'
			style={{ height: '100%', width: '100%' }}
			setSort={setSort}
			sort={sort}>
			<Column
				headerClassName={headerClassName}
				style={{ flexGrow: 1, flexBasis: '100px' }}
				field='formattedCode'
				header={TableNames.TableCode}
				sortable
				bodyClassName={bodyClassName}
			/>
			<Column
				headerClassName={headerClassName}
				style={{ flexGrow: 10, flexBasis: '100px' }}
				field='fullName'
				header={TableNames.TableName}
				sortable
				bodyClassName={bodyClassName}
			/>

            <Column
				headerClassName={headerClassName}
				style={{ flexGrow: 4, flexBasis: '100px' }}
				field='shift'
				header={TableNames.TableShift}
				sortable
				bodyClassName={bodyClassName}
			/>
		</CustomTable>
	);
};
