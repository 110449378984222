/* eslint-disable react-hooks/exhaustive-deps */
import {
	ACCEPT_INFORMATION_COLLABORATOR_ERROR,
	ACCEPT_INFORMATION_COLLABORATOR_SUCCESS,
	GET_COLLABORATOR,
	GET_COLLABORATOR_ERROR,
	GET_COLLABORATOR_SUCCESS,
	LOADING_OFF,
	REJECT_INFORMATION_COLLABORATOR,
	REJECT_INFORMATION_COLLABORATOR_ERROR,
	LOADING_ON,
} from '../../business/constants';
import { useContext, useEffect, useState } from 'react';
import { StoreContext } from '../../business/Provider';
import useApi from '../api';
import { Toast } from '../../components/toast';
import {
	REJECT_INFORMATION_COLLABORATOR_SUCCESS,
	ACCEPT_INFORMATION_COLLABORATOR,
} from './../../business/constants';
import { generateUrlBase } from '../../utils/utils';

const useCollaborators = () => {
	const [collaborators, setCollaborators] = useState(undefined);

	// CALL CUSTOM HOOK USE_API
	const { genericApiCall } = useApi();
	const context = useContext(StoreContext);
	const {
		getCollaboratorsState,
		dispatchGetCollaborators,
		//acceptInfoCollaboratorState,
		dispatchAcceptInfoCollaborator,
		//	rejectInfoCollaboratorState,
		dispatchRejectInfoCollaborator,
		dispatchLoading,
	} = context;

	const getCollaborators = async () => {
		try {
			const baseUrl = generateUrlBase('collaborators/changelog');
			dispatchGetCollaborators({ type: GET_COLLABORATOR });
			dispatchLoading({ type: LOADING_ON });
			const result = await genericApiCall(baseUrl, 'GET', {}, {}, {});

			const collaborators = result.data.results;
			if (result.status === 200) {
				dispatchGetCollaborators({
					type: GET_COLLABORATOR_SUCCESS,
					payload: collaborators,
				});
				dispatchLoading({ type: LOADING_OFF });
			} else {
				let message = {
					body: '',
					header: 'Error',
				};
				if (result.message) message.body = result.message;
				dispatchGetCollaborators({
					type: GET_COLLABORATOR_ERROR,
					payload: message,
				});
				dispatchLoading({ type: LOADING_OFF });
			}
			return result;
		} catch (error) {
			Toast(
				'warning',
				'Se encontró un error intente mas tarde.',
				error.message,
				error
			);
			dispatchGetCollaborators({ type: GET_COLLABORATOR_ERROR });

			dispatchLoading({ type: LOADING_OFF });
		}
	};
	const acceptInformation = async (collaborators) => {
		const baseUrl = generateUrlBase(`collaborators/changelog/accept`);
		dispatchAcceptInfoCollaborator({ type: ACCEPT_INFORMATION_COLLABORATOR });
		dispatchLoading({ type: LOADING_ON });
		try {
			const result = await genericApiCall(
				baseUrl,
				'POST',
				collaborators,
				{},
				{}
			);
			if (result.status === 200) {
				const collaboratorData = result.data;
				dispatchAcceptInfoCollaborator({
					type: ACCEPT_INFORMATION_COLLABORATOR_SUCCESS,
					payload: collaboratorData,
				});
				dispatchLoading({ type: LOADING_OFF });
				//		const dataAccept = getCollaboratorsState.collaborators;

				const newCollaborators = getCollaboratorsState?.collaborators;
				newCollaborators.forEach((collaborator) => {
					collaboratorData.results.forEach((collaboratorData) => {
						if (
							collaborator.collaboratorChangeId ===
							collaboratorData.collaboratorChangeId
						) {
							collaborator.status = 1;
						}
					});
				});
				dispatchGetCollaborators({
					type: GET_COLLABORATOR_SUCCESS,
					payload: newCollaborators,
				});
				dispatchLoading({ type: LOADING_OFF });
			} else {
				let message = {
					body: '',
					header: 'Error',
				};
				if (result.message) message.body = result.message;
				dispatchAcceptInfoCollaborator({
					type: ACCEPT_INFORMATION_COLLABORATOR_ERROR,
					payload: message,
				});
				dispatchLoading({ type: LOADING_OFF });
			}
			Toast('Success', 'Se ha aceptado la información del colaborador');
			return result;
		} catch (error) {
			Toast('warning', error.message, '', error);
			dispatchAcceptInfoCollaborator({
				type: ACCEPT_INFORMATION_COLLABORATOR_ERROR,
			});
			dispatchLoading({ type: LOADING_OFF });
		}
	};

	const rejectInformation = async (collaborators) => {
		const baseUrl = generateUrlBase(`collaborators/changelog/reject`);
		dispatchRejectInfoCollaborator({ type: REJECT_INFORMATION_COLLABORATOR });
		dispatchLoading({ type: LOADING_ON });

		try {
			//return setTimeout(() => {
			const result = await genericApiCall(
				baseUrl,
				'POST',
				collaborators,
				{},
				{}
			);
			if (result.status === 200) {
				const collaboratorData = result.data;
				dispatchRejectInfoCollaborator({
					type: REJECT_INFORMATION_COLLABORATOR_SUCCESS,
					payload: collaboratorData,
				});
				dispatchLoading({ type: LOADING_OFF });
				const newCollaborators = getCollaboratorsState?.collaborators;
				newCollaborators.forEach((collaborator) => {
					collaboratorData.results.forEach((collaboratorData) => {
						if (
							collaborator.collaboratorChangeId ===
							collaboratorData.collaboratorChangeId
						) {
							collaborator.status = 2;
						}
					});
				});

				dispatchGetCollaborators({
					type: GET_COLLABORATOR_SUCCESS,
					payload: newCollaborators,
				});
				dispatchLoading({ type: LOADING_OFF });
			} else {
				let message = {
					body: '',
					header: 'Error',
				};
				if (result.message) message.body = result.message;
				dispatchRejectInfoCollaborator({
					type: REJECT_INFORMATION_COLLABORATOR_ERROR,
					payload: message,
				});
				dispatchLoading({ type: LOADING_OFF });
			}
			//	}, 1000);
			Toast('success', 'Información rechazada con éxito', '', '');
			return result;
		} catch (error) {
			Toast('warning', error.message, '', error);
			dispatchRejectInfoCollaborator({
				type: REJECT_INFORMATION_COLLABORATOR_ERROR,
			});
			dispatchLoading({ type: LOADING_OFF });
		}
	};

	useEffect(() => {
		if (
			//Array.isArray(getCollaboratorsState?.collaborators) &&
			!getCollaboratorsState?.collaborators &&
			getCollaboratorsState?.loading === false &&
			getCollaboratorsState.error === null
		) {
			getCollaborators();
		}
	}, [getCollaboratorsState]);

	return {
		setCollaborators,
		collaborators,
		getCollaborators,
		acceptInformation,
		rejectInformation,
	};
};
export default useCollaborators;
