import moment from 'moment';
import React, { useState, createContext } from 'react';

export const FilterContext = createContext();

export const FilterProvider = ({ children }) => {
	const typeQuery = [
		{ name: 'Incidencias', code: 'I' },
		{ name: 'Checadas', code: 'C' },
	];
	const today = new Date();
	today.setDate(today.getDate() - 30);
	const [forceUpdateKey, setForceUpdateKey] = useState(0);
	const [forceUpdateKeyTwo, setForceUpdateKeyTow] = useState(0);
	const [typeQuerySelect, setTypeQuerySelect] = useState({
		name: 'Incidencias',
		code: 'I',
	});
	const [filterTypeQuerySelect, setFilterTypeQuerySelect] = useState({
		name: 'Incidencias',
		code: 'I',
	});
	const [showColumnsDate, setShowColumnsDate] = useState(false);
	const [typePeriodSelect, setTypePeriodSelect] = useState([]);
	const [startDate, setStartDate] = useState(today);
	const [endDate, setEndDate] = useState(new Date());
	const [workCentersSelect, setWorkCentersSelect] = useState([]);
	const [shiftSelect, setShiftSelect] = useState([]);
	const [departmentSelect, setDepartmentSelect] = useState([]);
	const [positionSelect, setPositionSelect] = useState([]);
	const [collaboratorsSelect, setCollaboratorsSelect] = useState([]);
	const [incidentTypeSelect, setIncidentTypeSelect] = useState([]);

	function onChangeTypeQuery(event) {
		const data = event.target.value;
		setTypeQuerySelect(data);
	}
	function onChangeTypePeriodSelect(event) {
		setTypePeriodSelect(event.target.value);
	}
	function onChangeShowColumDate(value) {
		setShowColumnsDate(value);
	}
	function onChangeStartDate(event) {
		const newStartDate = moment(event.target.value);
		let dateToSet = endDate;
		const date = new Date(newStartDate);
		dateToSet = date;

		setForceUpdateKey((prevKey) => prevKey + 1);

		setStartDate(dateToSet);
	}

	function onChangeEndDate(event) {
		const date = new Date(event.target.value);
		setEndDate(date);
		setForceUpdateKeyTow((prevKey) => prevKey + 1);
	}
	function onChangeWorkCentersSelection(workCentersSelectionData) {
		console.log('workCentersSelectionData', workCentersSelectionData.value);
		setWorkCentersSelect(workCentersSelectionData.value);
	}
	function onChangeShiftsSelection(shiftSelectionData) {
		setShiftSelect(shiftSelectionData.value);
	}
	function onChangeDepartmentsSelection(departmentsSelectionData) {
		setDepartmentSelect(departmentsSelectionData.value);
	}

	function onChangePositionsSelection(positionsSelectionData) {
		setPositionSelect(positionsSelectionData.value);
	}

	function onChangeCollaboratorsSelection(collaboratorsSelectionData) {
		setCollaboratorsSelect(collaboratorsSelectionData.value);
	}

	function onChangeIncidenTypes(incidenTypesData) {
		setIncidentTypeSelect(incidenTypesData.target.value);
	}

	function clearFilters() {
		const today = new Date();
		today.setDate(today.getDate() - 30);
		setFilterTypeQuerySelect({
			name: 'Incidencias',
			code: 'I',
		});
		setStartDate(today);
		setEndDate(new Date());
		setTypePeriodSelect([]);
		setWorkCentersSelect([]);
		setShiftSelect([]);
		setDepartmentSelect([]);
		setPositionSelect([]);
		setCollaboratorsSelect([]);
		setIncidentTypeSelect([]);
	}
	return (
		<FilterContext.Provider
			value={{
				typeQuery,
				clearFilters,
				typeQuerySelect,
				onChangeTypeQuery,
				typePeriodSelect,
				onChangeTypePeriodSelect,
				startDate,
				setStartDate,
				onChangeStartDate,
				endDate,
				setEndDate,
				onChangeEndDate,
				forceUpdateKey,
				forceUpdateKeyTwo,
				workCentersSelect,
				onChangeWorkCentersSelection,
				setWorkCentersSelect,
				shiftSelect,
				setShiftSelect,
				onChangeShiftsSelection,
				departmentSelect,
				setDepartmentSelect,
				onChangeDepartmentsSelection,
				positionSelect,
				setPositionSelect,
				onChangePositionsSelection,
				collaboratorsSelect,
				setCollaboratorsSelect,
				onChangeCollaboratorsSelection,
				incidentTypeSelect,
				setIncidentTypeSelect,
				onChangeIncidenTypes,
				filterTypeQuerySelect,
				setFilterTypeQuerySelect,
				showColumnsDate,
				onChangeShowColumDate,
			}}>
			{children}
		</FilterContext.Provider>
	);
};
