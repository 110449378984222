import { useContext, useEffect } from 'react';
import { StoreContext } from '../../business/Provider';
import useApi from '../api';
import { Toast } from '../../components/toast';
import {
	EDIT_INCIDENCES,
	EDIT_INCIDENCES_ERROR,
	EDIT_INCIDENCES_SUCCESS,
	GET_ACCUMULATED,
	GET_ACCUMULATED_ERROR,
	GET_ACCUMULATED_SUCCESS,
	GET_TYPE_INCIDENCES,
	GET_TYPE_INCIDENCES_ERROR,
	GET_TYPE_INCIDENCES_SUCCESS,
	LOADING_OFF,
	LOADING_ON,
	POST_NEW_INCIDENCES,
	POST_NEW_INCIDENCES_ERROR,
	POST_NEW_INCIDENCES_SUCCESS,
	PUT_INCIDENCES,
	PUT_INCIDENCES_ERROR,
	PUT_INCIDENCES_SUCCESS,
} from '../../business/constants';
import { generateUrlBase } from '../../utils/utils';

export const useTypeIncidences = () => {
	const context = useContext(StoreContext);
	const {
		dispatchGetTypeIncidences,
		dispatchLoading,
		getTypeIncidencesState,
		dispatchPostNewIncidences,
		dispatchPutIncidences,
		dispatchGetAccumulated,
		getAccumulatedState,
		dispatchEditIncidences,
	} = context;
	const { genericApiCall } = useApi();

	const getTypeIncidences = async () => {
		const baseUrl = generateUrlBase('incidences');

		dispatchGetTypeIncidences({ type: GET_TYPE_INCIDENCES });
		dispatchLoading({ type: LOADING_ON });
		try {
			const result = await genericApiCall(baseUrl, 'GET', {}, {}, {});

			const payload = result.data;

			if (result.status === 200) {
				dispatchGetTypeIncidences({
					type: GET_TYPE_INCIDENCES_SUCCESS,
					payload: payload,
				});
				dispatchLoading({ type: LOADING_OFF });

				//Toast('success', 'incidencias obtenidas con éxito', '', '');
			} else {
				let message = {
					body: '',
					header: 'Error',
				};
				if (result.message) message.body = result.message;
				dispatchGetTypeIncidences({
					type: GET_TYPE_INCIDENCES_ERROR,
					payload: message,
				});
				dispatchLoading({ type: LOADING_OFF });
			}
			//Toast('success', AlertToastInfoNames.CancelInvitationSuccess, '', '');
			return result;
		} catch (error) {
			dispatchGetTypeIncidences({ type: GET_TYPE_INCIDENCES_ERROR });
			dispatchLoading({ type: LOADING_OFF });
			Toast('warning', error.message, '', error);
		}
	};

	const createIncidences = async (params) => {
		const baseUrl = generateUrlBase('incidences');

		dispatchPostNewIncidences({ type: POST_NEW_INCIDENCES });
		dispatchLoading({ type: LOADING_ON });
		try {
			const result = await genericApiCall(baseUrl, 'POST', params, {}, {});

			const payload = result.data;
			console.log('payload', payload);

			if (result.status === 201) {
				dispatchPostNewIncidences({
					type: POST_NEW_INCIDENCES_SUCCESS,
					payload: payload,
				});
				Toast('success', 'Se ha creado con éxito');
				return await getTypeIncidences();
			} else {
				let message = {
					body: '',
					header: 'Error',
				};
				if (result.message) message.body = result.message;
				dispatchPostNewIncidences({
					type: POST_NEW_INCIDENCES_ERROR,
					payload: message,
				});
				dispatchLoading({ type: LOADING_OFF });
			}
			//Toast('success', AlertToastInfoNames.CancelInvitationSuccess, '', '');
			return result;
		} catch (error) {
			dispatchPostNewIncidences({ type: POST_NEW_INCIDENCES_ERROR });
			dispatchLoading({ type: LOADING_OFF });
			Toast('warning', error.message, '', error);
		}
	};

	const editIncidences = async (params) => {
		const baseUrl = generateUrlBase('incidences/leader');

		dispatchPutIncidences({ type: PUT_INCIDENCES });
		dispatchLoading({ type: LOADING_ON });
		try {
			const result = await genericApiCall(baseUrl, 'PUT', params, {}, {});

			const payload = result.data;

			if (result.status === 200) {
				dispatchPutIncidences({
					type: PUT_INCIDENCES_SUCCESS,
					payload: payload,
				});
				Toast('success', 'La incidencia se editó correctamente');

				return await getTypeIncidences();
			} else {
				let message = {
					body: '',
					header: 'Error',
				};
				if (result.message) message.body = result.message;
				dispatchPutIncidences({
					type: PUT_INCIDENCES_ERROR,
					payload: message,
				});
				dispatchLoading({ type: LOADING_OFF });
			}
			return result;
		} catch (error) {
			dispatchPutIncidences({ type: PUT_INCIDENCES_ERROR });
			dispatchLoading({ type: LOADING_OFF });
			Toast('warning', error.message, '', error);
		}
	};

	const getAccumulatedTypes = async () => {
		const baseUrl = generateUrlBase('incidences/accumulated');

		dispatchGetAccumulated({ type: GET_ACCUMULATED });
		dispatchLoading({ type: LOADING_ON });
		try {
			const result = await genericApiCall(baseUrl, 'GET', {}, {}, {});

			const payload = result.data;

			if (result.status === 200) {
				dispatchGetAccumulated({
					type: GET_ACCUMULATED_SUCCESS,
					payload: payload,
				});
				dispatchLoading({ type: LOADING_OFF });

				//Toast('success', 'incidencias obtenidas con éxito', '', '');
			} else {
				let message = {
					body: '',
					header: 'Error',
				};
				if (result.message) message.body = result.message;
				dispatchGetAccumulated({
					type: GET_ACCUMULATED_ERROR,
					payload: message,
				});
				dispatchLoading({ type: LOADING_OFF });
			}
			//Toast('success', AlertToastInfoNames.CancelInvitationSuccess, '', '');
			return result;
		} catch (error) {
			dispatchGetAccumulated({ type: GET_ACCUMULATED_ERROR });
			dispatchLoading({ type: LOADING_OFF });
			Toast('warning', error.message, '', error);
		}
	};

	const updateTypeIncident = async (incidence) => {
		const baseUrl = generateUrlBase('incidences');

		dispatchEditIncidences({ type: EDIT_INCIDENCES });
		dispatchLoading({ type: LOADING_ON });
		try {
			const result = await genericApiCall(baseUrl, 'PUT', incidence, {}, {});

			const payload = result.data;

			if (result.status === 200) {
				dispatchEditIncidences({
					type: EDIT_INCIDENCES_SUCCESS,
					payload: payload,
				});
				Toast('success', 'La incidencia se editó correctamente', '', '');

				return await getTypeIncidences();
			} else {
				let message = {
					body: '',
					header: 'Error',
				};
				if (result.message) message.body = result.message;
				dispatchEditIncidences({
					type: EDIT_INCIDENCES_ERROR,
					payload: message,
				});
				dispatchLoading({ type: LOADING_OFF });
			}
			return result;
		} catch (error) {
			dispatchEditIncidences({ type: EDIT_INCIDENCES_ERROR });
			dispatchLoading({ type: LOADING_OFF });
			Toast('warning', error.message, '', error);
		}
	};

	useEffect(() => {
		//	console.log('getTypeIncidencesState', getTypeIncidencesState);
		if (
			getTypeIncidencesState?.incidences === null &&
			getTypeIncidencesState?.loading === false &&
			getTypeIncidencesState?.error === null
		) {
			getTypeIncidences();
		}
	}, [getTypeIncidencesState]);

	useEffect(() => {
		//	console.log('getTypeIncidencesState', getTypeIncidencesState);
		if (
			getAccumulatedState?.accumulated === null &&
			getAccumulatedState?.loading === false &&
			getAccumulatedState?.error === null
		) {
			getAccumulatedTypes();
		}
	}, [getAccumulatedState]);

	return {
		getTypeIncidences,
		createIncidences,
		editIncidences,
		updateTypeIncident,
	};
};
