import React, { useContext } from 'react';
//import { getTranslation, useCustomTranslation } from '../../../hooks/useTranslations'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { NavigationContext } from '../../../context/navigationContext';
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons';
//import { useHover } from '../../../hooks/useHover';
import { NavigationContext } from '../../context/navigationContext';

export const BreadCrumbItem = ({
	item,
	close,
	redirect,
	closeBeforeAction,
}) => {
	//const { t } = useCustomTranslation();
	const navigation = useContext(NavigationContext);
	const border =
		navigation.currentTap?.url === item.url
			? 'border-b-4 border-primary-PCP001'
			: '';

	//if item.tapTitle is string, then it is a link

	const tapName =
		typeof item.tapTitle === 'string' || item.tapTitle instanceof String //getTranslation(t, item.tapTitle)
			? item.tapTitle
			: '';
	return (
		// eslint-disable-next-line react/no-unknown-property
		<li
			className={`w-[200px] h-[44px] mr-3 flex hover:cursor-pointer font-font-family-base ${border} `}>
			<div
				className={`w-10/12 h-full !text-primary-PCP001  truncate text-center justify-center items-center content-center flex`}
				onClick={() => redirect(item.url)}>
				<p>
					{/* {getTranslation(t, item.father) + ' ' + tapName} */}
					{tapName}
				</p>
			</div>
			<button
				className='z-10 flex justify-center content-center items-center '
				onClick={() => {
					close(item.url, item);
				}}>
				{/* <FontAwesomeIcon
					className={`!text-primary-PCP001 w-[20px] h-[20px] `}
					icon={faCircleXmark}
				/> */}
				<p className='!text-secondary-PCS002 flex !text-xl justify-center content-center items-center '>
					<span className={'icon-cancel-fill'}></span>
				</p>
			</button>
		</li>
	);
};
