import React, { useContext, useEffect, useState } from 'react';

import { DataTable } from 'primereact/datatable';
import PropTypes from 'prop-types';
import { StoreContext } from '../../business/Provider';
import {
	DEFAULT_PAGE,
	ROWS_PER_PAGE,
	GRID_PAGES,
} from './../../utils/constants/table/index';

// eslint-disable-next-line
export const CustomTable = ({
	children,
	style,
	header,
	sortField,
	sort,
	setSort,
	doubleClick,
	tableClassName,
	height = 'h-9',
	rows = undefined,
	id = undefined,
	...props
}) => {
	const totalsRows = rows !== undefined ? rows : ROWS_PER_PAGE;
	//const { loadingState } = useContext(StoreContext);

	const [first] = useState(DEFAULT_PAGE);
	const [rowsNumber] = useState(totalsRows);

	const [showMenu, setShowMenu] = useState(false);

	const h = showMenu ? ' h-68 md:h-60 ' : ' h-5 ';

	return (
		<div className='flex w-full h-full flex-col'>
			{header !== undefined ? (
				<>
					<div
						className={
							'hidden lg:flex xl:flex 2xl:flex w-full  py-1 pl-1  ' + height
						}>
						{header}
					</div>
					<div
						className={
							'flex lg:hidden xl:hidden 2xl:hidden w-full h-full  py-1 pl-1 border' +
							h
						}>
						{showMenu === false ? (
							<div
								onClick={() => setShowMenu(!showMenu)}
								className='flex  w-full  justify-end content-start items-center '>
								<p className='!text-primary-ShadowPCP004 mr-2 flex !text-2xl justify-center content-center items-center hover:!text-secondary-PCS002 '>
									<span className={'icon-menu'}></span>
								</p>
							</div>
						) : (
							<div className='flex lg:hidden xl:hidden 2xl:hidden w-full h-full py-1 pl-1 border flex-row'>
								<div className='flex h-full flex-grow'>{header}</div>
								<div
									onClick={() => setShowMenu(!showMenu)}
									className='flex   justify-end content-start items-center'>
									<p className='!text-primary-ShadowPCP004 mr-2 flex !text-2xl justify-center content-center items-center hover:!text-secondary-PCS002 '>
										<span className={'icon-menu'}></span>
									</p>
								</div>
							</div>
						)}
					</div>
				</>
			) : null}
			<div className='flex w-full h-full pt-1 pl-1'>
				<div className='flex w-full h-full relative'>
					<DataTable
						first={first}
						rows={rowsNumber}
						paginatorClassName='!border-t !border-border !justify-start !text-xs'
						//rowHover
						id={id !== undefined ? id : 'testId'}
						removableSort
						paginatorTemplate='CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown '
						//filterDisplay='row' // input search

						rowsPerPageOptions={GRID_PAGES}
						resizableColumns
						sortField={sort.field}
						sortOrder={sort.order}
						columnResizeMode='fit'
						paginator
						scrollable
						scrollHeight='flex'
						scrollDirection='both'
						stripedRows
						//	showGridlines
						tableClassName={'!absolute !top-0 !left-0 !right-0 !bottom-0 '}
						className='flex w-full h-full relative !font-karla !text-[14px]'
						rowClassName='hover:!bg-[#3b82f6] !border-r !border-secondary-PCS004 '
						size='small'
						onRowDoubleClick={(e) => {
							if (doubleClick) {
								doubleClick(e.data);
							}
						}}
						//loading={loading || loadingState}
						onSort={(e) => setSort(e.multiSortMeta)}
						multiSortMeta={sort}
						sortMode='multiple'
						{...props}
						currentPageReportTemplate='Total de registros {totalRecords}'>
						{children}
					</DataTable>
				</div>
			</div>
		</div>
	);
};
CustomTable.defaultProps = {
	first: DEFAULT_PAGE,
	rows: ROWS_PER_PAGE,
	paginatorTemplate:
		'CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown',
	resizableColumns: true,
	columnResizeMode: 'fit',
	sortOrder: 1,
	paginator: true,
	scrollable: true,
	scrollHeight: 'flex',
	scrollDirection: 'both',
	stripedRows: true,
	tableClassName: '!text-xs ',
	rowClassName: 'hover:!bg-[#3b82f633]',
	style: { height: 'calc(100vh - 106px)', width: 'calc(100vw - 92px)' },
	doubleClick: () => {},
};
CustomTable.propTypes = {
	first: PropTypes.number,
	rows: PropTypes.number,
	paginatorTemplate: PropTypes.string,
	resizableColumns: PropTypes.bool,
	columnResizeMode: PropTypes.string,
	sortOrder: PropTypes.number,
	paginator: PropTypes.bool,
	scrollable: PropTypes.bool,
	scrollHeight: PropTypes.string,
	scrollDirection: PropTypes.string,
	stripedRows: PropTypes.bool,
	tableClassName: PropTypes.string,
	rowClassName: PropTypes.string,
	style: PropTypes.object,
	doubleClick: PropTypes.func,
};
