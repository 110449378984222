import {
  VERIFY_AVAILABLE_LICENSE,
  VERIFY_AVAILABLE_LICENSE_SUCCESS,
  VERIFY_AVAILABLE_LICENSE_ERROR,
  VERIFY_AVAILABLE_LICENSE_ERROR_SHOW,
} from '../../constants';

export const verifyAvailableLicenseReducer = (state, actions) => {
  switch (actions.type) {
    case VERIFY_AVAILABLE_LICENSE:
      return {
        ...state,
        loading: true,
      };
    case VERIFY_AVAILABLE_LICENSE_ERROR:
      return {
        availableLicense: state.availableLicense,
        loading: false,
        error: actions.payload,
        errorShow: false,
      };
    case VERIFY_AVAILABLE_LICENSE_SUCCESS:
      return {
        availableLicense: actions.payload,
        loading: false,
        error: null,
        errorShow: false,
      };
    case VERIFY_AVAILABLE_LICENSE_ERROR_SHOW:
      return {
        ...state,
        errorShow: true,
      };
    default:
      return state;
  }
};
