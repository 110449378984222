import * as yup from 'yup';

const ScheduleCollaboratorSchema = yup.object().shape({
    workShiftDetailId: yup.string().required('Seleccione un turno'),
    scheduled: yup.bool(),
    startsOn: yup.string().when("scheduled", (val) => {
        if(val[0]) { 
           return yup.string().required('Ingresa una fecha programada');
        } else { 
           return yup.string();
        }
   }),
});


export default ScheduleCollaboratorSchema;
