/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useContext, useEffect } from 'react';
import { StoreContext } from '../../../business/Provider';
import {
	LOADING_ON,
	LOADING_OFF,
	GET_DAYS_OFF_SUCCESS,
	POST_DAYS_OFF,
	PUT_DAYS_OFF_SUCCESS,
	IMPORT_DAYS_OFF,
	GET_HOLIDAYS_SUCCESS,
	GET_DAYS_OFF,
	IMPORT_DAYS_OFF_ERROR,
} from '../../../business/constants';
import {
	generateUrlBase,
	generateUrlBaseCommonUtils,
} from '../../../utils/utils';
import useApi from '../../api';
import { Toast } from '../../../components/toast';

const useHolidays = (yearHoliday) => {
	const context = useContext(StoreContext);
	const { genericApiCall } = useApi();
	const [daysOff, setDaysOff] = useState(undefined);
	const [yearSelected, setYearSelected] = useState(undefined);
	const {
		dispatchLoading,
		dispatchGetHolidays,
		dispatchPostHolidays,
		dispatchPutHolidays,
		dispatchDeleteHolidays,
		dispatchImportDaysOff,
		dispatchGetHolidaysByYear,
	} = context;

	const getDaysOff = async (year) => {
		dispatchGetHolidays({
			type: GET_DAYS_OFF,
		});
		const yearData = year !== undefined ? year : yearHoliday;
		const baseUrl = generateUrlBase(`days-off?year=${yearData}`);

		dispatchLoading({ type: LOADING_ON });
		try {
			const result = await genericApiCall(baseUrl, 'GET', {}, {}, {});
			const payload = result.data;
			if (result.status === 200) {
				dispatchGetHolidays({
					type: GET_DAYS_OFF_SUCCESS,
					payload: payload,
				});
				dispatchLoading({ type: LOADING_OFF });
				setDaysOff(payload);
				setYearSelected(year);
			}
			return result;
		} catch (error) {
			Toast('warning', error.message, '', error);
			dispatchLoading({ type: LOADING_OFF });
		}
	};

	const getHolidays = async (year) => {
		const baseUrl = generateUrlBaseCommonUtils(`api/holidays/${year}`);
		dispatchLoading({ type: LOADING_ON });
		try {
			const result = await genericApiCall(baseUrl, 'GET', {}, {}, {});
			if (result.status === 200) {
				dispatchGetHolidaysByYear({
					type: GET_HOLIDAYS_SUCCESS,
					payload: result.data,
				});
				dispatchLoading({ type: LOADING_OFF });
				if (result?.data && result?.data?.length === 0)
					Toast(
						'warning',
						'No se encontraron días festivos de ley para el año indicado'
					);
			}
			return result;
		} catch (error) {
			dispatchLoading({ type: LOADING_OFF });

			Toast('warning', error.message, '', error);
		}
	};

	const putDaysOff = async (id, data) => {
		const baseUrl = generateUrlBase(`days-off/${id}`);
		dispatchLoading({ type: LOADING_ON });
		try {
			const result = await genericApiCall(baseUrl, 'PUT', data, {}, {});
			const payload = result.data;
			if (result.status === 200) {
				dispatchPutHolidays({
					type: PUT_DAYS_OFF_SUCCESS,
					payload: payload,
				});
				dispatchLoading({ type: LOADING_OFF });
				Toast('success', 'Se ha editado el día festivo correctamente');
				return getDaysOff();
			}
			return result;
		} catch (error) {
			Toast('warning', error.message, '', error);
			dispatchLoading({ type: LOADING_OFF });
		}
	};

	const deleteDaysOff = async (id) => {
		const baseUrl = generateUrlBase(`days-off/${id}`);
		dispatchLoading({ type: LOADING_ON });
		try {
			const result = await genericApiCall(baseUrl, 'DELETE', {}, {}, {});
			const payload = result.data;
			if (result.status === 200) {
				Toast('success', 'Se ha eliminado el día festivo del calendario');
				dispatchDeleteHolidays({
					type: PUT_DAYS_OFF_SUCCESS,
					payload: payload,
				});

				dispatchLoading({ type: LOADING_OFF });
				return getDaysOff();
			}
			return result;
		} catch (error) {
			Toast('warning', error.message, '', error);
			dispatchLoading({ type: LOADING_OFF });
		}
	};

	const postDaysOff = async (data) => {
		const baseUrl = generateUrlBase(`days-off`);
		dispatchLoading({ type: LOADING_ON });
		try {
			const result = await genericApiCall(baseUrl, 'POST', data, {}, {});
			const payload = result.data;
			if (result.status === 200 || result.status === 201) {
				dispatchPostHolidays({
					type: POST_DAYS_OFF,
					payload: payload,
				});
				dispatchLoading({ type: LOADING_OFF });
				Toast('success', 'Se ha creado el día festivo correctamente');
				return getDaysOff();
			}
			return result;
		} catch (error) {
			console.log('error ==============', error);
			Toast('warning', error.message, '');
			dispatchLoading({ type: LOADING_OFF });
		}
	};

	const importHolidays = async (data) => {
		const baseUrl = generateUrlBase(`days-off/holidays`);
		dispatchLoading({ type: LOADING_ON });
		try {
			const result = await genericApiCall(baseUrl, 'POST', data, {}, {});
			const payload = result.data;
			if (result.status === 200 || result.status === 201) {
				dispatchImportDaysOff({
					type: IMPORT_DAYS_OFF,
					payload: payload,
				});
				dispatchLoading({ type: LOADING_OFF });
				Toast('success', 'Se han importado correctamente los días festivos');
				getDaysOff(yearSelected);
			} else {
				let message = {
					body: '',
					header: 'Error',
				};
				if (result.message) message.body = result.message;
				dispatchImportDaysOff({
					type: IMPORT_DAYS_OFF_ERROR,
					payload: message,
				});
				dispatchLoading({ type: LOADING_OFF });
			}
			return result;
		} catch (error) {
			console.log('error ==============', error);
			Toast('warning', error.message, '', error);
			dispatchLoading({ type: LOADING_OFF });
		}
	};

	useEffect(() => {
		getDaysOff(yearHoliday);
	}, []);

	return {
		getDaysOff,
		putDaysOff,
		postDaysOff,
		deleteDaysOff,
		getHolidays,
		importHolidays,
	};
};

export default useHolidays;
