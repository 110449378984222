import React, { useState, useEffect } from 'react';
import { RadioButton } from 'primereact/radiobutton';
import { ContentRadio } from './ContentRadio';
import { Button } from 'primereact/button';
import { Input } from '../../../../components/Input/Input';
import {Toast} from '../../../../components/toast'
import _ from 'lodash';
import useCompanySetting from '../../../../hooks/company/useCompanySetting';

export const AssistComponent = ({ ConfigChange, setConfigChange }) => {
	const [tempWorkCenterSize, setTempWorkCenterSize] = useState(500);
	const [tempHomeOfficeQuantity, setHomeOfficeQuantity] = useState(3);
	let stateWorkCenterInit = {
		verifyBiometricData: false,
		workCenterRestriction: false,
		workCenterSize: '500',
		toleranceBeforeEntry: '120',
		toleranceBeforeExit: '60',
		toleranceAfterExit: '180',
		homeOfficeQuantity: '3',
	};
	const [attendance, setAttendance] = useState(stateWorkCenterInit);
	const [tempAttendance, setTempAttendance] = useState({});
	const { postCompanySettingAttendance, getCompanySettings, companySettings } =
		useCompanySetting();

	useEffect(() => {
		if (_.isEqual(attendance, tempAttendance)) {
			setConfigChange({ ...ConfigChange, AssistChange: false });
		} else {
			setConfigChange({ ...ConfigChange, AssistChange: true });
		}
	}, [attendance]);

	useEffect(() => {
		if (companySettings === undefined) {
			getCompanySettings();
		} else {
			if (
				(companySettings.workCenterRestriction === null ||
					companySettings.workCenterRestriction === undefined) &&
				(companySettings.workCenterSize === null ||
					companySettings.workCenterSize === undefined) &&
				(companySettings.toleranceBeforeEntry === null ||
					companySettings.toleranceBeforeEntry === undefined) &&
				(companySettings.toleranceBeforeExit === null ||
					companySettings.toleranceBeforeExit === undefined) &&
				(companySettings.homeOfficeQuantity === null ||
					companySettings.homeOfficeQuantity === undefined) &&
				(companySettings.toleranceAfterExit === null ||
					companySettings.toleranceAfterExit === undefined)
			) {
				setAttendance(stateWorkCenterInit);
				setTempAttendance(stateWorkCenterInit);
			} else {
				const tempHQuantity =
					companySettings.homeOfficeQuantity !== null
						? companySettings.homeOfficeQuantity
						: '3';
				const tempWorkCenterSize =
					companySettings.workCenterSize !== null
						? companySettings.workCenterSize
						: '500';
				const tempToleranceBeforeExit =
					companySettings.toleranceBeforeExit !== null
						? companySettings.toleranceBeforeExit
						: '60';
				const tempToleranceBeforeEntry =
					companySettings.toleranceBeforeEntry !== null
						? companySettings.toleranceBeforeEntry
						: '120';
				const tempToleranceAfterExit =
						companySettings.toleranceAfterExit !== null
							? companySettings.toleranceAfterExit
							: '180';
				

				companySettings.workCenterSize = null;	
				companySettings.homeOfficeQuantity = null;
				if(companySettings.workCenterRestriction){
					companySettings.workCenterSize = tempWorkCenterSize;
					companySettings.homeOfficeQuantity = tempHQuantity;
				}

				companySettings.toleranceBeforeExit = tempToleranceBeforeExit;
				companySettings.toleranceBeforeEntry = tempToleranceBeforeEntry;
				companySettings.toleranceAfterExit = tempToleranceAfterExit;
				setAttendance({ ...attendance, ...companySettings });
				setTempAttendance({ ...attendance, ...companySettings });
				if (
					companySettings.homeOfficeQuantity !== undefined ||
					companySettings.homeOfficeQuantity !== null
				) {
					setHomeOfficeQuantity(tempHQuantity);
				} else {
					setHomeOfficeQuantity('3');
				}

				if (
					companySettings.workCenterSize !== undefined ||
					companySettings.workCenterSize !== null
				) {
					setTempWorkCenterSize(tempWorkCenterSize);
				} else {
					setTempWorkCenterSize('500');
				}
			}
		}
	}, [companySettings]);

	useEffect(() => {
		setErrors({
			workCenterSize:
				attendance.workCenterRestriction &&
				(attendance.workCenterSize === undefined ||
					attendance?.workCenterSize?.length === 0 ||
					attendance.workCenterSize === null),
			homeOfficeQuantity:
				attendance.workCenterRestriction &&
				(attendance.homeOfficeQuantity === undefined ||
					attendance?.homeOfficeQuantity?.length === 0 ||
					attendance.homeOfficeQuantity === null),
			toleranceBeforeEntry:
				attendance.toleranceBeforeEntry === undefined ||
				attendance?.toleranceBeforeEntry?.length === 0 ||
				attendance.toleranceBeforeEntry === null,
			toleranceBeforeExit:
				attendance.toleranceBeforeExit === undefined ||
				attendance?.toleranceBeforeExit?.length === 0 ||
				attendance.toleranceBeforeExit === null,
			toleranceAfterExit:
				attendance.toleranceAfterExit === undefined ||
				attendance?.toleranceAfterExit?.length === 0 ||
				attendance.toleranceAfterExit === null,
		});
	}, [attendance]);

	const [errors, setErrors] = useState({
		workCenterSize:
			attendance.workCenterRestriction &&
			(attendance.workCenterSize === undefined ||
				attendance?.workCenterSize?.length === 0 ||
				attendance.workCenterSize === null),
		homeOfficeQuantity:
			attendance.workCenterRestriction &&
			(attendance.homeOfficeQuantity === undefined ||
				attendance?.homeOfficeQuantity?.length === 0 ||
				attendance.homeOfficeQuantity === null),
		toleranceBeforeEntry:
			attendance.toleranceBeforeEntry === undefined ||
			attendance?.toleranceBeforeEntry?.length === 0 ||
			attendance.toleranceBeforeEntry === null,
		toleranceBeforeExit:
			attendance.toleranceBeforeExit === undefined ||
			attendance?.toleranceBeforeExit?.length === 0 ||
			attendance.toleranceBeforeExit === null,
		toleranceAfterExit:
			attendance.toleranceAfterExit === undefined ||
			attendance?.toleranceAfterExit?.length === 0 ||
			attendance.toleranceAfterExit === null,
	});

	const handleInput = (e) => {
		if(e.target.id==='homeOfficeQuantity' && e.target.value>15){
			Toast(
				'warning',
				'El valor máximo de lugares de trabajo es 15'
			);
			e.target.value=15

		}
		if(e.target.id==='workCenterSize' && e.target.value>1000){
			Toast(
				'warning',
				'El valor máximo de la geocerca es de 1 000 metros'
			);
			e.target.value=1001

		}
		const regex = /^[0-9\b]+$/;
		if (e.target.value === '' || regex.test(e.target.value)) {
			const { name, value } = e.target;
			let valueData = value.trim() === '' ? undefined : value;
			if(e.target.id==='workCenterSize')
			{
				valueData = value.trim() === '' ? undefined : parseInt(value);
			}
			setAttendance({
				...attendance,
				[name]: valueData,
			});

			if (name === 'workCenterSize') setTempWorkCenterSize(valueData);
			if (name === 'homeOfficeQuantity') setHomeOfficeQuantity(valueData);

			if (e.target.value === '') {
				setErrors({
					...errors,
					[name]: true,
				});
			} else {
				if (attendance.workCenterRestriction) {
					setErrors({
						...errors,
						[name]:
							attendance[name] === undefined ||
							attendance[name]?.length === 0 ||
							attendance[name] === null,
					});
				} else {
					setErrors({
						...errors,
						[name]: false,
					});
				}
			}
		}
	};

	const renderRestrictionContent = () => {
		return (
			<div
				className={`flex w-full flex-col h-2/5 ${
					attendance.workCenterRestriction ? 'visible' : 'hidden'
				}`}>
				<div className='flex h-full w-full flex-col'>
					<p className='h-4'>{'Tamaño de geocerca (metros):'}</p>
					<div className='flex flex-col pl-2 space-y-2'>
						<div className='flex flex-row content-center items-center space-x-2'>
							<Input
								value={
									(attendance.workCenterSize === undefined &&
										tempWorkCenterSize === undefined) ||
									attendance.workCenterSize === null
										? ''
										: attendance.workCenterSize === tempWorkCenterSize
										? attendance.workCenterSize
										: tempWorkCenterSize
								}
								className={'flex w-full h-4 !rounded-lg text-left'}
								classNameContent={'w-32 h-4 !rounded-lg'}
								onChange={(e) => handleInput(e)}
								name={'workCenterSize'}
							/>
							<p>{'Metros'}</p>
						</div>
					</div>
				</div>
				<div className='flex h-full w-full flex-col'>
					<p className='h-4'>{'Cantidad de lugares de trabajo'}</p>
					<div className='flex flex-col pl-2 space-y-2'>
						<div className='flex flex-row content-center items-center space-x-2'>
							<Input
								value={
									(attendance.homeOfficeQuantity === undefined &&
										tempHomeOfficeQuantity === undefined) ||
									attendance.homeOfficeQuantity === null
										? ''
										: attendance.homeOfficeQuantity === tempHomeOfficeQuantity
										? attendance.homeOfficeQuantity
										: tempHomeOfficeQuantity
								}
								className={'flex w-full h-4 !rounded-lg text-left'}
								classNameContent={'w-32 h-4 !rounded-lg'}
								onChange={(e) => handleInput(e)}
								name={'homeOfficeQuantity'}
							/>
						</div>
					</div>
				</div>
			</div>
		);
	};

	const renderRadioButtonContentType = () => {
		return (
			<ContentRadio
				name={'Registro de asistencia con:'}
				labelRadio1={'Biométricos (Huella y/o reconocimiento facial)'}
				labelRadio2={'Sin biométricos'}
				id1={'withBio'}
				radio1={
					<RadioButton
						inputId='withBio'
						name='biometric'
						value={true}
						onChange={(e) =>
							setAttendance({ ...attendance, verifyBiometricData: e.value })
						}
						checked={attendance.verifyBiometricData === true}
					/>
				}
				id2={'withoutBio'}
				radio2={
					<RadioButton
						inputId='withoutBio'
						name='biometric'
						value={false}
						onChange={(e) =>
							setAttendance({ ...attendance, verifyBiometricData: e.value })
						}
						checked={attendance.verifyBiometricData === false}
					/>
				}
			/>
		);
	};

	const renderRadioButtonContentRegister = () => {
		return (
			<ContentRadio
				name={'Permitir registro de asistencia en:'}
				labelRadio1={'Cualquier ubicación'}
				labelRadio2={'Dentro de sus centros y lugares de trabajo'}
				id1={'FreeZone'}
				radio1={
					<RadioButton
						inputId='FreeZone'
						name='restriction'
						value={false}
						onChange={(e) =>
							setAttendance({
								...attendance,
								workCenterRestriction: e.value,
								workCenterSize: null,
								homeOfficeQuantity: null,
							})
						}
						checked={attendance.workCenterRestriction === false}
					/>
				}
				id2={'OutZone'}
				radio2={
					<RadioButton
						inputId='OutZone'
						name='restriction'
						value={true}
						onChange={(e) =>
							setAttendance({
								...attendance,
								workCenterRestriction: e.value,
								workCenterSize: tempWorkCenterSize,
								homeOfficeQuantity: tempHomeOfficeQuantity,
							})
						}
						checked={attendance.workCenterRestriction === true}
					/>
				}
			/>
		);
	};

	const renderContentStartWork = () => {
		return (
			<div className='flex w-full flex-row h-1/5'>
				<div className='flex h-full w-full flex-col'>
					<p className='h-4'>{'Se considera registro de entrada:'}</p>
					<div className='flex flex-col pl-2 space-y-2'>
						<div className='flex flex-row content-center items-center space-x-2'>
							<Input
								value={
									attendance.toleranceBeforeEntry === undefined ||
									attendance.toleranceBeforeEntry === null
										? ''
										: attendance.toleranceBeforeEntry
								}
								className={'flex w-full h-4 !rounded-lg text-left'}
								classNameContent={'w-32 h-4 !rounded-lg'}
								onChange={(e) => handleInput(e)}
								name={'toleranceBeforeEntry'}
							/>
							<p>{'minutos antes del inicio del turno'}</p>
						</div>
					</div>
				</div>
			</div>
		);
	};
	const renderContentEndWork = () => {
		return (
			<div className='flex w-full flex-row h-1/5'>
				<div className='flex h-full w-full flex-col'>
					<p className='h-4'>{'Se considera registro de salida:'}</p>
					<div className='flex flex-col pl-2 space-y-2'>
						<div className='flex flex-row content-center items-center space-x-2'>
							<Input
								value={
									attendance.toleranceBeforeExit === undefined ||
									attendance.toleranceBeforeExit === null
										? ''
										: attendance.toleranceBeforeExit
								}
								className={'flex w-full h-4 !rounded-lg text-left'}
								classNameContent={'w-32 h-4 !rounded-lg'}
								onChange={(e) => handleInput(e)}
								name={'toleranceBeforeExit'}
							/>
							<p>{'minutos antes del fin del turno'}</p>
						</div>
					</div>
				</div>
			</div>
		);
	};

	const renderContentLimit = () => {
		return (
			<div className='flex w-full flex-row h-1/5'>
				<div className='flex h-full w-full flex-col'>
					<p className='h-4'>{'Límite de registro de salida:'}</p>
					<div className='flex flex-col pl-2 space-y-2'>
						<div className='flex flex-row content-center items-center space-x-2'>
							<Input
								value={
									attendance.toleranceAfterExit === undefined ||
									attendance.toleranceAfterExit === null
										? ''
										: attendance.toleranceAfterExit
								}
								className={'flex w-full h-4 !rounded-lg text-left'}
								classNameContent={'w-32 h-4 !rounded-lg'}
								onChange={(e) => handleInput(e)}
								name={'toleranceAfterExit'}
							/>
							<p>{'minutos después del fin del turno'}</p>
						</div>
					</div>
				</div>
			</div>
		);
	};
	return (
		<div className='flex !w-full !h-full flex-col relative '>
			<div className='flex w-full h-[90%] relative  '>
				<div className='flex flex-col inset-0 absolute overflow-auto  p-3 space-y-2'>
					{renderRadioButtonContentRegister()}
					{renderRestrictionContent()}
					{renderRadioButtonContentType()}
					{renderContentStartWork()}
					{renderContentEndWork()}
					{renderContentLimit()}
				</div>
			</div>
			<div className='flex w-full h-[10%] justify-end '>
				<Button
					disabled={Object.values(errors).indexOf(true) !== -1}
					label='Actualizar Datos'
					className='flex !w-64 !h-6 bg-blue-800 text-white'
					onClick={async () => {
						if (Object.values(errors).indexOf(true) === -1) {
							var result = await postCompanySettingAttendance(attendance);
							if (result.status == 200) {
								setTempAttendance(attendance);
								setConfigChange({ ...ConfigChange, AssistChange: false });
							}
						}
					}}
				/>
			</div>
		</div>
	);
};
