import React from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { GeneralNames } from '../../namesConstants/names';

/* eslint-disable react/prop-types */

export const CustomAlertDialog = ({
	title,
	message,
	onCancelFunction,
	onConfirmFunction,
	open,
	setOpen,
	agreeLabel,
	agreeIcon,
	style = {},
}) => {
	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
		if (onCancelFunction !== undefined) {
			onCancelFunction();
		}
	};
	const handleAccept = () => {
		setOpen(false);
		if (onConfirmFunction !== undefined) {
			onConfirmFunction();
		}
	};

	const renderFooter = () => {
		return (
			<div className='flex flex-row w-full h-full mt-10 justify-end'>
				<Button
					className='p-button-text ml-6'
					label={GeneralNames.GeneralCancel}
					icon='pi pi-times'
					onClick={() => handleClose()}
				/>
				<Button
					type='submit'
					label={agreeLabel ? agreeLabel : GeneralNames.GeneralSave}
					icon={agreeIcon ? agreeIcon : 'pi pi-check'}
					onClick={() => handleAccept()}
				/>
			</div>
		);
	};
	return (
		<div>
			<Dialog
				visible={open}
				// TransitionComponent={Transition}
				// keepMounted
				header={title}
				onHide={() => handleClose()}
				aria-describedby='alert-dialog-slide-description'
				style={style}>
				{message}

				{renderFooter()}
			</Dialog>
		</div>
	);
};
