import React, { useContext, useEffect } from 'react';
import { Dialog } from 'primereact/dialog';
import { TableWorkCenterLocation } from './TableWorkCenterLocation';
import { StoreContext } from '../../../../business/Provider';
import { ProgressSpinner } from 'primereact/progressspinner';

export const WorkLocationCollaboratorDialog = ({
	openWorkCenterLocationDialog,
	onClose,
	title,
    isHomeOffice,
	workcenters,
	idPersoniaCollaborator,
	getWorkCenters,
	postWorkCenterLocationCollaboratorsOnOperation
}) => {
    const { loadingState } = useContext(StoreContext);
    const renderLoading = () => {
        return loadingState ? (
            <div className='flex h-full w-full absolute top-0 left-0 right-0 z-50  bg-white opacity-80 justify-center content-center items-center flex-col'>
                <ProgressSpinner mode='indeterminate' className='flex w-full h-8 ' />
            </div>
        ) : null;
    };

	return (
		<Dialog
			header={
				<p className='text-font-size-base text-[#5e5e5e] font-font-family-base font-bold '>
					{title}
				</p>
			}
			visible={openWorkCenterLocationDialog}
			modal
			style={{ width: '60vw', height: '85vh', position: 'relative' }}
			onHide={() => onClose()}>
			{
				<TableWorkCenterLocation postWorkCenterLocationCollaboratorsOnOperation={postWorkCenterLocationCollaboratorsOnOperation} list={workcenters} isHomeOffice={isHomeOffice}  onClose={onClose} idPersoniaCollaborator={idPersoniaCollaborator} getWorkCenters={getWorkCenters}/>
			}
			{renderLoading()}
		</Dialog>
	);
};
