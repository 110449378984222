import {
	SEND_UN_INVITE_SUCCESS,
	SEND_UN_INVITE_ERROR,
	SEND_UN_INVITE,
	SEND_UN_INVITE_ERROR_SHOW,
} from './../../constants';

export const sendUnInviteReducer = (state, actions) => {
	switch (actions.type) {
		case SEND_UN_INVITE:
			return {
				...state,
				loading: true,
			};
		case SEND_UN_INVITE_ERROR:
			return {
				invitations: state.invitations,
				//	collaboratorsOptions: state.collaboratorsOptions,
				loading: false,
				error: actions.payload,
				errorShow: false,
			};
		case SEND_UN_INVITE_SUCCESS:
			return {
				invitations: actions.payload,
				//collaboratorsOptions: getCollaboratorOptions(actions.payload),
				loading: false,
				error: null,
				errorShow: false,
			};
		case SEND_UN_INVITE_ERROR_SHOW:
			return {
				...state,
				errorShow: true,
			};
		default:
			return state;
	}
};
