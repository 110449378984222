import React, { useEffect } from 'react';
import { Input } from '../../../../../components/Input/Input';
import { Checkbox } from 'primereact/checkbox';
import { MultiSelect } from 'primereact/multiselect';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { considerationTypeData, typesIncidencesData } from '../../utils';
import { InputSwitch } from 'primereact/inputswitch';
import PropTypes from 'prop-types';
import { MultiSelectComponentFormik } from '../../../../../components/MultiSelect/MultiSelectComponentFormik';
import { FullLoading } from '../../../../../components/FullLoading';
export const FormComponent = ({
	formikDataObject,
	accumulatedData,
	closeDialog,
	isEdit,
	loadingState,
}) => {
	useEffect(() => {
		if (formikDataObject.values.incidenceType?.id === 3) {
			// Actualiza varios valores a la vez utilizando setValues
			formikDataObject.setValues({
				...formikDataObject.values,
				decreasesSeventhDay: false,
				considerationType: {
					id: 1,
					name: 'Ninguno',
				},
			});
		}
	}, [formikDataObject.values.incidenceType]);

	const renderDropComponent = (
		label,
		value,
		name,
		options,
		optionLabel,
		placeHolder,
		disabled,
		error,
		errorText
	) => {
		return (
			<div className='flex w-1/2 h-full flex-col p-1 '>
				<div className='flex w-full h-full content-center items-center'>
					<label
						className={
							' font-bold text-sm ' +
							(disabled ? 'opacity-50 text-gray-400' : '')
						}>
						{label}
					</label>
				</div>
				<div className='flex w-full h-full content-center items-center justify-start flex-col'>
					<Dropdown
						value={value}
						onChange={formikDataObject.handleChange}
						name={name}
						id={name}
						options={options}
						optionLabel={optionLabel}
						className={
							'!w-full !h-5 !flex ' +
							(error && errorText ? ' !border-red-500' : '')
						}
						placeholder={placeHolder}
						disabled={disabled}
						//	handleBlur={formikDataObject.handleBlur}
					/>
					{error && errorText && (
						<small
							id={`${name}-${errorText}`}
							className='p-error  text-start flex w-full'>
							{errorText}
						</small>
					)}
				</div>
			</div>
		);
	};

	const renderMultiSelect = () => {
		return (
			<MultiSelectComponentFormik
				dataValues={accumulatedData}
				name='accumulatedTypes'
				formikDataObject={formikDataObject}
				value={formikDataObject.values.accumulatedTypes}
				onChange={formikDataObject.handleChange}
				label='acumulados'
				title='Acumulados'
			/>
		);
	};

	const renderCheckbox = (id, name, value, label, onChange, disabled) => {
		return (
			<div className='flex w-1/2 h-full flex-row p-1 content-center items-center'>
				<Checkbox
					inputId={id}
					// value={value}
					name={name}
					id={id}
					onChange={onChange}
					checked={value}
					disabled={disabled}
				/>
				<label
					htmlFor='cb1'
					className={
						'p-checkbox-label ml-1 ' +
						(disabled ? 'opacity-50 text-gray-400' : '')
					}>
					{label}
				</label>
			</div>
		);
	};
	const renderSwitch = (id, name, value, label, onChange) => {
		return (
			<div className='flex w-1/2 h-full flex-row p-1 content-center items-center'>
				<InputSwitch id={id} name={name} checked={value} onChange={onChange} />
				<label htmlFor='cb1' className={'p-checkbox-label ml-1 '}>
					{label}
				</label>
			</div>
		);
	};
	const renderInputText = (
		placeholder,
		value,
		id,
		name,
		error,
		errorText,
		maxLength = null,
		important = false
	) => {
		return (
			<Input
				placeholder={placeholder}
				classNameContent={
					'flex w-1/2 h-full flex-col p-1 ' +
					(isEdit ? 'opacity-50 text-gray-400' : '')
				}
				value={value}
				error={error}
				errorText={errorText}
				onChange={formikDataObject?.handleChange}
				type={'text'}
				id={id}
				name={name}
				important={important}
				//onBlur={formikDataObject?.handleBlur}
				maxLength={maxLength}
				disabled={isEdit}
			/>
		);
	};
	return (
		<form
			onSubmit={formikDataObject.handleSubmit}
			className='flex w-full h-full flex-col relative'>
			<FullLoading loadingState={loadingState} />
			<div className='flex w-full h-20  flex-row'>
				{renderInputText(
					'Clave',
					formikDataObject.values.code,
					'code',
					'code',
					formikDataObject?.touched?.code &&
						Boolean(formikDataObject.errors.code),
					formikDataObject?.touched?.code && formikDataObject.errors.code,
					4,
					true
				)}
				{renderInputText(
					'Nombre',
					formikDataObject.values.name,
					'name',
					'name',
					formikDataObject?.touched?.name &&
						Boolean(formikDataObject.errors.name),
					formikDataObject?.touched?.name && formikDataObject.errors.name,
					null,
					true
				)}
			</div>
			<div className='flex w-full h-20  flex-row'>
				{renderDropComponent(
					'Tipo',
					formikDataObject.values.incidenceType,
					'incidenceType',
					typesIncidencesData,
					'name',
					'seleccione un tipo',
					false,
					formikDataObject?.touched?.incidenceType &&
						Boolean(formikDataObject.errors.incidenceType),
					formikDataObject?.touched?.incidenceType &&
						formikDataObject.errors.incidenceType
				)}
				{renderDropComponent(
					'Se considera',
					formikDataObject.values.considerationType,
					'considerationType',
					considerationTypeData,
					'name',
					'seleccione una opción',
					formikDataObject.values.incidenceType?.id === 3,
					formikDataObject?.touched?.considerationType &&
						Boolean(formikDataObject.errors.considerationType),
					formikDataObject?.touched?.considerationType &&
						formikDataObject.errors.considerationType
				)}
			</div>
			<div className='flex w-full h-12  flex-row'>
				{renderCheckbox(
					'getsSalary',
					'getsSalary',
					formikDataObject.values.getsSalary,
					'Derecho a sueldo',
					formikDataObject.handleChange,
					false
				)}
				{renderCheckbox(
					'decreasesSeventhDay',
					'decreasesSeventhDay',
					formikDataObject.values.decreasesSeventhDay,
					'Disminuye 7mo día',
					formikDataObject.handleChange,
					formikDataObject.values.incidenceType?.id === 3
				)}
			</div>
			<div className='flex w-full h-12  flex-row'>
				{renderSwitch(
					'leaderUse',
					'leaderUse',
					formikDataObject.values.leaderUse,
					'Justificante',
					formikDataObject.handleChange
				)}
			</div>
			<div className='flex w-full h-20  flex-row'>{renderMultiSelect()}</div>
			<div className='flex w-full h-12  flex-row p-1 justify-end'>
				<Button
					label='Cancelar'
					className='!flex !w-28 !h-full !bg-white !text-black !mr-2'
					aria-label='cancel'
					onClick={() => closeDialog()}
				/>
				<Button
					label={isEdit ? 'Editar' : 'Guardar'}
					className='!flex !w-28 !h-full'
					aria-label='Submit'
					type='submit'
					//onClick={() => onSubmitForm()}
				/>
			</div>
		</form>
	);
};

FormComponent.propTypes = {
	formikDataObject: PropTypes.object.isRequired,
};
