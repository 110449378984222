import React from 'react';
import { toast } from 'react-toastify';


// restrict type to primary, secondary, success, info, warning, danger
export const ToastList = (
	type = 'success',
	title,
	subTitle = [],
	error = undefined
) => {
	const container = (
		<div className=''>
			<p className='!text-black !font-bold  '>{title}</p>
			<ul className='!text-black !text-xs flex flex-col text-left mb-[2px]'>
				{
					subTitle.map((x) => { 
						return <li>
							<span className='mr-[2px]'>&#9679;</span>
							<span>{x}</span>
						</li>
					})
				}
			</ul>  
		</div>
	);

	switch (type) {
		case 'success':
			return toast.success(container, {
				position: 'bottom-right',
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				//className: toastSuccess,
				//	theme: 'light',
			});
		case 'warning':
			return toast.warning(container, {
				position: 'bottom-right',
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				//className: toastSuccess,
				//	theme: 'light',
			});
		case 'error':
			return toast.error(container, {
				position: 'bottom-right',
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				//className: toastSuccess,
				//	theme: 'light',
			});
		default:
			return toast.info(container, {
				position: 'bottom-right',
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,

				//	theme: 'light',
			});
	}
};
