import { Dialog } from 'primereact/dialog';
import { ProgressBar } from 'primereact/progressbar';
import React from 'react';

export const DialogProgressBar = ({ showDialog, statusBar }) => {
	const statusNumberBar = {
		0: 0,
		1: 25,
		2: 50,
		3: 75,
		4: 100,
	};

	const statusTextBar = {
		0: 'Procesando...',
		1: 'Inicio de inserción...',
		2: 'Fin de inserción...',
		3: 'Inicio de cálculo...',
		4: 'Fin de cálculo.',
	};
	return (
		<Dialog visible={showDialog} style={{ width: '50vw' }} closable={false}>
			<div className='flex h-16 w-full justify-center items-center content-center flex-col'>
				<div className='flex w-full h-1/2 justify-center content-center items-center font-bold'>
					<p>{statusTextBar[statusBar]}</p>
				</div>
				<ProgressBar
					value={statusNumberBar[statusBar]}
					className='!flex !w-full !h-1/2 !text-black !items-center !content-center'
				/>
			</div>
		</Dialog>
	);
};
