import { useContext } from 'react';
import { StoreContext } from '../../business/Provider';
import useApi from '../api';
import { generateUrlBase } from '../../utils/utils';
import {
	GET_INCIDENCES,
	GET_INCIDENCES_BY_PROCESS_ID,
	GET_INCIDENCES_ERROR,
	GET_INCIDENCES_ERROR_BY_PROCESS_ID,
	GET_INCIDENCES_SUCCESS,
	GET_INCIDENCES_SUCCESS_BY_PROCESS_ID,
	LOADING_OFF,
	LOADING_ON,
	POST_INCIDENCES,
	POST_INCIDENCES_ERROR,
	POST_INCIDENCES_SUCCESS,
} from '../../business/constants';
import { Toast } from '../../components/toast';

export const useIncidences = (filtersData) => {
	const context = useContext(StoreContext);
	const {
		dispatchGetIncidences,
		dispatchLoading,
		dispatchPostIncidences,
		dispatchGetIncidencesByProcessId,
	} = context;
	const { genericApiCall } = useApi();

	const getIncidences = async (params) => {
		const baseUrl = generateUrlBase('operations/incidences');

		dispatchGetIncidences({ type: GET_INCIDENCES });
		dispatchLoading({ type: LOADING_ON });
		try {
			const result = await genericApiCall(baseUrl, 'GET', {}, params, {});

			const payload = result.data;

			if (result.status === 200) {
				dispatchGetIncidences({
					type: GET_INCIDENCES_SUCCESS,
					payload: payload,
				});
				dispatchLoading({ type: LOADING_OFF });
			} else {
				let message = {
					body: '',
					header: 'Error',
				};
				if (result.message) message.body = result.message;
				dispatchGetIncidences({
					type: GET_INCIDENCES_ERROR,
					payload: message,
				});
				dispatchLoading({ type: LOADING_OFF });
			}
			return result;
		} catch (error) {
			dispatchGetIncidences({ type: GET_INCIDENCES_ERROR });
			dispatchLoading({ type: LOADING_OFF });
			Toast('warning', error.message, '', error);
		}
	};

	const getIncidencesByIdProgress = async (idProcess, filters) => {
		const baseUrl = generateUrlBase(
			'operations/incidences/process/' + idProcess
		);

		dispatchGetIncidencesByProcessId({ type: GET_INCIDENCES_BY_PROCESS_ID });
		try {
			const result = await genericApiCall(baseUrl, 'GET', {}, {}, {});
			const payload = result.data;
			if (result.status === 200) {
				dispatchGetIncidencesByProcessId({
					type: GET_INCIDENCES_SUCCESS_BY_PROCESS_ID,
					payload: payload,
				});

				const _filters = filters;

				//Toast('success', 'incidencias obtenidas con éxito', '', '');
				if (payload.data.step !== 4) {
					setTimeout(async () => {
						dispatchGetIncidencesByProcessId({
							type: GET_INCIDENCES_BY_PROCESS_ID,
						});
						return await getIncidencesByIdProgress(idProcess);
					}, 5000);
				} else {
					dispatchLoading({ type: LOADING_OFF });
					// 	 "isError": true,
					// "errorDetail": "Se ha generado un error al momento de insertar las incidencias"

					if (!payload.data.isError) {
						Toast('success', 'incidencias aplicadas con éxito', '', '');
					}
					return await getIncidences(filtersData);
				}
			} else {
				let message = {
					body: '',
					header: 'Error',
				};
				if (result.message) message.body = result.message;
				dispatchGetIncidencesByProcessId({
					type: GET_INCIDENCES_ERROR_BY_PROCESS_ID,
					payload: message,
				});
				dispatchLoading({ type: LOADING_OFF });
			}
			//Toast('success', AlertToastInfoNames.CancelInvitationSuccess, '', '');
			return result;
		} catch (error) {
			dispatchGetIncidencesByProcessId({
				type: GET_INCIDENCES_ERROR_BY_PROCESS_ID,
			});
			dispatchLoading({ type: LOADING_OFF });
			Toast('warning', error.message, '', error);
		}
	};

	const postIncidences = async (params, filters) => {
		const baseUrl = generateUrlBase('operations/incidences/process');

		dispatchPostIncidences({ type: POST_INCIDENCES });
		dispatchLoading({ type: LOADING_ON });
		try {
			const result = await genericApiCall(baseUrl, 'POST', params, {}, {});

			const payload = result.data;

			if (result.status === 200) {
				dispatchPostIncidences({
					type: POST_INCIDENCES_SUCCESS,
					payload: payload,
				});

				return await getIncidencesByIdProgress(payload.data, filters);
			} else {
				let message = {
					body: '',
					header: 'Error',
				};
				console.log(result);
				if (result.message) message.body = result.message;
				dispatchPostIncidences({
					type: POST_INCIDENCES_ERROR,
					payload: message,
				});
				dispatchLoading({ type: LOADING_OFF });
			}
			//Toast('success', AlertToastInfoNames.CancelInvitationSuccess, '', '');
			return result;
		} catch (error) {
			dispatchPostIncidences({ type: POST_INCIDENCES_ERROR });
			dispatchLoading({ type: LOADING_OFF });
			Toast('warning', error.message, '', error);
		}
	};

	return { getIncidences, postIncidences };
};
