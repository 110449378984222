import React, { useState, useEffect, useContext } from 'react';
import { MainContainer } from '../../../components/MainContainer';
import { ContentBody } from './components/ContentBody';
import { Dialog } from 'primereact/dialog';
//import { useState } from 'react';
import { ImportDayOff } from './components/ImportDaysOff';
import { FormAddDaysOff } from './components/FormAddDaysOff';
import months from './utils/months';
import useHolidays from '../../../hooks/catalogs/Holidays/useHolidays';
import useReload from '../../../hooks/useReload';
import DaysOffObject from './../../../Dto/Catalogs/DaysOffObject';
import { StoreContext } from '../../../business/Provider';

import {
	GeneralNames,
	HolidaysScreenNames,
	dayOffScreenNames,
} from '../../../namesConstants/names';
import { CustomAlertDialog } from '../../../components/Dialogs/CustomAlertDialog';
import useWorkCenter from '../../../hooks/catalogs/WorkCenter/useWorkCenter';
import { LOADING_OFF, LOADING_ON } from '../../../business/constants';
import { useFormik } from 'formik';
import DaysOffSchema from '../../../Validations/Catalogs/DaysOffSchema';
import { Toast } from '../../../components/toast';
import { generateArrayToIds } from '../../../utils/utils';
export const Holidays = () => {
	const [selectDate, setSelectDate] = useState(undefined);
	const [openExportDaysOff, setOpenExportDaysOff] = useState(false);
	const [openAddExportDaysOff, setOpenAddExportDaysOff] = useState(false);
	const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
	const [eventToDelete, setEventToDelete] = useState(undefined);
	const [selectYear, setSelectYear] = useState({
		year: new Date().getFullYear(),
	});
	const [isEdit, setEdit] = useState(false);
	const [workCentersFormData, setWorkCentersFormData] = useState([]);
	const [rowSelectedEdit, setRowSelectedEdit] = useState(null);

	const { getHolidayState, getHolidayByYearState, dispatchLoading } =
		useContext(StoreContext);

	const [holidays, setHolidays] = useState([]);
	const [forceUpdateKey, setForceUpdateKey] = useState(0);

	const {
		getDaysOff,
		deleteDaysOff,
		getHolidays,
		importHolidays,
		postDaysOff,
		putDaysOff,
	} = useHolidays(selectYear.year);
	const { getWorkCenters, getWorkCenterState } = useWorkCenter();

	const [importDaysOff, setImportDaysOff] = useState([]);
	const [processImportDaysOff, setProcessImportDaysOff] = useState([]);
	useReload([getDaysOff, getWorkCenters]);

	useEffect(() => {
		dispatchLoading({
			type: getHolidayState.loading ? LOADING_ON : LOADING_OFF,
		});
		if (
			getHolidayState.loading === false &&
			getHolidayState.holidays !== undefined &&
			getHolidayState.holidays !== null
		) {
			//console.log(getHolidayState.holidays?.results);
			setForceUpdateKey((prevKey) => prevKey + 1);
			setHolidays(getHolidayState.holidays?.results);
		}
	}, [getHolidayState]);

	useEffect(() => {
		if (
			getHolidayByYearState.holidays !== null &&
			getHolidayByYearState.holidays.length > 0
		) {
			const holidays = getHolidayByYearState.holidays;
			holidays.map((holiday) => {
				return {
					...holiday,
					selected: false,
				};
			});
			setProcessImportDaysOff(holidays);
		}
	}, [getHolidayByYearState]);

	useEffect(() => {
		if (
			getWorkCenterState.loading === false &&
			getWorkCenterState.workCenters !== undefined &&
			getWorkCenterState.workCenters !== null
		) {
			setWorkCentersFormData(getWorkCenterState.workCenters?.results);
		}
	}, [getWorkCenterState]);

	const onCloseExport = () => {
		setOpenExportDaysOff(false);
		setImportDaysOff([]);
		setProcessImportDaysOff([]);
	};

	const onCloseDialog = () => {
		setOpenAddExportDaysOff(false);
		setEdit(false);
		//setOpenAddExportDaysOff(false);
		//formikDataObject.resetForm();
		formikDataObject.resetForm();
	};

	function openFormDialog(type) {
		const validation = type === 'edit';
		//	console.log('validation', validation);
		setOpenAddExportDaysOff(!openAddExportDaysOff);
		setEdit(validation);
	}

	const onSubmitForm = async () => {
		try {
			return isEdit ? await editDaysOff() : await createDaysOff();
			//console.log('result', result);
		} catch (error) {
			Toast('warning', error);
		}
	};
	const formikDataObject = useFormik({
		initialValues: { ...DaysOffObject },
		validationSchema: DaysOffSchema,
		onSubmit: onSubmitForm,
	});

	const createDaysOff = async () => {
		try {
			const { workCenters } = formikDataObject.values;
			const workCenterArraysString = workCenters.map(
				(workCenter) => workCenter.workCenterId
			);

			formikDataObject.setValues({
				...formikDataObject.values,
				//appliesAllWorkCenter: workCenters.length === workCentersFormData.length,
			});

			let validation = workCenters.length === workCentersFormData.length;

			const values = formikDataObject.values;
			const date = new Date(selectDate);
			const year = date.getFullYear();
			values['year'] = year;
			values['date'] = date;
			values.appliesAllWorkCenter = validation;

			values.workCenters = workCenterArraysString;

			let result = await postDaysOff(values);

			if (result.status === 200) {
				onCloseDialog();
			}
		} catch (error) { }
	};

	function findChanges(arr1, arr2) {
		const addedElements = arr2.filter((item) => !arr1.includes(item));
		const removedElements = arr1.filter((item) => !arr2.includes(item));

		return {
			added: addedElements,
			removed: removedElements,
		};
	}

	const editDaysOff = async () => {
		try {
			let workCentersDelete = [];
			let workCenterAdd = [];
			let workCenterArraysString = [];
			if (rowSelectedEdit.workCenters !== null) {
				const _workCenters = generateArrayToIds(
					formikDataObject.values.workCenters,
					'workCenterId'
				);

				const workCenterValidations = findChanges(
					rowSelectedEdit.workCenters,
					_workCenters
				);
				console.log('workCenterValidations', workCenterValidations);
				workCentersDelete = workCenterValidations.removed;
				workCenterAdd = workCenterValidations.added;
			}

			const { workCenters } = formikDataObject.values;
			workCenterArraysString = generateArrayToIds(workCenters, 'workCenterId');

			let valuesToSend = formikDataObject.values;

			let validationApplies = workCenters.length === workCentersFormData.length;
			valuesToSend.appliesAllWorkCenter = validationApplies;
			valuesToSend.addWorkCenters =
				rowSelectedEdit.workCenters === null
					? workCenterArraysString
					: workCenterAdd;

			valuesToSend.deleteWorkCenters = workCentersDelete;

			let result = await putDaysOff(
				valuesToSend.companyDayOffId,
				formikDataObject.values
			);
			if (result.status === 200) {
				onCloseDialog();
			}
		} catch (error) {
			console.log(error);
			//Toast('Warning', error);
		}
	};

	function onEdit(row) {
		openFormDialog('edit');
		const _workCenters = filterDataByIds(workCentersFormData, row.workCenters);
		setRowSelectedEdit(row);
		formikDataObject.setValues({
			...row,
		});
		formikDataObject.setFieldValue(
			'workCenters',
			row.appliesAllWorkCenter === false ? _workCenters : workCentersFormData
		);
	}

	function onAdd() {
		formikDataObject.setValues({ ...formikDataObject.values });
		setOpenAddExportDaysOff(!openAddExportDaysOff);
	}

	function filterDataByIds(data, idsToFilter) {
		return idsToFilter !== null
			? data.filter((item) => idsToFilter.includes(item.workCenterId))
			: [];
	}

	const dialogDaysOff = () => {
		return (
			<Dialog
				header={
					<p className='text-font-size-base text-[#5e5e5e] font-font-family-base font-bold '>
						{HolidaysScreenNames.LegalHolidayNames}
					</p>
				}
				className='w-[85vw] sm:w-[80vw] md:w-[75vw] lg:w-[50vw] xl:w-[45vw] 2xl:w-[35vw]'
				visible={openExportDaysOff}
				modal
				style={{ height: '85vh', position: 'relative' }}
				onHide={() => onCloseExport()}>
				{
					<ImportDayOff
						onClose={onCloseExport}
						getHolidaysAction={getHolidays}
						importDaysOff={importDaysOff}
						setImportDaysOff={setImportDaysOff}
						importHolidays={importHolidays}
						holidays={holidays}
						selectYear={selectYear}
						processImportDaysOff={processImportDaysOff}
					/>
				}
			</Dialog>
		);
	};

	const dialogAddDaysOff = () => {
		return (
			<Dialog
				header={
					<p className='text-font-size-base text-[#5e5e5e] font-font-family-base font-bold '>
						{`${selectDate !== undefined && selectDate !== null
								? `${selectDate?.getDate()} de ${months[selectDate.getMonth()]}`
								: ''
							}`}
					</p>
				}
				className='sm:w-[70vw] md:w-[70vw] lg:w-[50vw] xl:w-[45vw] 2xl:w-[35vw]'
				visible={openAddExportDaysOff}
				modal
				style={{ height: '50vh', position: 'relative' }}
				onHide={() => onCloseDialog()}>
				{
					<FormAddDaysOff
						onClose={onCloseDialog}
						daysOff={holidays}
						setDaysOff={setHolidays}
						selectDateForm={selectDate}
						selectYear={selectYear}
						getDaysOff={getDaysOff}
						postDaysOff={postDaysOff}
						putDaysOff={putDaysOff}
						getWorkCenters={getWorkCenters}
						getWorkCenterState={getWorkCenterState}
						workCenters={workCentersFormData}
						formikDataObject={formikDataObject}
					/>
				}
			</Dialog>
		);
	};

	const deleteEventDayOff = async () => {
		if (eventToDelete !== undefined) {
			let result = await deleteDaysOff(eventToDelete.companyDayOffId);
			if (result?.status === 200) {
				getDaysOff(selectYear.year);
			}
		}
	};

	const renderAlert = () => {
		return (
			<CustomAlertDialog
				title={dayOffScreenNames.CustomAlertDialogTittleReject}
				setOpen={setOpenDeleteDialog}
				open={openDeleteDialog}
				message={dayOffScreenNames.CustomAlertDialogSuccessMessageDelete}
				cancelLabel={GeneralNames.GeneralCancel}
				agreeLabel={GeneralNames.GeneralAccept}
				agreeIcon='pi pi-check'
				onConfirmFunction={() => {
					deleteEventDayOff();
				}}
				onCancelFunction={() => {
					setOpenDeleteDialog(false);
					setEventToDelete(undefined);
				}}
				style={{ width: '30vw' }}
			/>
		);
	};

	return (
		<MainContainer>
			<ContentBody
				setOpenExportDaysOff={setOpenExportDaysOff}
				openExportDaysOff={openExportDaysOff}
				openAddExportDaysOff={openAddExportDaysOff}
				setOpenAddExportDaysOff={setOpenAddExportDaysOff}
				selectDate={selectDate}
				setSelectDate={setSelectDate}
				holidays={holidays}
				setHolidays={setHolidays}
				selectYear={selectYear}
				setSelectYear={setSelectYear}
				setOpenDeleteDialog={setOpenDeleteDialog}
				setEventToDelete={setEventToDelete}
				getDaysOff={getDaysOff}
				onEditFunction={onEdit}
				onAdd={onAdd}
				openFormDialog={openFormDialog}
				forceUpdateKey={forceUpdateKey}
			/>
			{dialogDaysOff()}
			{dialogAddDaysOff()}
			{renderAlert()}
		</MainContainer>
	);
};
