import {
	RE_IMPORT_DATA,
	RE_IMPORT_DATA_ERROR,
	RE_IMPORT_DATA_SUCCESS,
	RE_IMPORT_DATA_ERROR_SHOW,
} from './../../constants';

export const reImportInfoReducer = (state, actions) => {
	switch (actions.type) {
		case RE_IMPORT_DATA:
			return {
				...state,
				loading: true,
			};
		case RE_IMPORT_DATA_ERROR:
			return {
				invitations: state.invitations,
				//	collaboratorsOptions: state.collaboratorsOptions,
				loading: false,
				error: actions.payload,
				errorShow: false,
			};
		case RE_IMPORT_DATA_SUCCESS:
			return {
				invitations: actions.payload,
				//collaboratorsOptions: getCollaboratorOptions(actions.payload),
				loading: false,
				error: null,
				errorShow: false,
			};
		case RE_IMPORT_DATA_ERROR_SHOW:
			return {
				...state,
				errorShow: true,
			};
		default:
			return state;
	}
};
