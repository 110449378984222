import React from 'react';
import { MainContainer } from '../../../components/MainContainer';
import { SidebarNames } from '../../../namesConstants/names';
import { IconsPanelControl } from '../../../components/IconsPanelControl';
import { SearchComponent } from '../../../components/SearchComponent';
import { HeaderTable } from './components/Table/HeaderTable';
import { TableIncidences } from './components/Table/TableIncidences';
import { DialogProgressBar } from './components/dialogs/DialogProgressBar';
import PropTypes from 'prop-types';

export const SendIncidencesComponent = ({ service }) => {
	const renderHeader = () => {
		return (
			<HeaderTable
				typePeriod={service.typePeriod}
				typePeriods={service.typePeriods}
				period={service.period}
				periods={service.periods}
				handleTypePeriodSelectChange={service.handleTypePeriodSelectChange}
				handlePeriodSelectChange={service.handlePeriodSelectChange}
			/>
		);
	};

	const renderTableHeader = () => {
		return (
			<div className='flex w-full h-full '>
				<div className='flex flex-grow h-full p-1'>
					<SearchComponent
						clearTextLabel={service.clearTextLabel}
						onChange={service.onGlobalFilterChange}
						value={service.globalFilterValue}
					/>
				</div>
				<div className='flex w-[15%] h-full  justify-end items-center pr-1 content-center '>
					<div className='flex w-full h-5'>
						<IconsPanelControl iconsData={service.iconsData} />
					</div>
				</div>
			</div>
		);
	};

	const renderBody = () => {
		return (
			<TableIncidences
				renderTableHeader={renderTableHeader}
				incidences={service.incidences}
				filters={service.filters}
				setFilters={service.setFilters}
				toSend={service.toSend}
				setToSend={service.setToSend}
				sort={service.sort}
				setSort={service.setSort}
				valueInputFilters={service.valueInputFilters}
				setValueInputFilters={service.setValueInputFilters}
			/>
		);
	};

	return (
		<MainContainer
			nameScreen={SidebarNames.SidebarIncidences}
			iNeedFullLoading={false}>
			<div className='flex w-full h-full justify-center items-center content-center relative flex-col'>
				<DialogProgressBar
					showDialog={service.showDialog}
					statusBar={service.statusBar}
				/>
				{renderHeader()}
				{renderBody()}
			</div>
		</MainContainer>
	);
};

SendIncidencesComponent.propTypes = {
	service: PropTypes.object,
};
