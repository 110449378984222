/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect } from 'react';
import { StoreContext } from '../../../business/Provider';
import {
	LOADING_ON,
	LOADING_OFF,
	GET_SCHEDULES_SUCCESS,
	POST_SCHEDULES_SUCCESS,
	PUT_SCHEDULES_SUCCESS,
	GET_SCHEDULES_WORKSHIFT_SUCCESS,
} from '../../../business/constants';
import { generateUrlBase } from '../../../utils/utils';
import useApi from '../../api';
import { Toast } from '../../../components/toast';

const useSchedules = () => {
	const context = useContext(StoreContext);
	const { genericApiCall } = useApi();

	const {
		getScheduleState,
		dispatchLoading,
		dispatchGetSchedule,
		dispatchPostSchedule,
		dispatchPutSchedule,
		dispatchGetScheduleWorkShift,
		getScheduleWorkShiftState,
	} = context;

	const getWorkShift = async () => {
		const baseUrl = generateUrlBase(`work-shift`);
		dispatchLoading({ type: LOADING_ON });
		try {
			const result = await genericApiCall(baseUrl, 'GET', {}, {}, {});
			const payload = result.data;
			if (result.status === 200) {
				dispatchGetScheduleWorkShift({
					type: GET_SCHEDULES_WORKSHIFT_SUCCESS,
					payload: payload,
				});
				dispatchLoading({ type: LOADING_OFF });
			}

			return result;
		} catch (error) {
			Toast('warning', error.message, '', error);
			dispatchLoading({ type: LOADING_OFF });
		}
	};

	const getSchedules = async () => {
		const baseUrl = generateUrlBase('work-shift/detail');
		dispatchLoading({ type: LOADING_ON });
		try {
			const result = await genericApiCall(baseUrl, 'GET', {}, {}, {});
			const payload = result.data;
			if (result.status === 200) {
				dispatchGetSchedule({
					type: GET_SCHEDULES_SUCCESS,
					payload: payload,
				});
				dispatchLoading({ type: LOADING_OFF });
			}
			return result;
		} catch (error) {
			Toast('warning', error.message, '', error);
			dispatchLoading({ type: LOADING_OFF });
		}
	};

	const postSchedules = async (data) => {
		const baseUrl = generateUrlBase('work-shift/detail');
		dispatchLoading({ type: LOADING_ON });
		return genericApiCall(baseUrl, 'POST', data, {}, {})
			.then((response) => {
				if (response.status === 200 || response.status === 201) {
					const payload = response.data;
					Toast('success', 'Se ha creado el horario de forma correcta');
					dispatchPostSchedule({
						type: POST_SCHEDULES_SUCCESS,
						payload: payload,
					});
					dispatchLoading({ type: LOADING_OFF });
					return getSchedules();
				} else if (response.status === 409) {
					dispatchLoading({ type: LOADING_OFF });
					Toast('info', response.data.detail);
				}
			})
			.catch((error) => {
				Toast('warning', error.message, '', error);
				dispatchLoading({ type: LOADING_OFF });
			});
	};

	const putSchedules = async (data, id) => {
		const baseUrl = generateUrlBase(`work-shift/detail/${id}`);
		dispatchLoading({ type: LOADING_ON });
		try {
			const result = await genericApiCall(baseUrl, 'PUT', data, {}, {});
			const payload = result.data;
			if (result.status === 200 || result.status === 201) {
				Toast('success', 'El horario se ha actualizado de forma satisfactoria');
				dispatchPutSchedule({
					type: PUT_SCHEDULES_SUCCESS,
					payload: payload,
				});
				dispatchLoading({ type: LOADING_OFF });

				return getSchedules();
			} else if (result.status === 409) {
				dispatchLoading({ type: LOADING_OFF });

				Toast('info', result.data.detail);
			}
			return result;
		} catch (error) {
			Toast('warning', error.message, '', error);
			dispatchLoading({ type: LOADING_OFF });
		}
	};

	useEffect(() => {
		if (
			getScheduleState.schedules === null &&
			getScheduleState.loading === false
		) {
			getSchedules();
		}
	}, [getScheduleState.schedules]);

	useEffect(() => {
		if (
			getScheduleWorkShiftState.workShifts === null &&
			getScheduleWorkShiftState.loading === false
		) {
			getWorkShift();
		}
	}, [getScheduleWorkShiftState]);

	return {
		getSchedules,
		postSchedules,
		putSchedules,
		getWorkShift,
	};
};

export default useSchedules;
