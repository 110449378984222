import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'primereact/button';
import { Input } from '../../../../components/Input/Input';
import { Dropdown } from 'primereact/dropdown';
import { SelectButton } from 'primereact/selectbutton';

import { Calendar } from 'primereact/calendar';
import './style.css';

import PropTypes from 'prop-types';
import { days } from '../utils';
export const FormAddSchedule = ({
	onClose,
	workShifts,
	isEdit,
	selectedSchedule,
	formikDataObject,
}) => {
	useEffect(() => {
		const { workShiftId } = formikDataObject.values;
		if (
			workShiftId !== undefined &&
			workShiftId !== null &&
			workShiftId !== ''
		) {
			const workShiftSelected = workShifts.filter(
				(workShift) => workShift.workShiftId === workShiftId
			)[0];

			formikDataObject.setValues({
				...formikDataObject.values,
				workShiftType: workShiftSelected?.shiftWorkingDayType,
				hours: workShiftSelected?.hours,
				workShiftName: workShiftSelected?.name,
			});
		}
	}, [formikDataObject.values.workShiftId]);

	const keyAndName = () => {
		return (
			<div className='flex flex-row w-full space-x-1'>
				<div className='w-1/4 flex'>
					<Input
						value={formikDataObject.values.code}
						id='code'
						name={'code'}
						onChange={formikDataObject.handleChange}
						placeholder={'Código: '}
						classNameContent={'w-full flex-col w-full'}
						className={' !rounded-md !text-font-size-base w-full !h-5'}
						error={
							formikDataObject.touched.code &&
							Boolean(formikDataObject.errors.code)
						}
						errorText={formikDataObject.errors.code}
						onBlur={formikDataObject?.handleBlur}
					/>
				</div>
				<div className='w-3/4 flex'>
					<Input
						name={'description'}
						id={'description'}
						onChange={formikDataObject.handleChange}
						value={formikDataObject?.values?.description}
						placeholder={'Descripción:'}
						classNameContent={'w-full flex-col w-full'}
						className={' !rounded-md !text-font-size-base w-full !h-5'}
						error={
							formikDataObject.touched.description &&
							Boolean(formikDataObject.errors.description)
						}
						errorText={formikDataObject.errors.description}
						onBlur={formikDataObject?.handleBlur}
					/>
				</div>
			</div>
		);
	};

	const refStart = useRef();
	const refEnd = useRef();
	const refInputStart = useRef();
	const refInputEnd = useRef();
	function handleKeyPress(event, id) {
		if (id === 'starts') {
			if (event.key === 'Tab') {
				if (event.shiftKey) {
					refInputStart.current.focus();
					refStart.current.hide();
				} else {
					refInputStart.current.focus();
					refStart.current.hide();
					refEnd.current.show();
				}
			}
		}
		if (id === 'ends') {
			if (event.key === 'Tab') {
				if (event.shiftKey) {
					refInputEnd.current.focus();
					refEnd.current.hide();
					refStart.current.show();
				} else {
					refInputEnd.current.focus();
					refEnd.current.hide();
				}
			}
		}
	}
	const timeTypeSchedule = () => {
		return (
			<div className='flex flex-row w-full space-x-1'>
				<div className='flex flex-col w-4/12'>
					<label
						className='text-small-font-size font-semibold flex'
						htmlFor={'workShiftId'}>
						{'Turno de nómina:'}
					</label>
					<Dropdown
						name='workShiftId'
						value={formikDataObject.values.workShiftId}
						options={workShifts}
						optionLabel='name'
						onChange={formikDataObject.handleChange}
						optionValue='workShiftId'
						id='workShiftId'
						className='!border-r !border-border !w-full !rounded-lg !text-font-size-base !h-5'
					/>
					{formikDataObject.touched.workShiftId &&
						Boolean(formikDataObject.errors.workShiftId) ? (
						<small id={`error-workshift`} className='p-error block'>
							{formikDataObject.errors.workShiftId}
						</small>
					) : (
						<></>
					)}
				</div>
				<div className='w-1/12'>
					<Input
						disabled={true}
						name={'hours'}
						value={formikDataObject.values.hours}
						placeholder={'Horas:'}
						classNameContent={'w-full flex-col w-full'}
						className={'!rounded-md !text-font-size-base w-full !h-5'}
					/>
				</div>
				<div className='w-3/12'>
					<Input
						disabled={true}
						value={formikDataObject.values.workShiftName}
						name={'workShiftType'}
						placeholder={'Jornada:'}
						classNameContent={'w-full flex-col w-full'}
						className={' !rounded-md !text-font-size-base w-full !h-5'}
					/>
				</div>
				<div className='flex flex-col w-2/12'>
					<label
						className='text-small-font-size font-semibold flex'
						htmlFor='starts'>
						{'Inicio (24 horas):'}
					</label>
					<Calendar
						value={formikDataObject.values.starts}
						onChange={formikDataObject.handleChange}
						timeOnly
						name='starts'
						id='starts'
						mask='99:99'
						showTime
						className={`!h-5 !rounded-md`}
						ref={refStart}
						inputRef={refInputStart}
						onKeyDown={(e) => {
							handleKeyPress(e, 'starts');
						}}
					/>
					{formikDataObject.touched.starts &&
						Boolean(formikDataObject.errors.starts) ? (
						<small id={`error-workshift`} className='p-error block'>
							{formikDataObject.errors.starts}
						</small>
					) : (
						<></>
					)}
				</div>
				<div className='flex flex-col w-2/12'>
					<label
						className='text-small-font-size font-semibold flex'
						htmlFor={'ends'}>
						{'Fin (24 horas):'}
					</label>
					<Calendar
						value={formikDataObject.values.ends}
						onChange={formikDataObject.handleChange}
						timeOnly
						name='ends'
						id='ends'
						mask='99:99'
						className={`!h-5 !rounded-md`}
						showTime
						ref={refEnd}
						inputRef={refInputEnd}
						onKeyDown={(e) => {
							handleKeyPress(e, 'ends');
						}}
					/>
					{formikDataObject.touched.ends &&
						Boolean(formikDataObject.errors.ends) ? (
						<small id={`error-workshift`} className='p-error block'>
							{formikDataObject.errors.ends}
						</small>
					) : (
						<></>
					)}
				</div>
			</div>
		);
	};

	const renderItemSelectButton = (option) => {
		return (
			<div className='flex w-full h-full  justify-center content-center items-center'>
				<label>{option.name}</label>
			</div>
		);
	};

	const daysSchedule = () => {
		return (
			<div className='flex flex-row w-full'>
				<div className='w-full flex flex-col justify-center items-center content-center '>
					<div className='flex w-full'>
						<label
							className='text-small-font-size font-semibold flex'
							htmlFor={'restDays'}>{`Días de descanso:`}</label>
					</div>

					<div className='flex w-4/5  '>
						<SelectButton
							value={formikDataObject.values.restDays}
							onChange={formikDataObject.handleChange}
							optionLabel={'name'}
							options={days}
							multiple
							className={`!flex b-0 !w-full !justify-between !br-1 !h-5`}
							id='restDays'
							name='restDays'
							itemTemplate={renderItemSelectButton}
						/>
					</div>
				</div>
			</div>
		);
	};

	const form = () => {
		return (
			<div className='w-full h-4/5 flex flex-col  justify-start overflow-auto'>
				{keyAndName()}
				{timeTypeSchedule()}
				{daysSchedule()}
			</div>
		);
	};

	const bottoms = () => {
		return (
			<div className='w-full h-1/5 flex flex-row space-x-2 justify-end items-end'>
				<Button
					type='reset'
					label='Cancelar'
					className='flex !h-4 !w-auto !bg-white  rounded-lg  !border-[#757575] !text-[#757575] '
					onClick={() => {
						onClose();
					}}
					aria-label='cancel'
				/>
				<Button
					label={isEdit ? 'Editar' : 'Guardar'}
					type='submit'
					className='flex !w-auto  !h-4 !bg-secondary-ShadowPCS002 text-white rounded-lg'
					aria-label='Submit'
				/>
			</div>
		);
	};
	return (
		<div className='flex flex-row w-full  !h-full'>
			<div className='flex  w-full !h-full '>
				<form
					onSubmit={formikDataObject.handleSubmit}
					className='flex w-full h-full flex-col'>
					{form()}
					{bottoms()}
				</form>
			</div>
		</div>
	);
};
FormAddSchedule.propTypes = {
	formikDataObject: PropTypes.object.isRequired,
	onClose: PropTypes.func.isRequired,
	workShifts: PropTypes.arrayOf(PropTypes.any).isRequired,
	isEdit: PropTypes.bool.isRequired,
};
