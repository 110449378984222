import { LOADING_ON, LOADING_OFF } from '../constants';

export const loading = (state = false, actions) => {
	switch (actions.type) {
		case LOADING_ON:
			return true;
		case LOADING_OFF:
			return false;
		default:
			return state;
	}
};
