export const incidencesObject = {
	id: null,
	leaderUse: false,
	code: '',
	name: '',
	incidenceType: null,
	considerationType: null,
	getsSalary: false,
	decreasesSeventhDay: false,
	accumulatedTypes: [],
};
