import {
	PUT_SCHEDULES,
	PUT_SCHEDULES_ERROR,
	PUT_SCHEDULES_SUCCESS,
} from '../../../constants';

export const putSchedulesReducer = (state, actions) => {
	switch (actions.type) {
		case PUT_SCHEDULES:
			return {
				...state,
				loading: true,
				error: null,
				errorShow: false,
			};
		case PUT_SCHEDULES_ERROR:
			return {
				...state,
				loading: false,
				error: actions.payload,
				errorShow: true,
			};
		case PUT_SCHEDULES_SUCCESS:
			return {
				...state,
				loading: false,
				error: null,
				errorShow: false,
				schedules: actions.payload,
			};
		default:
			return state;
	}
};
