import { createContext, useContext, useEffect, useState } from 'react';
import { StoreContext } from '../../../business/Provider';
import { EmployeeManagementFilterContext } from '../../../context/wrapperContext/queries/EmployeeManagementFilterContext';
import useOperation from '../../../hooks/catalogs/Operation/useOperation';
export const EmployeeMaintenanceServiceContext = createContext();

export const EmployeeMaintenanceServiceManagerProvider = ({ children }) => {
	const {
		getCompanySettings,
		postWorkShiftScheduleContextState,
		getDataFilterOperationContextState,
	} = useContext(StoreContext);

	const filterManager = useContext(EmployeeManagementFilterContext);
	const { stateGetDataFiltersOperations } = getDataFilterOperationContextState;
	const { statePostWorkShiftSchedule, postWorkShiftScheduleClear_DP } =
		postWorkShiftScheduleContextState;

	useEffect(() => {
		if (statePostWorkShiftSchedule.data !== null) {
			const _data = statePostWorkShiftSchedule.data.map((data) => {
				return {
					error: data,
				};
			});
			setErrorDataSchedule(_data);
			setOpenDialogErrors(true);
		}
	}, [statePostWorkShiftSchedule.data]);
	const [openDialogErrors, setOpenDialogErrors] = useState(false);
	const [errorDataSchedule, setErrorDataSchedule] = useState([]);

	function onCloseErrorDialog() {
		setOpenDialogErrors(false);
		setErrorDataSchedule([]);
		postWorkShiftScheduleClear_DP();
	}

	const { dataFilterOperations } = useOperation();

	useEffect(() => {
		if (
			stateGetDataFiltersOperations.data !== null &&
			stateGetDataFiltersOperations.loading === false
		) {
			const { data } = stateGetDataFiltersOperations;

			filterManager.setPeriodsTypesData(data?.periodType);
			filterManager.setDepartmentsData(data?.department);
			filterManager.setPositionsData(data?.position);
			filterManager.setShiftData(data?.shift);
			filterManager.setWorkCenterData(data?.workCenter);
			filterManager.setActualLeaderData(data?.actualLeader);
			filterManager.setCollaboratorData(data?.employee);
		}
	}, [stateGetDataFiltersOperations.data]);

	useEffect(() => {
		console.log(
			'getDataFilterOperationError_DP',
			stateGetDataFiltersOperations
		);
		if (
			getCompanySettings.companies !== null &&
			stateGetDataFiltersOperations.data === null &&
			stateGetDataFiltersOperations.loading === false &&
			stateGetDataFiltersOperations.error === null
		) {
			const { attendanceImportDetail } = getCompanySettings?.companies;
			const data = JSON.parse(attendanceImportDetail);
			if (
				data.OrgChart &&
				stateGetDataFiltersOperations?.isLoadData === false
			) {
				dataFilterOperations();
			}
		}

		// dataFilterOperations();
	}, [stateGetDataFiltersOperations, getCompanySettings]);

	return (
		<EmployeeMaintenanceServiceContext.Provider
			key={'Employee Maintenance'}
			displayName='Employee Maintenance'
			value={{ openDialogErrors, errorDataSchedule, onCloseErrorDialog }}>
			{children}
		</EmployeeMaintenanceServiceContext.Provider>
	);
};
