import {
	POST_COMPANY_SETTINGS_INCIDENTS,
	POST_COMPANY_SETTINGS_INCIDENTS_ERROR,
    POST_COMPANY_SETTINGS_INCIDENTS_SUCCESS,
} from '../../constants';

export const postCompanyIncidentReducer = (state, actions) => {
	switch (actions.type) {
		case POST_COMPANY_SETTINGS_INCIDENTS:
			return {
				...state,
				loading: true,
				error: null,
				errorShow: false,
			};
		case POST_COMPANY_SETTINGS_INCIDENTS_ERROR:
			return {
				...state,
				loading: false,
				error: actions.payload,
				errorShow: true,
			};
		case POST_COMPANY_SETTINGS_INCIDENTS_SUCCESS:
			return {
				...state,
				loading: false,
				error: null,
				errorShow: false,
				companies: actions.payload,
			};
		default:
			return state;
	}
};
