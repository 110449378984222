import React, { useState, useContext } from 'react';

import { CustomTable } from './../../components/Table/CustomTable';
import { Column } from 'primereact/column';
import { FilterMatchMode } from 'primereact/api';

import { ToolBarThreeColumns } from './../../components/Table/ToolBarThreeColumns';
import data from './data.json';
import { initialStateTable } from '../Invitations/const';
import { headerClassName } from '../../assets/customStyles';
export const AssistScreen = () => {
	const [sort, setSort] = useState([{ field: '', order: -1 }]);
	const [selectedRows, setSelectedRows] = useState(null);
	const [filters, setFilters] = useState(initialStateTable);

	const [globalFilterValue, setGlobalFilterValue] = useState('');
	const [startDateValue, setStartDateValue] = useState(new Date());
	const [endDateValue, setEndDateValue] = useState(new Date());

	const [selectFilter, setSelectFilter] = useState({
		status: 0,
	});

	const [filterOpc, setFilterOpc] = useState({
		status: [
			{
				label: 'Todos',
				value: 0,
			},
			{
				label: 'Respondido',
				value: 1,
			},
			{
				label: 'Cancelado',
				value: 2,
			},
			{
				label: 'Enviado',
				value: 3,
			},
			{
				label: 'Completado',
				value: 4,
			},
		],
	});

	const onGlobalFilterChange = (e) => {
		const { value } = e.target;
		const _filters = { ...filters };
		_filters.global.value = value;
		setFilters(_filters);
		setGlobalFilterValue(value);
	};

	const handleFilterChange = (e) => {
		const { name, value } = e.target;
		setSelectFilter({
			...selectFilter,
			[name]: value,
		});
	};

	const clearTextLabel = () => {
		setGlobalFilterValue('');
		setFilters({
			global: { value: null, matchMode: FilterMatchMode.CONTAINS },
		});
	};

	const renderHeader = () => (
		<ToolBarThreeColumns
			value={globalFilterValue}
			setValue={setGlobalFilterValue}
			setFilters={setFilters}
			onChange={onGlobalFilterChange}
			placeholder={'Buscar'}
			filterOpc={filterOpc}
			selectFilter={selectFilter}
			handleFilterChange={handleFilterChange}
			startDateValue={startDateValue}
			endDateValue={endDateValue}
			setStartDateValue={setStartDateValue}
			setEndDateValue={setEndDateValue}
			clearTextLabel={clearTextLabel}
			iNeedEndDate
			iNeedStartDate
		/>
	);

	const renderDataTable = () => {
		const bodyClassName =
			'!border-r-2 !border-b-0 !border-secondary-PCS004 !text-secondary-PCS001';
		return (
			<CustomTable
				value={data}
				loading={false}
				header={renderHeader()}
				selection={selectedRows}
				onSelectionChange={(e) => setSelectedRows(e.value)}
				// onRowDoubleClick={(e) => {
				// 	goToRanges(e.data);
				// }}
				filters={filters}
				responsiveLayout='scroll'
				emptyMessage={'No hay datos para mostrar'}
				currentPageReportTemplate={
					'Mostrando {first} a {last} de {totalRecords} Asistencias'
				}
				style={{ height: '100%', width: '100%' }}
				setSort={setSort}
				sort={sort}>
				<Column
					bodyClassName={bodyClassName}
					headerClassName={headerClassName}
					style={{ flexGrow: 1, flexBasis: '100px' }}
					field='code'
					header={'Código'}
					sortable
				/>
				<Column
					headerClassName={headerClassName}
					style={{ flexGrow: 1, flexBasis: '100px' }}
					field='nameCollaborator'
					header={'Colaborador'}
					sortable
					bodyClassName={bodyClassName}
				/>
				<Column
					headerClassName={headerClassName}
					style={{ flexGrow: 1, flexBasis: '100px' }}
					field='nameLeader'
					header={'Líder'}
					sortable
					bodyClassName={bodyClassName}
					//align='center'
				/>
				<Column
					headerClassName={headerClassName}
					style={{ flexGrow: 1, flexBasis: '100px' }}
					field='typeAssist'
					header={'Tipo'}
					sortable
					bodyClassName={bodyClassName}
					//align='center'
				/>
				<Column
					headerClassName={headerClassName}
					style={{ flexGrow: 1, flexBasis: '100px' }}
					field='dateAssist'
					header={'Fecha'}
					sortable
					bodyClassName={bodyClassName}
					//	align='center'
				/>
				<Column
					headerClassName={headerClassName}
					style={{ flexGrow: 1, flexBasis: '100px' }}
					field='timeAssist'
					header={'Valor'}
					sortable
					bodyClassName={bodyClassName}
					//align='center'
				/>
				<Column
					headerClassName={headerClassName}
					style={{ flexGrow: 1, flexBasis: '100px' }}
					field='statusAssist'
					header={'Status'}
					sortable
					bodyClassName={bodyClassName}
					//align='center'
				/>
			</CustomTable>
		);
	};
	return (
		<div className='flex w-full h-full relative flex-col'>
			<div className='flex w-full h-[5%] justify-center content-center items-center p-1'>
				<div className='flex justify-start content-center items-center w-full h-full rounded-md'>
					<h1 className='text-2xl font-bold'>{'Asistencias'}</h1>
				</div>
			</div>
			<div className='flex w-full h-[95%] relative  '>
				<div className='flex w-full h-full content-center items-center justify-center flex-row '>
					{renderDataTable()}
				</div>
			</div>
		</div>
	);
};
