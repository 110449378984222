import { LOADING_ON_MESSAGE } from '../../constants';
import { LOADING_OFF_MESSAGE } from './../../constants';

export const loadingWhitMessageRed = (state, actions) => {
	switch (actions.type) {
		case LOADING_ON_MESSAGE:
			return {
				...state,
				loading: true,
				message: actions.payload,
			};
		case LOADING_OFF_MESSAGE:
			return {
				...state,
				loading: false,
				message: '',
			};

		default:
			return state;
	}
};
