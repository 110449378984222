import React from 'react';
import { IoMdAddCircleOutline, IoMdCreate } from 'react-icons/io';
import { regexName } from '../../utils/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faFileExcel,
	faSearch,
	faXmark,
} from '@fortawesome/free-solid-svg-icons';
import { GeneralNames } from '../../namesConstants/names';
export const ToolbarTwoColumns = ({
	onChange,
	placeholder,
	value = '',
	onClickAction = () => {},
	clearTextLabel = () => {},
	type = 'Add',
	hidden = false,
}) => {
	const renderIcono = (tipo) => {
		switch (tipo) {
			case 'Edit':
				return <IoMdCreate className='text-sm text-white' title='Editar' />;
			case 'Export':
				return (
					<FontAwesomeIcon
						size='sm'
						className='text-white flex justify-center content-center items-center'
						icon={faFileExcel}
						title={GeneralNames.GeneralExportToExcel}
					/>
				);
			default:
				return (
					<IoMdAddCircleOutline
						className='text-2xl text-white'
						title='Agregar'
					/>
				);
		}
	};

	return (
		<div className='flex w-full h-full flex-row  '>
			<div
				className={`flex flex-grow h-full  p-1 pl-4 ${hidden ? 'pr-4' : ''}`}>
				<div className='flex-row flex w-full h-full !rounded-none'>
					<div className='flex flex-grow  border-2 border-primary-ShadowPCP004 rounded-l-lg'>
						<input
							className='flex h-full w-full rounded-l-lg !border-0 ml-1 !bg-none'
							type='text'
							maxLength={80}
							pattern={regexName}
							value={value}
							onChange={onChange}
							placeholder={placeholder}
						/>
						<button
							className={`w-4 h-full ${value.length === 0 ? 'hidden' : ''}`}
							onClick={() => clearTextLabel()}>
							<FontAwesomeIcon
								size='sm'
								className='pi text-black text-center hover:text-gray-400'
								icon={faXmark}
							/>
						</button>
					</div>
					<div className='flex h-full w-8 bg-primary-ShadowPCP004 justify-center items-center rounded-r-lg'>
						<FontAwesomeIcon
							className='pi text-white text-center'
							icon={faSearch}
						/>
					</div>
				</div>
			</div>
			<div
				className={`flex w-[10%] h-full justify-center content-center items-center pt-1 pb-1 pr-1  ${
					hidden ? 'hidden' : 'visible'
				}`}>
				<div className='flex w-full h-full bg-primary-ShadowPCP004 rounded-md justify-end items-center content-center'>
					<div
						onClick={onClickAction}
						className='flex w-3 h-3 border rounded-full mr-1 justify-center content-center items-center text-white cursor-pointer '>
						{renderIcono(type)}
					</div>
				</div>
			</div>
		</div>
	);
};
