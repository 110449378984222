import React from 'react';
import { GridContentCalendar } from './GridContentCalendar';
import { HeaderComponent } from './HeaderComponent';
import { InfoDate } from './InfoDate';
export const ContentBody = ({
	setOpenExportDaysOff,
	openExportDaysOff,
	openAddExportDaysOff,
	setOpenAddExportDaysOff,
	selectDate,
	setSelectDate,
	holidays,
	//setHolidays,
	selectYear,
	setSelectYear,
	setOpenDeleteDialog,
	setEventToDelete,
	getDaysOff,
	onEditFunction,
	openFormDialog,
	onAdd,
	forceUpdateKey,
}) => {
	const bgHolidays = '!bg-[#FF8585]';
	const bgCompanyDate = '!bg-[#F7E195]';

	return (
		<div className='flex flex-col h-full w-full pl-2 absolute '>
			<HeaderComponent
				selectYear={selectYear}
				setSelectYear={setSelectYear}
				setOpenExportDaysOff={setOpenExportDaysOff}
				openExportDaysOff={openExportDaysOff}
				getDaysOff={getDaysOff}
			/>
			<div className='flex flex-row h-full w-full overflow-auto'>
				<div className='flex flex-col sm:w-[40%] md:w-[40%] lg:w-[30%] xl:w-[30%] 2xl:w-[25%]'>
					<InfoDate
						bgHolidays={bgHolidays}
						bgCompanyDate={bgCompanyDate}
						holidays={holidays}
						selectDate={selectDate}
						setSelectDate={setSelectDate}
						setOpenDeleteDialog={setOpenDeleteDialog}
						setEventToDelete={setEventToDelete}
						setOpenAddExportDaysOff={setOpenAddExportDaysOff}
						onEditFunction={onEditFunction}
					/>
				</div>
				<div className='flex flex-col  sm:w-[60%] md:w-[60%] lg:w-[70%] xl:w-[70%] 2xl:w-[75%] space-y-2'>
					<GridContentCalendar
						holidays={holidays}
						selectYear={selectYear}
						bgHolidays={bgHolidays}
						bgCompanyDate={bgCompanyDate}
						openAddExportDaysOff={openAddExportDaysOff}
						setOpenAddExportDaysOff={setOpenAddExportDaysOff}
						selectDate={selectDate}
						setSelectDate={setSelectDate}
						openFormDialog={openFormDialog}
						onEditFunction={onEditFunction}
						onAdd={onAdd}
						forceUpdateKey={forceUpdateKey}
					/>
				</div>
			</div>
		</div>
	);
};
