import { useReducer } from 'react';
import { actionTypesOperations } from '../../../business/constans/operation';
import {
	getDataFiltersOperation_IS,
	getDataFiltersOperationRed,
} from '../../../business/reducers/catalogs/operation/getFiltersOperationsRed';

export const useOperationState = () => {
	const { GET_FILTER_OPERATION } = actionTypesOperations;
	const [stateGetDataFiltersOperations, dispatch] = useReducer(
		getDataFiltersOperationRed,
		getDataFiltersOperation_IS
	);
	const getDataFilterOperation_DP = () => {
		return dispatch({ type: GET_FILTER_OPERATION.BASE });
	};

	const getDataFilterOperationSuccess_DP = (data) => {
		return dispatch({ type: GET_FILTER_OPERATION.SUCCESS, payload: data });
	};
	const getDataFilterOperationError_DP = (data) => {
		return dispatch({ type: GET_FILTER_OPERATION.ERROR, payload: data });
	};

	const getDataFilterOperationClear_DP = () => {
		return dispatch({ type: GET_FILTER_OPERATION.CLEAR });
	};
	return {
		stateGetDataFiltersOperations,
		getDataFilterOperation_DP,
		getDataFilterOperationSuccess_DP,
		getDataFilterOperationError_DP,
		getDataFilterOperationClear_DP,
	};
};
