import React, { useState } from 'react';
import { Column } from 'primereact/column';
//components
import { CustomTable } from '../../../components/Table/CustomTable';
//utils and constants
import { StatusArrayValuesTable } from '../../../utils/utils';
import {
	GeneralNames,
	InvitationsScreenNames,
	TableNames,
} from '../../../namesConstants/names';
import { headerClassName } from '../../../assets/customStyles';

export const TableModalComponent = ({
	filtersList,
	typeInvitation,
	renderHeader,
	//loading,
	filters,
	toSend,
	setToSend,
	setShowCloseDialog,
	setDialogContent,
	sendInvitationFunctionAction,
	sendUnInviteFunctionAction,
	loading,
}) => {
	const [sort, setSort] = useState([{ field: 'name', order: 1 }]);
	const bodyClassName =
		'!border-r-2 !border-b-0 !border-secondary-PCS004 !text-black';
	return (
		<>
			{typeInvitation === 'invitation' ? (
				<CustomTable
					sortField='name'
					value={filtersList}
					loading={loading}
					header={undefined}
					style={{ height: '100%', width: '100%', overflow: 'auto' }}
					//loading={loading}
					filters={filters}
					responsiveLayout='scroll'
					globalFilterFields={[
						'name',
						'firstLastName',
						'secondLastName',
						'code',
						'email',
						'statusInvitation',
						'formattedCode',
						'fullName',
						'statusText',
					]}
					emptyMessage={GeneralNames.GeneralEmptyMessage}
					currentPageReportTemplate={
						InvitationsScreenNames.CurrentPageReportTemplate
					}
					resizableColumns
					columnResizeMode='fit'
					setSort={setSort}
					sort={sort}>
					<Column
						headerClassName={headerClassName}
						style={{ flexGrow: 4, flexBasis: '100px' }}
						field='fullName'
						header={TableNames.TableFirstName}
						sortable
						bodyClassName={bodyClassName}
						body={(rowData) => {
							return rowData?.fullName;
						}}
					/>
					<Column
						headerClassName={headerClassName}
						style={{ flexGrow: 4, flexBasis: '100px' }}
						field='error'
						header={TableNames.TableErrorName}
						sortable
						bodyClassName={bodyClassName}
						body={(rowData) => {
							return rowData?.error;
						}}
					/>
				</CustomTable>
			) : (
				<CustomTable
					sortField='name'
					value={filtersList}
					loading={loading}
					header={undefined}
					style={{ height: '100%', width: '100%', overflow: 'auto' }}
					//loading={loading}
					filters={filters}
					responsiveLayout='scroll'
					globalFilterFields={[
						'name',
						'firstLastName',
						'secondLastName',
						'code',
						'email',
						'statusInvitation',
						'formattedCode',
						'fullName',
						'statusText',
					]}
					emptyMessage={GeneralNames.GeneralEmptyMessage}
					currentPageReportTemplate={
						InvitationsScreenNames.CurrentPageReportTemplate
					}
					resizableColumns
					columnResizeMode='fit'
					setSort={setSort}
					sort={sort}>
					<Column
						headerClassName={headerClassName}
						style={{ flexGrow: 1, flexBasis: '30px' }}
						field='code'
						header={TableNames.TableCode}
						sortable
						bodyClassName={bodyClassName}
						body={(rowData) => {
							return rowData?.code;
						}}
					/>

					<Column
						headerClassName={headerClassName}
						style={{ flexGrow: 4, flexBasis: '100px' }}
						field='fullName'
						header={TableNames.TableFirstName}
						sortable
						bodyClassName={bodyClassName}
						body={(rowData) => {
							return rowData?.fullName;
						}}
					/>
					<Column
						headerClassName={headerClassName}
						style={{ flexGrow: 4, flexBasis: '80px' }}
						field='email'
						header={TableNames.TableEmail}
						sortable
						bodyClassName={bodyClassName}
						body={(rowData) => {
							return rowData?.email;
						}}
					/>
					<Column
						headerClassName={headerClassName}
						style={{ flexGrow: 3, flexBasis: '70px' }}
						field='invitationStatus'
						header={TableNames.TableStatusInvitation}
						sortable
						bodyClassName={bodyClassName}
						body={(rowData) => {
							const filter = StatusArrayValuesTable.filter(
								(item) => item.value === rowData?.invitationStatus
							)[0]?.label;
							return rowData?.email === null || rowData?.email === ''
								? 'No disponible'
								: filter;
						}}
					/>
				</CustomTable>
			)}
		</>
	);
};
