import React, { useEffect } from 'react';
import useCompanies from '../../hooks/company/useCompany';

export const CompanyInfo = () => {
	const { companyInfo } = useCompanies();

	return (
		<>
			<div
				// ref={refCloseCompanyInfo}
				className='flex flex-col h-full w-full  text-secondary-contrast-color-text justify-center !text-secondary-PCS002'>
				<div className='flex w-full  text-sm  !font-bold'>
					{/* {currentCompany ? currentCompany.alias : ''} */}
					{companyInfo?.rfc}
				</div>
				<div className='flex w-full  text-sm tracking-wider !text-black font-semibold'>
					{companyInfo?.name}
				</div>

				<div className='flex w-full  text-[10px] !text-black'>
					{/* {currentCompany ? currentCompany.alias : ''} */}
					{companyInfo?.alias}
				</div>
			</div>
		</>
	);
};
