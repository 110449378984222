import * as yup from 'yup';

const DaysOffSchema = yup.object().shape({
	year:  yup.number(undefined),
	date: yup.string(''),
	officialDay: yup.bool(true),
	appliesAllWorkCenter: yup.bool(false),
	description: yup.string('').required('Descripción requerida'),
	workCenters: yup.array(undefined),
	addWorkCenters: yup.array(undefined),
	deleteWorkCenters: yup.array(undefined),
});


export default DaysOffSchema;
