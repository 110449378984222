import React from 'react';
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
export const MapContent = ({ zoom, children, centerCoordinate }) => {
	const [mapInstance, setMapInstance] = React.useState(null);
	const containerStyle = {
		width: '100%',
		height: '100%',
	};

	const center = {
		lat: -3.745,
		lng: -38.523,
	};
	const libraries = ['drawing', 'places'];
	const { isLoaded } = useJsApiLoader({
		id: 'google-map-script',
		googleMapsApiKey: 'AIzaSyC7dbcuzdaxgodiB9nT8UpO1Z8i6xYThnM',
		//	libraries: libraries,
		libraries: 'drawing' | 'places',
	});

	return isLoaded ? (
		<GoogleMap
			mapContainerStyle={containerStyle}
			center={centerCoordinate === null ? center : centerCoordinate}
			zoom={zoom}
			onLoad={(map) => setTimeout(() => setMapInstance(map))}
			options={{
				draggableCursor: 'default',
				draggingCursor: 'pointer',
				streetViewControl: true,
				disableDefaultUI: true,
			}}
			//onLoad={onLoad}
			//onUnmount={onUnmount}
		>
			{/* Child components, such as markers, info windows, etc. */}
			{mapInstance && children}
		</GoogleMap>
	) : (
		<></>
	);
};
