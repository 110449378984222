import React from 'react';
import personiaLogo from '../../assets/img/Logo_CONTPAQi_Colabora.png';
import { MainContainer } from '../../components/MainContainer';

export const HomeScreen = () => {
	return (
		<MainContainer>
			<div className='flex w-full h-full justify-center items-center content-center relative'>
				<img
					className=' bg-center opacity-20 object-contain  w-[20%] absolute  '
					src={personiaLogo}
					alt='Logo'></img>
			</div>
		</MainContainer>
	);
};
