export const BodyWorkShiftTemplate = ({ row, openDialogSingle }) => {
	return (
		<div
			className='flex w-full h-full'
			onClick={() => {
				openDialogSingle(row);
			}}>
			<p>{row.shift}</p>
		</div>
	);
};
