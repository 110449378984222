import React from 'react';

import CustomDropdown from './../Input/Dropdown';
import CustomDatePicker from './../Calendar/CustomDatePicker';
import { GeneralNames } from '../../namesConstants/names';
import { regexName } from '../../utils/utils';

import { IconsPanelControl } from './../IconsPanelControl/index';
import { SearchComponent } from '../SearchComponent';

export const ToolBarThreeColumns = ({
	placeholder,
	onChange,
	value,
	filterOpc,
	filterOpcEmployee,
	handleFilterChange,
	handleFilterEmployee,
	selectFilter,
	selectFilterEmployee,
	iNeedStartDate,
	iNeedEndDate,
	startDate,
	endDate,
	setStartDate,
	setEndDate,
	placeholderNameAdditional = '',
	clearTextLabel = () => { },
	iconsDataPanel = [],
}) => {
	const setStartDatePicker = (e) => {
		setStartDate(e.target.value);
	};

	const setEndDatePicker = (e) => {
		setEndDate(e.target.value);
	};

	const renderStartAndEndDate = () => {
		return (
			iNeedStartDate && (
				<div className='flex h-full w-full gap-x-1'>
					{
						<div className='flex h-full w-1/2'>
							<CustomDatePicker
								//	today={null}
								value={startDate}
								setValue={setStartDatePicker}
								label={
									placeholderNameAdditional !== ''
										? GeneralNames.GeneralStartDate +
										' ' +
										placeholderNameAdditional
										: GeneralNames.GeneralStartDate
								}
								name={'searchStartDate'}
								showIcon
								labelClassName='text-small-font-size font-semibold font-input-font-family'
							//maxDate={endDate}
							//size={12}
							/>
						</div>
					}
					{iNeedEndDate && (
						<div className='flex h-full w-1/2 '>
							<CustomDatePicker
								value={endDate}
								setValue={setEndDatePicker}
								label={
									placeholderNameAdditional !== ''
										? GeneralNames.GeneralEndDate +
										' ' +
										placeholderNameAdditional
										: GeneralNames.GeneralEndDate
								}
								name={'searchEndDate'}
								showIcon
								labelClassName='text-small-font-size font-semibold font-input-font-family'
								minDateField={startDate}
							//size={12}
							/>
						</div>
					)}
				</div>
			)
		);
	};

	const renderFilter = () => {
		return (
			<div className='flex w-full h-full gap-x-1'>
				{filterOpcEmployee !== undefined ? (
					<div className='flex w-1/3 h-full'>
						<CustomDropdown
							name='statusEmployee'
							value={selectFilterEmployee?.statusEmployee}
							handler={handleFilterEmployee}
							options={filterOpcEmployee}
							optionLabel='label'
							optionValue='value'
							className='!border-r !border-border flex-grow'
						/>
					</div>
				) : null}

				<div className='flex w-1/3 h-full'>
					<CustomDropdown
						name="status"
						value={selectFilter?.status}
						handler={handleFilterChange}
						options={filterOpc}
						optionLabel='label'
						optionValue='value'
						className='!border-r !border-border flex-grow'
					/>
				</div>
				<div className="flex h-full w-2/3">{renderStartAndEndDate()}</div>
			</div>
		);
	};

	const renderToolBarWeb = () => {
		return (
			<div className='hidden lg:flex xl:flex 2xl:flex w-full h-full'>
				<div className='flex w-1/5 h-full p-1'>
					<SearchComponent
						placeholder={placeholder}
						value={value}
						regexName={regexName}
						onChange={onChange}
						clearTextLabel={clearTextLabel}
					/>
				</div>
				<div className='flex w-3/5 h-full p-1 '>{renderFilter()}</div>
				<div className='flex w-1/5 h-full p-1'>
					<IconsPanelControl iconsData={iconsDataPanel} />
				</div>
			</div>
		);
	};

	const renderToolbarMobile = () => {
		return (
			<div className='flex lg:hidden xl:hidden 2xl:hidden flex-wrap'>
				<div className='flex w-full md:w-1/2 h-6 p-1'>
					<SearchComponent
						placeholder={placeholder}
						value={value}
						regexName={regexName}
						onChange={onChange}
						clearTextLabel={clearTextLabel}
					/>
				</div>
				<div className='flex w-full md:w-1/2  h-6 gap-x-1'>
					<div className='flex w-full h-full p-1  '>
						<CustomDropdown
							name='status'
							value={selectFilter?.status}
							handler={handleFilterChange}
							options={filterOpc}
							optionLabel='label'
							optionValue='value'
							className='!border-r !border-border flex-grow   '
						/>
					</div>
				</div>
				{iNeedStartDate && (
					<div className='flex w-full md:w-1/2  h-6 gap-x-1'>
						<div className='flex h-full w-full p-1'>
							<CustomDatePicker
								value={startDate}
								setValue={setStartDatePicker}
								label={GeneralNames.GeneralStartDate}
								name={'searchStartDate'}
								showIcon
								labelClassName='text-small-font-size font-semibold font-input-font-family'
								maxDate={endDate}
							//size={12}
							/>
						</div>
					</div>
				)}
				{iNeedEndDate && (
					<div className='flex w-full md:w-1/2  h-6 gap-x-1 '>
						<div className='flex h-full w-full p-1  '>
							<CustomDatePicker
								value={endDate}
								setValue={setEndDatePicker}
								label={GeneralNames.GeneralEndDate}
								name={'searchEndDate'}
								showIcon
								labelClassName='text-small-font-size font-semibold font-input-font-family'
								minDateField={startDate}
							//size={12}
							/>
						</div>
					</div>
				)}
				<div className='flex w-full md:w-1/2 h-6 p-1'>
					<IconsPanelControl iconsData={iconsDataPanel} />
				</div>
			</div>
		);
	};

	return (
		<>
			{renderToolBarWeb()}
			{renderToolbarMobile()}
		</>
	);
};

export default ToolBarThreeColumns;
