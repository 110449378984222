export const LOADING_ON = 'LOADING_ON';
export const LOADING_OFF = 'LOADING_OFF';
export const ERROR_SHOW = 'ERROR_SHOW';
export const CLEAR = 'CLEAR';
export const FILTER = 'FILTER';

//Collaborators constants

export const GET_COLLABORATORS = 'GET_COLLABORATORS';
export const GET_COLLABORATORS_ERROR = 'GET_COLLABORATORS_ERROR';
export const GET_COLLABORATORS_SUCCESS = 'GET_COLLABORATORS_SUCCESS';
export const GET_COLLABORATORS_SUCCESS_ERROR_SHOW =
	'GET_COLLABORATORS_SUCCESS_ERROR_SHOW';

//collaboratorConstants
export const GET_COLLABORATOR = 'GET_COLLABORATOR';
export const GET_COLLABORATOR_ERROR = 'GET_COLLABORATOR_ERROR';
export const GET_COLLABORATOR_SUCCESS = 'GET_COLLABORATOR_SUCCESS';

export const ACCEPT_INFORMATION_COLLABORATOR =
	'ACCEPT_INFORMATION_COLLABORATOR';
export const ACCEPT_INFORMATION_COLLABORATOR_ERROR =
	'ACCEPT_INFORMATION_COLLABORATOR_ERROR';
export const ACCEPT_INFORMATION_COLLABORATOR_SUCCESS =
	'ACCEPT_INFORMATION_COLLABORATOR_SUCCESS';
export const ACCEPT_INFORMATION_COLLABORATOR_ERROR_SHOW =
	'ACCEPT_INFORMATION_COLLABORATOR_ERROR_SHOW';

export const REJECT_INFORMATION_COLLABORATOR =
	'REJECT_INFORMATION_COLLABORATOR';
export const REJECT_INFORMATION_COLLABORATOR_ERROR =
	'REJECT_INFORMATION_COLLABORATOR_ERROR';
export const REJECT_INFORMATION_COLLABORATOR_SUCCESS =
	'REJECT_INFORMATION_COLLABORATOR_SUCCESS';
export const REJECT_INFORMATION_COLLABORATOR_ERROR_SHOW =
	'REJECT_INFORMATION_COLLABORATOR_ERROR_SHOW';

export const GET_INVITATIONS = 'GET_iNVITATIONS';
export const GET_INVITATIONS_ERROR = 'GET_INVITATIONS_ERROR';
export const GET_INVITATIONS_SUCCESS = 'GET_INVITATIONS_SUCCESS';
export const GET_INVITATIONS_ERROR_SHOW = 'GET_INVITATIONS_ERROR_SHOW';

export const SEND_INVITATIONS = 'SEND_iNVITATIONS';
export const SEND_INVITATIONS_ERROR = 'SEND_INVITATIONS_ERROR';
export const SEND_INVITATIONS_SUCCESS = 'SEND_INVITATIONS_SUCCESS';
export const SEND_INVITATIONS_ERROR_SHOW = 'SEND_INVITATIONS_ERROR_SHOW';

export const SEND_UN_INVITE = 'SEND_UN_INVITE';
export const SEND_UN_INVITE_ERROR = 'SEND_UN_INVITE_ERROR';
export const SEND_UN_INVITE_SUCCESS = 'SEND_UN_INVITE_SUCCESS';
export const SEND_UN_INVITE_ERROR_SHOW = 'SEND_UN_INVITE_ERROR_SHOW';

export const GET_COMPANY = 'GET_COMPANY';
export const GET_COMPANY_ERROR = 'GET_COMPANY_ERROR';
export const GET_COMPANY_SUCCESS = 'GET_COMPANY_SUCCESS';

export const GET_COMPANY_SETTINGS = 'GET_COMPANY_SETTINGS';
export const GET_COMPANY_SETTINGS_ERROR = 'GET_COMPANY_SETTINGS_ERROR';
export const GET_COMPANY_SETTINGS_SUCCESS = 'GET_COMPANY_SETTINGS_SUCCESS';

export const POST_COMPANY_SETTINGS_ATTENDANCE =
	'POST_COMPANY_SETTINGS_ATTENDANCE';
export const POST_COMPANY_SETTINGS_ATTENDANCE_ERROR =
	'POST_COMPANY_SETTINGS_ATTENDANCE_ERROR';
export const POST_COMPANY_SETTINGS_ATTENDANCE_SUCCESS =
	'POST_COMPANY_SETTINGS_ATTENDANCE_SUCCESS';

export const POST_COMPANY_SETTINGS_INCIDENTS =
	'POST_COMPANY_SETTINGS_INCIDENTS';
export const POST_COMPANY_SETTINGS_INCIDENTS_ERROR =
	'POST_COMPANY_SETTINGS_INCIDENTS_ERROR';
export const POST_COMPANY_SETTINGS_INCIDENTS_SUCCESS =
	'POST_COMPANY_SETTINGS_INCIDENTS_SUCCESS';

export const POST_COMPANY_SETTINGS_NOTIFICATIONS =
	'POST_COMPANY_SETTINGS_NOTIFICATIONS';
export const POST_COMPANY_SETTINGS_NOTIFICATIONS_ERROR =
	'POST_COMPANY_SETTINGS_NOTIFICATIONS_ERROR';
export const POST_COMPANY_SETTINGS_NOTIFICATIONS_SUCCESS =
	'POST_COMPANY_SETTINGS_NOTIFICATIONS_SUCCESS';

export const GET_COMPANY_SETTINGS_NOTIFICATIONS =
	'GET_COMPANY_SETTINGS_NOTIFICATIONS';
export const GET_COMPANY_SETTINGS_NOTIFICATIONS_ERROR =
	'GET_COMPANY_SETTINGS_NOTIFICATIONS_ERROR';
export const GET_COMPANY_SETTINGS_NOTIFICATIONS_SUCCESS =
	'GET_COMPANY_SETTINGS_NOTIFICATIONS_SUCCESS';

export const GET_STATUS_INVITATIONS = 'GET_STATUS_INVITATIONS';
export const GET_STATUS_INVITATIONS_ERROR = 'GET_STATUS_INVITATIONS_ERROR';
export const GET_STATUS_INVITATIONS_SUCCESS = 'GET_STATUS_INVITATIONS_SUCCESS';
export const GET_STATUS_INVITATIONS_ERROR_SHOW =
	'GET_STATUS_INVITATIONS_ERROR_SHOW';

export const RE_IMPORT_DATA = 'RE_IMPORT_DATA';
export const RE_IMPORT_DATA_ERROR = 'RE_IMPORT_DATA_ERROR';
export const RE_IMPORT_DATA_SUCCESS = 'RE_IMPORT_DATA_SUCCESS';
export const RE_IMPORT_DATA_ERROR_SHOW = 'RE_IMPORT_DATA_ERROR_SHOW';

export const VERIFY_AVAILABLE_LICENSE = 'VERIFY_AVAILABLE_LICENSE';
export const VERIFY_AVAILABLE_LICENSE_ERROR = 'VERIFY_AVAILABLE_LICENSE_ERROR';
export const VERIFY_AVAILABLE_LICENSE_SUCCESS =
	'VERIFY_AVAILABLE_LICENSE_SUCCESS';
export const VERIFY_AVAILABLE_LICENSE_ERROR_SHOW =
	'VERIFY_AVAILABLE_LICENSE_ERROR_SHOW';

export const INVITATION_FORWARD = 'INVITATION_FORWARD';
export const INVITATION_FORWARD_ERROR = 'INVITATION_FORWARD_ERROR';
export const INVITATION_FORWARD_SUCCESS = 'INVITATION_FORWARD_SUCCESS';
export const INVITATION_FORWARD_ERROR_SHOW = 'INVITATION_FORWARD_ERROR_SHOW';

export const GET_WORK_CENTER = 'GET_WORK_CENTER';
export const GET_WORK_CENTER_ERROR = 'GET_WORK_CENTER_ERROR';
export const GET_WORK_CENTER_SUCCESS = 'GET_WORK_CENTER_SUCCESS';
export const GET_WORK_CENTER_ERROR_SHOW = 'GET_WORK_CENTER_ERROR_SHOW';

export const GET_WORK_CENTER_BY_ID = 'GET_WORK_CENTER_BY_ID';
export const GET_WORK_CENTER_BY_ID_ERROR = 'GET_WORK_CENTER_BY_ID_ERROR';
export const GET_WORK_CENTER_BY_ID_SUCCESS = 'GET_WORK_CENTER_BY_ID_SUCCESS';
export const GET_WORK_CENTER_BY_ID_ERROR_SHOW =
	'GET_WORK_CENTER_BY_ID_ERROR_SHOW';

export const GET_COLLABORATORS_WORK_CENTER = 'GET_COLLABORATORS_WORK_CENTER';
export const GET_COLLABORATORS_WORK_CENTER_ERROR =
	'GET_COLLABORATORS_WORK_CENTER_ERROR';
export const GET_COLLABORATORS_WORK_CENTER_SUCCESS =
	'GET_COLLABORATORS_WORK_CENTER_SUCCESS';
export const GET_COLLABORATORS_WORK_CENTER_ERROR_SHOW =
	'GET_COLLABORATORS_WORK_CENTER_ERROR_SHOW';

export const POST_WORK_CENTER = 'POST_WORK_CENTER';
export const POST_WORK_CENTER_ERROR = 'POST_WORK_CENTER_ERROR';
export const POST_WORK_CENTER_SUCCESS = 'POST_WORK_CENTER_SUCCESS';
export const POST_WORK_CENTER_ERROR_SHOW = 'POST_WORK_CENTER_ERROR_SHOW';

export const PATCH_WORK_CENTER = 'PATCH_WORK_CENTER';
export const PATCH_WORK_CENTER_ERROR = 'PATCH_WORK_CENTER_ERROR';
export const PATCH_WORK_CENTER_SUCCESS = 'PATCH_WORK_CENTER_SUCCESS';
export const PATCH_WORK_CENTER_ERROR_SHOW = 'PATCH_WORK_CENTER_ERROR_SHOW';

export const DELETE_WORK_CENTER = 'DELETE_WORK_CENTER';
export const DELETE_WORK_CENTER_ERROR = 'DELETE_WORK_CENTER_ERROR';
export const DELETE_WORK_CENTER_SUCCESS = 'DELETE_WORK_CENTER_SUCCESS';
export const DELETE_WORK_CENTER_ERROR_SHOW = 'DELETE_WORK_CENTER_ERROR_SHOW';

export const PUT_WORK_CENTER = 'PUT_WORK_CENTER';
export const PUT_WORK_CENTER_ERROR = 'PUT_WORK_CENTER_ERROR';
export const PUT_WORK_CENTER_SUCCESS = 'PUT_WORK_CENTER_SUCCESS';
export const PUT_WORK_CENTER_ERROR_SHOW = 'PUT_WORK_CENTER_ERROR_SHOW';

export const GET_DAYS_OFF = 'GET_DAYS_OFF';
export const GET_DAYS_OFF_ERROR = 'GET_DAYS_OFF_ERROR';
export const GET_DAYS_OFF_SUCCESS = 'GET_DAYS_OFF_SUCCESS';
export const GET_DAYS_OFF_ERROR_SHOW = 'GET_DAYS_OFF_ERROR_SHOW';

export const POST_DAYS_OFF = 'POST_DAYS_OFF';
export const POST_DAYS_OFF_ERROR = 'POST_DAYS_OFF_ERROR';
export const POST_DAYS_OFF_SUCCESS = 'POST_DAYS_OFF_SUCCESS';
export const POST_DAYS_OFF_ERROR_SHOW = 'POST_DAYS_OFF_ERROR_SHOW';

export const PUT_DAYS_OFF = 'PUT_DAYS_OFF';
export const PUT_DAYS_OFF_ERROR = 'PUT_DAYS_OFF_ERROR';
export const PUT_DAYS_OFF_SUCCESS = 'PUT_DAYS_OFF_SUCCESS';
export const PUT_DAYS_OFF_ERROR_SHOW = 'PUT_DAYS_OFF_ERROR_SHOW';

export const DELETE_DAYS_OFF = 'DELETE_DAYS_OFF';
export const DELETE_DAYS_OFF_ERROR = 'DELETE_DAYS_OFF_ERROR';
export const DELETE_DAYS_OFF_SUCCESS = 'DELETE_DAYS_OFF_SUCCESS';
export const DELETE_DAYS_OFF_ERROR_SHOW = 'DELETE_DAYS_OFF_ERROR_SHOW';

export const GET_SCHEDULES_WORKSHIFT = 'GET_SCHEDULES_WORKSHIFT';
export const GET_SCHEDULES_WORKSHIFT_ERROR = 'GET_SCHEDULES_WORKSHIFT_ERROR';
export const GET_SCHEDULES_WORKSHIFT_SUCCESS =
	'GET_SCHEDULES_WORKSHIFT_SUCCESS';
export const GET_SCHEDULES_WORKSHIFT_ERROR_SHOW =
	'GET_SCHEDULES_WORKSHIFT_ERROR_SHOW';

export const GET_SCHEDULES = 'GET_SCHEDULES';
export const GET_SCHEDULES_ERROR = 'GET_SCHEDULES_ERROR';
export const GET_SCHEDULES_SUCCESS = 'GET_SCHEDULES_SUCCESS';
export const GET_SCHEDULES_ERROR_SHOW = 'GET_SCHEDULES_ERROR_SHOW';

export const POST_SCHEDULES = 'POST_SCHEDULES';
export const POST_SCHEDULES_ERROR = 'POST_SCHEDULES_ERROR';
export const POST_SCHEDULES_SUCCESS = 'POST_SCHEDULES_SUCCESS';
export const POST_SCHEDULES_ERROR_SHOW = 'POST_SCHEDULES_ERROR_SHOW';

export const PUT_SCHEDULES = 'PUT_SCHEDULES';
export const PUT_SCHEDULES_ERROR = 'PUT_SCHEDULES_ERROR';
export const PUT_SCHEDULES_SUCCESS = 'PUT_SCHEDULES_SUCCESS';
export const PUT_SCHEDULES_ERROR_SHOW = 'PUT_SCHEDULES_ERROR_SHOW';

export const GET_HOLIDAYS = 'GET_HOLIDAYS';
export const GET_HOLIDAYS_SUCCESS = 'GET_HOLIDAYS_SUCCESS';
export const GET_HOLIDAYS_ERROR = 'GET_HOLIDAYS_ERROR';
export const GET_HOLIDAYS_ERROR_SHOW = 'GET_HOLIDAYS';

export const IMPORT_DAYS_OFF = 'IMPORT_DAYS_OFF';
export const IMPORT_DAYS_OFF_SUCCESS = 'IMPORT_DAYS_OFF_SUCCESS';
export const IMPORT_DAYS_OFF_ERROR = 'IMPORT_DAYS_OFF_ERROR';
export const IMPORT_DAYS_OFF_ERROR_SHOW = 'IMPORT_DAYS_OFF_ERROR_SHOW';

export const GET_OPERATION_COLABORATORS = 'GET_OPERATION_COLABORATORS';
export const GET_OPERATION_COLABORATORS_ERROR =
	'GET_OPERATION_COLABORATORS_ERROR';
export const GET_OPERATION_COLABORATORS_SUCCESS =
	'GET_OPERATION_COLABORATORS_SUCCESS';
export const GET_OPERATION_COLABORATORS_ERROR_SHOW =
	'GET_OPERATION_COLABORATORS_ERROR_SHOW';

export const GET_WORKCENTER_LOCATION_COLABORATORS =
	'GET_WORKCENTER_LOCATION_COLABORATORS';
export const GET_WORKCENTER_LOCATION_COLABORATORS_ERROR =
	'GET_WORKCENTER_LOCATION_COLABORATORS_ERROR';
export const GET_WORKCENTER_LOCATION_COLABORATORS_SUCCESS =
	'GET_WORKCENTER_LOCATION_COLABORATORS_SUCCESS';
export const GET_WORKCENTER_LOCATION_COLABORATORS_ERROR_SHOW =
	'GET_WORKCENTER_LOCATION_COLABORATORS_ERROR_SHOW';

export const POST_WORKCENTER_LOCATION_COLABORATORS =
	'POST_WORKCENTER_LOCATION_COLABORATORS';
export const POST_WORKCENTER_LOCATION_COLABORATORS_ERROR =
	'POST_WORKCENTER_LOCATION_COLABORATORS_ERROR';
export const POST_WORKCENTER_LOCATION_COLABORATORS_SUCCESS =
	'POST_WORKCENTER_LOCATION_COLABORATORS_SUCCESS';
export const POST_WORKCENTER_LOCATION_COLABORATORS_ERROR_SHOW =
	'POST_WORKCENTER_LOCATION_COLABORATORS_ERROR_SHOW';

export const GET_OPERATION_SCHEDULE_COLABORATORS =
	'GET_OPERATION_SCHEDULE_COLABORATORS';
export const GET_OPERATION_SCHEDULE_COLABORATORS_ERROR =
	'GET_OPERATION_SCHEDULE_COLABORATORS_ERROR';
export const GET_OPERATION_SCHEDULE_COLABORATORS_SUCCESS =
	'GET_OPERATION_SCHEDULE_COLABORATORS_SUCCESS';
export const GET_OPERATION_SCHEDULE_COLABORATORS_ERROR_SHOW =
	'GET_OPERATION_SCHEDULE_COLABORATORS_ERROR_SHOW';

export const DELETE_OPERATION_SCHEDULE_COLABORATOR =
	'DELETE_OPERATION_SCHEDULE_COLABORATOR';
export const DELETE_OPERATION_SCHEDULE_COLABORATOR_ERROR =
	'DELETE_OPERATION_SCHEDULE_COLABORATOR_ERROR';
export const DELETE_OPERATION_SCHEDULE_COLABORATOR_SUCCESS =
	'DELETE_OPERATION_SCHEDULE_COLABORATOR_SUCCESS';
export const DELETE_OPERATION_SCHEDULE_COLABORATOR_ERROR_SHOW =
	'DELETE_OPERATION_SCHEDULE_COLABORATOR_ERROR_SHOW';

export const POST_OPERATION_SCHEDULE_COLABORATORS =
	'POST_OPERATION_SCHEDULE_COLABORATORS';
export const POST_OPERATION_SCHEDULE_COLABORATORS_ERROR =
	'POST_OPERATION_SCHEDULE_COLABORATORS_ERROR';
export const POST_OPERATION_SCHEDULE_COLABORATORS_SUCCESS =
	'POST_OPERATION_SCHEDULE_COLABORATORS_SUCCESS';
export const POST_OPERATION_SCHEDULE_COLABORATORS_ERROR_SHOW =
	'POST_OPERATION_SCHEDULE_COLABORATORS_ERROR_SHOW';

export const EXPORT = 'EXPORT';
export const EXPORT_ERROR = 'EXPORT_ERROR';
export const EXPORT_SUCCESS = 'EXPORT_SUCCESS';
export const EXPORT_ERROR_SHOW = 'EXPORT_ERROR_SHOW';

export const GET_PERIODS = 'GET_PERIODS';
export const GET_PERIODS_ERROR = 'GET_PERIODS_ERROR';
export const GET_PERIODS_SUCCESS = 'GET_PERIODS_SUCCESS';
export const GET_PERIODS_ERROR_SHOW = 'GET_PERIODS_ERROR_SHOW';

export const GET_INCIDENCES = 'GET_INCIDENCES';
export const GET_INCIDENCES_ERROR = 'GET_INCIDENCES_ERROR';
export const GET_INCIDENCES_SUCCESS = 'GET_INCIDENCES_SUCCESS';
export const GET_INCIDENCES_ERROR_SHOW = 'GET_INCIDENCES_ERROR_SHOW';

export const POST_INCIDENCES = 'POST_INCIDENCES';
export const POST_INCIDENCES_ERROR = 'POST_INCIDENCES_ERROR';
export const POST_INCIDENCES_SUCCESS = 'POST_INCIDENCES_SUCCESS';
export const POST_INCIDENCES_ERROR_SHOW = 'POST_INCIDENCES_ERROR_SHOW';

export const GET_INCIDENCES_BY_PROCESS_ID = 'GET_INCIDENCES_BY_PROCESS_ID';
export const GET_INCIDENCES_BY_PROCESS_ID_ClEAR =
	'GET_INCIDENCES_BY_PROCESS_ID_ClEAR';
export const GET_INCIDENCES_ERROR_BY_PROCESS_ID =
	'GET_INCIDENCES_ERROR_BY_PROCESS_ID';
export const GET_INCIDENCES_SUCCESS_BY_PROCESS_ID =
	'GET_INCIDENCES_SUCCESS_BY_PROCESS_ID';
export const GET_INCIDENCES_ERROR_SHOW_BY_PROCESS_ID =
	'GET_INCIDENCES_ERROR_SHOW_BY_PROCESS_ID';

export const GET_TYPE_INCIDENCES = 'GET_TYPE_INCIDENCES';
export const GET_TYPE_INCIDENCES_ERROR = 'GET_TYPE_INCIDENCES_ERROR';
export const GET_TYPE_INCIDENCES_SUCCESS = 'GET_TYPE_INCIDENCES_SUCCESS';
export const GET_TYPE_INCIDENCES_ERROR_SHOW = 'GET_TYPE_INCIDENCES_ERROR_SHOW';

export const POST_NEW_INCIDENCES = 'POST_NEW_INCIDENCES';
export const POST_NEW_INCIDENCES_ERROR = 'POST_NEW_INCIDENCES_ERROR';
export const POST_NEW_INCIDENCES_SUCCESS = 'POST_NEW_INCIDENCES_SUCCESS';
export const POST_NEW_INCIDENCES_ERROR_SHOW = 'POST_NEW_INCIDENCES_ERROR_SHOW';

export const PUT_INCIDENCES = 'PUT_INCIDENCES';
export const PUT_INCIDENCES_ERROR = 'PUT_INCIDENCES_ERROR';
export const PUT_INCIDENCES_SUCCESS = 'PUT_INCIDENCES_SUCCESS';
export const PUT_INCIDENCES_ERROR_SHOW = 'PUT_INCIDENCES_ERROR_SHOW';

export const GET_ACCUMULATED = 'GET_ACCUMULATED';
export const GET_ACCUMULATED_ERROR = 'GET_ACCUMULATED_ERROR';
export const GET_ACCUMULATED_SUCCESS = 'GET_ACCUMULATED_SUCCESS';
export const GET_ACCUMULATED_ERROR_SHOW = 'GET_ACCUMULATED_ERROR_SHOW';

export const GET_CATALOGS_REPORTS = 'GET_CATALOGS_REPORTS';
export const GET_CATALOGS_REPORTS_ERROR = 'GET_CATALOGS_REPORTS_ERROR';
export const GET_CATALOGS_REPORTS_SUCCESS = 'GET_CATALOGS_REPORTS_SUCCESS';
export const GET_CATALOGS_REPORTS_ERROR_SHOW =
	'GET_CATALOGS_REPORTS_ERROR_SHOW';

export const GET_QUERIES = 'GET_QUERIES';
export const GET_QUERIES_ERROR = 'GET_QUERIES_ERROR';
export const GET_QUERIES_SUCCESS = 'GET_QUERIES_SUCCESS';
export const GET_QUERIES_ERROR_SHOW = 'GET_QUERIES_ERROR_SHOW';

export const EDIT_INCIDENCES = 'EDIT_INCIDENCES';
export const EDIT_INCIDENCES_ERROR = 'EDIT_INCIDENCES_ERROR';
export const EDIT_INCIDENCES_SUCCESS = 'EDIT_INCIDENCES_SUCCESS';
export const EDIT_INCIDENCES_ERROR_SHOW = 'EDIT_INCIDENCES_ERROR_SHOW';

export const PUT_IMPORT_PERIODS = 'PUT_IMPORT_PERIODS';
export const PUT_IMPORT_PERIODS_ERROR = 'PUT_IMPORT_PERIODS_ERROR';
export const PUT_IMPORT_PERIODS_SUCCESS = 'PUT_IMPORT_PERIODS_SUCCESS';
export const PUT_IMPORT_PERIODS_ERROR_SHOW = 'PUT_IMPORT_PERIODS_ERROR_SHOW';

export const GET_COMPANY_VERIFY_LICENSE = 'GET_COMPANY_VERIFY_LICENSE';
export const GET_COMPANY_VERIFY_LICENSE_ERROR =
	'GET_COMPANY_VERIFY_LICENSE_ERROR';
export const GET_COMPANY_VERIFY_LICENSE_SUCCESS =
	'GET_COMPANY_VERIFY_LICENSE_SUCCESS';

export const GET_ORGANIZATION_CHART = 'GET_ORGANIZATION_CHART';
export const GET_ORGANIZATION_CHART_ERROR = 'GET_ORGANIZATION_CHART_ERROR';
export const GET_ORGANIZATION_CHART_SUCCESS = 'GET_ORGANIZATION_CHART_SUCCESS';

export const POST_EMPLOYEE_IMPORT = 'POST_EMPLOYEE_IMPORT';
export const POST_EMPLOYEE_IMPORT_SUCCESS = 'POST_EMPLOYEE_IMPORT_SUCCESS';
export const POST_EMPLOYEE_IMPORT_ERROR = 'POST_EMPLOYEE_IMPORT_ERROR';

export const GET_FILTERS_ORGANIZATION_CHART = 'GET_FILTERS_ORGANIZATION_CHART';
export const GET_FILTERS_ORGANIZATION_CHART_SUCCESS =
	'GET_FILTERS_ORGANIZATION_CHART_SUCCESS';
export const GET_FILTERS_ORGANIZATION_CHART_ERROR =
	'GET_FILTERS_ORGANIZATION_CHART_ERROR';

export const GET_LEADERS_ORGANIZATION_CHART = 'GET_LEADERS_ORGANIZATION_CHART';
export const GET_LEADERS_ORGANIZATION_CHART_SUCCESS =
	'GET_LEADERS_ORGANIZATION_CHART_SUCCESS';
export const GET_LEADERS_ORGANIZATION_CHART_ERROR =
	'GET_LEADERS_ORGANIZATION_CHART_ERROR';

export const PUT_ORG_CHART_LEADERS = 'PUT_ORG_CHART_LEADERS';
export const PUT_ORG_CHART_LEADERS_SUCCESS = 'PUT_ORG_CHART_LEADERS_SUCCESS';
export const PUT_ORG_CHART_LEADERS_ERROR = 'PUT_ORG_CHART_LEADERS_ERROR';
export const PUT_ORG_CHART_LEADERS_CLEAR = 'PUT_ORG_CHART_LEADERS_CLEAR';

export const LOADING_ON_MESSAGE = 'LOADING_ON_MESSAGE';
export const LOADING_OFF_MESSAGE = 'LOADING_OFF_MESSAGE';
