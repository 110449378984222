import {
	GET_OPERATION_COLABORATORS,
	GET_OPERATION_COLABORATORS_ERROR,
	GET_OPERATION_COLABORATORS_SUCCESS,
} from '../../../constants';

export const getOperationCollaboratorsReducer = (state, actions) => {
	switch (actions.type) {
		case GET_OPERATION_COLABORATORS:
			return {
				...state,
				loading: true,
				collaborators: undefined,
				error: null,
				errorShow: false,
				isDataLoaded: false,
			};
		case GET_OPERATION_COLABORATORS_ERROR:
			return {
				...state,
				loading: false,
				error: actions.payload,
				errorShow: true,
				isDataLoaded: false,
			};
		case GET_OPERATION_COLABORATORS_SUCCESS:
			return {
				...state,
				loading: false,
				error: null,
				errorShow: false,
				collaborators: actions.payload,
				isDataLoaded: true,
			};
		default:
			return state;
	}
};
