import { useState, useContext, useEffect } from 'react';
import { StoreContext } from '../../business/Provider';
import { Toast } from '../../components/toast';
import { generateUrlBase } from '../../utils/utils';
import useApi from '../api';
import {
	GET_COMPANY_SUCCESS,
	LOADING_OFF,
	LOADING_ON,
} from './../../business/constants';

const useCompanies = () => {
	const [companyInfo, setCompanyInfo] = useState(undefined);
	const context = useContext(StoreContext);
	const { companyState, dispatchCompany, dispatchLoading } = context;

	const { genericApiCall } = useApi();

	const getCompanyInfo = async () => {
		const baseUrl = generateUrlBase(`company-info`);
		dispatchLoading({ type: LOADING_ON });
		try {
			const result = await genericApiCall(baseUrl, 'GET', {}, {}, {});
			const payload = result.data;

			if (result.status === 200) {
				dispatchCompany({
					type: GET_COMPANY_SUCCESS,
					payload: payload,
				});
				dispatchLoading({ type: LOADING_OFF });
				setCompanyInfo(payload);
			}
			return result;
		} catch (error) {
			Toast('warning', error.message, '', error);
			dispatchLoading({ type: LOADING_OFF });
		}
	};

	useEffect(() => {
		if (
			companyState.companies === null &&
			companyState.loading === false &&
			companyState.error === null
		) {
			getCompanyInfo();
		}
	}, [companyState]);

	return {
		companyInfo,
		getCompanyInfo,
		companyState,
	};
};

export default useCompanies;
