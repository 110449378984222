import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import axios from 'axios';
import { locale, addLocale } from 'primereact/api';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import {
	msalConfig,
	loginRequest,
	uris,
} from './Auth/b2cService/b2cServiceConfig';
import { Buffer } from 'buffer';
import * as serviceWorker from './serviceWorker.js';
import { primeReactTranslations } from './primeReactTranslateOptions';
import { generateUrlBase } from './utils/utils';
import App from './App';
import { StoreProvider } from './business/Provider.js';

const createArrayScopes = (scopes) => {
	const newStr = scopes.slice(1, -1);
	const dataMap = newStr.split(',');
	const dataMap2 = dataMap.map((item) => item.slice(1, -1));
	return dataMap2;
};

const decryptValue = (value) => {
	return Buffer.from(value, 'base64').toString();
};

const setConfigurationAndDoRendering = (configuration) => {
	const clientId = decryptValue(
		configuration?.data?.msalConfig?.auth?.clientId
	);
	const authority = decryptValue(
		configuration.data.msalConfig?.auth?.authority
	);
	const clientSecret = decryptValue(
		configuration?.data?.msalConfig?.auth?.clientSecret
	);
	const knownAuthorities = decryptValue(
		configuration?.data?.msalConfig?.auth?.knownAuthorities
	);
	const redirectUri = decryptValue(
		configuration?.data?.msalConfig?.redirectUri
	);
	const scopes = decryptValue(configuration?.data?.loginRequest?.scope);
	const ctiPortalURI = decryptValue(configuration?.data?.ctiPortalURI);

	msalConfig.auth.clientId = clientId;
	msalConfig.auth.authority = authority;
	msalConfig.auth.clientSecret = clientSecret;
	msalConfig.auth.knownAuthorities = [knownAuthorities];
	msalConfig.redirectUri = redirectUri;
	msalConfig.ctiPortalURI = ctiPortalURI;
	loginRequest.scopes = createArrayScopes(scopes);
	uris.platformAccountUri = ctiPortalURI + '/cuenta';
	localStorage.setItem('ctiPortalURI', JSON.stringify(ctiPortalURI));

	const msalInstance = new PublicClientApplication(msalConfig);

	const root = ReactDOM.createRoot(document.getElementById('root'));

	root.render(
		// <React.StrictMode>
		<MsalProvider instance={msalInstance}>
			<StoreProvider>
				<App pca={msalInstance} />
			</StoreProvider>
		</MsalProvider>
		// </React.StrictMode>
	);
};

const initializeLocale = () => {
	addLocale('es', primeReactTranslations); // Register Spanish translations with PrimeReact
	locale('es'); // Set the locale for PrimeReact components
};
const initConfiguration = async () => {
	try {
		const url = generateUrlBase('configuration');
		const response = await axios.get(url, {
			headers: {
				'Content-Type': 'application/json',
				'Access-Control-Allow-Origin': '*',
				origin: 'x-requested-with',
			},
		});
		setConfigurationAndDoRendering(response.data);
	} catch (error) {
		console.error('Error getting configuration:', error);
	}
};
initializeLocale();
initConfiguration();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

serviceWorker.unregister();
