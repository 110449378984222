import { useState } from 'react';
import { MapPin, MapPinOff } from 'lucide-react';
import { CustomAlertDialog } from '../../../../components/Dialogs/CustomAlertDialog';
import { CollaboratorScreenNames } from '../../../../namesConstants/names';


export const LocationBody = ({row, updateCollaboratorOpenWorkArea}) => {
	const [openDialog, setOpenDialog] = useState(false);
	const renderAlert = () => {
		const customTitle=CollaboratorScreenNames.CustomAlertOpenWorkArea
		.replace('{fullname}', row.fullName)
		.replace('{openWorkArea}', row.openWorkArea ? 'habilitar' : 'quitar')
		return (
			<CustomAlertDialog
				title={customTitle}
				setOpen={setOpenDialog}
				open={openDialog}
				cancelLabel={'No'}
				agreeLabel={'Si'}
				agreeIcon='pi pi-check'
				onConfirmFunction={() => {
					updateCollaboratorOpenWorkArea(row.id, !row.openWorkArea);
				}}
				onCancelFunction={() => {
					setOpenDialog(false)
				}}
				style={{ width: '30vw' }}
			/>
		)
	}
	return (
		<>
		<div className='flex w-full h-full justify-center content-center items-center'>
			{row.openWorkArea ? (
				<div
					title='El colaborador registra asistencia en cualquier ubicación'
					onClick={() => {
						setOpenDialog(true);
					}}
					className='flex w-3 h-3  rounded-full justify-center content-center items-center  cursor-pointer ml-1'>
					<MapPinOff className='text-xl' />
				</div>
			) : (
				<div
					title='El colaborador registra asistencia en sus centros y lugares de trabajo'
					onClick={() => {
						setOpenDialog(true)
					}}
					className='flex w-3 h-3  rounded-full justify-center content-center items-center  cursor-pointer ml-1'>
						
					<MapPin className='text-xl' />
				</div>
			)}
		</div>
		{renderAlert()}
		</>
	);
};
