import { Button } from 'primereact/button';
import React, { useContext, useEffect, useState } from 'react';
import { useIntegrations } from '../../hooks/integrations/useIntegrations';
import { StoreContext } from '../../business/Provider';
import { FullLoading } from '../FullLoading';
export const IntegrationComponent = ({ children }) => {
	const [companyData, setCompanyData] = useState(null);

	const { postIntegrations } = useIntegrations();
	const { loadingState, getCompanySettings } = useContext(StoreContext);

	useEffect(() => {
		const { companies } = getCompanySettings;

		if (companies !== null) {
			setCompanyData(companies);
		}
	}, [getCompanySettings]);

	const postData = async () => {
		try {
			return await postIntegrations();
		} catch (error) {
			console.log(error);
		}
	};

	const renderMessage = () => {
		return (
			<div className='flex bg-white w-full h-full justify-center content-center items-center relative overflow-x-hidden overflow-y-hidden'>
				<FullLoading loadingState={loadingState} />
				<div className='flex w-full h-full md:w-1/2 md:h-1/2 lg:w-[45%] lg:h-[35%] xl:w-1/3 xl:h-1/3 2xl:w-1/3 2xl:h-1/3 flex-col border shadow-2xl rounded-md border-[#004B93]'>
					<div className='flex w-full h-[15%] content-center items-center'>
						<p className='ml-2 font-bold'>
							{'Importar información de la nómina'}
						</p>
					</div>
					<div className='flex w-full h-[70%] p-1 justify-center items-center content-center'>
						<p className='ml-2 font-semibold text-justify mr-2'>
							{
								'Para comenzar a utilizar el control de asistencia de Colabora es necesario realizar una réplica inicial de información desde su sistema de nómina para obtener el detalle de  Colaboradores, Turnos, Tarjetas de vacaciones, Incapacidades, entre otros. Este proceso solo se realizará una vez y puede tardar varios minutos.'
							}
						</p>
					</div>
					<div className='flex w-full h-[15%] justify-end'>
						<Button
							label='Confirmar'
							className='flex !w-32 !h-4 !bg-blue-800 !text-white !mr-2'
							onClick={() => postData()}
						/>
					</div>
				</div>
			</div>
		);
	};

	const renderEmptyWindow = () => {
		return (
			<div className='flex bg-white w-full h-full justify-center content-center items-center relative overflow-x-hidden overflow-y-hidden' />
		);
	};

	const validateMessage =
		companyData?.attendanceComplete === 100 ? children : renderMessage();

	return companyData === null ? renderEmptyWindow() : validateMessage;
};
