const ScheduleObject = {
	workShiftId: '',
	starts: null,
	ends: null,
	code: '',
	description: '',
	restDays: [],
	workShiftType: '',
	hours: '',
	workShiftName: '',
	colaboraInstanceId: '',
	externalInstanceId: '',
};

export default ScheduleObject;
