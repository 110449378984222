export const typesIncidencesData = [
	{ id: 2, name: 'Días' },
	{ id: 3, name: 'Horas' },
];
export const considerationTypeData = [
	{ id: 1, name: 'Ninguno' },
	{ id: 2, name: 'Ausencia' },
	{ id: 3, name: 'Incapacidad' },
	{ id: 4, name: 'Vacaciones' },
];
