const workCenterObject = {
	workCenterId: '',
	name: '',
	homeOffice: false,
	isActive: true,
	zipCode: '',
	federalEntity: '',
	municipality: '',
	suburb: '',
	street: '',
	externalNumber: '',
	internalNumber: '',
	geoLocation: '',
	collaborators: [],
	geoFenceSize: 500,
	toAdd: [],
	toDelete: [],
};

export default workCenterObject;
