import { MultiSelect } from 'primereact/multiselect';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
export const MultiSelectComponentFormik = ({
	formikDataObject,
	value,
	name,

	dataValues,
	onChange,
	label,
	title,
}) => {
	const [selectAll, setSelectAll] = useState(false);
	function selectData() {
		const _dataValueMultiSelect = dataValues;
		let elements = _dataValueMultiSelect.map((element) => {
			return element;
		});
		if (selectAll && dataValues.length !== elements.length) {
			setSelectAll(false);
		}
	}
	function addValuesHeaders(state) {
		const valueToSet = state ? dataValues : [];
		formikDataObject.setFieldValue(name, valueToSet);
	}

	function formatSelection() {
		const data = formikDataObject.values[name];
		let message = '';
		if (data.length === 1) {
			message = `${data.length} elemento seleccionado`;
		} else if (data.length === dataValues.length) {
			message = `Todos`;
		} else if (data.length > 1 && data.length < dataValues.length) {
			message = `${data.length} elementos seleccionados`;
		} else {
			message = `No hay ${label} seleccionados`;
		}
		return message;
	}

	const panelHeaderTemplate = () => {
		return (
			<div
				className='p-multiselect-item !pt-[0.5rem] !pb-[0.5rem] !pl-[1rem] !pr-[1rem] !bg-[#f8f9fa]'
				onClick={() => {
					addValuesHeaders(!selectAll);
					setSelectAll(!selectAll);
				}}>
				<div className='p-checkbox p-component !mr-[0.5rem]'>
					<div
						className={`p-checkbox-box ${
							selectAll || value.length === dataValues.length
								? '!bg-[#2196F3] !border-[#2196F3]'
								: ''
						}`}>
						<span
							className={`p-checkbox-icon p-c ${
								selectAll || value.length === dataValues.length
									? 'pi pi-check'
									: ''
							}`}></span>
					</div>
				</div>
				<span>Todos </span>
			</div>
		);
	};
	return (
		<div className='flex w-full h-full flex-col p-1 '>
			<div className='flex w-full h-full content-center items-center'>
				<label className=' font-bold text-sm'>{title}</label>
			</div>
			<div className='flex w-full h-full content-center items-center flex-col'>
				<MultiSelect
					name={name}
					value={value}
					options={dataValues}
					optionLabel='name'
					id={name}
					//placeholder='Selecciona una opción'
					onSelect={() => selectData()}
					maxSelectedLabels={1}
					selectedItemsLabel={formatSelection()}
					onChange={onChange}
					panelHeaderTemplate={panelHeaderTemplate}
					className={'!w-full !h-5 !flex '}
					///onBlur={formikDataObject.handleBlur}
					selectedItemTemplate={formatSelection}
				/>
			</div>
		</div>
	);
};

MultiSelectComponentFormik.propTypes = {
	formikDataObject: PropTypes.object.isRequired,
	dataValues: PropTypes.array.isRequired,
	value: PropTypes.array.isRequired,
	onChange: PropTypes.func.isRequired,
	name: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
};
