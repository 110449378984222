import {
	GET_ORGANIZATION_CHART,
	GET_ORGANIZATION_CHART_ERROR,
	GET_ORGANIZATION_CHART_SUCCESS,
} from '../../constants';

export const getOrganizationChartRed = (state, actions) => {
	switch (actions.type) {
		case GET_ORGANIZATION_CHART:
			return {
				...state,
				loading: true,
				error: null,
				errorShow: false,
				isLoad: false,
			};
		case GET_ORGANIZATION_CHART_ERROR:
			return {
				...state,
				loading: false,
				error: actions.payload,
				errorShow: true,
				isLoad: false,
			};
		case GET_ORGANIZATION_CHART_SUCCESS:
			return {
				...state,
				loading: false,
				error: null,
				errorShow: false,
				data: actions.payload,
				isLoad: true,
			};
		default:
			return state;
	}
};
