import React, { useState } from 'react';
import { CustomTable } from '../../../../components/Table/CustomTable';
import {
	GeneralNames,
	TableNames,
	TypesIncidencesNames,
} from '../../../../namesConstants/names';
import { Column } from 'primereact/column';
import { InputSwitch } from 'primereact/inputswitch';
import { headerClassName } from '../../../../assets/customStyles';
import { MdModeEditOutline } from 'react-icons/md';
export const TableTypesIncidences = ({
	list,
	filters,
	header,
	editTypeIncidenceAction,
	handleOnEditData,
}) => {
	const [sort, setSort] = useState([{ field: 'name', order: 1 }]);

	const bodyClassName =
		'!border-r-2 !border-b-0 !border-secondary-PCS004 !text-black !justify-start';

	const renderBodyIsLeader = (rowData) => {
		const valueChecked = rowData.leaderUse === null ? false : rowData.leaderUse;

		const valueToSend = {
			id: rowData.id,
			leaderUse:
				rowData.leaderUse === null || rowData.leaderUse === false
					? true
					: !rowData.leaderUse,
		};
		return (
			<>
				<InputSwitch
					checked={valueChecked}
					onChange={() => editTypeIncidenceAction(valueToSend)}
				/>
			</>
		);
	};

	const renderBodyActions = (rowData) => {
		return (
			<div className='flex justify-center content-center items-center'>
				<div className='flex w-3 h-3  rounded-full justify-center content-center items-center   cursor-pointer ml-1 '>
					<p title='Editar'>
						<MdModeEditOutline
							className='text-2xl text-black'
							onClick={() => handleOnEditData(rowData)}
						/>
					</p>
				</div>
			</div>
		);
	};

	return (
		<CustomTable
			value={list}
			sortField='name'
			filters={filters}
			header={header()}
			responsiveLayout='scroll'
			globalFilterFields={[
				'code',
				'name',
				'descriptionItConsiders',
				'descriptionTypeOfIncident',
			]}
			emptyMessage={GeneralNames.GeneralEmptyMessage}
			currentPageReportTemplate={TypesIncidencesNames.CurrentPageReportTemplate}
			resizableColumns
			columnResizeMode='fit'
			style={{ height: '100%', width: '100%' }}
			setSort={setSort}
			sort={sort}>
			<Column
				headerClassName={headerClassName}
				style={{ flexGrow: 1, flexBasis: '100px' }}
				field='code'
				header={TableNames.TableCode}
				sortable
				bodyClassName={bodyClassName}
			/>
			<Column
				headerClassName={headerClassName}
				style={{ flexGrow: 4, flexBasis: '100px' }}
				field='name'
				header={TableNames.TableName}
				sortable
				bodyClassName={bodyClassName}
			/>
			<Column
				headerClassName={headerClassName}
				style={{ flexGrow: 4, flexBasis: '100px' }}
				field='descriptionItConsiders'
				header={'Se considera '}
				sortable
				bodyClassName={bodyClassName}
			/>
			<Column
				headerClassName={headerClassName}
				style={{ flexGrow: 4, flexBasis: '100px' }}
				field='descriptionTypeOfIncident'
				header={TableNames.TableType}
				sortable
				bodyClassName={bodyClassName}
			/>
			<Column
				headerClassName={headerClassName}
				style={{ flexGrow: 4, flexBasis: '50px' }}
				field='leaderUse'
				header={'Justificante'}
				sortable
				body={renderBodyIsLeader}
				bodyClassName={bodyClassName}
			/>
			<Column
				headerClassName={headerClassName}
				style={{ flexGrow: 1, flexBasis: '50px' }}
				field='leaderUse'
				header={'Acciones'}
				sortable
				body={renderBodyActions}
				bodyClassName={bodyClassName}
			/>
		</CustomTable>
	);
};
