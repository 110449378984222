import React from 'react';
// import input from material ui
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';

export const Input = ({
	rows,
	cols,
	onChange,
	value,
	type,
	placeholder,
	error,
	errorText,
	disabled,
	name,
	ref,
	onBlur,
	className,
	onkeydown,
	defaultValue,
	classNameContent,
	maxLength,
	important = false,
	keyFilter = null,
	validateOnly = false,
}) => {
	// material ui text field with custom props and label
	return (
		<div
			className={
				'field  rounded-none !font-input-font-family ' + classNameContent
			}>
			<label className='text-small-font-size font-semibold flex' htmlFor={name}>
				{placeholder}{' '}
				{
					important ? <div className='flex h-1 w-1 rounded-full bg-red-700 ml-1 my-auto ' /> : null
				}
			</label>
			{type === 'textArea' ? (
				<InputTextarea
					className={`w-full !rounded-none !text-font-size-base ${
						error && errorText ? 'p-invalid' : ''
					} ${className}`}
					name={name}
					id={name}
					rows={rows}
					cols={cols}
					value={value}
					onChange={onChange}
					onBlur={onBlur}
				/>
			) : (
				<InputText
					className={`w-full !rounded-none !text-font-size-base ${
						error && errorText ? 'p-invalid' : ''
					} ${className}`}
					id={name}
					value={value}
					onBlur={onBlur}
					onChange={onChange}
					ref={ref}
					disabled={disabled}
					type={type}
					aria-describedby={`${name}-${errorText}`}
					name={name}
					onKeyDownCapture={onkeydown}
					defaultValue={defaultValue}
					keyfilter={keyFilter}
					validateOnly={validateOnly}
					maxLength={maxLength}
				/>
			)}
			{error && errorText && (
				<small id={`${name}-${errorText}`} className='p-error block'>
					{errorText}
				</small>
			)}
		</div>
	);
};

Input.defaultProps = {
	important: false,
	onChange: () => {},
	onBlur: () => {},
	onkeydown: () => {},
};
