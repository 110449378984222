import {
	PiBuildingsLight,
	PiClockCountdownThin,
	PiHouseLineLight,
} from 'react-icons/pi';

export const ActionBodyTemplate = ({
	row,
	getWorkCenterLocationCollaboratorsOnOperation,
	setIsHomeOffice,
	setIdPersoniaCollaborator,
	setOpenWorkCenterLocationDialog,
	setTitle,
	openDialogSingle,
}) => {
	const getWorkCentersInside = async (homeOffice, idPersoniaCollaborator) => {
		 homeOffice
			? getWorkCenterLocationCollaboratorsOnOperation(
					idPersoniaCollaborator,
					'home-office'
			  )
			:  getWorkCenterLocationCollaboratorsOnOperation(
					idPersoniaCollaborator,
					'company'
			  );
	};
	return (
		<td className='flex  content-center items-center justify-end flex-row'>
			<div
				title='Centros de trabajo'
				onClick={() => {
					setIsHomeOffice(false);
					setIdPersoniaCollaborator(row.id);
					setOpenWorkCenterLocationDialog(true);
					setTitle(`Centros de trabajo - ${row.fullName} ${row.formattedCode}`);
					getWorkCentersInside(false, row.id);
				}}
				className='flex w-3 h-3  rounded-full justify-center content-center items-center  cursor-pointer ml-1'>
				<PiBuildingsLight className={'text-2xl text-black'} />
			</div>
			<div
				title='Lugares de trabajo'
				onClick={() => {
					setIsHomeOffice(true);
					setIdPersoniaCollaborator(row.id);
					setOpenWorkCenterLocationDialog(true);
					getWorkCentersInside(true, row.id);
					setTitle(`Lugares de trabajo - ${row.fullName} ${row.formattedCode}`);
				}}
				className='flex w-3 h-3  rounded-full justify-center content-center items-center  cursor-pointer ml-1'>
				<PiHouseLineLight className={'text-2xl text-black'} />
			</div>
			<div
				title='Modificar turno'
				onClick={() => {
					openDialogSingle(row);
				}}
				className='flex w-3 h-3  rounded-full justify-center content-center items-center  cursor-pointer ml-1'>
				<PiClockCountdownThin className={'text-2xl text-black'} />
			</div>
		</td>
	);
};
