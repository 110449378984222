import React, { createContext, useContext, useEffect, useState } from 'react';
import { StoreContext } from '../../business/Provider';
import { Toast } from '../../components/toast';
import useReload from '../../hooks/useReload';
import { useIncidences } from '../../hooks/incidences/useIncidences';
import { FilterMatchMode } from 'primereact/api';
import { usePeriods } from '../../hooks/periods/usePerdiods';
import { GeneralNames } from '../../namesConstants/names';
import { LOADING_OFF, LOADING_ON } from '../../business/constants';
import {
	downloadFileBase64,
	downloadFileExcel,
	formatDate,
	getFileNameWithDateTime,
} from '../../utils/utils';
import { tableColumnsData } from '../../screens/Incidenses/SendIncidences/utils';
import moment from 'moment';
import { useExport } from '../../hooks/export/useExport';
import { SendIncidencesFilterContext } from '../../context/wrapperContext/queries/SendIncidencesFilterContext';

export const SendIncidencesServiceContext = createContext();

export const SendIncidencesServiceManagerProvider = ({ children }) => {
	const filterContext = useContext(SendIncidencesFilterContext);
	//console.log('fc =>', filterContext);
	const { exportPayRollData } = useExport();
	const [toSend, setToSend] = useState([]);
	const [sort, setSort] = useState([
		{
			field: 'incidenceDate',
			order: -1,
		},
	]);

	const [incidences, setIncidences] = useState([]);
	const [statusBar, setStatusBar] = useState(null);
	const [globalFilterValue, setGlobalFilterValue] = useState('');

	const [showDialog, setShowDialog] = useState(false);
	const [filters, setFilters] = useState({
		global: { value: null, matchMode: FilterMatchMode.CONTAINS },
		statusText: { value: null, matchMode: FilterMatchMode.CONTAINS },
	});
	const [valueInputFilters, setValueInputFilters] = useState({
		statusText: '',
	});

	const isPayrollCall = JSON.parse(sessionStorage.getItem('isPayrollCall'));

	const {
		getPeriodState,
		getIncidencesState,
		getIncidencesStateByProcessId,
		loadingState,
		dispatchLoading,
		companyState,
		loadingContextState,
	} = useContext(StoreContext);
	const { stateLoadingWithMessage } = loadingContextState;
	const { getIncidences, postIncidences } = useIncidences({
		StartDate: filterContext.period?.initialDate,
		EndDate: filterContext.period?.finalDate,
		PeriodTypeId: filterContext.typePeriod?.periodType,
	});
	const { getPeriods, importPeriods } = usePeriods();
	useReload([getDataPeriods, getPeriods]);

	useEffect(() => {
		//debugger;
		if (
			getPeriodState.periods !== null &&
			getPeriodState.periods.length !== 0 &&
			filterContext.isLoadTypesPeriods === false
		) {
			const typePeriod = getPeriodState?.periods;
			// Encuentra el primer elemento en typePeriod que tiene la propiedad 'periods'
			const periodWithPeriods = typePeriod.find(
				(item) => item.periods && item.periods.length > 0
			);
			filterContext.setTypePeriods(typePeriod);
			validatePeriods(periodWithPeriods, typePeriod);
			filterContext.setIsLoadTypesPeriods(true);
		}
	}, [getPeriodState.periods, filterContext.isLoadTypesPeriods]);

	useEffect(() => {
		if (getIncidencesState.incidences !== null) {
			const data = getIncidencesState?.incidences?.results;
			const newResults = data?.map((incidence, i) => {
				return {
					...incidence,
					errorDetail:
						incidence.errorDetail === null ? '' : incidence.errorDetail,
				};
			});

			setIncidences(newResults);
		}
	}, [getIncidencesState]);

	useEffect(() => {
		if (getIncidencesStateByProcessId?.data !== null) {
			const { data } = getIncidencesStateByProcessId.data;

			setStatusBar(data?.step);
			if (data?.step === 4) {
				setTimeout(async () => {
					setShowDialog(false);
					setStatusBar(null);
				}, 1000);
			}
		}
	}, [getIncidencesStateByProcessId]);

	function validatePeriods(_periodWithPeriods, typePeriod) {
		if (_periodWithPeriods !== undefined) {
			filterContext.setTypePeriod(_periodWithPeriods);
			const periods = _periodWithPeriods.periods;
			const periodsConverter = periods.map((item) => {
				return {
					...item,
					name:
						item.name +
						' - ' +
						formatDate(item.initialDate) +
						' al ' +
						formatDate(item.finalDate),
				};
			});

			filterContext.setPeriods(periodsConverter);
			const period = periodSelectFunc(periods);

			const typePeriodValue = _periodWithPeriods;
			async function getData() {
				return await getIncidencesAction(period, typePeriodValue?.periodType);
			}
			if (period !== null) {
				const periodSelectData = periodsConverter.find(
					(item) => item.periodId === period.periodId
				);

				filterContext.setPeriod(periodSelectData);
				getData(); // Llama a getData como una función para cargar los datos
			}
		} else {
			const periodSelect = typePeriod[0];
			return notPeriods(periodSelect);
		}
	}

	async function getDataPeriods() {
		return await getIncidencesAction(
			filterContext.period,
			filterContext.typePeriod?.periodType
		);
	}

	function periodSelectFunc(periodos) {
		if (periodos && periodos.length > 0) {
			const fechaActual = moment();

			let periodBefore;
			let periodAfter;
			const periodoActual = periodos
				.sort((a, b) => moment(a.initialDate).isAfter(moment(b.initialDate)))
				.find((periodo) => {
					const fechaInicial = moment(periodo.initialDate);
					const fechaFinal = moment(periodo.finalDate);

					return fechaActual.isBetween(
						fechaInicial,
						fechaFinal,
						undefined,
						'[]'
					);
				});

			const OrderDes = periodos.sort((a, b) =>
				moment(a.initialDate).isAfter(moment(b.initialDate))
			);
			periodAfter = OrderDes.find((periodo) => {
				const fechaInicial = moment(periodo.initialDate);
				return fechaInicial.isSameOrAfter(fechaActual);
			});

			const OrderAsc = periodos.sort((a, b) =>
				moment(a.initialDate).isAfter(moment(b.initialDate))
			);
			periodBefore = OrderAsc.sort((a, b) =>
				moment(a.initialDate).isBefore(moment(b.initialDate))
			).find((periodo) => {
				const fechaFinal = moment(periodo.initialDate);
				return fechaFinal.isSameOrBefore(fechaActual);
			});

			const returnPeriod = periodoActual || null;
			if (returnPeriod == null) {
				if (periodAfter) {
					Toast(
						'',
						'Se está consultando incidencias de un periodo cercano a la fecha actual',
						'',
						''
					);
					return periodAfter;
				} else if (periodBefore) {
					Toast(
						'',
						'Se está consultando incidencias de un periodo anterior',
						'',
						''
					);
					return periodBefore;
				} else {
					Toast(
						'warning',
						'No se encontró un periodo cercano a la fecha actual',
						'',
						''
					);
					return null;
				}
			} else {
				return returnPeriod;
			}
		}
	}

	async function handleTypePeriodSelectChange(data) {
		filterContext.setTypePeriod(data);
		const periods = data.periods;

		const periodsConverter = periods.map((item) => {
			return {
				...item,
				name:
					item.name +
					' - ' +
					formatDate(item.initialDate) +
					' al ' +
					formatDate(item.finalDate),
			};
		});

		filterContext.setPeriods(periodsConverter);
		const _period = periodSelectFunc(periodsConverter);
		filterContext.setPeriod(_period);

		return periodsConverter.length > 0
			? await getIncidencesAction(_period, data?.periodType)
			: notPeriods(data);
	}

	async function notPeriods(_data) {
		setIncidences([]);
		return await importPeriods(_data.periodType);
	}

	async function handlePeriodSelectChange(data) {
		filterContext.setPeriod(data);

		return await getIncidencesAction(
			data,
			filterContext.typePeriod?.periodType
		);
	}

	async function getIncidencesAction(periodSelect, periodType) {
		if (periodSelect === undefined) {
			setIncidences([]);
		}

		const params = {
			StartDate: periodSelect.initialDate,
			EndDate: periodSelect.finalDate,
			PeriodTypeId: periodType,
		};
		const result = await getIncidences(params);
		return periodType !== undefined &&
			periodSelect.initialDate !== undefined &&
			periodSelect.finalDate !== undefined
			? result
			: null;
	}

	function onGlobalFilterChange(e) {
		const { value } = e.target;
		const _filters = { ...filters };
		_filters.global.value = value;
		setFilters(_filters);
		setGlobalFilterValue(value);
	}

	function clearTextLabel() {
		setGlobalFilterValue('');
		setFilters({
			global: { value: '', matchMode: FilterMatchMode.CONTAINS },
		});
	}
	const bodyDate = (rowData) => {
		const date = moment.utc(rowData);
		date.startOf('day');
		const formattedDate = date.format('DD/MM/YYYY');
		return formattedDate;
	};
	const statusChange = (type) => {
		switch (type) {
			case 1:
				return '005EB8';
			case 2:
				return 'FF8585';
			default:
				return '65CD81';
		}
	};
	function dataToExcel() {
		// modificar data en los campos requestDate y statusChangeDate para formato de fecha dd/mm/yyyy
		const dataExcel = incidences.map(
			({
				code,
				errorDetail,
				incidenceDate,
				incidenceTime,
				justifiedBy,
				justifiedOn,
				name,
				shiftBegins,
				shiftEnds,
				status,
				statusText,
				typeIncidence,
				workShift,
			}) => ({
				code: {
					value: parseInt(code),
					style: {},
				},
				errorDetail: {
					value: errorDetail,
					style: { wrapText: true },
				},
				incidenceDate: {
					value: bodyDate(incidenceDate),
					style: {
						horizontalAlignment: 'center',
						verticalAlignment: 'bottom',
					},
				},
				incidenceTime: {
					value: incidenceTime,
					style: {},
				},
				justifiedBy: {
					value: justifiedBy,
					style: {},
				},
				justifiedOn: {
					value: bodyDate(justifiedOn),
					style: {},
				},
				name: {
					value: name,
					style: {},
				},
				shiftBegins: {
					value: shiftBegins,
					style: {},
				},
				shiftEnds: {
					value: shiftEnds,
					style: {},
				},
				statusText: {
					value: statusText,
					style: {
						fontColor: statusChange(status),
						horizontalAlignment: 'center',
						verticalAlignment: 'bottom',
					},
				},
				typeIncidence: {
					value: typeIncidence,
					style: {},
				},
				workShift: {
					value: workShift,
					style: {},
				},
			})
		);
		const toExcel = {
			filename: 'Envio de Incidencias',
			templateFileName: 'query_template.xlsx',
			replace: [
				{
					type: 0,
					tag: '#simple.name',
					prop: 'name',
					default: 'Simple',
				},
				{
					type: 1,
					tag: '#simple.company',
					prop: 'company',
					default: 'Simple',
				},
				{
					type: 1,
					tag: '#printDate',
					prop: 'printDate',
					default: new Date().toLocaleDateString(),
					format: ['numberFormat', 'dd mmmm yyyy'],
				},
				{
					type: 1,
					tag: '#simple.appName',
					prop: 'appName',
					default: 'Simple',
				},
				{
					type: 1,
					tag: '#simple.name',
					prop: 'report',
					default: 'Simple',
				},
				{
					type: 2,
					tag: '#simple.cols',
					prop: 'cols',
				},
				{
					type: 4,
					tag: '#simple.rows',
					prop: 'rows',
					colprop: 'cols',
				},
			],
			data: {
				company: companyState?.companies?.name
					? companyState.companies.name
					: '',
				autoFilter: true,
				gridLinesVisible: true,
				printDate: new Date().toLocaleDateString(),
				appName: 'Colabora',
				name: 'Incidencias',
				report: `Incidencias - ${filterContext.typePeriod.name} - ${filterContext.period.name}`,
				cols: [],
				rows: dataExcel,
			},
		};
		// cols = [{ title: "", prop:"", width: ""}]
		// title es el nombre de la columna y prop es el nombre de la propiedad del objeto
		// width es el ancho de la columna

		tableColumnsData.forEach((header, index) => {
			let width = 35;
			switch (tableColumnsData[index].field) {
				case 'code':
					width = 12;
					break;
				case 'errorDetail':
					width = 50;
					break;
				case 'incidenceDate':
					width = 25;
					break;
				case 'incidenceTime':
					width = 12;
					break;
				case 'statusText':
					width = 20;
					break;
				default:
					break;
			}
			const col = {
				title: tableColumnsData[index].label,
				prop: tableColumnsData[index].field,
				width: width,
			};
			toExcel.data.cols.push(col);
		});
		return toExcel;
	}
	async function toExcelExport() {
		if (incidences.length > 0) {
			dispatchLoading({ type: LOADING_ON });
			const toExcel = dataToExcel();
			const filName = getFileNameWithDateTime('Envío_Incidencias');

			await downloadFileBase64(toExcel, 'xlsx', filName)
				.then(() => {
					dispatchLoading({ type: LOADING_OFF });
				})
				.catch((error) => {
					dispatchLoading({ type: LOADING_OFF });
				});
		} else {
			Toast(
				'warning',
				GeneralNames.EmptyDataMessageExport,
				GeneralNames.EmptyDataMessageBodyExport,
				'success'
			);
		}
	}

	const iconsData = [
		{
			icon: 'excel',
			title: 'Exportar a EXCEL',
			onClick: isPayrollCall ? toExcelExportPayroll : toExcelExport,
		},
		...(sessionStorage.getItem('isPayrollCall') === 'false'
			? [
					{
						icon: 'sendEvidences',
						title: 'Enviar incidencias a la nómina',
						onClick: sendIncidences,
					},
			  ]
			: []),
	];

	async function toExcelExportPayroll() {
		if (incidences.length > 0) {
			try {
				const result = await exportPayRollData({
					periodId: filterContext.period.periodId,
				});
				if (result.status === 200) {
					dispatchLoading({ type: LOADING_ON });
					const fillName = getFileNameWithDateTime('Envío_Incidencias');
					await downloadFileExcel(result, fillName)
						.then(() => {
							dispatchLoading({ type: LOADING_OFF });
						})
						.catch(() => {
							dispatchLoading({ type: LOADING_OFF });
						});
				}
				return result;
			} catch (error) {}
		} else {
			Toast(
				'warning',
				GeneralNames.EmptyDataMessageExport,
				GeneralNames.EmptyDataMessageBodyExport,
				'success'
			);
		}
	}

	async function sendIncidences() {
		if (incidences !== null && incidences.length !== 0 && toSend.length !== 0) {
			setShowDialog(true);
			const _data = toSend.length === 0 ? incidences : toSend;

			let _idsIncidences = [];
			_data.forEach((incidence) => {
				_idsIncidences.push(incidence.attendanceId);
			});

			const filters = {
				StartDate: filterContext.period?.initialDate,
				EndDate: filterContext.period?.finalDate,
				PeriodTypeId: filterContext.typePeriod?.periodType,
			};
			const result = await postIncidences(_idsIncidences, filters);
			if (result.status === 200) {
				setStatusBar(0);
			}
			setTimeout(async () => {
				setStatusBar(null);
			}, 2000);
			setToSend([]);
			return result;
		} else {
			Toast('warning', 'No hay incidencias por enviar');
		}
	}
	return (
		<SendIncidencesServiceContext.Provider
			value={{
				typePeriod: filterContext.typePeriod,
				typePeriods: filterContext.typePeriods,
				period: filterContext.period,
				periods: filterContext.periods,
				handlePeriodSelectChange,
				handleTypePeriodSelectChange,
				clearTextLabel,
				onGlobalFilterChange,
				globalFilterValue,
				iconsData,
				loadingState,
				incidences,
				filters,
				setFilters,
				toSend,
				setToSend,
				sort,
				setSort,
				valueInputFilters,
				setValueInputFilters,
				showDialog,
				statusBar,
			}}>
			{children}
		</SendIncidencesServiceContext.Provider>
	);
};
