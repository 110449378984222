import { TableNames } from '../../../../namesConstants/names';
import { TiDeleteOutline } from 'react-icons/ti';

const normalBodyTemplate = (row, col) => {
	return <>{row[col]}</>;
};
const bodyLeader = (row, item, service) => {
	return row !== null && row.trim() !== '' ? (
		<div className='flex w-full flex-row justify-between'>
			<div title={row} className='flex w-full truncate'>
				<p className='truncate'>{row}</p>
			</div>
			<div className='flex-grow '>
				<TiDeleteOutline
					onClick={() => service.OpenModalDeleted(item)}
					className='!text-2xl !cursor-pointer !hover:text3xl'
				/>
			</div>
		</div>
	) : (
		<>{''}</>
	);
};

export const dataTableOrgChart = (service) => {
	return [
		{
			field: 'code',
			headerName: TableNames.TableCode,
			sorteable: true,
			body: (row) => normalBodyTemplate(row, 'code'),
			style: { flexGrow: 1, flexBasis: '30px' },
		},
		{
			field: 'fullName',
			headerName: TableNames.TableName,
			sorteable: true,
			body: (row) => normalBodyTemplate(row, 'fullName'),
			style: { flexGrow: 1, flexBasis: '100px' },
		},
		{
			field: 'position',
			headerName: TableNames.TablePosition,
			sorteable: true,
			body: (row) => normalBodyTemplate(row, 'position'),
			style: { flexGrow: 1, flexBasis: '100px' },
		},
		{
			field: 'department',
			headerName: TableNames.TableDepartment,
			sorteable: true,
			body: (row) => normalBodyTemplate(row, 'department'),
			style: { flexGrow: 1, flexBasis: '100px' },
		},
		{
			field: 'actualLeaderFullName',
			headerName: TableNames.TableActualLeader,
			sorteable: true,
			body: (row) => bodyLeader(row.actualLeaderFullName, row, service),
			style: { flexGrow: 1, flexBasis: '100px' },
		},
		{
			field: 'actualLeaderCompanyName',
			headerName: TableNames.TableCompanyName,
			sorteable: true,
			body: (row) => normalBodyTemplate(row, 'actualLeaderCompanyName'),
			style: { flexGrow: 1, flexBasis: '100px' },
		},
	];
};
