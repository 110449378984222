import React from 'react';
import { ButtonDropDown } from '../Buttons/ButtonDropdown';
import { uris } from './../../Auth/b2cService/b2cServiceConfig';
import { GeneralNames } from '../../namesConstants/names';
import { useSelector } from 'react-redux';

export const ProfileCard = ({
	showPopover,
	setShowPopover,
	refCloseProfileCard,
	closeFunction,
	setShowDialog,
}) => {
	const user = useSelector(({ auth }) => auth.user);

	const userProfileRedirect = () => {
		window.open(uris.platformAccountUri, '_blank').focus();
		//window.open(uris.myAccount, '_blank').focus();
		return null;
	};

	const userTermsRedirect = () => {
		window.open(uris.terms, '_blank').focus();
	};
	const userPrivacyRedirect = () => {
		window.open(uris.privacy, '_blank').focus();
	};

	const closeFunctionDialog = () => {
		closeFunction(true);
	};

	const showInfoModal = () => {
		return setShowDialog(true);
	};

	const settingsMenu = [
		{
			id: 1,
			text: GeneralNames.GeneralMyProfile,
			icon: 'icon-person-fill',
			action: userProfileRedirect,
		},
		{
			id: 2,
			text: GeneralNames.GeneralTermsAndConditions,
			icon: 'icon-draft-fill',
			action: userTermsRedirect,
		},
		{
			id: 4,
		 	text: GeneralNames.GeneralPrivacyPolicy,
		 	icon: 'icon-visibility-fill',
		 	action: userPrivacyRedirect,
		},
	];

	return (
		<div
			ref={refCloseProfileCard}
			className=' flex flex-col w-full relative   '>
			<div
				onClick={() => setShowPopover(!showPopover)}
				className='flex w-full h-full flex-row text-secondary-contrast-color-text  rounded-md hover:cursor-pointer '>
				<div className='flex h-full flex-row w-[25%] lg:content-center xl:content-center 2xl:content-center lg:items-center xl:items-center 2xl:items-center lg:justify-center xl:justify-center 2xl:justify-center'>
					{
						<p className='!text-secondary-PCS002 flex text-4xl lg:!text-5xl justify-center content-center items-center '>
							<span className={'icon-avatar'}></span>
						</p>
					}

					<p className='flex  xs:hidden sm:hidden md:hidden lg:hidden xl:hidden 2xl:hidden !text-secondary-PCS002  !text-xl justify-center content-center items-center '>
						<span className={'icon-expand-more'}></span>
					</p>
				</div>
				<div className='hidden xs:flex sm:flex md:flex lg:flex xl:flex 2xl:flex h-full w-[75%] flex-col '>
					<div className='flex items-start content-center justify-between text-center w-full h-1/2 text-code-font-size'>
						<p className='text-center !text-black md:text-sm lg:text-md font-bold truncate'>
							{user?.data?.displayName}
						</p>
						{
							<p className='!text-secondary-PCS002 flex !text-xl justify-center content-center items-center '>
								<span className={'icon-expand-more'}></span>
							</p>
						}
					</div>
					<div className='flex justify-start items-start content-start font-light w-full h-1/2 text-small-font-size truncate'>
						<p className='!text-black text-[.6rem]  lg:text-xs text-left font-semibold'>
							{user?.data?.email}
						</p>
					</div>
				</div>
			</div>
			{showPopover ? (
				<ul className='flex w-56 flex-col pt-0 absolute top-[3rem] divide-y right-1 rounded-2xl bg-white drop-shadow-2xl  '>
					{settingsMenu.map((item, index) => {
						return (
							<li key={index}>
								<ButtonDropDown
									key={item.text}
									className='h-12 w-full p-2 !font-h3-font-family'
									Icon={item.icon}
									//title={getTranslation(t, item.text)}
									title={item.text}
									onClick={item.action}
								/>
							</li>
						);
					})}
					<li onClick={() => showInfoModal()}>
						<ButtonDropDown
							key={'off'}
							className={' h-12 border w-full border-t-8 border-gray-500'}
							Icon={'icon-info-fill'}
							title={GeneralNames.GeneralVersion}
							onClick={() => showInfoModal()}
						/>
					</li>
					<li onClick={() => closeFunctionDialog()}>
						<ButtonDropDown
							key={'off'}
							className={
								'!text-red h-12 border w-full border-t-8 border-gray-500 !text-secondary-PCS002'
							}
							Icon={'icon-cancel-fill'}
							//title={getTranslation(t, ToolBarNames.LOGOUT)}
							title={GeneralNames.GeneralLogout}
							onClick={() => closeFunctionDialog()}
						/>
					</li>
				</ul>
			) : null}
		</div>
	);
};
