const DaysOffObject = {
	companyDayOffId: undefined,
	colaboraInstanceId: undefined,
	year: undefined,
	date: undefined,
	officialDay: true,
	appliesAllWorkCenter: true,
	description: '',
	workCenters: [],
	addWorkCenters: [],
	deleteWorkCenters: [],
};

export default DaysOffObject;
