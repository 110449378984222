import React, { useEffect, useState, createContext } from "react";

export const LoadingContext = createContext();
export const LoadingProvider = ({ children }) => {
  const [loading, setLoading] = useState(undefined);

  useEffect(() => {
    if (loading === undefined) {
      setLoading(false)
    }
  }, [loading]);
  return (
    <LoadingContext.Provider
      value={{ loading, setLoading }}
    >
      {children}
    </LoadingContext.Provider>
  )
}
