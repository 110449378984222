import { Dialog } from 'primereact/dialog';
import React, { useState } from 'react';
import { CustomTable } from '../../../../components/Table/CustomTable';
import {
	ErrorsNames,
	GeneralNames,
	InvitationsScreenNames,
	TableNames,
} from '../../../../namesConstants/names';
import { bodyClassName, headerClassName } from '../Const/Constants';
import { Column } from 'primereact/column';
export const ModalErrors = ({ service }) => {
	const [sort, setSort] = useState([{ field: 'fullName', order: 1 }]);
	const content = () => {
		return (
			<CustomTable
				sortField='fullName'
				value={service.errorDataSchedule}
				//loading={loading}
				header={undefined}
				style={{ height: '100%', width: '100%', overflow: 'auto' }}
				//loading={loading}
				filters={undefined}
				responsiveLayout='scroll'
				globalFilterFields={['error']}
				emptyMessage={GeneralNames.GeneralEmptyMessage}
				currentPageReportTemplate={ErrorsNames.CurrentPageReportTemplate}
				resizableColumns
				columnResizeMode='fit'
				setSort={setSort}
				sort={sort}>
				{/* <Column
					headerClassName={headerClassName}
					style={{ flexGrow: 4, flexBasis: '100px' }}
					field='fullName'
					header={TableNames.TableFirstName}
					sortable
					bodyClassName={bodyClassName}
					body={(rowData) => {
						return rowData?.fullName;
					}}
				/> */}
				<Column
					headerClassName={headerClassName}
					style={{ flexGrow: 4, flexBasis: '100px' }}
					field='error'
					header={TableNames.TableErrorName}
					sortable
					bodyClassName={bodyClassName}
					body={(rowData) => {
						return rowData?.error;
					}}
				/>
			</CustomTable>
		);
	};
	return (
		<Dialog
			header={'Se encontraron errores al asignar horarios'}
			visible={service.openDialogErrors}
			modal
			style={{ width: '60vw', position: 'relative', height: '60vh' }}
			onHide={() => {
				service.onCloseErrorDialog();
			}}>
			{content()}
		</Dialog>
	);
};
