import React from 'react';
import { Dialog } from 'primereact/dialog';
import { TableModalComponent } from './TableModalComponent';
export const ModalComponent = ({ service }) => {
	const renderTable = () => {
		return (
			<TableModalComponent
				filtersList={service.collaboratorsList}
				typeInvitation={service.typeInvitation}
			/>
		);
	};

	const value = service.lengthUsersSend - service.collaboratorsList.length;
	const content = () => {
		return (
			<div className='flex flex-col w-full h-full space-y-2'>
				<p>
					{service.typeInvitation === 'invitation'
						? 'No se pudo enviar la invitación a los siguientes colaboradores: '
						: 'No se pudo anular la invitación a los siguientes colaboradores, debido a que no cuentan con una invitación previa'}
				</p>
				<p>
					{service.typeInvitation === 'invitation'
						? `${value} invitaciones enviadas satisfactoriamente.`
						: `${value} invitaciones canceladas satisfactoriamente.`}
				</p>
				{renderTable()}
			</div>
		);
	};

	return (
		<Dialog
			header={
				service.typeInvitation === 'invitation'
					? 'Envío masivo de invitaciones'
					: 'Anulación masiva de invitaciones'
			}
			visible={service.openModal}
			modal
			style={{ width: '60vw', position: 'relative', height: '60vh' }}
			onHide={() => {
				service.setOpenModal(false);
				service.handleModalClose();
			}}>
			{content()}
		</Dialog>
	);
};
