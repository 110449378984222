import { useState, useContext, useEffect } from 'react';
import {
	GET_INVITATIONS,
	GET_INVITATIONS_SUCCESS,
	GET_INVITATIONS_ERROR,
	SEND_INVITATIONS,
	SEND_INVITATIONS_SUCCESS,
	SEND_INVITATIONS_ERROR,
	RE_IMPORT_DATA,
	RE_IMPORT_DATA_SUCCESS,
	RE_IMPORT_DATA_ERROR,
	VERIFY_AVAILABLE_LICENSE,
	VERIFY_AVAILABLE_LICENSE_SUCCESS,
	VERIFY_AVAILABLE_LICENSE_ERROR,
	SEND_UN_INVITE,
	SEND_UN_INVITE_SUCCESS,
	SEND_UN_INVITE_ERROR,
	INVITATION_FORWARD,
	INVITATION_FORWARD_SUCCESS,
	INVITATION_FORWARD_ERROR,
	LOADING_ON,
	LOADING_OFF,
	SEND_INVITATIONS_ERROR_SHOW,
	INVITATION_FORWARD_ERROR_SHOW,
} from '../../business/constants';
import { StoreContext } from '../../business/Provider';
import { Toast } from '../../components/toast';
import useApi from '../api';
import { generateUrlBase } from '../../utils/utils';
import { AlertToastInfoNames } from '../../namesConstants/names';
import moment from 'moment';

const useInvitations = (
	updateProgressBar,
	setShowProgressBarDialog,
	validateErrorsInData
) => {
	//console.log('useInvitations', updateProgressBar);
	const [invitations, setInvitations] = useState(undefined);
	const { genericApiCall } = useApi();
	const {
		getInvitationsState,
		dispatchGetInvitations,
		dispatchPostInvitations,
		dispatchSendUnInvite,
		dispatchInvitationForward,
		dispatchLoading,
		dispatchReImportData,
		dispatchVerifyAvailableLicense,
	} = useContext(StoreContext);

	// Helper functions to handle common actions

	const handleToast = (type, message, details = '') => {
		Toast(type, message, '', details);
	};

	// Consolidated API call with basic structure
	const apiCall = async (
		url,
		method,
		data = {},
		dispatchSuccess,
		dispatchError
	) => {
		dispatchLoading({ type: LOADING_ON });

		try {
			const result = await genericApiCall(url, method, data);
			if (result.status === 200) {
				dispatchSuccess(result.data);
			} else {
				dispatchError(result.message);
			}
			return result;
		} catch (error) {
			dispatchLoading({ type: LOADING_OFF });
			dispatchError(error.message);
			handleToast('warning', error.message);
		} finally {
			dispatchLoading({ type: LOADING_OFF });
		}
	};

	// Fetch Invitations
	const getInvitations = async () => {
		dispatchGetInvitations({ type: GET_INVITATIONS });
		dispatchLoading({ type: LOADING_ON });
		const baseUrl = generateUrlBase('collaborators');
		try {
			const result = await genericApiCall(baseUrl, 'GET', {}, {}, {});
			const payload = result.data.results;
			const newPayload = payload.map((item) => {
				return {
					...item,
					invitationResponseDate:
						item.invitationResponseDate !== null
							? moment(
									item.invitationResponseDate.replace(/\.\d+$/, '')
							  ).toDate() // truncar milisegundos
							: item.invitationResponseDate,
					invitationSendingDate:
						item.invitationSendingDate !== null
							? moment(
									item.invitationSendingDate.replace(/\.\d+$/, '')
							  ).toDate() // truncar milisegundos
							: item.invitationSendingDate,
				};
			});

			if (result.status === 200) {
				dispatchGetInvitations({
					type: GET_INVITATIONS_SUCCESS,
					payload: newPayload,
				});
				dispatchLoading({ type: LOADING_OFF });
			} else {
				let message = {
					body: '',
					header: 'Error',
				};
				if (result.message) message.body = result.message;
				dispatchGetInvitations({
					type: GET_INVITATIONS_ERROR,
					payload: message,
				});
				dispatchLoading({ type: LOADING_OFF });
			}
			return result;
		} catch (error) {
			Toast('warning', error.message, '', error);
			dispatchGetInvitations({ type: GET_INVITATIONS_ERROR });
			dispatchLoading({ type: LOADING_OFF });
		}
	};

	//Send Invitations
	const sendInvitation = async (receiveData) => {
		dispatchPostInvitations({ type: SEND_INVITATIONS });
		dispatchLoading({ type: LOADING_ON });
		try {
			const baseUrl = generateUrlBase('invitations/invite');
			const result = await genericApiCall(baseUrl, 'POST', receiveData, {}, {});
			const collaborators = result.data.results;
			if (result.status === 200) {
				dispatchPostInvitations({
					type: SEND_INVITATIONS_SUCCESS,
					payload: collaborators,
				});
				Toast('success', AlertToastInfoNames.SendInvitationSuccess, '', '');
				dispatchLoading({ type: LOADING_OFF });
				return getInvitations();
			} else {
				let message = {
					body: '',
					header: 'Error',
				};
				if (result.message) message.body = result.message;
				dispatchPostInvitations({
					type: SEND_INVITATIONS_ERROR_SHOW,
					payload: message,
				});
				dispatchLoading({ type: LOADING_OFF });
			}
			Toast('success', AlertToastInfoNames.SendInvitationSuccess, '', '');
			return result;
		} catch (error) {
			Toast('warning', error.message, '', error);
			dispatchPostInvitations({ type: SEND_INVITATIONS_ERROR });
			dispatchLoading({ type: LOADING_OFF });
			return error;
		}
	};

	function splitArray(arr, n) {
		// Verificar si n es NaN y establecerlo en 20 si lo es
		if (isNaN(n)) {
			n = 20;
		}
		const result = [];
		for (let i = 0; i < arr.length; i += n) {
			const arrayToPush = arr.slice(i, i + n);
			result.push(arrayToPush);
		}
		return result;
	}
	const sendInvitationsInParallel = async (dataArray) => {
		dispatchPostInvitations({ type: SEND_INVITATIONS });

		const totalValue =
			window.REACT_APP_BATCH_INVITATIONS !== undefined &&
			window.REACT_APP_BATCH_INVITATIONS !== null
				? parseInt(window.REACT_APP_BATCH_INVITATIONS, 10)
				: 20; // Leer el valor de la variable de entorno

		const dataSplit = splitArray(dataArray, totalValue); // Divide el array en partes

		const urls = dataSplit.map(() => generateUrlBase('invitations/invite'));

		const totalRequests = urls.length;
		let completedRequests = 0;
		let newData = [];
		try {
			const promises = urls.map((url, index) =>
				apiCall(
					url,
					'POST',
					dataSplit[index], // Pasamos el chunk de datos correspondiente
					(data) => {
						completedRequests++;
						newData.push(...data.data.errorInvitations);
						updateProgressBar((completedRequests / totalRequests) * 100); // Actualizamos el progreso
						dispatchPostInvitations({
							type: SEND_INVITATIONS_SUCCESS,
							payload: dataSplit[index], // El chunk que fue procesado
						});
					},
					(message) => {
						dispatchPostInvitations({
							type: SEND_INVITATIONS_ERROR,
							payload: { header: 'Error', body: message },
						});
					}
				)
			);

			await Promise.all(promises); // Esperamos a que todas las promesas se resuelvan
			//console.log('Todos los datos obtenidos:', results);

			handleToast('success', AlertToastInfoNames.SendInvitationSuccess); // Mostramos toast de éxito
			setShowProgressBarDialog(false); // Ocultamos el diálogo de progreso
			//console.log('new Data to send', newData);
			validateErrorsInData(newData);
			return await getInvitations(); // Refetch invitations once all are completed
		} catch (error) {
			console.error('Error en las peticiones:', error);
			handleToast('warning', error.message); // Mostramos toast de error
		}
	};

	//sen invitation massive

	// Send Invitation Forward
	const sendInvitationForward = async (receiveData) => {
		// invitationForward,
		// 	dispatchInvitationForward,
		dispatchInvitationForward({ type: INVITATION_FORWARD });
		dispatchLoading({ type: LOADING_ON });
		try {
			const baseUrl = generateUrlBase('invitations/forward');
			const result = await genericApiCall(baseUrl, 'POST', receiveData, {}, {});
			const collaborators = result.data.results;
			if (result.status === 200) {
				dispatchInvitationForward({
					type: INVITATION_FORWARD_SUCCESS,
					payload: collaborators,
				});

				dispatchLoading({ type: LOADING_OFF });
				const resultAwait = await getInvitations();
				Toast('success', AlertToastInfoNames.SendInvitationSuccess, '', '');
				return resultAwait;
			} else {
				let message = {
					body: '',
					header: 'Error',
				};
				if (result.message) message.body = result.message;
				dispatchInvitationForward({
					type: INVITATION_FORWARD_ERROR_SHOW,
					payload: message,
				});
				dispatchLoading({ type: LOADING_OFF });
			}
			Toast('success', AlertToastInfoNames.SendInvitationSuccess, '', '');
			return result;
		} catch (error) {
			Toast('warning', error.message, '', error);
			dispatchInvitationForward({ type: INVITATION_FORWARD_ERROR });
			dispatchLoading({ type: LOADING_OFF });
		}
	};

	// Send Un-invite
	const sendUnInvite = async (receiveData) => {
		const baseUrl = generateUrlBase('invitations/invite');
		dispatchSendUnInvite({ type: SEND_UN_INVITE });
		dispatchLoading({ type: LOADING_ON });
		try {
			const result = await genericApiCall(
				baseUrl,
				'DELETE',
				receiveData,
				{},
				{}
			);
			const collaborators = result.data;
			if (result.status === 200) {
				dispatchSendUnInvite({
					type: SEND_UN_INVITE_SUCCESS,
					payload: collaborators,
				});
				dispatchLoading({ type: LOADING_OFF });
				const resultAwait = await getInvitations();
				if (result.data.data.length > 0) {
					Toast('warning', AlertToastInfoNames.sendUnInvitationWarning, '', '');
				} else {
					Toast('success', AlertToastInfoNames.sendUnInvitationSuccess, '', '');
				}
				return result;
			} else {
				let message = {
					body: '',
					header: 'Error',
				};
				if (result.message) message.body = result.message;
				dispatchSendUnInvite({
					type: SEND_UN_INVITE_ERROR,
					payload: message,
				});
				dispatchLoading({ type: LOADING_OFF });
			}
		} catch (error) {
			dispatchSendUnInvite({ type: SEND_UN_INVITE_ERROR });
			dispatchLoading({ type: LOADING_OFF });
			Toast('warning', error.message, '', error);
		}
	};

	const sendUnInviteParallel = async (data) => {};

	// Re-Import Data
	const reImportDataUser = async (data) => {
		dispatchReImportData({ type: RE_IMPORT_DATA });
		const url = generateUrlBase('import-data');

		await apiCall(
			url,
			'POST',
			data,
			(result) => {
				dispatchReImportData({
					type: RE_IMPORT_DATA_SUCCESS,
					payload: result,
				});
				handleToast('success', AlertToastInfoNames.ReImportDataSuccess);
			},
			() => {
				dispatchReImportData({ type: RE_IMPORT_DATA_ERROR });
			}
		);
	};

	// Verify Available License
	const verifyAvailableLicense = async (data) => {
		dispatchVerifyAvailableLicense({ type: VERIFY_AVAILABLE_LICENSE });
		const url = generateUrlBase('invitations/verify-available');

		return await apiCall(
			url,
			'POST',
			data,
			(result) => {
				if (result < data.length) {
					dispatchVerifyAvailableLicense({
						type: VERIFY_AVAILABLE_LICENSE_ERROR,
					});
					handleToast('warning', AlertToastInfoNames.MaxLicenseAvailable);
				} else {
					dispatchVerifyAvailableLicense({
						type: VERIFY_AVAILABLE_LICENSE_SUCCESS,
						payload: result,
					});
				}
			},
			() => {
				dispatchVerifyAvailableLicense({
					type: VERIFY_AVAILABLE_LICENSE_ERROR,
				});
			}
		);
	};

	useEffect(() => {
		if (!getInvitationsState.isDataLoaded) getInvitations();
	}, []);

	return {
		invitations,
		setInvitations,
		getInvitations,
		sendInvitation,
		sendInvitationsInParallel,
		sendInvitationForward,
		sendUnInvite,
		reImportDataUser,
		verifyAvailableLicense,
		sendUnInviteParallel,
	};
};

export default useInvitations;
