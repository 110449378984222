import { useState, useContext, useEffect } from 'react';
import { StoreContext } from '../../business/Provider';
import { Toast } from '../../components/toast';
import { generateUrlBase } from '../../utils/utils';
import useApi from '../api';
import {
	LOADING_OFF,
	LOADING_ON,
	GET_COMPANY_SETTINGS,
	GET_COMPANY_SETTINGS_ERROR,
	GET_COMPANY_SETTINGS_SUCCESS,
	POST_COMPANY_SETTINGS_ATTENDANCE,
	POST_COMPANY_SETTINGS_ATTENDANCE_ERROR,
	POST_COMPANY_SETTINGS_ATTENDANCE_SUCCESS,
	POST_COMPANY_SETTINGS_INCIDENTS,
	POST_COMPANY_SETTINGS_INCIDENTS_SUCCESS,
	POST_COMPANY_SETTINGS_INCIDENTS_ERROR,
	GET_COMPANY_SETTINGS_NOTIFICATIONS,
	GET_COMPANY_SETTINGS_NOTIFICATIONS_SUCCESS,
	GET_COMPANY_SETTINGS_NOTIFICATIONS_ERROR,
	POST_COMPANY_SETTINGS_NOTIFICATIONS_SUCCESS,
	POST_COMPANY_SETTINGS_NOTIFICATIONS,
	POST_COMPANY_SETTINGS_NOTIFICATIONS_ERROR,
} from './../../business/constants';
import { AlertToastInfoNames } from '../../namesConstants/names';
import { ToastList } from '../../components/toastList';

const useCompanySetting = () => {
	const [companySettings, setCompanySettings] = useState(undefined);
	const [notifications, setNotifications] = useState(undefined);
	const context = useContext(StoreContext);
	const {
		companySettingInitialState,
		dispatchLoading,
		dispatchCompanySetting,
		dispatchCompanySettingsIncident,
		dispatchCompanySettingsAttendance,
		dispatchCompanySettingNotification,
	} = context;

	const { genericApiCall } = useApi();

	const getCompanySettings = async () => {
		dispatchLoading({ type: LOADING_ON });
		dispatchCompanySetting({ type: GET_COMPANY_SETTINGS });
		const baseUrl = generateUrlBase(`company-settings`);
		try {
			const result = await genericApiCall(baseUrl, 'GET', {}, {}, {});
			const payload = result.data.data;
			if (result.status === 200) {
				dispatchCompanySetting({
					type: GET_COMPANY_SETTINGS_SUCCESS,
					payload: payload,
				});
				dispatchLoading({ type: LOADING_OFF });
				setCompanySettings(payload);
			}
			return result;
		} catch (error) {
			if (error.status !== 404) {
				dispatchCompanySetting({ type: GET_COMPANY_SETTINGS_ERROR });
				Toast('warning', error.message, '', error);
				dispatchLoading({ type: LOADING_OFF });
			} else {
				dispatchLoading({ type: LOADING_OFF });
				return null;
			}
		}
	};

	const postCompanySettingIncident = async (receiveData) => {
		dispatchCompanySettingsIncident({ type: POST_COMPANY_SETTINGS_INCIDENTS });
		dispatchLoading({ type: LOADING_ON });
		try {
			const baseUrl = generateUrlBase('company-settings/incidents');
			const result = await genericApiCall(baseUrl, 'POST', receiveData, {}, {});
			const resultData = result.data.data;
			if (result.status === 200) {
				dispatchCompanySettingsIncident({
					type: POST_COMPANY_SETTINGS_INCIDENTS_SUCCESS,
					payload: resultData,
				});
				dispatchLoading({ type: LOADING_OFF });
				Toast('success', AlertToastInfoNames.SendIncidenteSuccess, '', '');
			} else {
				let message = {
					body: '',
					header: 'Error',
				};
				if (result.message) message.body = result.message;
				dispatchCompanySettingsIncident({
					type: POST_COMPANY_SETTINGS_INCIDENTS_ERROR,
					payload: message,
				});
				dispatchLoading({ type: LOADING_OFF });
			}
			return result;
		} catch (error) {
			Toast('warning', error.message, '', error);
			dispatchCompanySettingsIncident({
				type: POST_COMPANY_SETTINGS_INCIDENTS_ERROR,
			});
			dispatchLoading({ type: LOADING_OFF });
		}
	};

	const getCompanySettingNotifications = async () => {
		dispatchCompanySettingNotification({
			type: GET_COMPANY_SETTINGS_NOTIFICATIONS,
		});
		dispatchLoading({ type: LOADING_ON });
		try {
			const baseUrl = generateUrlBase('company-settings/notifications');
			const result = await genericApiCall(baseUrl, 'GET', {}, {}, {});
			const resultData = result.data.results;
			if (result.status === 200) {
				dispatchCompanySettingNotification({
					type: GET_COMPANY_SETTINGS_NOTIFICATIONS_SUCCESS,
					payload: resultData,
				});
				dispatchLoading({ type: LOADING_OFF });
				setNotifications(resultData);
			} else {
				let message = {
					body: '',
					header: 'Error',
				};
				if (result.message) message.body = result.message;
				dispatchCompanySettingNotification({
					type: GET_COMPANY_SETTINGS_NOTIFICATIONS_ERROR,
					payload: message,
				});
				dispatchLoading({ type: LOADING_OFF });
			}
			return result;
		} catch (error) {
			if (error.status !== 404) {
				dispatchCompanySettingNotification({
					type: GET_COMPANY_SETTINGS_NOTIFICATIONS_ERROR,
				});
				Toast('warning', error.message, '', error);
				dispatchLoading({ type: LOADING_OFF });
			} else {
				dispatchLoading({ type: LOADING_OFF });
			}
		}
	};

	const postCompanySettingNotifications = async (datalist) => {
		dispatchCompanySettingNotification({
			type: POST_COMPANY_SETTINGS_NOTIFICATIONS,
		});
		dispatchLoading({ type: LOADING_ON });
		try {
			const baseUrl = generateUrlBase('company-settings/notifications');
			const result = await genericApiCall(baseUrl, 'POST', datalist, {}, {});
			const resultData = result.data.results;
			if (result.status === 200) {
				dispatchCompanySettingNotification({
					type: POST_COMPANY_SETTINGS_NOTIFICATIONS_SUCCESS,
					payload: resultData,
				});
				dispatchLoading({ type: LOADING_OFF });
				let showalert = false;
				resultData.forEach((item) => {
					if (item.isError === true && item.message !== null) {
						showalert = true;
						Toast('warning', item.message, '', item.message);
					}
				});
				if (!showalert) {
					Toast('success', AlertToastInfoNames.SendNotificationSuccess, '', '');
				}
			} else {
				let message = {
					body: '',
					header: 'Error',
				};
				if (result.message) message.body = result.message;
				dispatchCompanySettingNotification({
					type: POST_COMPANY_SETTINGS_NOTIFICATIONS_ERROR,
					payload: message,
				});
				dispatchLoading({ type: LOADING_OFF });
			}
			return result;
		} catch (error) {
			dispatchCompanySettingNotification({
				type: POST_COMPANY_SETTINGS_NOTIFICATIONS_ERROR,
			});
			Toast('warning', error.message, '', error);
			dispatchLoading({ type: LOADING_OFF });
		}
	};

	const postCompanySettingAttendance = async (receiveData) => {
		dispatchCompanySettingsAttendance({
			type: POST_COMPANY_SETTINGS_ATTENDANCE,
		});
		dispatchLoading({ type: LOADING_ON });
		try {
			const baseUrl = generateUrlBase('company-settings/attendance');
			const result = await genericApiCall(baseUrl, 'POST', receiveData, {}, {});
			const resultData = result.data;
			if (result.status === 200) {
				dispatchCompanySettingsAttendance({
					type: POST_COMPANY_SETTINGS_ATTENDANCE_SUCCESS,
					payload: resultData,
				});
				dispatchLoading({ type: LOADING_OFF });
			} else {
				let message = {
					body: '',
					header: 'Error',
				};
				if (result.message) message.body = result.message;
				dispatchCompanySettingsAttendance({
					type: POST_COMPANY_SETTINGS_ATTENDANCE_ERROR,
					payload: message,
				});
				dispatchLoading({ type: LOADING_OFF });
			}
			Toast('success', AlertToastInfoNames.SendAttendanceSuccess, '', '');
			return result;
		} catch (error) {
			ToastList('warning', error.message, error.errorData, error);
			dispatchCompanySettingsAttendance({
				type: POST_COMPANY_SETTINGS_ATTENDANCE_ERROR,
			});
			dispatchLoading({ type: LOADING_OFF });
			return error;
		}
	};

	return {
		companySettings,
		getCompanySettings,
		companySettingInitialState,
		postCompanySettingAttendance,
		postCompanySettingIncident,
		postCompanySettingIncident,
		getCompanySettingNotifications,
		notifications,
		postCompanySettingNotifications,
	};
};

export default useCompanySetting;
