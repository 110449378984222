import {
	GET_INVITATIONS_SUCCESS,
	GET_INVITATIONS_ERROR,
	GET_INVITATIONS,
	GET_INVITATIONS_ERROR_SHOW,
} from './../../constants';

export const getInvitationsReducer = (state, actions) => {
	switch (actions.type) {
		case GET_INVITATIONS:
			return {
				...state,
				invitations: [],
				loading: true,
				isDataLoaded: false,
			};
		case GET_INVITATIONS_ERROR:
			return {
				invitations: state.invitations,
				//	collaboratorsOptions: state.collaboratorsOptions,
				loading: false,
				error: actions.payload,
				errorShow: false,
				isDataLoaded: false,
			};
		case GET_INVITATIONS_SUCCESS:
			return {
				invitations: actions.payload,
				//collaboratorsOptions: getCollaboratorOptions(actions.payload),
				loading: false,
				error: null,
				errorShow: false,
				isDataLoaded: true,
			};
		case GET_INVITATIONS_ERROR_SHOW:
			return {
				...state,
				errorShow: true,
				isDataLoaded: false,
			};
		default:
			return state;
	}
};
