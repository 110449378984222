import React, { useEffect, useRef, useState } from 'react';
import { CustomTable } from '../../../../components/Table/CustomTable';
import { GeneralNames, TableNames } from '../../../../namesConstants/names';
import { Column } from 'primereact/column';
import { MdDelete } from 'react-icons/md';
import { headerClassName } from '../../../../assets/customStyles';
export const TableWorkShift = ({
	workShifts,
	setCollaboratorWorkShiftDetialId,
	setOpenDialogDeleteSchedule,
}) => {
	const [sort, setSort] = useState([{ field: 'name', order: 1 }]);
	// const headerClassName =
	// 	'hover:!text-success  !bg-secondary-PCS004 !text-secondary-PCS001 !font-semibold !border-r-2 !border-secondary-PCS003 !justify-center';
	const bodyClassName = `!border-r-2 !border-b-0 !border-secondary-PCS004 !text-black !justify-left`;
	const bodyClassNameCenter = `!border-r-2 !border-b-0 !border-secondary-PCS004 !text-black !justify-center`;

	const [selectedWorkCenter, setSelectedWorkCenter] = useState([]);
	const actionBodyTemplate = (row) => {
		return (
			<div className='flex justify-center content-center items-center'>
				<div className='flex w-3 h-3  rounded-full justify-center content-center items-center  cursor-pointer ml-1'>
					<p title='Eliminar'>
						<MdDelete
							className='text-2xl text-[#669ED4]'
							onClick={() => {
								setCollaboratorWorkShiftDetialId(
									row.collaboratorWorkShiftDetialId
								);
								setOpenDialogDeleteSchedule(true);
							}}
						/>
					</p>
				</div>
			</div>
		);
	};

	const renderContent = () => {
		return (
			<div className='w-full h-full'>
				<CustomTable
					id={'DataTableOperationWorkShiftCollaborators'}
					value={workShifts}
					sortField='Description'
					responsiveLayout='scroll'
					emptyMessage={GeneralNames.GeneralEmptyMessage}
					resizableColumns
					columnResizeMode='fit'
					style={{ height: '100%', width: '100%' }}
					setSort={setSort}
					sort={sort}
					selection={selectedWorkCenter}
					onSelectionChange={(e) => setSelectedWorkCenter(e.value)}>
					<Column
						filterHeaderClassName={
							'!border-r-2 !border-b-0 !border-secondary-PCS004'
						}
						headerClassName={headerClassName}
						style={{ flexGrow: 2, flexBasis: '150px' }}
						field={'startDate'}
						header={'Fecha de Inicio'}
						sortable
						bodyClassName={bodyClassName}
					/>
					<Column
						filterHeaderClassName={
							'!border-r-2 !border-b-0 !border-secondary-PCS004'
						}
						headerClassName={headerClassName}
						style={{ flexGrow: 2, flexBasis: '150px' }}
						field={'formattedWorkShiftDetail'}
						header={'Turno'}
						sortable
						bodyClassName={bodyClassName}
					/>
					<Column
						filterHeaderClassName={
							'!border-r-2 !border-b-0 !border-secondary-PCS004'
						}
						headerClassName={headerClassName}
						style={{ flexGrow: 2, flexBasis: '250px' }}
						field={'description'}
						header={'Descripción'}
						sortable
						bodyClassName={bodyClassName}
					/>
					<Column
						headerClassName={headerClassName}
						style={{ flexGrow: 2, flexBasis: '50px' }}
						header={TableNames.TableActions}
						filterHeaderClassName={
							'!border-r-2 !border-b-0 !border-secondary-PCS004'
						}
						body={actionBodyTemplate}
						bodyClassName={bodyClassNameCenter}
					/>
				</CustomTable>
			</div>
		);
	};

	return renderContent();
};
