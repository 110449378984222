export const days = [
	{ name: 'D', value: '1' },
	{ name: 'L', value: '2' },
	{ name: 'M', value: '3' },
	{ name: 'I', value: '4' },
	{ name: 'J', value: '5' },
	{ name: 'V', value: '6' },
	{ name: 'S', value: '7' },
];

export const headersTranslations = [
	'Clave',
	'Nombre',
	'Horario',
	'Turno Nómina',
	'Días de Descanso',
];

export const headers = [
	'code',
	'description',
	'schedule',
	'workShiftName',
	'fullRestDays',
];
const getDaysFromValues = (str) => {
	// Convertir la cadena en un arreglo de números
	const values = str.split(',');

	// Filtrar y mapear los días correspondientes
	const result = days
		.filter((day) => values.includes(day.value))
		.map((day) => day.name);

	// Convertir el arreglo resultante en una cadena
	return result.join(',');
};
export const workShiftType = [
	{ type: 'Diurna', value: 1 },
	{ type: 'Nocturna', value: 2 },
	{ type: 'Mixta', value: 3 },
	{ type: 'Por Horas', value: 4 },
	{ type: 'Reducida', value: 5 },
	{ type: 'Continuada', value: 6 },
	{ type: 'Partida', value: 7 },
	{ type: 'Por turnos', value: 8 },
	{ type: 'Otra Jornada', value: 99 },
];

export const dataToExcel = (dataToExcel, companyInfo) => {
	const _data = dataToExcel;
	//const formatDate = (date) => date ? new Intl.DateTimeFormat('es-ES').format(new Date(date)) : '';
	const dataExcel = _data.map(
		({ code, description, schedule, workShiftName, restDays }) => {
			const fullRestDays = getDaysFromValues(restDays);
			return { code, description, schedule, workShiftName, fullRestDays };
		}
	);

	const toExcel = {
		filename: 'Horarios',
		templateFileName: 'simple_template.xlsx',
		replace: [
			{
				type: 0,
				tag: '#simple.name',
				prop: 'name',
				default: 'Simple',
			},
			{
				type: 1,
				tag: '#simple.company',
				prop: 'company',
				default: 'Simple',
			},
			{
				type: 1,
				tag: '#printDate',
				prop: 'printDate',
				default: new Date().toLocaleDateString(),
				format: ['numberFormat', 'dd mmmm yyyy'],
			},
			{
				type: 1,
				tag: '#simple.appName',
				prop: 'appName',
				default: 'Simple',
			},
			{
				type: 1,
				tag: '#simple.name',
				prop: 'name',
				default: 'Simple',
			},
			{
				type: 2,
				tag: '#simple.cols',
				prop: 'cols',
			},
			{
				type: 3,
				tag: '#simple.rows',
				prop: 'rows',
				colprop: 'cols',
			},
		],
		data: {
			company: companyInfo?.name,
			autoFilter: true,
			gridLinesVisible: true,
			printDate: new Date().toLocaleDateString(),
			appName: 'Colabora',
			name: 'Horarios',
			cols: [],
			rows: dataExcel,
		},
	};

	headers.forEach((header, index) => {
		const col = {
			title: headersTranslations[index],
			prop: header,
			//width: wchCols[index].wch,
		};
		toExcel.data.cols.push(col);
	});
	return toExcel;
};

export function getDateHour(value) {
	if (value !== undefined) {
		const [h, m, s] = value.split(':');
		const today = new Date();
		today.setHours(h);
		today.setMinutes(m);
		today.setSeconds(s);

		return today;
	}
}

export function getFormattedTimeFromDate(date) {
	const hours = date.getHours().toString().padStart(2, '0');
	const minutes = date.getMinutes().toString().padStart(2, '0');
	const seconds = date.getSeconds().toString().padStart(2, '0');
	return `${hours}:${minutes}:${seconds}`;
}

export function numbersToStringWithCommas(arrayOfNumbers) {
	// Filter out undefined values and then join them with commas
	const result = arrayOfNumbers.filter((item) => item !== undefined).join(', ');
	return result;
}
