import React from 'react';
import { ToolbarSearch } from '../../../../components/ToolbarSearch';
import {
	GeneralNames,
	OrganizationChartNames,
	TableNames,
} from '../../../../namesConstants/names';
import { CustomTable } from '../../../../components/Table/CustomTable';
import { headerClassName } from '../../../../assets/customStyles';

import { bodyClassName } from '../../../Operation/EmployeeManagement/Const/Constants';
import { dataTableOrgChart } from './utils';

import { CiUser } from 'react-icons/ci';
import { Column } from 'primereact/column';
import { PropTypes } from 'prop-types';
import { FaRegUser } from 'react-icons/fa6';
export const OrgChartTableComponent = ({ service }) => {
	const renderHeader = () => {
		return (
			<ToolbarSearch
				onChange={service.onGlobalFilterChange}
				placeholder={GeneralNames.GeneralNamesSearchGeneral}
				value={service.globalFilterValue}
				clearTextLabel={service.clearTextLabel}
				iconsTable={service.iconsData}
			/>
		);
	};

	const actionBodyTemplate = (rowData) => {
		return (
			<div className='flex justify-center content-center items-center'>
				<button
					onKeyDown={(e) => {
						if (e.key === 'Enter' || e.key === ' ') {
							service.onSelectValueByTable(rowData);
						}
					}}
					onClick={() => {
						service.onSelectValueByTable(rowData);
					}}
					title={OrganizationChartNames.TextTooltip}
					className='flex w-4 h-4 justify-center content-center items-center cursor-pointer'
					aria-label='Select Row'>
					<FaRegUser className='text-black text-2xl' />
				</button>
			</div>
		);
	};

	function onDoubleClick(e) {
		service.onSelectValueByTable(e);
	}

	const _dataTableOrgChart = dataTableOrgChart(service);

	return (
		<div className='flex  w-4/5 ml-1 border rounded-md shadow-2xl'>
			<CustomTable
				value={service.dataTable}
				sortField='fullName'
				header={renderHeader()}
				sortOrder={-1}
				//loading={loading}
				filters={service.filters}
				doubleClick={onDoubleClick}
				responsiveLayout='scroll'
				globalFilterFields={[
					'code',
					'fullName',
					'position',
					'department',
					'actualLeaderFullName',
					'actualLeaderCompanyName',
				]}
				emptyMessage={GeneralNames.GeneralEmptyMessage}
				currentPageReportTemplate={
					OrganizationChartNames.CurrentPageReportTemplate
				}
				resizableColumns
				columnResizeMode='fit'
				selectionMode='checkbox'
				style={{ height: '100%', width: '100%' }}
				setSort={service.setSort}
				sort={service.sort}
				selection={service.toSend}
				onSelectionChange={(e) => service.setToSend(e.value)}>
				{service.dataTable && service.dataTable.length > 0 && (
					<Column
						headerClassName={headerClassName}
						bodyClassName='!text-center !text-secondary-PCS004 !border-r-2 !border-b-0 !border-secondary-PCS004'
						selectionMode='multiple'
						style={{ minWidth: '10px' }}
					/>
				)}
				{_dataTableOrgChart.map((item, index) => {
					let keyIndex = index + '_orgChart';
					return (
						<Column
							key={keyIndex}
							headerClassName={headerClassName}
							style={item.style}
							field={item.field}
							header={item.headerName}
							sortable={item.sorteable}
							bodyClassName={bodyClassName}
							body={item.body}
						/>
					);
				})}

				<Column
					headerClassName={headerClassName}
					style={{ flexGrow: 1, flexBasis: '50px' }}
					header={TableNames.TableActions}
					body={actionBodyTemplate}
					bodyClassName={bodyClassName}
				/>
			</CustomTable>
		</div>
	);
};

OrgChartTableComponent.propTypes = {
	service: PropTypes.object,
};
