import {
	GET_PERIODS,
	GET_PERIODS_ERROR,
	GET_PERIODS_SUCCESS,
	GET_PERIODS_ERROR_SHOW,
} from './../../constants';

export const getPeriodReducer = (state, actions) => {
	switch (actions.type) {
		case GET_PERIODS:
			return {
				...state,
				loading: true,
				isLoadData: false,
			};
		case GET_PERIODS_ERROR:
			return {
				...state,
				periods: state.periods,
				loading: false,
				error: actions.payload,
				errorShow: false,
				isLoadData: false,
			};
		case GET_PERIODS_SUCCESS:
			return {
				...state,
				periods: actions.payload,
				loading: false,
				error: null,
				errorShow: false,
				isLoadData: true,
			};
		case GET_PERIODS_ERROR_SHOW:
			return {
				...state,
				errorShow: true,
				isLoadData: false,
			};
		default:
			return state;
	}
};
