export const msalConfig = {
	auth: {
		clientId: '',
		authority: '',
		redirectUri: window.location.origin,
		postLogoutRedirectUri: '', // redirect to portal maestro after logout
		knownAuthorities: [],
		clientSecret: '',
	},
	cache: {
		cacheLocation: 'localStorage', // This configures where your cache will be stored
		storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
	},
	system: {
		iframeHashTimeout: 10000,
		allowRedirectInIframe: true,
	},
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
	scopes: [],
};

export const uris = {
	platformAccountUri: '',
	myAccount: 'https://app.devctinube.com/cuenta',
	fqas: 'https://manager.knowintive.com/ServiceCTiContNube',
	terms: 'https://www.contpaqi.com/licenciasdeuso',
	privacy: ' https://www.contpaqi.com/avisos-de-privacidad',
};

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
	graphMeEndpoint: 'Enter_the_Graph_Endpoint_Here/v1.0/me',
};
