import React, { useState, useEffect, useContext } from 'react';
import { RadioButton } from 'primereact/radiobutton';
import { ContentRadio } from './ContentRadio';
import { Button } from 'primereact/button';
import useCompanySetting from '../../../../hooks/company/useCompanySetting';
import { Input } from '../../../../components/Input/Input';
import { useTypeIncidences } from '../../../../hooks/incidences/useTypeIncidences';
import useReload from '../../../../hooks/useReload';
import { StoreContext } from '../../../../business/Provider';
import _ from 'lodash';
import { Dropdown } from 'primereact/dropdown';

export const IncidentComponent = ({setConfigChange, ConfigChange}) => {
	const context = useContext(StoreContext);
	const [incident, setIncident] = useState({
		allowDelays: true,
		delayTimeLimit: "0",
		delayIncidenceTypeId: null,
		absenceIncidenceTypeId: null,
	});
	const [tempIncident, setTempIncident] = useState({});
	const [typeIncidents, setTypeIncidents] = useState([]);

	const nullType =
		incident.absenceIncidenceTypeId === null ||
		incident.absenceIncidenceTypeId === undefined ||
		incident.delayIncidenceTypeId === null ||
		incident.delayIncidenceTypeId === undefined;

	const ValidationForm =
		(incident.allowDelays &&
			(incident.delayTimeLimit === undefined ||
				incident?.delayTimeLimit?.length === 0 ||
				incident.delayTimeLimit === null)) ||
		nullType;

	const [tempTime, setTempTime] = useState('10');
	const [error, setError] = useState(ValidationForm);
	const { postCompanySettingIncident, getCompanySettings, companySettings } =
		useCompanySetting();

	const { getTypeIncidences } = useTypeIncidences();
	useReload([getTypeIncidences]);
	const { getTypeIncidencesState } = context;


	useEffect(()=> {
		if(_.isEqual(incident, tempIncident)){
			setConfigChange({...ConfigChange, IncidentChange:false})
		}else{
			setConfigChange({...ConfigChange, IncidentChange:true})
		}
	}, [incident])

	useEffect(() => {
		if (getTypeIncidencesState?.incidences !== null) {
			setTypeIncidents(getTypeIncidencesState?.incidences?.results);
		}
	}, [getTypeIncidencesState]);

	useEffect(() => {
		if (incident.allowDelays) setError(ValidationForm);
		else setError(nullType);
	}, [incident]);

	useEffect(() => {
		if (companySettings === undefined) {
			getCompanySettings();
		} else {
			if (
				companySettings.allowDelays === undefined ||
				companySettings.allowDelays === null
			) {
				setTempTime("15");
			} else {
				const ConfigCompanyInicident ={
					...incident,
					allowDelays: companySettings.allowDelays,
					absenceIncidenceTypeId: companySettings.absenceIncidenceTypeId,
					delayIncidenceTypeId: companySettings.delayIncidenceTypeId,
					delayTimeLimit: companySettings.allowDelays
						? `${companySettings.delayTimeLimit}`
						: "10",
				}
				setIncident(ConfigCompanyInicident);
				setTempIncident(ConfigCompanyInicident)
				const timeTemp = companySettings.delayTimeLimit !== null ? `${companySettings.delayTimeLimit}` : "10"
				setTempTime(timeTemp);
			}
		}
	}, [companySettings]);

	const handleInput = (e) => {
		const regex = /^[0-9\b]+$/;
		if (e.target.value === '' || regex.test(e.target.value)) {
			const { value } = e.target;
			let valueData = value.trim() === '' ? undefined : value;
			setIncident({
				...incident,
				delayTimeLimit: valueData,
			});
			setTempTime(valueData);

			if (e.target.value === '') {
				setError(true);
			} else {
				setError(nullType);
			}
		}
	};

	const renderRadioButtonContentTolerance = () => {
		return (
			<ContentRadio
				name={'Configurar tolerancia de retardo:'}
				labelRadio1={'Sí'}
				labelRadio2={'No'}
				id1={'yes'}
				radio1={
					<RadioButton
						inputId='yes'
						name='tolerance'
						value={true}
						onChange={(e) => {
							setIncident({ ...incident, allowDelays: e.value });
						}}
						checked={incident.allowDelays === true}
					/>
				}
				id2={'no'}
				radio2={
					<RadioButton
						inputId='no'
						name='typetoleranceone'
						value={false}
						onChange={(e) => {
							setIncident({
								...incident,
								allowDelays: e.value,
								delayTimeLimit: `${tempTime}`,
							});
						}}
						checked={incident.allowDelays === false}
					/>
				}
			/>
		);
	};

	const renderContentMinutesTolerance = () => {
		return (
			<div
				className={`flex w-full flex-row h-1/5 ${
					incident.allowDelays ? 'visible' : 'hidden'
				} `}>
				<div className='flex h-full w-full flex-col'>
					<p className='h-4'>{'Tolerancia de retardo:'}</p>
					<div className='flex flex-col pl-2 space-y-2'>
						<div className='flex flex-row content-center items-center space-x-2'>
							<Input
								disabled={!incident.allowDelays}
								value={
									(incident.delayTimeLimit === undefined &&
										tempTime === undefined) ||
									(incident.delayTimeLimit === null && tempTime === null)
										? ''
										: incident.delayTimeLimit === tempTime
										? `${incident.delayTimeLimit}`
										: `${tempTime}`
								}
								className={'flex w-full h-4 !rounded-lg text-left'}
								classNameContent={'w-32 h-4 !rounded-lg'}
								onChange={(e) => handleInput(e)}
							/>
							<p>{'minutos'}</p>
						</div>
					</div>
				</div>
			</div>
		);
	};

	const handlerCustomDropDown = (e) => {
		const { name, value } = e.target;
		setIncident({
			...incident,
			[name]: value,
		});
	};
	const renderDelayIncidentType = () => {
		return (
			<div className='flex w-full flex-col'>
				<p className='h-4'>{'Tipo de incidencia para retardos:'}</p>
				<div className='flex flex-col pl-2 space-y-2'>
					<div className='flex flex-row !h-4'>
						<Dropdown
							className={` !border-r !border-border !rounded-lg !w-80 !justify-center !content-center !items-center`}
							name='delayIncidenceTypeId'
							onChange={(e) => handlerCustomDropDown(e)}
							value={incident.delayIncidenceTypeId}
							options={typeIncidents}
							optionLabel='name'
							optionValue='id'
						/>
					</div>
				</div>
			</div>
		);
	};
	const renderAbsenceIncidentType = () => {
		return (
			<div className='flex w-full flex-col '>
				<p className='h-4'>{'Tipo de incidencia para faltas:'}</p>
				<div className='flex flex-col pl-2 space-y-2'>
					<div className='flex flex-row !h-4'>
						<Dropdown
							className={` !border-r !border-border !rounded-lg !w-80 !justify-center !content-center !items-center`}
							onChange={(e) => handlerCustomDropDown(e)}
							name='absenceIncidenceTypeId'
							value={incident.absenceIncidenceTypeId}
							options={typeIncidents}
							optionLabel='name'
							optionValue='id'
						/>
					</div>
				</div>
			</div>
		);
	};

	const renderIncidentsType = () => {
		return (
			<div className='w-full flex flex-col space-y-3'>
				{renderAbsenceIncidentType()}
				{renderDelayIncidentType()}
			</div>
		);
	};

	return (
		<div className='flex !w-full !h-full flex-col'>
			<div className='flex w-full h-[90%] relative  '>
				<div className='flex flex-col p-3 space-y-2 overflow-auto absolute'>
					{renderRadioButtonContentTolerance()}
					{renderContentMinutesTolerance()}
					{renderIncidentsType()}
				</div>
			</div>
			<div className='flex w-full h-[10%] justify-end'>
				<Button
					disabled={error}
					label='Actualizar Datos'
					className='flex !w-64 !h-6 bg-blue-800 text-white'
					onClick={async () => {
						if (!error) {
							var result = await postCompanySettingIncident(incident);
							if(result.status == 200){
								setTempIncident(incident);
								setConfigChange({...ConfigChange, IncidentChange:false})
							}
						}
					}}
				/>
			</div>
		</div>
	);
};
