import {
	REJECT_INFORMATION_COLLABORATOR,
	REJECT_INFORMATION_COLLABORATOR_ERROR,
	REJECT_INFORMATION_COLLABORATOR_SUCCESS,
	REJECT_INFORMATION_COLLABORATOR_ERROR_SHOW,
} from '../../constants';

export const rejectInfoCollaboratorReducer = (state, actions) => {
	switch (actions.type) {
		case REJECT_INFORMATION_COLLABORATOR:
			return {
				...state,
				loading: true,
			};
		case REJECT_INFORMATION_COLLABORATOR_ERROR:
			return {
				collaborators: state.collaborators,
				//	collaboratorsOptions: state.collaboratorsOptions,
				loading: false,
				error: actions.payload,
				errorShow: false,
			};
		case REJECT_INFORMATION_COLLABORATOR_SUCCESS:
			return {
				collaborators: actions.payload,
				//collaboratorsOptions: getCollaboratorOptions(actions.payload),
				loading: false,
				error: null,
				errorShow: false,
			};
		case REJECT_INFORMATION_COLLABORATOR_ERROR_SHOW:
			return {
				...state,
				errorShow: true,
			};
		default:
			return state;
	}
};
