import React, { useContext } from 'react';

import { NavigationContext } from '../../context/navigationContext';
import { ButtonDropDown } from '../Buttons/ButtonDropdown';
export const SubSidebarItem = ({
	keyData,
	item,
	close,
	setIsSelect,
	setDataSecondSidebar,
}) => {
	const navigation = useContext(NavigationContext);

	const defaultButtonStyle =
		'btn btn-link btn-sm hover:!border-none hover:!cursor-default !text-white';
	const SelectContentStyle = `w-full text-white`;
	return (
		<>
			<li>
				<ButtonDropDown
					key={keyData}
					onClick={() => {
						navigation.selectTap(item.url, '', item.title);
						setIsSelect(item?.url);
						setDataSecondSidebar([]);
						close();
					}}
					className={` h-6 w-full ${
						item?.children !== undefined
							? defaultButtonStyle
							: ' !border-none hover:!bg-menu-hover-bg-default'
					} `}>
					<div
						className={`flex h-full  content-center items-center ${
							item?.children !== undefined
								? 'w-5/6 font-bold'
								: SelectContentStyle
						}`}>
						<p className=' font-h3-font-family text-code-font-size '>
							{item.title}
						</p>
					</div>
				</ButtonDropDown>
			</li>
		</>
	);
};
