import { useMsal } from '@azure/msal-react';
import React, { useEffect } from 'react';
export const Logout = () => {
	const { instance } = useMsal();
	useEffect(() => {
		// localStorage to string
		const url = instance.getConfiguration().auth.postLogoutRedirectUri;
		// get redirect url
		const currentAccount = instance.getAllAccounts()[0];
		instance.logoutRedirect({
			account: currentAccount,
			postLogoutRedirectUri: url,
			onRedirectNavigate: (url) => {
				return false;
			},
		});
	}, [instance]);

	return <></>;
};
