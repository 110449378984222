import React from 'react';
import { CustomTable } from '../../../../../components/Table/CustomTable';
import { GeneralNames } from '../../../../../namesConstants/names';

import { headerClassName } from '../../../../../assets/customStyles';
import { tableColumnsData } from '../../utils';
import { InputText } from 'primereact/inputtext';
import { FilterMatchMode } from 'primereact/api';
import { Column } from 'primereact/column';
export const TableIncidences = ({
	renderTableHeader,
	incidences,
	filters,
	setFilters,
	toSend,
	setToSend,
	sort,
	setSort,
	valueInputFilters,
	setValueInputFilters,
}) => {
	const bodyClassName =
		'!border-r-2 !border-b-0 !border-secondary-PCS004 !text-black';
	const handleChange = (e) => {
		const { value, name } = e.target;
		const _FinalValues = { ...valueInputFilters, [name]: value };
		setValueInputFilters(_FinalValues);
		const _FinalFilters = {
			...filters,
			[name]: { value: value, matchMode: FilterMatchMode.CONTAINS },
		};
		setFilters(_FinalFilters);
	};

	const rowFilterMultiSelectTemplate = (name, value) => {
		return (
			<InputText
				className={`w-full !rounded-md !text-font-size-base !flex `}
				id={name}
				value={value}
				placeholder={'Buscar por estado'}
				name={name}
				onChange={(e) => {
					handleChange(e);
				}}
			/>
		);
	};

	const clearFilter = (name) => {
		const _FinalValues = { ...valueInputFilters, [name]: '' };
		setValueInputFilters(_FinalValues);
		const _FinalFilters = {
			...filters,
			[name]: { value: null, matchMode: FilterMatchMode.CONTAINS },
		};
		setFilters(_FinalFilters);
	};

	return (
		<div className='flex w-full h-full relative'>
			<CustomTable
				value={incidences}
				sortField={sort.field}
				header={renderTableHeader()}
				filters={filters}
				sortOrder={sort.order}
				globalFilterFields={[
					'incidenceTime',
					'code',
					'name',
					'incidenceDate',
					'workShift',
					'typeIncidence',
					'statusText',
					'errorDetail',
				]}
				emptyMessage={GeneralNames.GeneralEmptyMessage}
				currentPageReportTemplate={
					'	CollaboratorScreenNames.CurrentPageReportTemplate'
				}
				resizableColumns
				columnResizeMode='fit'
				responsiveLayout='scroll'
				selection={toSend}
				removableSort
				selectionMode='checkbox'
				onSelectionChange={(e) => setToSend(e.value)}
				setSort={setSort}
				sort={sort}>
				<Column
					selectionMode={'multiple'}
					headerClassName={headerClassName}
					bodyClassName='!text-center !text-secondary-PCS004 !border-r-2 !border-b-0 !border-secondary-PCS004'
					style={{ minWidth: '10px' }}
				/>

				{tableColumnsData.map((column, index) => {
					const key = index.toString();
					const field = column.field || '';
					return (
						<Column
							key={key}
							filterHeaderClassName={
								'!border-r-2 !border-b-0 !border-secondary-PCS004'
							}
							headerClassName={headerClassName}
							style={{ flexGrow: 3, flexBasis: column.heigh }}
							field={field}
							header={column.label}
							sortable={true}
							filter={column.filter}
							showFilterMenu={column.showFilterMenu}
							showFilterOperator={column.showFilterMatchModes}
							showFilterMatchModes={column.showFilterMatchModes}
							filterField={column.field}
							filterPlaceholder={column.filterPlaceHolder}
							dataType={column.dataType}
							format={column.format}
							//sortFunction={dateSortFunction}
							onFilterClear={() => {
								clearFilter(column.field);
							}}
							bodyClassName={bodyClassName}
							filterElement={rowFilterMultiSelectTemplate(
								column.field,
								valueInputFilters[column.field]
							)}
							body={(rowData) => {
								return column.body !== undefined
									? column.body(rowData)
									: rowData[column.field];
							}}
						/>
					);
				})}
			</CustomTable>
		</div>
	);
};
