import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { faSearch, faXmark } from '@fortawesome/free-solid-svg-icons';
export const SearchComponent = ({
	value,
	regexName,
	onChange,
	placeholder,
	clearTextLabel,
	hidden = false,
}) => {
	return (
		<div className={`flex w-full h-full    ${hidden ? 'pr-4' : ''}`}>
			<div className='flex-row flex w-full h-full !rounded-none '>
				<div className='flex w-full  border-2 border-primary-ShadowPCP004 rounded-l-lg'>
					<input
						className='flex h-full flex-grow rounded-l-lg !border-0 ml-1 !bg-none'
						type='text'
						maxLength={80}
						pattern={regexName}
						value={value}
						onChange={onChange}
						placeholder={placeholder}
					/>
					<div className={'flex h-full w-4'}>
						<button
							className={`w-4 h-full ${value?.length === 0 ? 'hidden' : ''}`}
							onClick={() => clearTextLabel()}>
							<FontAwesomeIcon
								size='sm'
								className=' !text-black !text-center hover:!text-gray-400'
								icon={faXmark}
							/>
						</button>
					</div>
				</div>
				<div className='flex h-full w-8 md:!w-12 lg:!w-14  bg-primary-ShadowPCP004 justify-center items-center rounded-r-lg'>
					<FontAwesomeIcon
						className='pi text-white text-center'
						icon={faSearch}
					/>
				</div>
			</div>
		</div>
	);
};
