// Msal imports
import axios from 'axios';
import { loginRequest } from '../b2cService/b2cServiceConfig';

export const getAccessToken = async ({ instance, accounts }) => {
	const account = accounts[0] || {};

	const requestdata = {
		...loginRequest,
		forceRefresh: true,
		account,
	};

	try {
		const atsResponse = await instance.acquireTokenSilent(requestdata);
		localStorage.setItem('accessToken', atsResponse.accessToken);
		axios.defaults.headers.common = {
			...axios.defaults.headers.common,
			Authorization: atsResponse.accessToken,
		};

		return atsResponse ? atsResponse.accessToken : '';
	} catch (error) {
		return instance.acquireTokenRedirect(requestdata);
	}
};

export const getLocalAccessToken = () => {
	return localStorage.getItem('accessToken');
};
