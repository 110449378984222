import React, { useContext } from 'react';
import { FullLoading } from './../FullLoading/index';
import { StoreContext } from '../../business/Provider';
export const MainContainer = ({
	nameScreen,
	alertComponent,
	children,
	iNeedFullLoading = true,
	cancelTittle = false,
}) => {
	const { loadingState, loadingContextState } = useContext(StoreContext);

	const { stateLoadingWithMessage } = loadingContextState;

	return (
		<div className='flex w-full h-full relative flex-col overflow-x-hidden overflow-y-hidden '>
			{cancelTittle === false ? (
				<div className='flex w-full h-[5%] justify-start content-center items-center divide-x '>
					<h1 className='text-[18px] font-bold ml-2 font-karla'>
						{nameScreen}
					</h1>
				</div>
			) : null}
			<div
				className={
					'flex w-full  relative   ' + (cancelTittle ? 'h-full' : 'h-[95%]  ')
				}>
				{iNeedFullLoading ? (
					<FullLoading
						loadingState={loadingState || stateLoadingWithMessage.loading}
						message={stateLoadingWithMessage.message}
					/>
				) : null}
				{children}
			</div>
			{alertComponent}
		</div>
	);
};
