import React from 'react';
export const ContentRadio = ({
	name,
	id1,
	radio1,
	labelRadio1,
	id2,
	radio2,
	labelRadio2,
}) => {
	return (
		<div className='flex w-full h-auto flex-col space-y-3'>
			<div className='flex w-full h-[10%]'>
				<h1 className='text-xl font-semibold'>{name}</h1>
			</div>
			<div className='flex w-full h-[90%] flex-col'>
				<div className='flex flex-row items-center w-full h-auto'>
					{radio1}
					<label htmlFor={id1} className='ml-1'>
						{labelRadio1}
					</label>
				</div>
				<div className='flex flex-row items-center w-full h-auto'>
					{radio2}
					<label htmlFor={id2} className='ml-1'>
						{labelRadio2}
					</label>
				</div>
			</div>
		</div>
	);
};
