import React, { useEffect, useState, createContext } from "react";
import { obtainCoordinatesClient } from "../utils/utils";

export const PositionUserGPSContext = createContext();

export const PositionUserGPSProvider = ({ children }) => {
  const [positionUserGps, setPositionUserGps] = useState(undefined);

  useEffect(() => {
    if (navigator.geolocation) {
      obtainCoordinatesClient()
        .then((resp) => {
          setPositionUserGps(resp);
        })
        .catch((error) => {
          console.error("Error al obtener la posición: ", error);
          setPositionUserGps(undefined);
        });
    } else {
      console.log("Geolocalización no es compatible con este navegador.");
      setPositionUserGps(undefined); 
    }
  }, []);

  return (
    <PositionUserGPSContext.Provider value={{ positionUserGps }}>
      {children}
    </PositionUserGPSContext.Provider>
  );
};