import * as yup from 'yup';

const workCenterSchema = yup.object().shape({
	name: yup.string('').required('El nombre es requerido'),
	zipCode: yup.string('').required('El código postal es requerido'),
	federalEntity: yup.string(''),
	municipality: yup.string(''),
	suburb: yup.string(''),
	street: yup.string(''),
	externalNumber: yup.string(''),
	internalNumber: yup.string(''),
	geoLocation: yup.string('').required('La ubicación es requerida'),
	workCenterId: yup.string(''),
	collaborators: yup.array([]),
	geoFenceSize: yup
		.number()
		.min(1, 'El valor debe ser igual o mayor a 1.')
		.required(`el valor no debe estar vacio y debe ser mayor a 0.`),
	toAdd: yup.array([]),
	toDelete: yup.array([]),
	homeOffice: yup.bool(false),
});

export default workCenterSchema;
