import {
	GET_INCIDENCES_BY_PROCESS_ID,
	GET_INCIDENCES_BY_PROCESS_ID_ClEAR,
	GET_INCIDENCES_ERROR_BY_PROCESS_ID,
	GET_INCIDENCES_ERROR_SHOW_BY_PROCESS_ID,
	GET_INCIDENCES_SUCCESS_BY_PROCESS_ID,
} from './../../constants';

export const getIncidencesByProcessIdReducer = (state, actions) => {
	switch (actions.type) {
		case GET_INCIDENCES_BY_PROCESS_ID:
			return {
				...state,
				loading: true,
			};
		case GET_INCIDENCES_ERROR_BY_PROCESS_ID:
			return {
				data: state.data,
				//	collaboratorsOptions: state.collaboratorsOptions,
				loading: false,
				error: actions.payload,
				errorShow: false,
			};
		case GET_INCIDENCES_BY_PROCESS_ID_ClEAR:
			return { ...state };
		case GET_INCIDENCES_SUCCESS_BY_PROCESS_ID:
			return {
				data: actions.payload,
				//collaboratorsOptions: getCollaboratorOptions(actions.payload),
				loading: false,
				error: null,
				errorShow: false,
			};
		case GET_INCIDENCES_ERROR_SHOW_BY_PROCESS_ID:
			return {
				...state,
				errorShow: true,
			};

		default:
			return state;
	}
};
