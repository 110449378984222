import React, { useEffect, useState, createContext } from 'react';

export const ReloadContext = createContext();
export const ReloadProvider = ({ children }) => {
	const [reload, setReload] = useState(false);

	useEffect(() => {
		reload && setTimeout(() => setReload(false), 1000);
	}, [reload]);

	return (
		<ReloadContext.Provider value={{ reload, setReload }}>
			{children}
		</ReloadContext.Provider>
	);
};
