import {
	GET_COMPANY_VERIFY_LICENSE,
	GET_COMPANY_VERIFY_LICENSE_ERROR,
	GET_COMPANY_VERIFY_LICENSE_SUCCESS,
} from '../../constants';

export const getCompanyVerifyLicenseReducer = (state, actions) => {
	switch (actions.type) {
		case GET_COMPANY_VERIFY_LICENSE:
			return {
				...state,
				loading: true,
				error: null,
				errorShow: false,
			};
		case GET_COMPANY_VERIFY_LICENSE_ERROR:
			return {
				...state,
				loading: false,
				error: actions.payload,
				errorShow: true,
			};
		case GET_COMPANY_VERIFY_LICENSE_SUCCESS:
			return {
				...state,
				loading: false,
				error: null,
				errorShow: false,
				data: actions.payload,
			};
		default:
			return state;
	}
};
