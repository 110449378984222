export const INCIDENCES_TYPES = [
    { name: "Asistencia", code: "ASIS" },
    { name: "Días de descanso", code: "DESC" },
    { name: "Vacaciones", code: "VAC" },
    { name: "Día no laborable", code: "NLAB" }
  ];
  export const INCIDENCES_TYPES_COLS = [
    {
        title: 'Nombre',
        prop: 'name',
        width: 30,
    },
    {
        title: 'Código',
        prop: 'code',
        width: 20,
    }
  ]