import React, { useEffect, useState } from 'react';
import CustomDropdown from '../../../../components/Input/Dropdown';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { HolidaysYears } from '../../../../utils/utils';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { Toast } from '../../../../components/toast';
import { Input } from '../../../../components/Input/Input';
export const ImportDayOff = ({
	onClose,
	getHolidaysAction,
	importDaysOff,
	setImportDaysOff,
	importHolidays,
	holidays,
	processImportDaysOff,
	selectYear,
}) => {
	const [step, setStep] = useState(0);
	const [disabledExport, setDisabledExport] = useState(true);

	useEffect(() => {
		if (processImportDaysOff.length > 0 && step === 0) {
			const newDataImportHolidays = processImportDaysOff.filter((importDay) => {
				const iDayDateMoment = moment(importDay.date, 'YYYY-MM-DD');

				return !holidays.some((holiday) => {
					const holiDay = moment(holiday.date, 'YYYY-MM-DD');
					return iDayDateMoment.isSame(holiDay);
				});
			});

			const updatedDataImportHolidays = newDataImportHolidays.map(
				(importDay) => ({
					...importDay,
					selected: true,
				})
			);

			if (updatedDataImportHolidays.length === 0) {
				Toast(
					'warning',
					`Los días festivos de ley para el año indicado ya se importaron previamente`
				);
				setDisabledExport(true);
			} else {
				Toast('success', 'Se han obtenido los días festivos correctamente');
				setDisabledExport(false);
			}

			setImportDaysOff(updatedDataImportHolidays);
			setStep(1);
		}
	}, [holidays, importDaysOff, processImportDaysOff]);

	const getHolidaysByYear = (year) => {
		setStep(0);
		return getHolidaysAction(year);
	};
	const handleCheck = (e) => {
		const { name, value } = e.target;
		const dataToSelect = [...importDaysOff].map((data) => {
			if (data.description === name) {
				data = { ...data, selected: !value };
			}
			return data;
		});

		setImportDaysOff(
			[...importDaysOff].map((data) => {
				if (data.description === name) {
					data = { ...data, selected: !value };
				}
				return data;
			})
		);
	};

	const sendDataToImportHolidays = async (e) => {
		try {
			const holidaysFilterArray = importDaysOff.filter(
				(data) => data.selected === true
			);
			if (holidaysFilterArray.length > 0) {
				const newDataToSend = [];
				holidaysFilterArray.forEach((holiday) => {
					const item = {
						...holiday,
						year: selectYear.year,
					};
					newDataToSend.push(item);
				});

				let result = await importHolidays(newDataToSend);
				onClose();
				return result;
			} else {
				Toast('warning', 'No se ha seleccionado ningún día festivo');
			}
		} catch (error) {}
	};

	const formDate = () => {
		return (
			<div className='flex flex-col w-full h-1/5 space-y-1'>
				<div className='flex flex-row w-full h-auto'>
					<div className='flex flex-col w-full '>
						<div className='flex w-full h-1/3'>
							<label
								className='text-small-font-size font-semibold flex'
								htmlFor={'year'}>
								{'Año:'}
								<div className='h-2 w-2 rounded-full bg-red ml-2 my-auto'></div>
							</label>
						</div>
						<div className='flex w-full h-2/3 border'>
							<Input
								name='year'
								value={selectYear.year}
								disabled={true}
								classNameContent={' !w-full '}
								className='!border-r !border-border h-full  w-full'
							/>
						</div>
					</div>

					<div className='flex flex-col w-1/5  justify-end  items-center content-center'>
						<div className='flex w-full h-1/3'></div>
						<div className='flex w-full h-2/3  justify-start content-center items-center '>
							<button
								className={`w-4 h-4 rounded-md bg-primary-ShadowPCP004  justify-center content-center items-center ml-1`}
								onClick={() => getHolidaysByYear(selectYear.year)}>
								<FontAwesomeIcon
									className='pi text-white text-center'
									icon={faSearch}
								/>
							</button>
						</div>
					</div>
				</div>
			</div>
		);
	};

	const loadDaysOff = () => {
		return (
			<div className='w-full flex flex-col h-3/5 justify-center items-center relative'>
				<div className='flex absolute right-0 left-0 top-0 bottom-0 overflow-y-auto flex-col'>
					{importDaysOff.map((dayOff, index) => {
						const newIndex = index.toString();
						return (
							<div
								className={`flex flex-row w-full h-auto border-b border-[#757575] content-center`}
								key={`index-${newIndex}`}>
								<div className=' flex-row w-[75%] h-auto font-bold text-black p-1 space-x-1 flex items-start justify-start'>
									<Checkbox
										checked={dayOff.selected}
										onChange={(e) => handleCheck(e)}
										value={dayOff.selected}
										name={dayOff.description}
									/>
									<p>{dayOff.description}</p>
								</div>
								<div className='w-[25%]  h-auto text-right font-bold text-[#757575] flex items-center justify-end content-center p-1'>
									{dayOff.date}
								</div>
							</div>
						);
					})}
				</div>
			</div>
		);
	};

	const buttons = () => {
		return (
			<div className='w-full h-1/5 flex flex-row space-x-2 justify-end items-end'>
				<Button
					label='Cancelar'
					className='flex !h-4 !w-auto !bg-white  rounded-lg  !border-[#757575] !text-[#757575] '
					onClick={() => {
						onClose();
					}}
				/>
				<Button
					label='Importar'
					disabled={disabledExport}
					className='flex !w-auto  !h-4 !bg-secondary-ShadowPCS002 text-white rounded-lg'
					onClick={() => {
						sendDataToImportHolidays();
					}}
				/>
			</div>
		);
	};
	return (
		<div className='flex flex-row  w-full space-x-3 p-3 !h-full'>
			<div className='flex flex-col w-full !h-full'>
				{formDate()}
				{loadDaysOff()}
				{buttons()}
			</div>
		</div>
	);
};
