import { useReducer } from 'react';
import { verifyAvailableLicenseReducer } from '../../../business/reducers/invitations/verifyAvailableLicenseReducer';
import { verifyAvailableLicenseState } from '../../../business/initialState/invitations/verifyAvailableLicenseState';

export const useVerifyLicenseState = () => {
	const [state, dispatch] = useReducer(
		verifyAvailableLicenseReducer,
		verifyAvailableLicenseState
	);
	return {
		state,
		dispatch,
	};
};
