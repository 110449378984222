import { useReducer } from 'react';
import { postImportEmployeeReducer } from '../../../business/reducers/company/postImportEmployeeRed';
import { postImportEmployeesIS } from '../../../business/initialState/company/postImportEmploeesIS';

export const useImportEmployee = () => {
	const [state, dispatch] = useReducer(
		postImportEmployeeReducer,
		postImportEmployeesIS
	);
	return { state, dispatch };
};
