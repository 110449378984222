import React, { useState, useEffect, useMemo } from 'react';
import { Input } from '../../../../components/Input/Input';
import { InputSwitch } from 'primereact/inputswitch';
import { MapContent } from '../../../../components/Map';
import {
	obtainCoordinates,
	obtainPredictionAddress,
} from './../../../../utils/utils';
import { Circle, Marker } from '@react-google-maps/api';
import useCompanySetting from '../../../../hooks/company/useCompanySetting';
import PlacesAutocomplete, {
	geocodeByAddress,
	getLatLng,
	geocodeByPlaceId,
} from 'react-places-autocomplete';
import { PositionUserGPSContext } from '../../../../context/PositionUserGPS';
import { useContext } from 'react';

export const CoordinateComponent = ({
	address,
	setAddress,
	formik,
	isAddressValid,
	setIsAddressValid,
	geolocation,
	setGeolocation,
	circles,
	setCircles,
	coordinates,
	setCoordinates,
}) => {
	const [initPositionGps, setInitPositionGps] = useState(false);
	const { positionUserGps } = useContext(PositionUserGPSContext);
	useEffect(() => {
		if (
			(coordinates === undefined || coordinates === null) &&
			positionUserGps !== undefined
		) {
			setCoordinates(positionUserGps);
			setInitPositionGps(true);
		}
	}, [positionUserGps]);

	const [zoom, setZoom] = useState(16);
	const [searchText, setSearchText] = useState(undefined);
	const { getCompanySettings, companySettings } = useCompanySetting();
	useEffect(() => {
		if (companySettings === undefined) {
			getCompanySettings()
				.then((resp) => {
					if (
						formik.values.geoFenceSize === '' &&
						formik.values.geoFenceSize === undefined &&
						formik.values.geoFenceSize === null &&
						resp?.workCenterSize !== null &&
						resp?.workCenterSize != undefined
					) {
						setGeofenceInit(resp?.workCenterSize);
					}
					if (
						formik.values.geoFenceSize === 500 &&
						resp?.workCenterSize !== null &&
						resp?.workCenterSize != undefined
					) {
						setGeofenceInit(resp?.workCenterSize);
					}
				})
				.catch((err) => {});
		} else {
			if (
				formik.values.geoFenceSize === '' &&
				formik.values.geoFenceSize === undefined &&
				formik.values.geoFenceSize === null &&
				companySettings?.workCenterSize !== null &&
				companySettings?.workCenterSize !== undefined
			) {
				setGeofenceInit(companySettings?.workCenterSize);
			}
			if (
				formik.values.geoFenceSize === 500 &&
				companySettings?.workCenterSize !== null &&
				companySettings?.workCenterSize !== undefined
			) {
			}
		}
	}, [companySettings]);

	const setGeofenceInit = (value) => {
		formik.setFieldValue('geoFenceSize', value);
		let intGeofence = parseInt(value, 10);
		zoomMap(intGeofence);
	};

	const formattedAddress = (valueFormat) => {
		valueFormat?.address_components.forEach((element) => {
			element.types.forEach((type) => {
				if (type === 'street_number') {
					formik.setFieldValue('externalNumber', element.long_name);
				} else if (type === 'route') {
					formik.setFieldValue('street', element.long_name);
				} else if (type === 'sublocality_level_1') {
					formik.setFieldValue('suburb', element.long_name);
				} else if (type === 'locality') {
					formik.setFieldValue('municipality', element.long_name);
				} else if (type === 'postal_code') {
					formik.setFieldValue('zipCode', element.long_name);
				} else if (type === 'administrative_area_level_1') {
					formik.setFieldValue('federalEntity', element.long_name);
				} else if (type === 'country') {
					formik.setFieldValue('country', element.long_name);
				}
			});
		});
	};

	const handleGetCoordinates = async (value) => {
		setCircles([]);
		setCoordinates(null);
		const result = await obtainCoordinates(value);
		const { results } = result;
		const { geometry } = results[0];
		setCircles([geometry.location]);
		const newResult = results[0];
		formattedAddress(newResult);
		const coordinate = `${geometry.location.lat},${geometry.location.lng}`;
		formik.setFieldValue('geoLocation', coordinate);
		setCoordinates(geometry.location);
		setGeolocation(geometry.location);
	};

	const options = {
		strokeColor: '#FF0000',
		strokeOpacity: 0.8,
		strokeWeight: 2,
		fillColor: '#FF0000',
		fillOpacity: 0.35,
		clickable: false,
		draggable: false,
		editable: false,
		//radius: 300,
		zIndex: 1,
	};
	const handleOnChange = () => {
		setInitPositionGps(false);
		if (address.length > 10) {
			handleGetCoordinates(address);
			setIsAddressValid(false);
		} else {
			setIsAddressValid(true);
		}
	};
	const renderMarker = () => {
		return (
			<Marker
				title='marker'
				visible={initPositionGps ? false : true}
				position={coordinates}
				onVisibleChanged={(e) => {}}
				onLoad={(e) => {
					e.setPosition(coordinates);
				}}
			/>
		);
	};

	const renderCicle = () => {
		return (
			<Circle
				visible={initPositionGps ? false : true}
				key={'keyCircleMaps'}
				center={coordinates}
				options={options}
				radius={
					formik.values.geoFenceSize === '' ||
					formik.values.geoFenceSize === undefined ||
					formik.values.geoFenceSize === null
						? 500
						: formik.values.geoFenceSize
				}
			/>
		);
	};

	const renderMap = useMemo(() => {
		return (
			<MapContent centerCoordinate={coordinates} zoom={zoom} key={'one-map'}>
				{renderCicle()}
				{renderMarker()}
			</MapContent>
		);
	}, [coordinates]);

	const handleChange = (address) => {
		if (!formik.values.homeOffice) {
			var regex = /^[a-zA-ZáéíóúÁÉÍÓÚñÑ0-9, ]*$/;
			if (regex.test(address)) {
				setSearchText(address);
			} else {
				let textClean = address.replace(/[^a-zA-ZáéíóúÁÉÍÓÚñÑ0-9, ]/g, '');
				setSearchText(textClean);
			}
			setAddress('');

			if (address === '') {
				setGeolocation(null);
				setCoordinates(null);
				setIsAddressValid(true);
				setCoordinates(positionUserGps);
				setInitPositionGps(true);
			}
		}
	};

	const handleSelect = async (address, placeId) => {
		setInitPositionGps(false);
		geocodeByAddress(address)
			.then((results) => getLatLng(results[0]))
			.then(async (latLng) => {
				setGeolocation(latLng);
				setCoordinates(latLng);
				const coordinate = `${latLng.lat},${latLng.lng}`;
				formik.setFieldValue('geoLocation', coordinate);
				setAddress(address);
				setSearchText(address);
				setIsAddressValid(false);
				const [place] = await geocodeByPlaceId(placeId);
				place?.address_components.forEach((element) => {
					element.types.forEach((type) => {
						if (type === 'street_number') {
							formik.setFieldValue('externalNumber', element.long_name);
						} else if (type === 'route') {
							formik.setFieldValue('street', element.long_name);
						} else if (type === 'sublocality_level_1') {
							formik.setFieldValue('suburb', element.long_name);
						} else if (type === 'locality') {
							formik.setFieldValue('municipality', element.long_name);
						} else if (type === 'postal_code') {
							formik.setFieldValue('zipCode', element.long_name);
						} else if (type === 'administrative_area_level_1') {
							formik.setFieldValue('federalEntity', element.long_name);
						} else if (type === 'country') {
							formik.setFieldValue('country', element.long_name);
						}
					});
				});
			})
			.catch((error) => console.error('Error', error));
	};

	const renderSearchFunc = ({
		getInputProps,
		getSuggestionItemProps,
		suggestions,
	}) => (
		<div className='sm:w-1/4 md:w-1/4 lg:w-1/4 xl:w-1/3 2xl:w-1/3 '>
			<Input
				{...getInputProps()}
				placeholder={'Búsqueda:'}
				important
				classNameContent={'w-full flex-col'}
				className={'w-full !rounded-md !text-font-size-base w-full'}
				error={
					isAddressValid ||
					(formik.touched.zipCode && Boolean(formik.errors.zipCode)) ||
					(formik.touched.street && Boolean(formik.errors.street))
				}
				errorText={
					isAddressValid || formik.touched.street
						? 'Por favor ingresa una dirección válida'
						: formik.touched.zipCode
						? 'Por favor ingresa una dirección que contenga el código postal'
						: 'Por favor ingresa una dirección válida'
				}
				disabled={formik.values.homeOffice}
				value={
					address !== null && address !== undefined && address !== ''
						? address
						: searchText
				}
			/>
			<div className='!fixed border z-40'>
				{suggestions.map((suggestion) => {
					const className = `{suggestion.active
				? 'suggestion-item-active'
				: 'suggestion-item'} p-2`;
					// inline style for demonstration purpose
					const style = suggestion.active
						? { backgroundColor: '#fafafa', cursor: 'pointer' }
						: { backgroundColor: '#ffffff', cursor: 'pointer' };
					return (
						<div
							{...getSuggestionItemProps(suggestion, {
								className,
								style,
							})}>
							<span>{suggestion.description}</span>
						</div>
					);
				})}
			</div>
		</div>
	);

	const zoomMap = (geoFenceSize) => {
		let initZoom = 18;
		let tempRound = Math.round(geoFenceSize / 450);
		if (zoom != tempRound) {
			if (tempRound < 1) {
				setZoom(initZoom);
			} else {
				let initPlusZoom = 17.3;
				setZoom(initPlusZoom - tempRound);
			}
		}
	};
	const componentAutoComplete = () => {
		return (
			<PlacesAutocomplete
				value={
					searchText === undefined || searchText === null ? '' : searchText
				}
				key={'places-autocomplete'}
				onChange={handleChange}
				onSelect={handleSelect}>
				{renderSearchFunc}
			</PlacesAutocomplete>
		);
	};

	const renderGeoFenceSize = () => {
		return (
			<Input
				disabled={formik.values.homeOffice}
				type={'inputNumber'}
				classNameContent={'flex flex-col w-full'}
				className={'w-full !rounded-md !text-font-size-base '}
				name={'geoFenceSize'}
				value={
					formik.values.geoFenceSize === undefined ||
					formik.values.geoFenceSize === null
						? ''
						: formik.values.geoFenceSize
				}
				onChange={(event) => {
					if (!formik.values.homeOffice) {
						let regex = /^[0-9\b]+$/;
						if (regex.test(event.target.value)) {
							const value = event.target.value === '' ? 0 : event.target.value;
							let intGeofence = parseInt(value, 10);
							formik.setFieldValue('geoFenceSize', intGeofence);
							if (intGeofence > 0) {
								zoomMap(intGeofence);
							} else {
								setZoom(18);
							}
							if (
								address !== null &&
								address !== undefined &&
								address !== '' &&
								address.length > 0
							) {
								handleOnChange();
							}
						}
						if (event.target.value === '') {
							formik.setFieldValue('geoFenceSize', '');
						}
					}
				}}
				error={
					formik.touched.geoFenceSize && Boolean(formik.errors.geoFenceSize)
				}
				errorText={formik.touched.geoFenceSize && formik.errors.geoFenceSize}
				important
				placeholder={'Tamaño de geocerca (metros):'}
			/>
		);
	};
	return (
		<div className='flex h-full w-full flex-col space-y-1'>
			<div className='flex w-full sm:h-[60%] md:h-[60%] lg:h-[50%] xl:h-[40%] 2xl:h-[30%] flex-col space-y-1 overflow-auto'>
				<div className='flex w-full justify-start flex w-full sm:flex-row md:flex-row lg:flex-row xl:flex-row 2xl:flex-row space-x-2'>
					{componentAutoComplete()}
					<Input
						classNameContent={
							'w-full flex-col sm:w-3/4 md:w-3/4 lg:w-3/4 xl:w-1/2 2xl:w-1/2'
						}
						className={'w-full !rounded-md !text-font-size-base w-full'}
						name={address}
						disabled={true}
						value={address === undefined || address === null ? '' : address}
						placeholder={'Dirección:'}
						onChange={(e) => {
							if (!formik.values.homeOffice) setAddress(e.target.value);
						}}
					/>
				</div>
				<div className='flex w-full sm:flex-col md:flex-col lg:flex-col xl:flex-col 2xl:flex-row sm:space-y-1 md:space-y-1 lg:space-y-1 xl:space-y-1 2xl:space-x-2'>
					<div className='sm:w-2/3 md:w-2/3 lg:w-2/3 xl:w-2/3 2xl:w-1/3 flex'>
						<Input
							disabled={formik.values.homeOffice}
							important
							classNameContent={'flex-col flex w-full'}
							className={'w-full !rounded-md !text-font-size-base '}
							name={'name'}
							value={
								formik.values.name === null || formik.values.name === undefined
									? ''
									: formik.values.name
							}
							onChange={(e) => {
								if (!formik.values.homeOffice) {
									var regex = /^[a-zA-ZáéíóúÁÉÍÓÚñÑ ]*$/;
									if (regex.test(e.target.value)) {
										formik.setFieldValue('name', e.target.value);
									}
								}
							}}
							error={formik.touched.name && Boolean(formik.errors.name)}
							errorText={formik.touched.name && formik.errors.name}
							placeholder={'Nombre: '}
						/>
					</div>
					<div className='flex sm:flex-row md:flex-row lg:flex-row xl:flex-row 2xl:flex-row sm:w-2/3 md:w-1/3 lg:w-1/3 xl:w-1/3 2xl:w-1/3 space-x-1'>
						<div className='flex flex-row w-full'>{renderGeoFenceSize()}</div>
						<div className='flex h-full flex-col w-1/3 sm:justify-center md:justify-center lg:justify-center xl:justify-center'>
							<p className='ml-2 font-semibold'>Estatus:</p>
							<InputSwitch
								disabled={formik.values.homeOffice}
								className='ml-2'
								name='isActive'
								checked={formik.values.isActive}
								onChange={(e) => {
									if (!formik.values.homeOffice) {
										formik.setFieldValue('isActive', e.target.value);
									}
								}}
							/>
						</div>
					</div>
				</div>
			</div>
			<div className='flex w-full sm:h-[40%] md:h-[40%] lg:h-[50%] xl:h-[60%] 2xl:h-[70%]'>
				{coordinates !== null ? (
					renderMap
				) : (
					<MapContent
						centerCoordinate={coordinates}
						zoom={zoom}
						key={'second-map-empty'}
					/>
				)}
			</div>
		</div>
	);
};
