import {
	INVITATION_FORWARD_SUCCESS,
	INVITATION_FORWARD_ERROR,
	INVITATION_FORWARD,
	INVITATION_FORWARD_ERROR_SHOW,
} from './../../constants';

export const invitationForwardReducer = (state, actions) => {
	switch (actions.type) {
		case INVITATION_FORWARD:
			return {
				...state,
				loading: true,
			};
		case INVITATION_FORWARD_ERROR:
			return {
				invitations: state.invitations,
				//	collaboratorsOptions: state.collaboratorsOptions,
				loading: false,
				error: actions.payload,
				errorShow: false,
			};
		case INVITATION_FORWARD_SUCCESS:
			return {
				invitations: actions.payload,
				//collaboratorsOptions: getCollaboratorOptions(actions.payload),
				loading: false,
				error: null,
				errorShow: false,
			};
		case INVITATION_FORWARD_ERROR_SHOW:
			return {
				...state,
				errorShow: true,
			};
		default:
			return state;
	}
};
