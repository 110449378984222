import React, { useContext } from 'react';
import { Dialog } from 'primereact/dialog';

//components

import { AlertComponent } from './components/AlertComponent';
import { TableComponent } from './components/TableComponent';
import { HeaderComponent } from './components/HeaderComponent';
import { MainContainer } from '../../components/MainContainer';
import { ModalComponent } from './components/ModalComponent';

//utils and constants

import { InvitationsScreenNames } from '../../namesConstants/names';

//hooks and context

import { InvitationsContext } from '../../Services/Invitations/InvitationsService';
import { ModalProgressBarComponent } from './components/ModalProgressBar';

export const InvitationsScreen = () => {
	const service = useContext(InvitationsContext);

	const renderAlert = () => {
		return (
			<AlertComponent
				typeInvitation={service.typeInvitation}
				openDialog={service.openDialog}
				setOpenDialog={service.setOpenDialog}
				sendInvitationsAction={service.sendInvitationsAction}
				sendUnInviteAction={service.sendUnInviteAction}
			/>
		);
	};
	const renderModal = () => {
		return <ModalComponent service={service} />;
	};
	const renderDialog = () => {
		return (
			<Dialog
				header='ALERTA'
				visible={service.showCloseDialog}
				style={{ width: '20vw' }}
				onHide={() => service.setShowCloseDialog(false)}>
				<p className='m-0'>{service.dialogContent}</p>
			</Dialog>
		);
	};

	const renderTable = () => {
		return (
			<TableComponent service={service}>
				<HeaderComponent service={service} />
			</TableComponent>
		);
	};

	return (
		<MainContainer
			nameScreen={InvitationsScreenNames.InvitationsScreenTitle}
			alertComponent={renderAlert()}>
			<ModalProgressBarComponent service={service} />
			{renderDialog()}
			{renderTable()}
			{renderModal()}
		</MainContainer>
	);
};
