import months from './months';

export const localeConfig = {
	firstDayOfWeek: 1,
	dayNames: [
		'Domingo',
		'Lunes',
		'Martes',
		'Miércoles',
		'Jueves',
		'Viernes',
		'Sábado',
	],
	dayNamesShort: ['Dom', 'Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sáb'],
	dayNamesMin: ['D', 'L', 'M', 'I', 'J', 'V', 'S'],
	monthNames: months,
	monthNamesShort: [
		'Ene',
		'Feb',
		'Mar',
		'Abr',
		'May',
		'Jun',
		'Jul',
		'Ago',
		'Sep',
		'Oct',
		'Nov',
		'Dic',
	],
	today: 'Hoy',
	clear: 'Limpiar',
};

export const monthsData = [
	{ id: 1, label: 'Enero' },
	{ id: 2, label: 'Febrero' },
	{ id: 3, label: 'Marzo' },
	{ id: 4, label: 'Abril' },
	{ id: 5, label: 'Mayo' },
	{ id: 6, label: 'Junio' },
	{ id: 7, label: 'Julio' },
	{ id: 8, label: 'Agosto' },
	{ id: 9, label: 'Septiembre' },
	{ id: 10, label: 'Octubre' },
	{ id: 11, label: 'Noviembre' },
	{ id: 12, label: 'Diciembre' },
];

export const isHoliday = (date, holidays) => {
	if (holidays.length === 0) return false;
	else
		return holidays.some(
			(holiday) =>
				date.toDateString() === new Date(holiday.date).toDateString() &&
				holiday.officialDay
		);
};

export const isCompanyDate = (date, holidays) => {
	if (holidays.length === 0) return false;
	else
		return holidays.some(
			(dateProvided) =>
				date.toDateString() === new Date(dateProvided.date).toDateString() &&
				!dateProvided.officialDay
		);
};
