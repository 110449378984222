import {
	DELETE_DAYS_OFF,
	DELETE_DAYS_OFF_ERROR,
	DELETE_DAYS_OFF_SUCCESS,
} from '../../../constants';

export const deleteHolidaysReducer = (state, actions) => {
	switch (actions.type) {
		case DELETE_DAYS_OFF:
			return {
				...state,
				loading: true,
				error: null,
				errorShow: false,
			};
		case DELETE_DAYS_OFF_ERROR:
			return {
				...state,
				loading: false,
				error: actions.payload,
				errorShow: true,
			};
		case DELETE_DAYS_OFF_SUCCESS:
			return {
				...state,
				loading: false,
				error: null,
				errorShow: false,
				holidays: actions.payload,
			};
		default:
			return state;
	}
};
