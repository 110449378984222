import {
	GET_HOLIDAYS,
	GET_HOLIDAYS_ERROR,
	GET_HOLIDAYS_SUCCESS,
} from '../../../constants';

export const getHolidaysByYearReducer = (state, actions) => {
	switch (actions.type) {
		case GET_HOLIDAYS:
			return {
				...state,
				loading: true,
				error: null,
				errorShow: false,
			};
		case GET_HOLIDAYS_ERROR:
			return {
				...state,
				loading: false,
				error: actions.payload,
				errorShow: true,
			};
		case GET_HOLIDAYS_SUCCESS:
			return {
				...state,
				loading: false,
				error: null,
				errorShow: false,
				holidays: actions.payload,
			};
		default:
			return state;
	}
};
