import { useReducer } from 'react';
import { getInvitationsReducer } from '../../../business/reducers/invitations/GetInvitationsReducer';
import { getInvitationsInitialState } from '../../../business/initialState/invitations/getInvitationsInitialState';
import { sendUnInviteReducer } from '../../../business/reducers/invitations/sendUnInviteReducer';
import { sendUnInviteInitialState } from '../../../business/initialState/invitations/senUnInviteInitialState';

export const useGetInvitationsState = () => {
	const [state, dispatch] = useReducer(
		getInvitationsReducer,
		getInvitationsInitialState
	);
	return { state, dispatch };
};

export const useSendUnInvitedState = () => {
	const [state, dispatch] = useReducer(
		sendUnInviteReducer,
		sendUnInviteInitialState
	);
	return { state, dispatch };
};
