import React from 'react';
import { SubSidebarItem } from '../SubSidebarItem';

export const SubSidebar = ({
	subSidebarData,
	current,
	close,
	setIsSelect,
	setDataSecondSidebar,
}) => {
	const { childrends } = subSidebarData;
	const validationFlexOrHidden =
		current && childrends?.length > 0 ? ' flex ' : ' hidden ';

	return (
		<div
			className={
				'w-[224px] bg-secondary-ShadowPCS002  absolute left-[90px] z-50 ' +
				validationFlexOrHidden
			}>
			<ul className='flex flex-col w-[224px] h-full'>
				{subSidebarData !== undefined && childrends?.length > 0
					? childrends.map((item, index) => {
							const key = index.toString();
							return (
								<SubSidebarItem
									key={key}
									item={item}
									keyData={key}
									close={close}
									setIsSelect={setIsSelect}
									setDataSecondSidebar={setDataSecondSidebar}
								/>
							);
					  })
					: null}
			</ul>
		</div>
	);
};
