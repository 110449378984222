import { FilterMatchMode } from 'primereact/api';
import { GeneralNames } from '../../namesConstants/names';

export const headers = [
	'formattedCode',
	'fullName',
	'email',
	'statusEmployee',
	'invitationSendingDate',
	'invitationResponseDate',
	'invitationStatus',
	'additionalInfo',
];

export const headersTranslations = [
	'Código',
	'Nombre',
	'Correo Electrónico',
	'Estado',
	'Fecha de Invitación',
	'Fecha de Respuesta',
	'Estado de Invitación',
	'Información',
];

export const wchCols = [
	{ wch: headersTranslations[0].length + 5 },
	{ wch: 30 },
	{ wch: 30 },
	{ wch: 15 },
	{ wch: headersTranslations[4].length },
	{ wch: headersTranslations[5].length },
	{ wch: headersTranslations[6].length },
	{ wch: 35 },
];

export const EmployeeStatusEnum = {
	0: 'Alta',
	1: 'Baja',
	2: 'Todos',
};

export const cols = [
	{ field: 'formattedCode', header: 'Código' },
	{ field: 'fullName', header: 'Nombre' },
	{ field: 'email', header: 'Correo Electrónico' },
	{ field: 'statusEmployee', header: 'Estado' },
	{ field: 'invitationSendingDate', header: 'Fecha de Invitación' },
	{ field: 'invitationResponseDate', header: 'Fecha de Respuesta' },
	{ field: 'invitationStatus', header: 'Estado de Invitación' },

	//{ field: 'invitationStatus', header: 'Estado de Invitación' },
	{ field: 'additionalInfo', header: 'Información' },
];

export const tableArrayStateOptions = [
	{
		label: GeneralNames.GeneralALl,
		value: 'all',
	},
	{
		label: GeneralNames.GeneralToInvite,
		value: 0,
	},
	{
		label: GeneralNames.GeneralStatusSends,
		value: 1,
	},
	{
		label: GeneralNames.GeneralStatusAccepted,
		value: 2,
	},
	{
		label: GeneralNames.GeneralStatusRejected,
		value: 5,
	},
	{
		label: GeneralNames.GeneralStatusExpiredPlural,
		value: 3,
	},
	{
		label: GeneralNames.GeneralStatusError,
		value: 255,
	},
	{
		label: GeneralNames.GeneralStatusUnavailable,
		value: 'unavailable',
	},
];
export const tableArrayStateEmployeeOptions = [
	{
		label: GeneralNames.GeneralALl,
		value: '3',
	},
	{
		label: GeneralNames.Active,
		value: '0',
	},
	{
		label: GeneralNames.Inactive,
		value: '1',
	},
];
export const initialStateTable = {
	global: { value: null, matchMode: FilterMatchMode.CONTAINS },
};
export const initialStateTableDialog = {
	global: { value: null, matchMode: FilterMatchMode.CONTAINS },
};

export const initialStateFilterOperationCollaborators = {
	global: { value: null, matchMode: FilterMatchMode.CONTAINS },
	formattedCode: { value: null, matchMode: FilterMatchMode.CONTAINS },
	fullName: { value: null, matchMode: FilterMatchMode.CONTAINS },
	periodTypeName: { value: null, matchMode: FilterMatchMode.CONTAINS },
	jobPositionaName: { value: null, matchMode: FilterMatchMode.CONTAINS },
	jobDepartmentName: { value: null, matchMode: FilterMatchMode.CONTAINS },
	shift: { value: null, matchMode: FilterMatchMode.CONTAINS },
	leaderName: { value: null, matchMode: FilterMatchMode.CONTAINS },
};
