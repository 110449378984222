import React, { useEffect, useState } from 'react';
import { MainContainer } from '../../../components/MainContainer';
import { TabPanel, TabView } from 'primereact/tabview';

import './TabViewConfiguration.css';
import { AssistComponent } from './Components/Assist';
import { IncidentComponent } from './Components/Incident';
import { CustomAlertDialog } from '../../../components/Dialogs/CustomAlertDialog';
import { AlertChangeData, GeneralNames } from '../../../namesConstants/names';

export const Configuration = () => {
	const [activeTab, setActiveTab] = useState(0);
	const defaultChange = {
		AssistChange:false,
		IncidentChange:false
	}
	const [ConfigChange, setConfigChange] = useState({
		AssistChange:false,
		IncidentChange:false
	});
	
	const [titleAlert, setTitleAlert] = useState('');
	const [tempIndexTab, setTempIndexTab] = useState(activeTab);
	const [openDialog, setOpenDialog] = useState(false);

	useEffect(()=>{
		let view = ''
		if(ConfigChange.AssistChange){
			view = 'asistencia laboral'	
		}
		if(ConfigChange.IncidentChange){
			view = 'incidencias'
		}
		const customTitle = AlertChangeData.CustomAlertDialogTittle.replace('{view}', view);

		setTitleAlert(customTitle);
	}, [ConfigChange]);

	const tabsList = [
		{
			title: 'Asistencia laboral',
			content: <AssistComponent setConfigChange={setConfigChange}  ConfigChange={ConfigChange} />,
			icon: 'pi pi-building',
		},
		{
			title: 'Incidencias',
			content: <IncidentComponent  setConfigChange={setConfigChange} ConfigChange={ConfigChange} />,
			icon: 'pi pi-share-alt',
		},
		// {
		// 	title: 'Notificaciones',
		// 	content: <NotificationComponent />,
		// 	icon: 'pi pi-share-alt',
		// },
	];


	
	const verifyChange = () => { return !ConfigChange.AssistChange && !ConfigChange.IncidentChange}
	const renderTabs = () => {
		return (
			<div className='flex w-full h-full '>
				<CustomAlertDialog
					title={titleAlert}
					setOpen={setOpenDialog}
					open={openDialog}
					message={AlertChangeData.CustomAlertDialogMessage}
					cancelLabel={GeneralNames.GeneralCancel}
					agreeLabel={GeneralNames.GeneralContinue}
					onConfirmFunction={() => { setOpenDialog(false); setConfigChange(defaultChange); setActiveTab(tempIndexTab);}}
					onCancelFunction={() => setOpenDialog(false)}
					style={{ width: '30vw' }}
				/>
				<TabView
					activeIndex={activeTab}
					onTabChange={(e) =>{ setTempIndexTab(e.index); if(e.index !== activeTab){if(verifyChange()){setActiveTab(e.index);} else{setOpenDialog(true)}}} }
					className='flex !w-full !h-full flex-col shadow-2xl border'>
					{tabsList.map((tab) => {
						return (
							<TabPanel
								key={tab.title}
								header={tab.title}
								//leftIcon={tab.icon}
								//contentStyle={{ height: '100%', width: '100' }}
								className='!flex !w-full !h-full !flex-col '
								contentClassName='!flex !w-full !h-full'
								style={{ height: '100% !important', width: '100% !important' }}
								headerClassName='!flex align-items-center !h-full !w-[300px]'>
								{tab.content}
							</TabPanel>
						);
					})}
				</TabView>
			</div>
		);
	};
	return (
		<MainContainer nameScreen='Configuración'>
			<div className='flex w-full h-full justify-center items-center  content-center relative p-2'>
				{renderTabs()}
			</div>
		</MainContainer>
	);
};
