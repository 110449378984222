import { useState } from 'react';

import { Column } from 'primereact/column';
import { CustomTable } from '../../../../components/Table/CustomTable';
import {
	ErrorsNames,
	GeneralNames,
	TableNames,
} from '../../../../namesConstants/names';
import { Dialog } from 'primereact/dialog';

export const ModalErrors = ({ service }) => {
	const headerClassName =
		'hover:!text-success  !bg-secondary-PCS004 !text-secondary-PCS001 !font-semibold !border-r-2 !border-secondary-PCS003 !justify-center  ';
	const bodyClassName = `!border-r-2 !border-b-0 !border-secondary-PCS004 !text-black !justify-left`;

	const [sort, setSort] = useState([{ field: 'fullName', order: 1 }]);
	const content = () => {
		return (
			<CustomTable
				sortField='fullName'
				value={service.errorDataTable}
				//loading={loading}
				header={undefined}
				style={{ height: '100%', width: '100%', overflow: 'auto' }}
				//loading={loading}
				filters={undefined}
				responsiveLayout='scroll'
				globalFilterFields={['error']}
				emptyMessage={GeneralNames.GeneralEmptyMessage}
				currentPageReportTemplate={ErrorsNames.CurrentPageReportTemplate}
				resizableColumns
				columnResizeMode='fit'
				setSort={setSort}
				sort={sort}>
				<Column
					headerClassName={headerClassName}
					style={{ flexGrow: 4, flexBasis: '100px' }}
					field='error'
					header={TableNames.TableErrorName}
					sortable
					bodyClassName={bodyClassName}
					body={(rowData) => {
						return rowData?.error;
					}}
				/>
			</CustomTable>
		);
	};
	return (
		<Dialog
			header={service.TextModalErrors}
			visible={service.visibleErrorDialog}
			modal
			style={{ width: '60vw', position: 'relative', height: '60vh' }}
			onHide={() => {
				service.onCloseErrorDialog();
			}}>
			{content()}
		</Dialog>
	);
};
