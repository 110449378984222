import React from 'react';
import { MainContainer } from '../../../components/MainContainer';

import personiaLogo from '../../../assets/img/Logo_CONTPAQi_Colabora.png';
import { GeneralNames } from '../../../namesConstants/names';
export const PrenominalPeriods = () => {
	return (
		<MainContainer>
			<div className='flex w-full h-full justify-center items-center content-center relative flex-col'>
				<h1 className='text-4xl font-bold text-center text-gray-700 '>
					{GeneralNames.GeneralNameComingSoon}
				</h1>
				<div className='flex w-1/2 h-1/2 relative'>
					<img
						className=' bg-center opacity-20 object-contain  w-full absolute  '
						src={personiaLogo}
						alt='Logo'></img>
				</div>
			</div>
		</MainContainer>
	);
};
