import React, { useState, useEffect, useContext } from 'react';
import { Column } from 'primereact/column';
import { CustomTable } from '../../components/Table/CustomTable';

import { ToolBarThreeColumns } from './../../components/Table/ToolBarThreeColumns';
import { size } from './utilsTable';
import { FilterMatchMode } from 'primereact/api';

//import { GetCollaboratorsAction } from '../../business/actions/collaborators/getCollaboratorsAction';
import { StoreContext } from './../../business/Provider';

import {
	CollaboratorScreenNames,
	GeneralNames,
	SidebarNames,
	TableNames,
} from '../../namesConstants/names';
import { CustomAlertDialog } from '../../components/Dialogs/CustomAlertDialog';
import { Toast } from '../../components/toast';
import useReload from '../../hooks/useReload';
//import { LoadingContext } from './../../context/LoadingContext';
import useCollaborators from '../../hooks/Collaborator/useCollaborator';
import { MainContainer } from '../../components/MainContainer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faX, faCheck } from '@fortawesome/free-solid-svg-icons';
import {
	validateStartDateCollaborators,
	StatusArrayValuesTableCollaborators,
	validateStartDateAndEndDateCollaborators,
	validateEndDateCollaborators,
	dictionaryNamesCollaborators,
	formatDate,
	downloadFileBase64,
	getFileNameWithDateTime,
} from '../../utils/utils';
import useCompanies from '../../hooks/company/useCompany';
import { LOADING_OFF, LOADING_ON } from '../../business/constants';
import { headerClassName } from '../../assets/customStyles';

export const CollaboratorScreen = () => {
	const [globalFilterValue, setGlobalFilterValue] = useState('');
	const [filters, setFilters] = useState({
		global: { value: null, matchMode: FilterMatchMode.CONTAINS },
	});
	const [filtersList, setFiltersList] = useState([]);
	//const [openDialogIndividual, setOpenDialogIndividual] = useState(false);
	const [openDialog, setOpenDialog] = useState(false);
	const [startDate, setStartDate] = useState(null);
	const [endDate, setEndDate] = useState(null);
	const { companyInfo } = useCompanies();
	const { getCollaboratorsState, dispatchLoading, companyState } =
		useContext(StoreContext);
	const [toSend, setToSend] = useState([]);
	const [typeAction, setTypeAction] = useState('accept');
	const [selectFilter, setSelectFilter] = useState({
		status: 'all',
	});
	const [individualRow, setIndividualRow] = useState({});
	const [isIndividualAction, setIsIndividualAction] = useState(false);
	const [sort, setSort] = useState([{ field: '', order: -1 }]);
	//const loadingObj = useContext(LoadingContext);
	const [filterOpc] = useState([
		{
			label: GeneralNames.GeneralALl,
			value: 'all',
		},
		...StatusArrayValuesTableCollaborators,
	]);
	const [companyData, setCompanyData] = useState(null);

	const { getCollaborators, acceptInformation, rejectInformation } =
		useCollaborators();

	useReload([getCollaborators]);

	useEffect(() => {
		if (
			getCollaboratorsState.loading === false &&
			getCollaboratorsState.collaborators !== undefined
		) {
			setFiltersList(getCollaboratorsState.collaborators);
		}
	}, [getCollaboratorsState]);

	useEffect(() => {
		if (companyState.companies !== null) {
			setCompanyData(companyState.companies);
		}
	}, [companyState]);

	useEffect(() => {
		let data = getCollaboratorsState?.collaborators;

		const newItem =
			selectFilter.status !== 'all'
				? data.filter((item) => item.status === selectFilter.status)
				: data;

		if (startDate !== null) {
			const ArrayListByStartDate = validateStartDateCollaborators(
				startDate,
				newItem
			);
			setFiltersList(ArrayListByStartDate);
		}
		if (endDate !== null) {
			const ArrayListByEndDate = validateEndDateCollaborators(endDate, newItem);
			setFiltersList(ArrayListByEndDate);
		}
		if (startDate !== null && endDate !== null) {
			const ArrayListByDate = validateStartDateAndEndDateCollaborators(
				startDate,
				endDate,
				newItem
			);
			setFiltersList(ArrayListByDate);
		} else {
			setFiltersList(newItem);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectFilter, startDate, endDate, getCollaboratorsState]);

	const onGlobalFilterChange = (e) => {
		const { value } = e.target;
		const _filters = { ...filters };
		_filters.global.value = value;
		setFilters(_filters);
		setGlobalFilterValue(value);
	};

	const handleFilterChange = (e) => {
		const { name, value } = e.target;
		setSelectFilter({
			...selectFilter,
			[name]: value,
		});
	};

	const returnRenderDialog = (type) => {
		setOpenDialog(true);
		setIsIndividualAction(true);
		setTypeAction(type);
		//setOpenDialogIndividual(false);
	};

	const clearFilters = () => {
		setSelectFilter({
			status: 'all',
		});
		setStartDate(null);
		setEndDate(null);
		//setFiltersList([]);
		setGlobalFilterValue('');
		setFilters({
			global: { value: '', matchMode: FilterMatchMode.CONTAINS },
		});
	};

	const clearTextLabel = () => {
		setGlobalFilterValue('');
		setFilters({
			global: { value: '', matchMode: FilterMatchMode.CONTAINS },
		});
	};

	const getDataExport = (listCollaborators) => {
		let arrayExport = [];
		listCollaborators.forEach((collaborator) => {
			let data = {
				code: collaborator.code,
				requestDate: collaborator.requestDate,
				name: `${collaborator.name} ${collaborator.firstLastName || ''} ${
					collaborator.secondLastName || ''
				}`,
				email: collaborator.email,
				fieldName: collaborator.fieldName,
				fieldOldValue: collaborator.fieldOldValue,
				fieldValue: collaborator.fieldValue,
				status: StatusArrayValuesTableCollaborators.filter((item) => {
					return item.value === collaborator.status;
				})[0].label,
				statusChangeDate: collaborator.statusChangeDate,
			};
			arrayExport.push(data);
		});
		return arrayExport;
	};

	const headers = [
		'code',
		'requestDate',
		'name',
		'email',
		'fieldName',
		'fieldOldValue',
		'fieldValue',
		'status',
		'statusChangeDate',
	];

	const headersTranslations = [
		'Código',
		'Fecha de Solicitud',
		'Nombre',
		'Correo Electrónico',
		'Datos a Cambiar',
		'Valor Anterior',
		'Nuevo Valor',
		'Estatus',
		'Fecha de Estatus',
	];

	const wchCols = [
		{ wch: headersTranslations[0].length + 5 },
		{ wch: headersTranslations[1].length },
		{ wch: 35 },
		{ wch: 35 },
		{ wch: headersTranslations[4].length + 10 },
		{ wch: headersTranslations[5].length + 10 },
		{ wch: headersTranslations[6].length + 15 },
		{ wch: headersTranslations[7].length + 5 },
		{ wch: headersTranslations[8].length },
	];
	const dataToExcel = () => {
		const data = getDataExport(filtersList);
		// modificar data en los campos requestDate y statusChangeDate para formato de fecha dd/mm/yyyy
		const formatDate = (date) =>
			date ? new Intl.DateTimeFormat('es-ES').format(new Date(date)) : '';

		const dataExcel = data.map(
			({
				code,
				requestDate,
				name,
				email,
				fieldName,
				fieldOldValue,
				fieldValue,
				status,
				statusChangeDate,
			}) => ({
				code,
				requestDate: formatDate(requestDate),
				name,
				email,
				fieldName,
				fieldOldValue,
				fieldValue,
				status,
				statusChangeDate: formatDate(statusChangeDate),
			})
		);

		const toExcel = {
			filename: 'Solicitudes',
			templateFileName: 'simple_template.xlsx',
			replace: [
				{
					type: 0,
					tag: '#simple.name',
					prop: 'name',
					default: 'Simple',
				},
				{
					type: 1,
					tag: '#simple.company',
					prop: 'company',
					default: 'Simple',
				},
				{
					type: 1,
					tag: '#printDate',
					prop: 'printDate',
					default: new Date().toLocaleDateString(),
					format: ['numberFormat', 'dd mmmm yyyy'],
				},
				{
					type: 1,
					tag: '#simple.appName',
					prop: 'appName',
					default: 'Simple',
				},
				{
					type: 1,
					tag: '#simple.name',
					prop: 'name',
					default: 'Simple',
				},
				{
					type: 2,
					tag: '#simple.cols',
					prop: 'cols',
				},
				{
					type: 3,
					tag: '#simple.rows',
					prop: 'rows',
					colprop: 'cols',
				},
			],
			data: {
				company: companyData?.name,
				autoFilter: true,
				gridLinesVisible: true,
				printDate: new Date().toLocaleDateString(),
				appName: 'Colabora',
				name: 'Solicitudes',
				cols: [],
				rows: dataExcel,
			},
		};
		// cols = [{ title: "", prop:"", width: ""}]
		// title es el nombre de la columna y prop es el nombre de la propiedad del objeto
		// width es el ancho de la columna

		headers.forEach((header, index) => {
			const col = {
				title: headersTranslations[index],
				prop: header,
				width: wchCols[index].wch,
			};
			toExcel.data.cols.push(col);
		});
		return toExcel;
	};

	async function toExcelExport() {
		//console.log('data', filtersList);
		if (filtersList.length > 0) {
			dispatchLoading({ type: LOADING_ON });
			const toExcel = dataToExcel();

			const fillName = getFileNameWithDateTime('Solicitudes');

			await downloadFileBase64(toExcel, 'xlsx', fillName)
				.then(() => {
					dispatchLoading({ type: LOADING_OFF });
				})
				.catch((error) => {
					dispatchLoading({ type: LOADING_OFF });
				});
		} else {
			Toast(
				'warning',
				GeneralNames.EmptyDataMessageExport,
				GeneralNames.EmptyDataMessageBodyExport,
				'success'
			);
		}
	}

	function acceptFunction() {
		toSend.length === 0
			? Toast(
					'warning',
					CollaboratorScreenNames.AlertValidationEmptyArrayTitle,
					CollaboratorScreenNames.AlertValidationEmptyArraySubTitle,
					'success'
			  )
			: returnRenderDialog('accept');
	}
	function onRejectClick() {
		toSend.length === 0
			? Toast(
					'warning',
					CollaboratorScreenNames.AlertValidationEmptyArrayTitle,
					CollaboratorScreenNames.AlertValidationEmptyArraySubTitle,
					'success'
			  )
			: returnRenderDialog('reject');
	}

	const iconsData = [
		{
			icon: 'reject',
			title: 'Rechazar Cambios',
			onClick: onRejectClick,
			small: true,
		},
		{ icon: 'accept', title: 'Aceptar Cambios', onClick: acceptFunction },
		{ icon: 'clear', title: 'Limpiar Filtros', onClick: clearFilters },
		{
			icon: 'excel',
			title: GeneralNames.GeneralExportToExcel,
			onClick: toExcelExport,
		},
	];

	const renderHeader = () => (
		<>
			<ToolBarThreeColumns
				size={size}
				value={globalFilterValue}
				setValue={setGlobalFilterValue}
				setFilters={setFilters}
				onChange={onGlobalFilterChange}
				placeholder={CollaboratorScreenNames.PlaceHolderDropdown}
				onExcelClick={async () => {}}
				onAcceptClick={() => {}}
				onRejectClick={() => {}}
				filterOpc={filterOpc}
				selectFilter={selectFilter}
				handleFilterChange={handleFilterChange}
				textAccept={CollaboratorScreenNames.TextIconAccept}
				iNeedEndDate
				iNeedStartDate
				startDate={startDate}
				endDate={endDate}
				setStartDate={setStartDate}
				setEndDate={setEndDate}
				reloadButtonDisabled={true}
				cleanFilters={clearFilters}
				clearTextLabel={clearTextLabel}
				iNeedCancelButton
				iconsDataPanel={iconsData}
			/>
		</>
	);

	const renderTableButton = (icon, toolTip, action) => {
		return (
			<div
				onClick={() => action()}
				title={toolTip}
				className='flex w-3 h-3  rounded-full justify-center content-center items-center   '>
				{/* <p
					title={toolTip}
					className='!text-white flex !text-2xl justify-center content-center items-center hover:!text-secondary-PCS002 '>
					<span className={icon}></span>
				</p> */}
				<FontAwesomeIcon
					size='sm'
					className='!text-black flex !text-sm justify-center content-center items-center '
					icon={icon}
					title={toolTip}
				/>
			</div>
		);
	};

	const actionBodyTemplate = (rowData) => {
		return (
			<div className='flex justify-center content-center items-center'>
				{rowData.status === 0
					? renderTableButton(
							faCheck,
							CollaboratorScreenNames.TextTooltip,
							() => {
								setOpenDialog(true);
								setTypeAction('accept');
								setIsIndividualAction(true);
								setIndividualRow(rowData);
							}
					  )
					: null}
				{rowData.status === 0
					? renderTableButton(
							faX,
							CollaboratorScreenNames.TextTooltipReject,
							() => {
								setOpenDialog(true);
								setTypeAction('reject');
								setIsIndividualAction(true);
								setIndividualRow(rowData);
							}
					  )
					: null}
			</div>
		);
	};

	const renderTable = () => {
		const bodyClassName =
			'!border-r-2 !border-b-0 !border-secondary-PCS004 !text-black';
		return (
			<CustomTable
				value={filtersList}
				sortField='name'
				header={renderHeader()}
				filters={filters}
				responsiveLayout='scroll'
				globalFilterFields={[
					'name',
					'rfc',
					'entryDate',
					'reEntryDate',
					'status',
					'firstLastName',
					'formattedCode',
					'fullName',
				]}
				emptyMessage={GeneralNames.GeneralEmptyMessage}
				currentPageReportTemplate={
					CollaboratorScreenNames.CurrentPageReportTemplate
				}
				resizableColumns
				columnResizeMode='fit'
				style={{ height: '100%', width: '100%' }}
				selection={toSend}
				//	selectionMode='checkbox'
				onSelectionChange={(e) => setToSend(e.value)}
				//isDataSelectable={(rowData) => rowData.data.answerDate === null}
				setSort={setSort}
				isDataSelectable={(rowData) => rowData.data.status === 0}
				sort={sort}>
				<Column
					headerClassName={headerClassName}
					bodyClassName='!text-center !text-secondary-PCS004 !border-r-2 !border-b-0 !border-secondary-PCS004'
					selectionMode='multiple'
					style={{ minWidth: '10px' }}
				/>

				<Column
					headerClassName={headerClassName}
					style={{ flexGrow: 1, flexBasis: '60px' }}
					field='code'
					header={TableNames.TableCode}
					sortable
					bodyClassName={bodyClassName}
					body={(rowData) => {
						return rowData.code;
					}}
				/>
				<Column
					headerClassName={headerClassName}
					style={{ flexGrow: 2, flexBasis: '100px' }}
					field='requestDate'
					header={TableNames.TableDateOfApplication}
					dataType='date'
					sortable
					bodyClassName={bodyClassName}
					body={(rowData) => {
						return formatDate(rowData.requestDate);
					}}
				/>
				<Column
					headerClassName={headerClassName}
					style={{ flexGrow: 5, flexBasis: '100px' }}
					field='name'
					header={TableNames.TableFirstName}
					sortable
					bodyClassName={bodyClassName}
					body={(rowData) => {
						return rowData.fullName;
					}}
				/>
				<Column
					headerClassName={headerClassName}
					style={{ flexGrow: 5, flexBasis: '100px' }}
					field='email'
					header={TableNames.TableEmail}
					sortable
					bodyClassName={bodyClassName}
				/>

				<Column
					headerClassName={headerClassName}
					style={{ flexGrow: 2, flexBasis: '100px' }}
					field='fieldName'
					header={TableNames.TableDataToBeChanged}
					sortable
					bodyClassName={bodyClassName}
					body={(rowData) => {
						return dictionaryNamesCollaborators[rowData?.fieldName] !==
							undefined
							? dictionaryNamesCollaborators[rowData?.fieldName]
							: rowData?.fieldName;
					}}
				/>
				<Column
					headerClassName={headerClassName}
					style={{ flexGrow: 2, flexBasis: '100px' }}
					field='fieldOldValue'
					header={TableNames.TablePreviousValue}
					sortable
					bodyClassName={bodyClassName}
				/>
				<Column
					headerClassName={headerClassName}
					style={{ flexGrow: 2, flexBasis: '100px' }}
					field='fieldValue'
					header={TableNames.TableNewValue}
					sortable
					bodyClassName={bodyClassName}
				/>

				<Column
					headerClassName={headerClassName}
					style={{ flexGrow: 1, flexBasis: '100px' }}
					field='status'
					header={TableNames.TableState}
					sortable
					bodyClassName={bodyClassName}
					body={(rowData) => {
						return StatusArrayValuesTableCollaborators.filter((item) => {
							return item.value === rowData.status;
						})[0].label;
					}}
				/>
				<Column
					headerClassName={headerClassName}
					style={{ flexGrow: 2, flexBasis: '100px' }}
					field='statusChangeDate'
					header={TableNames.TableDateOfState}
					dataType='date'
					sortable
					bodyClassName={bodyClassName}
					body={(rowData) => {
						return formatDate(rowData.statusChangeDate);
					}}
				/>
				<Column
					headerClassName={headerClassName}
					style={{ flexGrow: 1, flexBasis: '100px' }}
					header={TableNames.TableActions} //Para eliminar encuesta
					body={actionBodyTemplate}
					bodyClassName={bodyClassName}
				/>
			</CustomTable>
		);
	};

	const acceptInfoAction = async () => {
		try {
			const dataToSend =
				toSend.length > 0 && isIndividualAction ? toSend : [individualRow];
			let values = '';
			dataToSend.forEach((item) => {
				if (values === '') {
					values = item.collaboratorChangeId;
				} else {
					values = values + ',' + item.collaboratorChangeId;
				}
			});
			let valuesToResponse = convertStringToArray(values);
			let response = await acceptInformation(valuesToResponse);
			setToSend([]);
			return response;
		} catch (error) {}
	};

	const rejectInfoAction = async () => {
		let dataToSend =
			toSend.length > 0 && isIndividualAction ? toSend : [individualRow];
		let values = '';

		dataToSend.forEach((item) => {
			if (values === '') {
				values = item.collaboratorChangeId;
			} else {
				values = values + ',' + item.collaboratorChangeId;
			}
		});
		try {
			let valuesToResponse = convertStringToArray(values);
			let response = await rejectInformation(valuesToResponse);
			setToSend([]);
			return response;
		} catch (error) {}
	};

	const convertStringToArray = (string) => {
		let array = string.split(',');
		return array;
	};

	const renderAlert = () => {
		return (
			<CustomAlertDialog
				title={
					typeAction === 'accept'
						? CollaboratorScreenNames.CustomAlertDialogTittleAccept
						: CollaboratorScreenNames.CustomAlertDialogTittleReject
				}
				setOpen={setOpenDialog}
				open={openDialog}
				message={
					typeAction === 'accept'
						? CollaboratorScreenNames.CustomAlertDialogSuccessMessageAccept
						: CollaboratorScreenNames.CustomAlertDialogSuccessMessageReject
				}
				cancelLabel={GeneralNames.GeneralCancel}
				agreeLabel={
					typeAction === 'accept'
						? GeneralNames.GeneralAccept
						: GeneralNames.GeneralReject
				}
				agreeIcon='pi pi-check'
				onConfirmFunction={() =>
					typeAction === 'accept' ? acceptInfoAction() : rejectInfoAction()
				}
				style={{ width: '30vw' }}
			/>
		);
	};
	return (
		<MainContainer
			nameScreen={SidebarNames.SidebarApplications}
			alertComponent={renderAlert()}>
			{renderTable()}
		</MainContainer>
	);
};
