import React, { useContext, useEffect } from 'react';
import { ReloadContext } from '../context/ReloadProvider';

const useReload = (functions = []) => {
	const { reload, setReload } = useContext(ReloadContext);
	useEffect(() => {
		reload && reloadData();
	}, [reload]);

	const reloadData = () => {
		Promise.all(functions.map((fu) => fu())).finally(() => setReload(false));
	};

	return null;
};

export default useReload;
