import React, { createContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { sidebarRoutes } from '../router/sidebarRoutes';

import { findNestedObj } from '../utils/utils';

const NavigationContext = createContext();

const NavigationProvider = ({ children }) => {
	const [save, setSave] = useState(true);
	const [awitTap, setAwaitTap] = useState(null);
	const [collapsed, setCollapsed] = useState(false);
	const [taps, setTaps] = useState([]);
	const [url, setUrl] = useState('/');
	const [showValidationAlertSave, setShowValidationAlertSave] = useState(false);
	const [validData, setValidData] = useState(false);
	const [urlStage, setUrlStage] = useState('');
	// array of obj:
	// { [pathname]: true/false }
	// ex a function if is true
	const [afterClose, setAfterClose] = useState(null);
	// save the url to remove
	const [closeUrl, setCloseUrl] = useState(null);
	const [currentTap, setCurrentTap] = useState({
		url: '/',
	});
	const changeCollapsed = () => {
		setCollapsed(!collapsed);
	};

	const navigate = useNavigate();
	const addTap = (tap) => {
		setTaps([...taps, tap]);
		setCurrentTap(tap);
		//set in local storage
	};
	const removeTap = (url, item) => {
		if (save === false) {
			setAwaitTap({
				removeTap: true,
				url: url,
			});
			return;
		}
		// use if we need to execute some code after close
		// afterClose is a array of objects path is the key, if is true then save the url to remove it ( more info in the last useEffect)
		if (afterClose != null && afterClose[window.location.pathname]) {
			setCloseUrl(url);
			return;
		}
		const urlSplit = url.split('/');
		// the first two elements are always empty get the third and fourth elements and join them whit a -
		const id = urlSplit[1] + '-' + urlSplit[2];
		setTaps(taps.filter((tap) => tap.id !== id));
		setCurrentTap(null);
	};

	/**
	 * It adds a new tap to the taps array if the tap doesn't exist, if it does exist it updates the tap's
	 * state and url
	 * @param urlTap - the url of the tap
	 * @param [state=null] - this is the state of the component that is being opened.
	 * @param [tapTitle=null] - The title of the tap.
	 */
	const selectTap = (
		urlTap,
		state = null,
		tapTitle = null,
		firstNameTapTitle = false
	) => {
		// save === false
		if (save === false) {
			setAwaitTap({
				url: urlTap,
				state: state,
				tapTitle: tapTitle,
			});
			return;
		}
		//get id from url'

		const urlSplit = urlTap.split('/');
		// the first two elements are always empty get the third and fourth elements and join them whit a -
		const id = urlSplit[1] + '-' + urlSplit[2];
		const father = findNestedObj(sidebarRoutes, 'id', urlSplit[1]);
		// if tap exists and url is same as tap url
		if (taps.find((tap) => tap.id === id)) {
			// if tap is not the current tap
			if (currentTap.id !== id) {
				// find the tap
				const current = taps.find((tap) => tap.id === id);
				// set data to current
				if (current.state !== null && state === null) {
					if (current.state?.clear !== false) {
						current.state = state;
					}
				} else if (current.state && state) {
					current.state = state;
					current.tapTitle = tapTitle;
				} else if (current.state === null && state) {
					current.state = state;
				}
				// if current url is different from incoming url
				if (current.url !== urlTap) {
					// set current url to incoming url
					current.url = urlTap;
				}
				// set current tap
				// if the same component is used and only the title is changed
				setCurrentTap(current);
			}
		} else {
			// find tap in routes
			let tap = findNestedObj(sidebarRoutes, 'id', id);
			if (tap) {
				if (state) {
					// if state is not null

					tap.state = state;
				}
				if (father) {
					// if father is not null
					tap.father = firstNameTapTitle ? tapTitle : father.tapTitle;
				}
				addTap(tap);
			} else {
				//create new tap
				let tap = {
					id: id,
					tapTitle: tapTitle,
					url: urlTap,
					state: state,
				};
				if (father) {
					tap.father = father.tapTitle;
				}
				addTap(tap);
			}
		}
	};

	// useEffect to get the current tap from local storage
	useEffect(() => {
		if (currentTap) {
			navigate(currentTap.url, {
				state: currentTap.state,
			});
		} else {
			if (taps && taps?.length > 0) {
				setCurrentTap(taps[taps?.length - 1]);
			} else {
				navigate('/');
			}
		}
	}, [currentTap]);

	useEffect(() => {
		if (awitTap && save) {
			if (awitTap.removeTap) {
				removeTap(awitTap.url);
			} else {
				selectTap(awitTap.url, awitTap.state, awitTap.tapTitle);
			}
			setAwaitTap(null);
		}
	}, [save]);
	// useEffect to remove the tap if the function is executed after close, you need to change the afterClose array to true
	// example in src\containers\Queries\index.jsx first useEffect
	useEffect(() => {
		if (closeUrl) {
			removeTap(closeUrl);
			setCloseUrl(null);
		}
	}, [afterClose]);

	return (
		<NavigationContext.Provider
			value={{
				taps,
				removeTap,
				selectTap,
				currentTap,
				changeCollapsed,
				collapsed,
				url,
				save,
				setSave,
				awitTap,
				setAwaitTap,
				showValidationAlertSave,
				setShowValidationAlertSave,
				validData,
				setValidData,
				urlStage,
				setUrlStage,
				setAfterClose,
				closeUrl,
			}}>
			{children}
		</NavigationContext.Provider>
	);
};

export { NavigationContext, NavigationProvider };
