import {
	EDIT_INCIDENCES,
	EDIT_INCIDENCES_ERROR,
	EDIT_INCIDENCES_ERROR_SHOW,
	EDIT_INCIDENCES_SUCCESS,
} from './../../constants';

export const editIncidencesReducer = (state, actions) => {
	switch (actions.type) {
		case EDIT_INCIDENCES:
			return {
				...state,
				loading: true,
			};
		case EDIT_INCIDENCES_ERROR:
			return {
				incidences: state.incidences,
				//	collaboratorsOptions: state.collaboratorsOptions,
				loading: false,
				error: actions.payload,
				errorShow: false,
			};
		case EDIT_INCIDENCES_SUCCESS:
			return {
				incidences: actions.payload,
				//collaboratorsOptions: getCollaboratorOptions(actions.payload),
				loading: false,
				error: null,
				errorShow: false,
			};
		case EDIT_INCIDENCES_ERROR_SHOW:
			return {
				...state,
				errorShow: true,
			};
		default:
			return state;
	}
};
