import React, { useEffect, useState, useRef } from 'react';
import months from '../utils/months';
import { TieredMenu } from 'primereact/tieredmenu';

export const InfoDate = ({
	bgHolidays,
	bgCompanyDate,
	holidays,
	selectDate,
	setSelectDate,
	setOpenDeleteDialog,
	setEventToDelete,
	setOpenAddExportDaysOff,
	onEditFunction,
}) => {
	const [selectionData, setSelectionData] = useState(undefined);
	useEffect(() => {
		if (selectDate !== undefined) {
			const _holidays = holidays.find(
				(dateProvided) =>
					selectDate !== undefined &&
					selectDate.toDateString() ===
						new Date(dateProvided.date).toDateString()
			);
			setSelectionData(_holidays);
		}
	}, [selectDate, holidays]);

	const menu = useRef([]);

	const bgGray = 'bg-[#F0F0F0]';
	let wh = 'w-[35px] h-[35px]';

	const infoPoints = () => {
		return (
			<div className='w-full flex flex-col h-auto space-y-1'>
				<div className='flex flex-row w-full space-x-1'>
					<div className={`${wh} rounded-full ${bgHolidays}`} />
					<p className='font-bold text-black flex items-center justify-center'>
						{'Días festivos'}
					</p>
				</div>
				<div className='flex flex-row w-full space-x-1'>
					<div className={`${wh} rounded-full ${bgCompanyDate}`} />
					<p className='font-bold text-black text-base flex items-center justify-center'>
						{'Días otorgados por la empresa'}
					</p>
				</div>
			</div>
		);
	};

	const infoSelectedDate = () => {
		const validationWorkCenterLength =
			selectionData?.workCenters === undefined ||
			selectionData?.workCenters === null
				? 'Sin definir'
				: `${selectionData?.workCenters?.length} Centros de Trabajo`;

		const validationAppliesAllWorkCenter = selectionData?.appliesAllWorkCenter
			? 'Todos los Centros de trabajo'
			: validationWorkCenterLength;

		return (
			<div
				className={`w-full flex flex-col h-auto space-y-2 ${
					selectionData !== undefined ? 'visible' : 'invisible'
				}`}>
				<div
					className={`flex flex-col w-full ${bgGray} pt-2 pb-2 pl-2 space-y-2 rounded-lg`}>
					<div className='flex flex-row w-full space-x-1'>
						<div
							className={`${wh} rounded-full ${
								selectionData !== undefined &&
								selectionData.officialDay === true
									? bgHolidays
									: bgCompanyDate
							}`}
						/>
						<div className='flex flex-col'>
							<p className='text-sm font-bold text-black'>
								{`${
									selectionData !== undefined
										? `${new Date(selectionData.date).getDate()} de ${
												months[new Date(selectionData.date).getMonth()]
										  }`
										: '.'
								}`}
							</p>
							<p className='text-sm font-bold text-[#757575]'>{`${
								selectionData !== undefined ? selectionData.description : '.'
							}`}</p>
						</div>
					</div>
					<div className='flex flex-col w-full'>
						<p className='text-sm font-bold text-black'>{'Tipo de día:'}</p>
						<p className='text-sm font-bold text-[#757575]'>
							{' '}
							{`${
								selectionData !== undefined &&
								selectionData.officialDay === true
									? 'Día festivo'
									: 'Día otorgado por la empresa'
							}`}
						</p>
					</div>
					<div className='flex flex-col w-full'>
						<p className='text-sm font-bold text-black'>
							{'Centro de trabajos:'}
						</p>
						<p className='text-sm font-bold text-[#757575]'>
							{selectionData !== undefined
								? validationAppliesAllWorkCenter
								: '.'}
						</p>
					</div>
				</div>
			</div>
		);
	};

	const clickEdit = (event) => {
		let date = new Date(event.date);
		setSelectDate(date);
		setOpenAddExportDaysOff(true);
		onEditFunction(event);
		return event;
	};

	const clickDelete = (event) => {
		setEventToDelete(event);
		setOpenDeleteDialog(true);
	};
	const listFreeDays = () => {
		return (
			<div
				className={`w-full flex flex-col h-4/6 ${
					holidays !== undefined && holidays.length > 0
						? 'visible'
						: 'invisible'
				}`}>
				<div
					className={`flex flex-col w-full ${bgGray} pt-2 pb-2 pl-2 space-y-2 rounded-lg overflow-y-auto 
                    sm:max-h-[25vh] md:max-h-[25vh] lg:max-h-[30vh] xl:max-h-[35vh] 2xl:max-h-[41vh]
                `}>
					{holidays.map((d, index) => {
						//convert index to string
						const newIndex = index.toString();
						let date = new Date(d.date);
						let isAfterToday = date > new Date();
						const itemsAfterToDay = [
							{
								label: 'Editar',
								icon: 'pi pi-fw pi-pencil !text-light-ShadowPCL004',
								command: () => {
									clickEdit(d);
								},
							},
							{
								label: 'Eliminar',
								icon: 'pi pi-trash  !text-light-ShadowPCL004',
								command: () => {
									clickDelete(d);
								},
							},
						];

						const itemsBeforeToday = [
							{
								label: 'Editar',
								icon: 'pi pi-fw pi-pencil !text-light-ShadowPCL004',
								command: () => {
									clickEdit(d);
								},
							},
						];

						return (
							<div
								className={`w-full flex flex-row `}
								key={`index-listFreeDays-${newIndex}`}>
								<div
									className={`flex flex-col  w-[85%]  ${
										holidays[index + 1] !== undefined
											? 'border-b border-black'
											: ''
									}`}>
									<p className='text-sm font-bold text-black'>{`${date.getDate()} de ${
										months[date.getMonth()]
									}`}</p>
									<p className='text-sm font-bold text-[#757575]'>
										{d.description}
									</p>
								</div>
								<div
									className={`flex flex-col w-[10%]  ${
										holidays[index + 1] !== undefined
											? 'border-b border-black'
											: ''
									}`}>
									<div className='card flex justify-content-center'>
										<TieredMenu
											model={isAfterToday ? itemsAfterToDay : itemsBeforeToday}
											popup
											ref={(element) => (menu.current[index] = element)}
											className='!p-0 !border-1'
										/>
										<i
											className={'pi pi-ellipsis-v'}
											onClick={(e) => menu.current[index].toggle(e)}
										/>
									</div>
								</div>
							</div>
						);
					})}
				</div>
			</div>
		);
	};

	return (
		<div className='flex flex-col h-full w-full pr-3 space-y-2'>
			{infoPoints()}
			{infoSelectedDate()}
			{listFreeDays()}
		</div>
	);
};
