import {
	GET_COMPANY_SETTINGS_NOTIFICATIONS,
	GET_COMPANY_SETTINGS_NOTIFICATIONS_ERROR,
    GET_COMPANY_SETTINGS_NOTIFICATIONS_SUCCESS,
} from '../../constants';

export const getCompanySettingNotificationReducer = (state, actions) => {
	switch (actions.type) {
		case GET_COMPANY_SETTINGS_NOTIFICATIONS:
			return {
				...state,
				loading: true,
				error: null,
				errorShow: false,
			};
		case GET_COMPANY_SETTINGS_NOTIFICATIONS_ERROR:
			return {
				...state,
				loading: false,
				error: actions.payload,
				errorShow: true,
			};
		case GET_COMPANY_SETTINGS_NOTIFICATIONS_SUCCESS:
			return {
				...state,
				loading: false,
				error: null,
				errorShow: false,
				notifications: actions.payload,
			};
		default:
			return state;
	}
};
