import React, { useEffect, useRef, useState, useContext } from 'react';
import { CustomTable } from '../../../../components/Table/CustomTable';
import { GeneralNames, ScheduleNames } from '../../../../namesConstants/names';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';

import { StoreContext } from '../../../../business/Provider';
import { ProgressSpinner } from 'primereact/progressspinner';
import { headerClassName } from '../../../../assets/customStyles';

export const TableWorkCenterLocation = ({
	list,
	isHomeOffice,
	onClose,
	idPersoniaCollaborator,
	postWorkCenterLocationCollaboratorsOnOperation
}) => {
	const { getWorkCenterState , getOperationWorkCenterLocationCollaboratorsState} = useContext(StoreContext);

	const [initListSelect, initSetListSelect] = useState([]);

	useEffect(() => {
		if (
			getWorkCenterState.loading === false &&
			getWorkCenterState.workCenters !== undefined &&
			getWorkCenterState.workCenters !== null
		) {
			let _result = getWorkCenterState?.workCenters?.results;
			if (!isHomeOffice) {
				const _transformList = _result
					.filter((w) => {
						return !w.homeOffice && w.isActive;
					})
					.map((data) => {
						return {
							workCenterId: data.workCenterId,
							name: data.name,
							fullAddress: data.fullAddress,
							geoFenceSize: data.geoFenceSize,
						};
					});
				setListFinal(_transformList);
				setSelectedWorkCenter(list);
				initSetListSelect(list);
			} 
		}
	}, [getWorkCenterState.workCenters , list]);

	useEffect(() => {
		if (
			getOperationWorkCenterLocationCollaboratorsState.loading === false &&
			getOperationWorkCenterLocationCollaboratorsState.workCenters !== undefined &&
			getOperationWorkCenterLocationCollaboratorsState.workCenters !== null
		) {
			const _result = getOperationWorkCenterLocationCollaboratorsState.workCenters?.results;
			if (isHomeOffice) {
				setListFinal(_result);
			}
		}
	}, [getOperationWorkCenterLocationCollaboratorsState.workCenters]);



	const [sort, setSort] = useState([{ field: 'name', order: 1 }]);

	const bodyClassName = `!border-r-2 !border-b-0 !border-secondary-PCS004 !text-black !justify-left`;
	const [selectedWorkCenter, setSelectedWorkCenter] = useState([]);
	const [listFinal, setListFinal] = useState([]);

	const sendData = async () => {
		const toDelete = initListSelect
			.filter(
				(workCenter) =>
					!selectedWorkCenter.some(
						(sw) => sw.workCenterId === workCenter.workCenterId
					)
			)
			.filter((workCenter) => workCenter !== undefined)
			.map((workCenter) => workCenter.workCenterId);

		const toAdd = selectedWorkCenter
			.filter(
				(workCenter) =>
					!initListSelect.some(
						(iw) => iw.workCenterId === workCenter.workCenterId
					)
			)
			.map((workCenter) => workCenter.workCenterId);

		const _request = { toDelete, toAdd };
		let result = await postWorkCenterLocationCollaboratorsOnOperation(
			idPersoniaCollaborator,
			_request
		);
		if (result.status === 200 || result.status === 201) {
			onClose();
		}
	};

	const buttoms = () => {
		return (
			<div className='w-full h-full flex flex-row space-x-2 justify-end items-end'>
				<Button
					label='Cancelar'
					className='flex !h-4 !w-auto !bg-white text-white rounded-lg !boder !border-[#757575] !text-[#757575] '
					onClick={() => {
						onClose();
					}}
				/>
				<Button
					label='Guardar'
					className='flex !w-auto  !h-4 !bg-secondary-ShadowPCS002 text-white rounded-lg'
					onClick={() => {
						sendData();
					}}
				/>
			</div>
		);
	};
	const { loadingState } = useContext(StoreContext);
	const renderLoading = () => {
		return loadingState ? (
			<div className='flex h-full w-full absolute top-0 left-0 right-0 z-50  bg-white opacity-80 justify-center content-center items-center flex-col'>
				<ProgressSpinner mode='indeterminate' className='flex w-full h-8 ' />
			</div>
		) : null;
	};

	const renderContent = () => {
		const content = (
			<div className='w-full h-full'>
				{renderLoading()}
				<div className={`${!isHomeOffice ? 'h-[90%]' : 'h-full'}`}>
					<CustomTable
						id={'DataTableOperationWorkCenterLocationCollaborators'}
						value={listFinal}
						sortField='Description'
						responsiveLayout='scroll'
						emptyMessage={GeneralNames.GeneralEmptyMessage}
						currentPageReportTemplate={ScheduleNames.CurrentPageReportTemplate}
						resizableColumns
						columnResizeMode='fit'
						style={{ height: '100%', width: '100%' }}
						setSort={setSort}
						sort={sort}
						selection={selectedWorkCenter}
						onSelectionChange={(e) => setSelectedWorkCenter(e.value)}>
						{!isHomeOffice ? (
							<Column
								headerClassName={headerClassName}
								bodyClassName='!text-center !text-secondary-PCS004 !border-r-2 !border-b-0 !border-secondary-PCS004'
								selectionMode='multiple'
								filterHeaderClassName={
									'!border-r-2 !border-b-0 !border-secondary-PCS004'
								}
								style={{ flexBasis: '40px' }}
							/>
						) : null}
						<Column
							filterHeaderClassName={
								'!border-r-2 !border-b-0 !border-secondary-PCS004'
							}
							headerClassName={headerClassName}
							style={{ flexGrow: 3, flexBasis: '200px' }}
							field={'name'}
							header={'Descripción'}
							sortable
							bodyClassName={bodyClassName}
						/>
						<Column
							filterHeaderClassName={
								'!border-r-2 !border-b-0 !border-secondary-PCS004'
							}
							headerClassName={headerClassName}
							style={{ flexGrow: 4, flexBasis: '200px' }}
							field={'fullAddress'}
							header={'Domicilio'}
							sortable
							bodyClassName={bodyClassName}
						/>
						<Column
							filterHeaderClassName={
								'!border-r-2 !border-b-0 !border-secondary-PCS004'
							}
							headerClassName={headerClassName}
							style={{ flexGrow: 1, flexBasis: '100px' }}
							field={'geoFenceSize'}
							header={'Geocerca'}
							sortable
							bodyClassName={bodyClassName + ' !justify-center'}
						/>
					</CustomTable>
				</div>
				{!isHomeOffice ? <div className='h-[10%]'>{buttoms()}</div> : null}
			</div>
		);
		return content;
	};

	return renderContent();
};
