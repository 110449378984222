import { React } from 'react';

import { Column } from 'primereact/column';

//components
import { CustomTable } from '../../../components/Table/CustomTable';

//utils and constants

import {
	GeneralNames,
	InvitationsScreenNames,
} from '../../../namesConstants/names';
import { headerClassName } from '../../../assets/customStyles';

import { createColumnsTableInvitations } from '../utilsTable';

export const TableComponent = ({ service, children }) => {
	const bodyClassName =
		'!border-r-2 !border-b-0 !border-secondary-PCS004 !text-black';

	const columnsData = createColumnsTableInvitations(service);

	return (
		<CustomTable
			value={service.filtersList}
			//loading={loading}
			header={children}
			//loading={service.loadingState}
			sortField='statusEmployee'
			filters={service.filters}
			responsiveLayout='scroll'
			globalFilterFields={[
				'name',
				'statusEmployee',
				'invitationSendingDate',
				'code',
				'email',
				'invitationStatus',
				'invitationResponseDate',
				'additionalInfo',
				'formattedCode',
			]}
			emptyMessage={GeneralNames.GeneralEmptyMessage}
			currentPageReportTemplate={
				InvitationsScreenNames.CurrentPageReportTemplate
			}
			resizableColumns
			columnResizeMode='fit'
			//	style={{ height: '100%', width: '100%' }}
			selection={service.toSend}
			selectionMode='checkbox'
			onSelectionChange={(e) => service.setToSend(e.value)}
			setSort={service.setSort}
			sort={service.sort}>
			<Column
				headerClassName={headerClassName}
				bodyClassName='!text-center !text-secondary-PCS004 !border-r-2 !border-b-0 !border-secondary-PCS004'
				selectionMode='multiple'
				style={{ minWidth: '10px' }}></Column>
			{columnsData.map((col, index) => (
				<Column
					key={index}
					headerClassName={headerClassName}
					field={col.field}
					header={col.header}
					style={col.style}
					body={col.body}
					bodyClassName={bodyClassName}
					sortable
				/>
			))}
		</CustomTable>
	);
};
