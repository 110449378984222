import { EXPORT, EXPORT_ERROR, EXPORT_SUCCESS } from '../../constants';

export const exportsReducer = (state, actions) => {
	switch (actions.type) {
		case EXPORT:
			return {
				...state,
				loading: true,
				error: null,
				errorShow: false,
			};
		case EXPORT_ERROR:
			return {
				...state,
				loading: false,
				error: actions.payload,
				errorShow: true,
			};
		case EXPORT_SUCCESS:
			return {
				...state,
				loading: false,
				error: null,
				errorShow: false,
				exports: actions.payload,
			};
		default:
			return state;
	}
};
