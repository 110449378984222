import * as yup from 'yup';

export const incidencesSchema = yup.object().shape({
	name: yup
		.string()
		.matches(
			/^[A-Za-z0-9\s/_\-.áéíóúÁÉÍÓÚ,.*+:()=]+$/,
			'El nombre contiene caracteres no permitidos'
		)
		.max(100, 'El nombre no puede tener más de 100 caracteres')
		.required('El campo nombre es obligatorio'),
	code: yup
		.string('')
		.required('El código es requerido')
		.matches(/^(?=.*[a-zA-Z])[a-zA-Z0-9]*[a-zA-Z][a-zA-Z0-9]*$/, 'Sólo se permiten letras y dígitos')
		.max(60),
	leaderUse: yup.boolean(false),
	incidenceType: yup.object().required('El campo es requerido'),
	considerationType: yup.object().required('El campo es requerido'),
	getsSalary: yup.boolean(false),
	decreasesSeventhDay: yup.boolean(false),
	//	accummulatedTypes: yup.ArraySchema(),
});
