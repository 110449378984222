import React from 'react';
import './styles.css';
import { Button } from 'primereact/button';
import { btnDropDown } from '../../assets/customStyles';

// comments about props
// onClick: function
// text: string (text to display)
// icon: html element (icon to display) ex: <FontAwesomeIcon icon={faPlus} />
// type: string (primary, secondary, success, info, warning, danger)
// disabled: boolean
export const ButtonDropDown = ({
	children,
	onClick,
	keyItem,
	title,
	Icon = null,
	disabled = false,
	className,
	...props
}) => {
	const iconRender = Icon ? (
		<p className='!text-secondary-PCS002 flex !text-2xl justify-center content-center items-center hover:!text-secondary-PCS002 '>
			<span className={Icon}></span>
		</p>
	) : null;
	return (
		<Button
			aria-controls='popup_menu'
			aria-haspopup
			key={keyItem}
			disabled={disabled}
			onClick={() => onClick()}
			variant='link'
			className={`!border-0 ${btnDropDown} 
            flex !font-h5-font-family !rounded-none  !justify-start hover:!border hover:border-secondary ${className}`}
			{...props}>
			{iconRender}
			{title ? <p className='pl-1'>{title}</p> : null}

			{children}
		</Button>
	);
};
