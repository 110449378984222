import {
	GET_QUERIES,
	GET_QUERIES_ERROR,
	GET_QUERIES_SUCCESS,
	GET_QUERIES_ERROR_SHOW,
} from './../../constants';

export const getQueriesReducer = (state, actions) => {
	switch (actions.type) {
		case GET_QUERIES:
			return {
				...state,
				loading: true,
			};
		case GET_QUERIES_ERROR:
			return {
				queries: state.queries,
				loading: false,
				error: actions.payload,
				errorShow: false,
			};
		case GET_QUERIES_SUCCESS:
			return {
				queries: actions.payload,
				loading: false,
				error: null,
				errorShow: false,
			};
		case GET_QUERIES_ERROR_SHOW:
			return {
				...state,
				errorShow: true,
			};

		default:
			return { queries: null, error: null, errorShow: false, loading: false };
	}
};
