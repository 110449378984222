/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect } from 'react';
import useApi from '../api';
import {
	GET_LEADERS_ORGANIZATION_CHART_ERROR,
	GET_LEADERS_ORGANIZATION_CHART_SUCCESS,
	GET_ORGANIZATION_CHART,
	GET_ORGANIZATION_CHART_ERROR,
	GET_ORGANIZATION_CHART_SUCCESS,
	LOADING_OFF,
	LOADING_ON,
} from '../../business/constants';
import { Toast } from '../../components/toast';
import { generateUrlBase } from '../../utils/utils';
import { StoreContext } from '../../business/Provider';
import { useIntegrations } from '../integrations/useIntegrations';

export const useOrganizationChart = () => {
	const {
		dispatchGetOrganizationChart,
		dispatchLoading,
		getCompanySettings,
		getOrgChartContextState,
		dispatchGetLeadersOrgChart,
		putOrgChartContextState,
		deleteLeaderOrgCharState,
	} = useContext(StoreContext);

	const {
		stateGetOrgChart,
		getOrgChartSuccess_DP,
		getOrgChartInit_DP,
		getOrgChartError_DP,
	} = getOrgChartContextState;

	const { putOrgChart_DP, putOrgChartSuccess_DP, putOrgChartError_DP } =
		putOrgChartContextState;

	const {
		deleteLeaderOrganizationChart_DP,
		deleteLeaderOrgChartSuccess_DP,
		deleteLeaderOrganizationChartERROR_DP,
	} = deleteLeaderOrgCharState;

	const getIsPayroll = JSON.parse(sessionStorage.getItem('isPayrollCall'));
	const isPayRoll = getIsPayroll ? 30 : 10;

	const { genericApiCall } = useApi();

	const getOrganizationChartData = async (_data) => {
		try {
			const baseUrl = generateUrlBase(
				'org-chart/employees?filters=' + _data + '&origin=' + isPayRoll
			);

			dispatchGetOrganizationChart({ type: GET_ORGANIZATION_CHART });
			dispatchLoading({ type: LOADING_ON });
			const result = await genericApiCall(baseUrl, 'GET', {}, {}, {});
			const data = result.data.results;

			if (result.status === 200) {
				dispatchGetOrganizationChart({
					type: GET_ORGANIZATION_CHART_SUCCESS,
					payload: data,
				});
				dispatchLoading({ type: LOADING_OFF });
			} else {
				let message = {
					body: '',
					header: 'Error',
				};
				if (result.message) message.body = result.message;
				dispatchGetOrganizationChart({
					type: GET_ORGANIZATION_CHART_ERROR,
					payload: message,
				});
				dispatchLoading({ type: LOADING_OFF });
			}
			return result;
		} catch (error) {
			Toast(
				'warning',
				'Se encontró un error intente mas tarde.',
				error.message,
				error
			);
			dispatchGetOrganizationChart({ type: GET_ORGANIZATION_CHART_ERROR });

			dispatchLoading({ type: LOADING_OFF });
		}
	};
	const getLeadersOrganizationChartData = async (companyId) => {
		let leaders = [];
		try {
			dispatchLoading({ type: LOADING_ON });
			const baseUrl = generateUrlBase(
				'org-chart/leaders?origin=' + isPayRoll + '&companyId=' + companyId
			);

			dispatchGetLeadersOrgChart({
				type: GET_LEADERS_ORGANIZATION_CHART_ERROR,
			});
			dispatchLoading({ type: LOADING_ON });
			const result = await genericApiCall(baseUrl, 'GET', {}, {}, {});
			const data = result.data.results;

			if (result.status === 200) {
				leaders = data;
				dispatchGetLeadersOrgChart({
					type: GET_LEADERS_ORGANIZATION_CHART_SUCCESS,
					payload: data,
				});
				dispatchLoading({ type: LOADING_OFF });
			} else {
				let message = {
					body: '',
					header: 'Error',
				};
				if (result.message) message.body = result.message;
				dispatchGetLeadersOrgChart({
					type: GET_LEADERS_ORGANIZATION_CHART_ERROR,
					payload: message,
				});
				dispatchLoading({ type: LOADING_OFF });
			}
			return result;
		} catch (error) {
			Toast(
				'warning',
				'Se encontró un error intente mas tarde.',
				error.message,
				error
			);
			dispatchGetLeadersOrgChart({
				type: GET_LEADERS_ORGANIZATION_CHART_ERROR,
			});

			dispatchLoading({ type: LOADING_OFF });
		} finally {
			dispatchLoading({ type: LOADING_OFF });
			return leaders;
		}
	};

	const putLeadersAssignment = async (data, dataToBase64) => {
		const baseUrl = generateUrlBase(`org-chart/leaders`);
		putOrgChart_DP();
		dispatchLoading({ type: LOADING_ON });
		try {
			//return setTimeout(() => {
			const result = await genericApiCall(baseUrl, 'PUT', data, {}, {});
			console.log('result', result);
			if (result.status === 200) {
				const collaboratorData = result.data;
				putOrgChartSuccess_DP(collaboratorData);
				dispatchLoading({ type: LOADING_OFF });
				Toast('success', 'Líder asignado correctamente', '', '');
			} else if (result.status === 207 || result.status === 409) {
				putOrgChartError_DP(result.data.errorData);
				dispatchLoading({ type: LOADING_OFF });
			} else {
				let message = {
					body: '',
					header: 'Error',
				};
				if (result.message) message.body = result.message;
				putOrgChartError_DP();
				dispatchLoading({ type: LOADING_OFF });
			}
			//	}, 1000);

			return await Promise.all([
				dataFilterOrganizationChart(),
				getOrganizationChartData(dataToBase64),
			]);
		} catch (error) {
			Toast('warning', error.message, '', error);
			putOrgChartError_DP();
			dispatchLoading({ type: LOADING_OFF });
		}
	};

	const dataFilterOrganizationChart = async (value = false) => {
		try {
			const baseUrl = generateUrlBase('org-chart/filters?invited=' + value);
			getOrgChartInit_DP();
			dispatchLoading({ type: LOADING_ON });
			const result = await genericApiCall(baseUrl, 'GET', {}, {}, {});
			const data = result.data.data;
			if (result.status === 200) {
				getOrgChartSuccess_DP(data);
				dispatchLoading({ type: LOADING_OFF });
			} else {
				let message = {
					body: '',
					header: 'Error',
				};
				if (result.message) message.body = result.message;
				getOrgChartError_DP(message);
				dispatchLoading({ type: LOADING_OFF });
			}
			return result;
		} catch (error) {
			Toast(
				'warning',
				'Se encontró un error intente mas tarde.',
				error.message,
				error
			);
			getOrgChartError_DP();
			dispatchLoading({ type: LOADING_OFF });
		}
	};

	const deleteLeaderPutOrganizationChart = async (id, _data) => {
		try {
			const baseUrl = generateUrlBase('org-chart/leaders?employeeId=' + id);

			deleteLeaderOrganizationChart_DP();
			dispatchLoading({ type: LOADING_ON });
			const result = await genericApiCall(baseUrl, 'DELETE', {}, {}, {});
			const data = result.data.results;

			if (result.status === 200) {
				deleteLeaderOrgChartSuccess_DP(data);
				dispatchLoading({ type: LOADING_OFF });
			} else {
				let message = {
					body: '',
					header: 'Error',
				};
				if (result.message) message.body = result.message;
				dispatchGetOrganizationChart();
				dispatchLoading({ type: LOADING_OFF });
			}
			return getOrganizationChartData(_data);
		} catch (error) {
			Toast(
				'warning',
				'Se encontró un error intente mas tarde.',
				error.message,
				error
			);
			deleteLeaderOrganizationChartERROR_DP();

			dispatchLoading({ type: LOADING_OFF });
		}
	};

	useEffect(() => {
		if (
			getCompanySettings.companies !== null &&
			stateGetOrgChart?.data === undefined &&
			stateGetOrgChart?.loading === false
		) {
			const { attendanceImportDetail } = getCompanySettings?.companies;
			const data = JSON.parse(attendanceImportDetail);
			if (data.OrgChart && stateGetOrgChart?.isLoad === false) {
				dataFilterOrganizationChart();
			}
		}
		// dataFilterOrganizationChart();
	}, [stateGetOrgChart, getCompanySettings]);

	return {
		getOrganizationChartData,
		dataFilterOrganizationChart,
		getLeadersOrganizationChartData,
		putLeadersAssignment,
		deleteLeaderPutOrganizationChart,
	};
};
