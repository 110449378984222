import React, { Fragment, useState } from 'react';
import { Configuration } from './Configuration';
import { SidebarNames } from '../../namesConstants/names';
import { TiChevronLeft, TiChevronRight } from 'react-icons/ti';
import { IconComponent } from '../../components/IconComponent';
import { IntegrationComponent } from '../../components/IntegrationComponent';
import { SidebarCatalogsComponent } from '../../components/SidebarCatalogsComponent';
export const ConfigurationScreen = () => {
	const [itemSelected, setItemSelected] = useState(1);
	const [currentTap, setCurrentTap] = useState({ id: 1 });
	const [isOpen, setIsOpen] = useState(true);

	const children = [
		{
			name: 'Catálogos',
			icon: 'AiOutlineSchedule',
			children: [
				{
					id: 1,
					//url: '/catalogs/workAreas',
					name: 'parametersOfAssistance',
					title: SidebarNames.ParametersOfAssistance,
					tapTitle: SidebarNames.ParametersOfAssistance,
					icon: 'MdOutlineSettingsInputComponent',
					translate: SidebarNames.ParametersOfAssistance,
					type: 'item',
				},
			],
		},
	];
	const renderComponent = (valueSelect) => {
		switch (valueSelect) {
			case 1:
				return <Configuration />;

			default:
				return null;
		}
	};
	return (
		<IntegrationComponent>
			<div className='flex w-full h-full flex-row'>
				<SidebarCatalogsComponent
					isOpen={isOpen}
					setCurrentTap={setCurrentTap}
					currentTap={currentTap}
					setIsOpen={setIsOpen}
					setItemSelected={setItemSelected}
					Data={children}
				/>
				<div className='flex h-full flex-grow'>
					{renderComponent(itemSelected)}
				</div>
			</div>
		</IntegrationComponent>
	);
};
