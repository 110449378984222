import { createContext, useState } from 'react';

export const SendIncidencesFilterContext = createContext();

export const SendIncidencesFilterProvider = ({ children }) => {
	const [typePeriod, setTypePeriod] = useState(null);
	const [typePeriods, setTypePeriods] = useState([]);
	const [period, setPeriod] = useState(null);
	const [periods, setPeriods] = useState([]);
	const [isLoadTypesPeriods, setIsLoadTypesPeriods] = useState(false);
	return (
		<SendIncidencesFilterContext.Provider
			key={'SendIncidencesFilter'}
			value={{
				typePeriod,
				setTypePeriod,
				typePeriods,
				setTypePeriods,
				period,
				setPeriod,
				periods,
				setPeriods,
				isLoadTypesPeriods,
				setIsLoadTypesPeriods,
			}}>
			{children}
		</SendIncidencesFilterContext.Provider>
	);
};
