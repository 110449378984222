import {
	POST_EMPLOYEE_IMPORT,
	POST_EMPLOYEE_IMPORT_ERROR,
	POST_EMPLOYEE_IMPORT_SUCCESS,
} from '../../constants';

export const postImportEmployeeReducer = (state, actions) => {
	switch (actions.type) {
		case POST_EMPLOYEE_IMPORT:
			return {
				...state,
				loading: true,
				error: null,
				errorShow: false,
				isImported: false,
			};
		case POST_EMPLOYEE_IMPORT_ERROR:
			return {
				...state,
				loading: false,
				error: actions.payload,
				errorShow: true,
				isImported: false,
			};
		case POST_EMPLOYEE_IMPORT_SUCCESS:
			return {
				...state,
				loading: false,
				error: null,
				errorShow: false,
				settings: actions.payload,
				isImported: true,
			};
		default:
			return state;
	}
};
