import React from 'react';
import { ProgressSpinner } from 'primereact/progressspinner';

export const FullLoading = ({ loadingState, message = '' }) => {
	const renderComponent = () => {
		return (
			<div className='flex h-full w-full absolute z-50 bg-white opacity-90 justify-center content-center items-center flex-col'>
				<ProgressSpinner mode='indeterminate' className='flex w-full h-8 ' />
				{message !== '' ? (
					<span className='font-bold text-lg'>{message}</span>
				) : null}
			</div>
		);
	};

	const show = loadingState;

	//const show = true;

	return <>{show ? renderComponent() : null}</>;
};
