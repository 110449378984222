import {
	DELETE_OPERATION_SCHEDULE_COLABORATOR,
	DELETE_OPERATION_SCHEDULE_COLABORATOR_ERROR,
	DELETE_OPERATION_SCHEDULE_COLABORATOR_SUCCESS,
} from '../../../constants';

export const deleteScheduleCollaboratorReducer = (state, actions) => {
	switch (actions.type) {
		case DELETE_OPERATION_SCHEDULE_COLABORATOR:
			return {
				...state,
				loading: true,
				error: null,
				errorShow: false,
			};
		case DELETE_OPERATION_SCHEDULE_COLABORATOR_ERROR:
			return {
				...state,
				loading: false,
				error: false,
				errorShow: true,
                deleted: false
			};
		case DELETE_OPERATION_SCHEDULE_COLABORATOR_SUCCESS:
			return {
				...state,
				loading: false,
				error: null,
				errorShow: false,
				deleted: true,
			};
		default:
			return state;
	}
};
