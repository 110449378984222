import React, { Component } from 'react';
import 'primeicons/primeicons.css';
import { FaExclamationTriangle } from 'react-icons/fa';
import { Button } from 'primereact/button';

class ErrorBoundary extends Component {
    constructor (props){
        super(props);
        this.state={
            hasError: false,
            mensajeError: ""
        }
    }
     
    static getDerivedStateFromError(error){
        console.error(error.message)
        return {hasError: true, mensajeError: error.message}
    }

    reloadPage = () => {
        window.location.reload();
    };
  render() {
    if(this.state.hasError){
        return (
            <div className='flex items-center justify-center w-full h-full'>
              <div className='px-4 lg:py-12'>
                <div className='lg:gap-4 lg:flex'>
                  <div className='flex flex-col items-center justify-center md:py-24 lg:py-32'>
                    <h1 className='font-bold text-600 text-9xl'>
                      <FaExclamationTriangle style={{ fontSize: '10rem', color: '#004B93' }}></FaExclamationTriangle>
                    </h1>
                    <p className='mb-2 text-2xl font-bold text-center text-gray-800 md:text-3xl'>
                      <span className='text-red-500'>¡Vaya!</span> Algo salió mal
                    </p>
                    <p className='mb-1 text-center text-gray-500 md:text-lg'>
                      Ocurrió un problema al procesar la petición. Por favor intenta de nuevo
                    </p>
                    <p className='mb-4 text-center text-gray-500 md:text-lg'>
                      Si el problema persiste contacta a tu administrador
                    </p>
                    <p className='mb-2 text-center text-gray-500 md:text-lg'>
                     Error: {this.state.mensajeError}
                    </p>
                    <Button
                      label='Recargar página'
                      className='flex !w-50 !h-6 bg-blue-800 text-white'
                      onClick={this.reloadPage}
                    />
                  </div>
                </div>
              </div>
            </div>
          );
    }
    else {
        return this.props.children
    }
  }
}

export default ErrorBoundary
