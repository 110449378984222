import React, { useContext } from 'react';

import { FullLoading } from './components/FullLoading';
import { LoadingContext } from './context/LoadingContext';

const WrapperApp = ({ children }) => {
	const { loading } = useContext(LoadingContext);
	return (
		<>
			<FullLoading zIndex={9999} show={!loading ? false : loading} />
			{children}
		</>
	);
};

export default WrapperApp;
