import React, { useState, Fragment, useContext } from 'react';
import { sidebarRoutes } from '../../router/sidebarRoutes';
import { SidebarItem } from '../SidebarItem';
import { containEmail } from '../../utils/utils';
import { StoreContext } from '../../business/Provider';

export const Sidebar = () => {
	const [dataSecondSidebar, setDataSecondSidebar] = useState([]);
	const { loadingState, loadingContextState } = useContext(StoreContext);

	const { stateLoadingWithMessage } = loadingContextState;
	const [isSelect, setIsSelect] = useState(false);
	// open hook for each sidebar item

	const reduceSidebar = sidebarRoutes?.reduce((acc, item) => {
		acc[item.id] = false;
		return acc;
	}, {});
	const [open, setOpen] = useState(reduceSidebar);

	function includeEmailInList(list) {
		const validate = containEmail();

		list.forEach((item) => {
			if (item.id === 'assist') {
				item.show = validate;
			}
		});

		return list;
	}
	const validatePilote = window.REACT_APP_WHITELIST_ENABLED;

	const newFilterList =
		validatePilote === true || validatePilote === 'true'
			? includeEmailInList(sidebarRoutes)
			: sidebarRoutes;

	return (
		<ul
			className={`flex h-full w-[90px] z-50  bg-secondary-ShadowPCS002 drop-shadow-2xl flex-col`}>
			{newFilterList.map((item, keyItem) => {
				const index = keyItem.toString();

				return item.show ? (
					<Fragment key={index}>
						<SidebarItem
							loadingState={loadingState || stateLoadingWithMessage.loading}
							itemSidebar={item}
							isSelectValue={isSelect}
							setIsSelect={setIsSelect}
							setDataSecondSidebar={setDataSecondSidebar}
							dataSecondSidebar={dataSecondSidebar}
							open={open}
							setOpen={setOpen}
						/>
					</Fragment>
				) : null;
			})}
		</ul>
	);
};
