import {
	PUT_IMPORT_PERIODS,
	PUT_IMPORT_PERIODS_ERROR,
	PUT_IMPORT_PERIODS_SUCCESS,
	PUT_IMPORT_PERIODS_ERROR_SHOW,
} from './../../constants';

export const putImportPeriodsReducer = (state, actions) => {
	switch (actions.type) {
		case PUT_IMPORT_PERIODS:
			return {
				...state,
				loading: true,
			};
		case PUT_IMPORT_PERIODS_ERROR:
			return {
				periods: state.periods,
				loading: false,
				error: actions.payload,
				errorShow: false,
			};
		case PUT_IMPORT_PERIODS_SUCCESS:
			return {
				periods: actions.payload,
				loading: false,
				error: null,
				errorShow: false,
			};
		case PUT_IMPORT_PERIODS_ERROR_SHOW:
			return {
				...state,
				errorShow: true,
			};
		default:
			return state;
	}
};
