import React from 'react';
import { Button } from 'primereact/button';
import { RadioButton } from 'primereact/radiobutton';
import { Input } from '../../../../components/Input/Input';

import { ContentRadio } from '../../../../components/ContentRadio';
import { MultiSelectComponentFormik } from '../../../../components/MultiSelect/MultiSelectComponentFormik';

export const FormAddDaysOff = ({
	onClose,

	formikDataObject,
	workCenters,
}) => {
	const formDate = () => {
		return (
			<div className='flex flex-col w-full h-4/5 space-y-1 overflow-auto'>
				<div className='flex flex-col w-full'>
					<ContentRadio
						name={'Tipo de día:'}
						labelRadio1={'Día festivo'}
						labelRadio2={'Día otorgado por la empresa'}
						id1={'dayHoliday'}
						radio1={
							<RadioButton
								inputId='dayHoliday'
								name='officialDay'
								id='officialDay'
								value={true}
								checked={formikDataObject.values.officialDay}
								onChange={(e) => {
									formikDataObject.setFieldValue('officialDay', e.value);
								}}
							/>
						}
						id2={'dayCompany'}
						radio2={
							<RadioButton
								inputId='dayCompany'
								name='dayCompany'
								value={false}
								checked={!formikDataObject.values.officialDay}
								onChange={(e) => {
									formikDataObject.setFieldValue('officialDay', e.value);
								}}
							/>
						}
					/>
				</div>
				<div className='flex flex-col w-full'>
					<Input
						placeholder={'Descripción:'}
						id='description'
						value={formikDataObject.values.description}
						className={'flex w-full h-auto !rounded-lg text-left'}
						classNameContent={'w-full h-auto !rounded-lg'}
						onChange={formikDataObject.handleChange}
						name={'description'}
						error={formikDataObject.touched.description}
						errorText={formikDataObject.errors.description}
					/>
				</div>
				<MultiSelectComponentFormik
					dataValues={workCenters}
					name='workCenters'
					id='workCenters'
					formikDataObject={formikDataObject}
					value={formikDataObject.values.workCenters}
					onChange={formikDataObject.handleChange}
					label='centros de trabajo'
					title='Centros de trabajo'
				/>
			</div>
		);
	};

	const buttons = () => {
		return (
			<div className='w-full h-1/5 flex flex-row space-x-2 justify-end items-end'>
				<Button
					label='Cancelar'
					className='flex !h-4 !w-auto !bg-white  rounded-lg  !border-[#757575] !text-[#757575] '
					onClick={() => onClose()}
				/>
				<Button
					label='Guardar'
					className='flex !w-auto  !h-4 !bg-secondary-ShadowPCS002 text-white rounded-lg'
					type='submit'
					// onClick={() => {
					// 	//onSubmitForm();
					// }}
				/>
			</div>
		);
	};

	return (
		<div className='flex flex-row  w-full space-x-3 !h-full'>
			<div className='flex flex-col w-full !h-full space-y-2'>
				<form
					className='flex w-full h-full flex-col'
					onSubmit={formikDataObject.handleSubmit}>
					{formDate()}
					{buttons()}
				</form>
			</div>
		</div>
	);
};
