import { bindActionCreators } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import { useMsal } from '@azure/msal-react';
import { InteractionStatus } from '@azure/msal-browser';
import { useEffect, useState, React } from 'react';

import { setUserData, logoutUser } from './store/userSlice';
import { b2cGetToken } from '../b2cService';
import SplashScreen from '../../components/SplashScreen';

const Auth = (props) => {
	const [waitAuthCheck, setWaitAuthCheck] = useState(true);
	const { instance, accounts, inProgress } = useMsal();

	useEffect(() => {
		if (inProgress === InteractionStatus.None) {
			b2cGetToken({ instance, accounts, inProgress }).then((accessToken) => {
				props.setUserData(accessToken);
				setWaitAuthCheck(false);
			});
		}
	}, [accounts, inProgress, instance, props]);

	return waitAuthCheck ? <SplashScreen /> : <>{props.children}</>;
};

function mapDispatchToProps(dispatch) {
	return bindActionCreators(
		{
			logout: logoutUser,
			setUserData,
		},
		dispatch
	);
}

export default connect(null, mapDispatchToProps)(Auth);
