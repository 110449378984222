import axios from 'axios';
import { GeneralNames } from '../namesConstants/names';
import moment from 'moment';
import { Toast } from '../components/toast';
import { Buffer } from 'buffer';
const XLSX = require('xlsx');
export const findNestedObj = (entireObj, keyToFind, valToFind) => {
	let foundObj;
	JSON.stringify(entireObj, (_, nestedValue) => {
		if (nestedValue && nestedValue[keyToFind] === valToFind) {
			foundObj = nestedValue;
		}
		return nestedValue;
	});
	return foundObj;
};

export const dayNames = [
	'domingo',
	'lunes',
	'martes',
	'miércoles',
	'jueves',
	'viernes',
	'sábado',
];

export const dayNamesShort = ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'];

export const dayNamesMin = ['D', 'L', 'M', 'X', 'J', 'V', 'S'];

export const monthNames = [
	'enero',
	'febrero',
	'marzo',
	'abril',
	'mayo',
	'junio',
	'julio',
	'agosto',
	'septiembre',
	'octubre',
	'noviembre',
	'diciembre',
];

export const monthNamesShort = [
	'ene',
	'feb',
	'mar',
	'abr',
	'may',
	'jun',
	'jul',
	'ago',
	'sep',
	'oct',
	'nov',
	'dic',
];

export const StatusArrayValues = [
	{
		label: 'Todos',
		value: 0,
	},
	{
		label: 'Respondido',
		value: 1,
	},
	{
		label: 'Cancelado',
		value: 2,
	},
	{
		label: 'Enviado',
		value: 3,
	},
	{
		label: 'Completado',
		value: 4,
	},
];

export const StatusArrayValuesTable = [
	{
		label: GeneralNames.GeneralToInvite,
		value: 0,
	},
	{
		label: GeneralNames.GeneralStatusSend,
		value: 1,
	},
	{
		label: GeneralNames.GeneralStatusAccepted,
		value: 2,
	},
	{
		label: GeneralNames.GeneralStatusRejected,
		value: 5,
	},
	{
		label: GeneralNames.GeneralStatusExpired,
		value: 3,
	},
	{
		label: GeneralNames.GeneralStatusError,
		value: 255,
	},
];

export const StatusArrayValuesTableCollaborators = [
	{
		label: GeneralNames.GeneralStatusPending,
		value: 0,
	},
	// {
	// 	label: GeneralNames.GeneralStatusApplied,
	// 	value: 1,
	// },
	{
		label: GeneralNames.GeneralStatusApplied,
		value: 1,
	},
	{
		label: GeneralNames.GeneralStatusRejected,
		value: 2,
	},
];

export const convertDateFormat = (date) => {
	if (date !== undefined) {
		const newDate = date.toString();
		const dateArray = newDate.split('/');
		const dateObject = new Date(dateArray[2], dateArray[1] - 1, dateArray[0]);
		return dateArray.length > 1 ? dateObject : date;
	}
};

export const regexName = new RegExp(
	`^[ A-Za-zñáéíóúü&#@0-9-()+*°!#$&=+_.,\\/"']*$`,
	'i'
);

export const exportExcel = (
	data,
	headers,
	headersTranslations,
	fileName,
	sheetName,
	wchCols
) => {
	const ws = XLSX.utils.json_to_sheet(data, { header: headers });
	ws['!cols'] = wchCols;
	// translate headers
	//create alphabet arrangement
	const totalRows = data.length + 1;
	let lastAlphabetCol = 'A';
	const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');
	headersTranslations.forEach((translation, index) => {
		ws[`${alphabet[index]}1`].v = translation;
		lastAlphabetCol = alphabet[index];
	});
	ws['!autofilter'] = { ref: `A1:${lastAlphabetCol}${totalRows}` };
	const date = new Date();
	const month = date.getMonth() + 1;
	const day = date.getDate();
	const year = date.getFullYear();
	const hour = date.getHours();
	const minutes = date.getMinutes();
	const seconds = date.getSeconds();

	const dateNow = `${year}${month < 10 ? '0' + month : month}${
		day < 10 ? '0' + day : day
	}`;
	const timeNow = `${hour < 10 ? '0' + hour : hour}${
		minutes < 10 ? '0' + minutes : minutes
	}${seconds < 10 ? '0' + seconds : seconds}`;

	const wb = XLSX.utils.book_new();
	XLSX.utils.book_append_sheet(wb, ws, sheetName);
	XLSX.writeFile(wb, fileName + '_' + dateNow + ['_'] + timeNow + '.xlsx');
};

// const saveAsExcelFile = (buffer, fileName) => {
// 	import('file-saver').then((module) => {
// 		if (module && module.default) {
// 			let EXCEL_TYPE =
// 				'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
// 			let EXCEL_EXTENSION = '.xlsx';
// 			const data = new Blob([buffer], {
// 				type: EXCEL_TYPE,
// 			});
// 			const date = new Date();
// 			const month = date.getMonth() + 1;
// 			const day = date.getDate();
// 			const year = date.getFullYear();
// 			const hour = date.getHours();
// 			const minutes = date.getMinutes();
// 			const seconds = date.getSeconds();

// 			const dateNow = `${day}-${month}-${year}`;
// 			const timeNow = `${hour}:${minutes}:${seconds}`;

// 			module.default.saveAs(
// 				data,
// 				fileName + '_' + dateNow + ['_'] + timeNow + EXCEL_EXTENSION
// 			);
// 		}
// 	});
// };

export const generateUrlBase = (section, version) => {
	const newVersion = version || 'v1';
	return `${window.REACT_APP_COLLABORATOR_API_URL}/${newVersion}/${section}`;
};
export const generateUrlExport = (section, version) => {
	const newVersion = version || 'v1';
	return `${window.REACT_COMMON_EXPORTFORMAT_URL}/${newVersion}/${section}`;
};

export const generateUrlBaseCommonUtils = (section) => {
	return `${window.REACT_COMMONUTILS_APIURL}/${section}`;
};
export const generateUrCollaboratorApiUrl = (section) => {
	return `${window.REACT_APP_COLLABORATOR_API_URL}/${section}`;
};
// 0 no enviado
// 1 pendiente

export const convertDateToFormatList = (date) => {
	const newDate = new Date(date);
	const day = newDate.getDate();
	const month = newDate.getMonth() + 1;
	const year = newDate.getFullYear();
	return `${day}-${month < 10 ? '0' + month : month}-${year} `;
};

export const validateStartDateAndEndDate = (startDate, endDate, newItem) => {
	let _newItem = newItem.filter((item) => item.invitationSendingDate !== null);
	const startDateMoment = moment(startDate, 'DD/MM/YYYY'); // Parsear startDate directamente
	const endDateMoment = moment(endDate, 'DD/MM/YYYY'); // Parsear endDate directamente

	const ArrayListByDate = _newItem.filter((item) => {
		const itemDate = moment(item?.invitationSendingDate, 'DD/MM/YYYY');
		return itemDate.isBetween(startDateMoment, endDateMoment, undefined, '[]'); // Usar isBetween para verificar el rango inclusivo
	});
	return ArrayListByDate;
};

export const validateStartDate = (startDate, newItem) => {
	// Filtrar elementos cuyo invitationSendingDate no sea nulo
	let _newItem = newItem.filter((item) => item.invitationSendingDate !== null);

	// Parsear startDate directamente

	const startDateMoment = moment(startDate, 'DD/MM/YYYY');

	// Filtrar _newItem para obtener elementos cuya invitationSendingDate sea igual o posterior a startDate
	const arrayListByStartDate = _newItem.filter((item) => {
		const itemDate = moment(item.invitationSendingDate, 'DD/MM/YYYY');
		return itemDate.isSameOrAfter(startDateMoment);
	});

	return arrayListByStartDate;
};

export const validateEndDate = (endDate, newItem) => {
	//filtrar elementos cuyo invitationSendingDate no sea nullo
	let _newItem = newItem.filter((item) => item.invitationSendingDate !== null);
	const endDateMoment = moment(endDate, 'DD/MM/YYYY'); // Parsear endDate directamente
	const ArrayListByEndDate = _newItem.filter((item) => {
		const itemDate = moment(item.invitationSendingDate, 'DD/MM/YYYY');
		return itemDate.isSameOrBefore(endDateMoment, 'day'); // Usar 'day' para comparaciones exactas de días
	});
	return ArrayListByEndDate;
};

const createDateByNumber = (date) => {
	const dateArray = date.split('-');
	const newDate = new Date(dateArray[2], dateArray[1] - 1, dateArray[0]);
	return newDate;
};
/**
 * It takes a start date, end date, and an array of objects. It then filters the array of objects by
 * the start date and end date.
 * @param startDate - 2020-01-01
 * @param endDate - "2020-07-31"
 * @param newItem - is an array of objects
 * @returns An array of objects.
 */
export const validateStartDateAndEndDateCollaborators = (
	startDate,
	endDate,
	newItem
) => {
	const ArrayListByDate = [];
	const dateS = new Date(startDate);
	const dateE = new Date(endDate);
	const startDateMoment = moment(dateS, 'YYYY-MM-DD');
	const endDateMoment = moment(dateE, 'YYYY-MM-DD');
	newItem.forEach((item) => {
		//const dateInArray = createDateByNumber(item?.statusChangeDate);
		const itemDate = moment(item?.statusChangeDate, 'YYYY-MM-DD');
		if (itemDate.isBetween(startDateMoment, endDateMoment, undefined, '[]')) {
			ArrayListByDate.push(item);
		}
	});
	return ArrayListByDate;
};

/**
 * It takes a start date and an array of objects, and returns an array of objects that have a date that
 * is equal to or greater than the start date.
 * @param startDate - is a date in the format of YYYY-MM-DD
 * @param newItem - is an array of objects
 * @returns An array of objects.
 */
export const validateStartDateCollaborators = (startDate, newItem) => {
	const date = new Date(startDate);
	const startDateMoment = moment(date, 'YYYY-MM-DD');
	const ArrayListByStartDate = [];
	newItem.forEach((item) => {
		//const dateInArray = createDateByNumber(item?.statusChangeDate);
		const itemDate = moment(item?.statusChangeDate, 'YYYY-MM-DD');
		if (itemDate.isSameOrAfter(startDateMoment)) {
			ArrayListByStartDate.push(item);
		}
	});
	return ArrayListByStartDate;
};

/**
 * It takes an array of objects, and returns a new array of objects that have a date property that is
 * before a given date.
 * @param endDate - is a date in the format of YYYY-MM-DD
 * @param newItem - is an array of objects
 * @returns An array of objects.
 */
export const validateEndDateCollaborators = (endDate, newItem) => {
	const date = new Date(endDate);
	const endDateMoment = moment(date, 'YYYY-MM-DD');
	const ArrayListByEndDate = [];
	newItem.forEach((item) => {
		//const dateInArray = createDateByNumber(item?.statusChangeDate);
		const itemDate = moment(item?.statusChangeDate, 'YYYY-MM-DD');
		if (itemDate.isSameOrBefore(endDateMoment)) {
			ArrayListByEndDate.push(item);
		}
	});
	return ArrayListByEndDate;
};

export const dictionaryNamesCollaborators = {
	BirthDate: 'Fecha de nacimiento',
	Name: 'Nombre(s)',
	FirstLastName: 'Apellido paterno',
	SecondLastName: 'Apellido materno',
	LastName2: 'Apellido materno',
	Gender: 'Sexo',
	Email: 'Correo electrónico',
	Phone: 'Teléfono',
	ZipCode: 'Código postal',
	Municipality: 'Municipio',
	Street: 'Calle',
	ExteriorNumber: 'Número exterior',
	InteriorNumber: 'Número interior',
	Suburb: 'Colonia',
	CellPhone: 'Celular',
	PhoneNumber: 'Teléfono',
	Curp: 'CURP',
	ExternalNumber: 'Número exterior',
	FederalEntity: 'Entidad federativa',
};

export const formatDate = (date) => {
	let dateFormated = '';
	if (date !== null) {
		dateFormated = moment(date).format('DD/MM/YYYY');
	}
	return dateFormated;
};

export function formatDateInvitations(dateData) {
	const [date] = dateData.split('T');
	const [year, month, day] = date.split('-');
	return `${day}/${month}/${year}`;
}

export async function downloadFile(toExcel, type, fileName) {
	const resp = await axios.post(
		`${window.REACT_COMMON_EXPORTFORMAT_URL}/export?to=${type}`,
		toExcel,
		{ responseType: 'blob' }
	);

	const mimeType =
		type === 'xlsx'
			? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
			: 'application/pdf;charset=UTF-8';
	import('file-saver').then((module) => {
		if (module && module.default) {
			// if (fileName === null) {
			// 	fileName = resp.headers['x-suggested-filename'];
			// }
			const data = new Blob([resp.data], {
				type: mimeType,
			});

			module.default.saveAs(data, fileName);
		}
	});
}

export async function downloadFileBase64(toExcel, type, fileName) {
	const jsonBlob = new Blob([JSON.stringify(toExcel)], {
		type: 'application/json',
	});

	// 3. Crear un FormData y agregar el Blob como archivo
	try {
		const formData = new FormData();
		formData.append('file', jsonBlob);
		const resp = await axios.post(
			`${window.REACT_COMMON_EXPORTFORMAT_URL}/export/v2`,
			formData,
			{
				responseType: 'blob',
			}
		);

		const mimeType =
			type === 'xlsx'
				? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
				: 'application/pdf;charset=UTF-8';
		import('file-saver').then((module) => {
			if (module && module.default) {
				// if (fileName === null) {
				// 	fileName = resp.headers['x-suggested-filename'];
				// }
				const data = new Blob([resp.data], {
					type: mimeType,
				});

				module.default.saveAs(data, fileName);
			}
		});
	} catch (error) {
		console.log(error);
	}
}
export const downloadFileExcel = async (resp, name) => {
	import('file-saver').then((module) => {
		if (module && module.default) {
			let EXCEL_TYPE =
				'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
			let fileName = resp.headers['x-suggested-filename'];

			const data = new Blob([resp.data], {
				type: EXCEL_TYPE,
			});

			module.default.saveAs(data, fileName === undefined ? name : fileName);
		}
	});
};

export const obtainCoordinates = async (address) => {
	const apiKey = 'AIzaSyAD_nPhuqlDsfEZ0t4TcV5DLhRcwC3yhQ8';
	// const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
	// 	address
	// )}&key=${apiKey}`;

	const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&components=country:MX&key=${apiKey}`;

	return await fetch(url)
		.then((response) => response.json())
		.then((data) => {
			return data;
		})
		.catch((error) => {
			// setError('Error de red. Verifica tu conexión.');
			Toast('Error de red. Verifica tu conexión. ' + error, 'error');
		});
};

export const obtainCoordinatesClient = async () => {
	return new Promise((resolve, reject) => {
		navigator.geolocation.getCurrentPosition(
			({ coords }) => {
				resolve({ lat: coords.latitude, lng: coords.longitude });
			},
			(err) => {
				resolve(null);
			}
		);
	});
};

export const HolidaysYears = [
	{
		label: new Date().getFullYear() - 1,
		value: new Date().getFullYear() - 1,
	},
	{
		label: new Date().getFullYear(),
		value: new Date().getFullYear(),
	},
	{
		label: new Date().getFullYear() + 1,
		value: new Date().getFullYear() + 1,
	},
];

export const days = [
	{ name: 'D', value: '1' },
	{ name: 'L', value: '2' },
	{ name: 'M', value: '3' },
	{ name: 'I', value: '4' },
	{ name: 'J', value: '5' },
	{ name: 'V', value: '6' },
	{ name: 'S', value: '7' },
];

/**
 * The function generates an array of ids from an array of objects using a specified id indicator.
 * @param dataArray - An array of objects containing data.
 * @param idIndicator - The idIndicator parameter is a string that represents the property name of the
 * object in the dataArray that contains the unique identifier or ID.
 * @returns an array of ids extracted from the objects in the dataArray.
 */
export function generateArrayToIds(dataArray, idIndicator = 'id') {
	let gArray = Array.isArray(dataArray) ? dataArray : [dataArray];
	const newData = dataArray === undefined && dataArray === null ? [] : gArray;
	const dataMod = newData.map((object) => object[idIndicator]);
	return dataMod;
}

/**
 * The function formatDateToYYYYMMDD formats a given date object into the YYYY-MM-DD format.
 * @param date - The `date` parameter is a JavaScript `Date` object that represents a specific date.
 * @returns The function `formatDateToYYYYMMDD` returns a string in the format "YYYY-MM-DD"
 * representing the input date.
 */
export function formatDateToYYYYMMDD(date) {
	const year = date.getFullYear();
	const month = String(date.getMonth() + 1).padStart(2, '0'); // Agregar cero inicial si es necesario
	const day = String(date.getDate()).padStart(2, '0'); // Agregar cero inicial si es necesario
	return `${year}-${month}-${day}`;
}

export const typeIncidences = [
	{ id: 1, name: 'Asistencia (duración horas del turno)' },
	{ id: 2, name: 'Día no laborable (duración horas del turno)' },
	{ id: 3, name: 'Falta (duración horas del turno)' },
	{ id: 4, name: 'Incapacidad (duración horas del turno)' },
	{ id: 5, name: 'Retardo (duración del retardo)' },
	{ id: 6, name: ' Vacaciones (duración horas del turno)' },
	{ id: 7, name: 'Permiso (duración horas del turno)' },
	{ id: 8, name: 'Día de descanso  (duración horas del turno)' },
	//{ id: 8, name: 'Otro' },
];

/**
 * Convierte un arreglo de objetos en un arreglo de enteros utilizando un indicador de identificación.
 * @param {Array} dataArray - El arreglo de datos de entrada.
 * @param {string} idIndicator - El nombre del indicador de identificación en los objetos (por defecto: 'id').
 * @returns {Array} - Un nuevo arreglo que contiene los valores de los indicadores de identificación como enteros.
 */
export function generateArrayToIdsInt(dataArray, idIndicator = 'id') {
	// Verifica si el arreglo de entrada es indefinido y, en caso afirmativo, crea un nuevo arreglo vacío.
	const newData = dataArray === undefined ? [] : dataArray;

	// Mapea el arreglo de entrada para extraer los valores de los indicadores de identificación y los convierte en enteros.
	const dataMod = newData.map((object) => parseInt(object[idIndicator], 10));

	// Devuelve el nuevo arreglo de enteros.
	return dataMod;
}

export function getFileNameWithDateTime(filename) {
	// Get the current date in the format 'YYYY-MM-DD'
	const currentDate = moment().format('DD/MM/YY');
	const [day, month, year] = currentDate.split('/');
	// Get the current time in the format 'HH-mm-ss'
	const currentTime = moment().format('HH:mm:ss');
	const [hour, minute, second] = currentTime.split(':');

	// Create the new file name by combining the original filename, date, and time
	const newFileName = `${filename}_${day}${month}${year}_${hour}${minute}${second}`;
	return newFileName;
}

export function getTokenData() {
	let token = window.localStorage.getItem('accessToken');
	if (token !== null) {
		let base64Url = token.split('.')[1];
		let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
		let jsonPayload = decodeURIComponent(
			window
				.atob(base64)
				.split('')
				.map(function (c) {
					return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
				})
				.join('')
		);
		return JSON.parse(jsonPayload);
	}
}

export function containEmail() {
	const strEmails = window.REACT_APP_WHITELIST_USERS;
	const emailsArray = strEmails.split(',');
	const tokenInfo = getTokenData();

	const { email } = tokenInfo;
	return emailsArray.includes(email);
}

export function isBase64(str) {
	try {
		return Buffer.from(str, 'base64').toString() === str;
	} catch (err) {
		return false;
	}
}

export function decodificarBase64(cadenaBase64) {
	try {
		return Buffer.from(cadenaBase64, 'base64').toString();
	} catch (error) {
		console.error('Error al decodificar Base64:', error);
		return null;
	}
}

function removeDashes(string) {
	return string.replace(/-/g, '');
}
export function checkIsCallByPayroll(string) {
	const str = removeDashes(string);
	// Split the string into individual characters
	const characters = str.split('');

	// Iterate over the characters
	for (let character of characters) {
		if (character !== '0') {
			return false; // If it finds a character other than "0", return true
		}
	}

	return true; // If all characters are "0", return false
}

export function sortByDataTable(data, sort) {
	if (sort.length === 0) {
		return data;
	}

	// Extraemos el campo y el orden del primer objeto de sort
	const { field, order } = sort[0];

	// Validamos que el campo y el orden existan
	if (!field || (order !== 1 && order !== -1)) {
		return data;
	}

	// Función para convertir a número si es posible
	const convertToNumberIfPossible = (val) => {
		return typeof val === 'string' && !isNaN(Number(val)) ? Number(val) : val;
	};

	// Ordenamos los datos según el campo y el orden, priorizando valores no vacíos
	return data.sort((a, b) => {
		let valA = a[field] ?? ''; // Si es null o undefined lo tratamos como cadena vacía
		let valB = b[field] ?? '';

		// Si ambos valores son vacíos, se consideran iguales
		if (valA === '' && valB === '') {
			return 0;
		}

		// Si uno de los valores es vacío, lo colocamos al final
		if (valA === '') {
			return 1; // Coloca `a` después de `b`
		}
		if (valB === '') {
			return -1; // Coloca `b` después de `a`
		}

		// Convertimos a número si son valores numéricos en string
		valA = convertToNumberIfPossible(valA);
		valB = convertToNumberIfPossible(valB);

		// Comparación para strings
		if (typeof valA === 'string' && typeof valB === 'string') {
			return order === 1 ? valA.localeCompare(valB) : valB.localeCompare(valA);
		}

		// Comparación para tipos no string (números, booleanos, etc.)
		if (valA < valB) {
			return order === 1 ? -1 : 1;
		} else if (valA > valB) {
			return order === 1 ? 1 : -1;
		} else {
			return 0; // Si son iguales
		}
	});
}

export function sortByKey(array, key, order = 'desc') {
	return array.sort((a, b) => {
		const valueA = a[key];
		const valueB = b[key];

		if (typeof valueA === 'string' && typeof valueB === 'string') {
			// Comparación de cadenas
			if (order === 'asc') {
				return valueA.localeCompare(valueB);
			} else {
				return valueB.localeCompare(valueA);
			}
		} else if (typeof valueA === 'number' && typeof valueB === 'number') {
			// Comparación de números
			if (order === 'asc') {
				return valueA - valueB;
			} else {
				return valueB - valueA;
			}
		} else if (moment.isMoment(valueA) && moment.isMoment(valueB)) {
			// Comparación de fechas usando moment.js
			if (order === 'asc') {
				return valueA.isBefore(valueB) ? -1 : 1;
			} else {
				return valueA.isAfter(valueB) ? -1 : 1;
			}
		} else {
			// Comparación genérica
			if (order === 'asc') {
				return valueA < valueB ? -1 : 1;
			} else {
				return valueA > valueB ? -1 : 1;
			}
		}
	});
}

export const createActionTypes = (base) => ({
	BASE: `${base}`,
	SUCCESS: `${base}_SUCCESS`,
	ERROR: `${base}_ERROR`,
	CLEAR: `${base}_CLEAR`,
});
