import {
	GET_ACCUMULATED,
	GET_ACCUMULATED_ERROR,
	GET_ACCUMULATED_ERROR_SHOW,
	GET_ACCUMULATED_SUCCESS,
} from './../../constants';

export const getAccumulatedReducer = (state, actions) => {
	switch (actions.type) {
		case GET_ACCUMULATED:
			return {
				...state,
				loading: true,
			};
		case GET_ACCUMULATED_ERROR:
			return {
				accumulated: state.data,
				//	collaboratorsOptions: state.collaboratorsOptions,
				loading: false,
				error: actions.payload,
				errorShow: false,
			};
		case GET_ACCUMULATED_SUCCESS:
			return {
				accumulated: actions.payload,
				//collaboratorsOptions: getCollaboratorOptions(actions.payload),
				loading: false,
				error: null,
				errorShow: false,
			};
		case GET_ACCUMULATED_ERROR_SHOW:
			return {
				...state,
				errorShow: true,
			};
		default:
			return state;
	}
};
