import React from 'react';
import { CustomAlertDialog } from '../../../components/Dialogs/CustomAlertDialog';
import {
	GeneralNames,
	InvitationsScreenNames,
} from '../../../namesConstants/names';
export const AlertComponent = ({
	typeInvitation,
	openDialog,
	setOpenDialog,
	sendInvitationsAction,
	sendUnInviteAction,
}) => {
	return (
		<CustomAlertDialog
			title={
				typeInvitation === 'invitation'
					? InvitationsScreenNames.CustomAlertDialogTittleInvite
					: InvitationsScreenNames.CustomAlertDialogTittleUnInvite
			}
			setOpen={setOpenDialog}
			open={openDialog}
			message={
				typeInvitation === 'invitation'
					? InvitationsScreenNames.CustomAlertDialogInviteMessage
					: InvitationsScreenNames.CustomAlertDialogUnInvitedMessage
			}
			cancelLabel={GeneralNames.GeneralCancel}
			agreeLabel={
				typeInvitation === 'invitation'
					? GeneralNames.GeneralSend
					: GeneralNames.GeneralAnnular
			}
			agreeIcon='pi pi-check'
			onConfirmFunction={() =>
				typeInvitation === 'invitation'
					? sendInvitationsAction()
					: sendUnInviteAction()
			}
			style={{ width: '30vw' }}
		/>
	);
};
