export const btn =
	'!leading-[1] !p-2 !font-font-family-base text-base !font-normal !shadow-none !border';
export const btnPrimary =
	'!text-primary-contrast-color-text !bg-primary !border-primary hover:!text-secondary-contrast-color-text hover:!bg-secondary hover:!border-secondary focus:!text-secondary-contrast-color-text';
export const btnSecondary =
	'!text-secondary-contrast-color-text !bg-secondary !border-secondary hover:!bg-primary hover:!border-primary hover:!text-primary-contrast-color-text focus:!text-primary-contrast-color-text';
export const btnSuccess =
	'!text-success-contrast-color-text !bg-success !border-success hover:!opacity-80 hover:!border-secondary focus:!text-secondary-contrast-color-text';
export const btnInfo =
	'!text-info-contrast-color-text !bg-info !border-info hover:!opacity-80';
export const btnWarning =
	'!text-warning-contrast-color-text !bg-warning !border-warning hover:!opacity-80';
export const btnDanger =
	'!text-danger-contrast-color-text !bg-danger !border-danger hover:!opacity-80';

export const btnPrimaryOutline =
	'!text-primary !bg-transparent !border-primary hover:!border-secondary';
export const btnSecondaryOutline =
	'!text-secondary !bg-transparent !border-secondary';
export const btnSuccessOutline =
	'!text-success !bg-transparent !border-success hover:!border-secondary';
export const btnInfoOutline =
	'!text-info !bg-transparent !border-info hover:!border-secondary ';
export const btnWarningOutline =
	'!text-black btnOpacity  !bg-transparent !border-warning hover:!border-secondary ';
export const btnDangerOutline =
	'!text-black btnOpacity  !bg-transparent !border-danger hover:!border-secondary ';

export const btnDisable = 'disabled:!opacity-40 disabled:!cursor-default';

export const btnDropDown =
	'!text-black !leading-[1]  !font-font-family-base text-base !font-normal !shadow-none !bg-transparent !text-left';

export const headerClassName =
	'hover:!text-success  !bg-secondary-PCS004 !text-secondary-PCS001 !font-semibold !border-r-2 !border-secondary-PCS003 !font-bold !text-sm !font-karla ';
