import React, { useEffect, useState } from 'react';
import { addLocale } from 'primereact/api';
import months from '../utils/months';
import { Calendar } from 'primereact/calendar';
import { isCompanyDate, isHoliday, localeConfig, monthsData } from '../utils';
import PropTypes from 'prop-types';
export const GridContentCalendar = ({
	holidays,
	selectYear,
	bgHolidays,
	bgCompanyDate,
	setSelectDate,
	openFormDialog,
	onEditFunction,
	onAdd,
	forceUpdateKey,
}) => {
	// eslint-disable-next-line react-hooks/exhaustive-deps
	let holidayCss = `${bgHolidays} !w-[25px] !h-[25px] !border-0 !rounded-[50%] !text-black flex items-center justify-center text-center`;
	let companyDatesCss = `${bgCompanyDate} !w-[25px] !h-[25px] !border-0 !rounded-[50%] !text-black flex items-center justify-center text-center`;
	let normalCss = `!w-[25px] !h-[25px] !border-0 !rounded-[50%] !text-black flex items-center justify-center text-center`;
	const w = 210;
	const h = 230;

	useEffect(() => {
		window.addEventListener('resize', updateWidth);
		return () => {
			window.removeEventListener('resize', updateWidth);
		};
	}, []);

	function splitDate(date) {
		let newDate = date.toString().split('T')[0];
		return newDate;
	}

	const dateTemplate = (e) => {
		const { year, month, day } = e;
		let date = new Date(year, month, day);
		let dateToday = new Date();

		let yearToday = dateToday.getFullYear();
		let monthToday = dateToday.getMonth();
		let dayToday = dateToday.getDate();

		let compareDate = new Date(yearToday, monthToday, dayToday);

		if (!date) return null;

		let validationClassName = '';
		if (isHoliday(date, holidays)) {
			validationClassName = holidayCss;
		} else if (isCompanyDate(date, holidays)) {
			validationClassName = companyDatesCss;
		} else {
			validationClassName = normalCss;
		}

		return (
			<div
				className={`${validationClassName} ${
					date.getDay() === 0 ? '!text-red-600 font-semibold' : ''
				}`}
				onClick={() => {
					setSelectDate(date);
				}}
				onDoubleClick={() => setDataDoubleClickHandler(date)}>
				<p
					className={`${
						compareDate.toDateString() === date.toDateString() ? 'today-c' : ''
					}`}>
					{date.getDate()}
				</p>
			</div>
		);
	};

	function setDataDoubleClickHandler(data) {
		let date = new Date(data);

		let yearToday = date.getFullYear();
		let monthToday = date.getMonth() + 1;
		let dayToday = date.getDate();
		let dateFormat =
			yearToday +
			'-' +
			(monthToday < 10 ? '0' + monthToday : monthToday) +
			'-' +
			(dayToday < 10 ? '0' + dayToday : dayToday);
		let dateFind = holidays.filter(
			(item) => splitDate(item.date) === dateFormat
		)[0];

		openFormDialog(dateFind === undefined ? 'create' : 'edit');
		return dateFind === undefined ? onAdd() : onEditFunction(dateFind);
	}

	addLocale('es', localeConfig);

	const templateHeader = (index) => {
		return <p className='pl-[5px] !text-lg '>{months[index - 1]}</p>;
	};

	const renderFullCalendar = (index) => {
		let viewDate = new Date(
			`${index < 10 ? '0' + index : index}/01/${selectYear.year}`
		);
		let min = viewDate;
		let max = new Date(`${index + 1}/01/${selectYear.year}`);
		max.setDate(max.getDate() - 1);
		return (
			<Calendar
				key={index}
				panelStyle={{
					width: `${w}px`,
					height: `${h}px`,
					overflow: 'hidden',
					padding: '0px',
				}}
				viewDate={viewDate}
				panelClassName={`w-[${w}px] h-[${h}px] p-0 !bg-[#F7F7F7] !rounded-b-lg !border-0`}
				className={`w-[${w}px] h-[${h}px] p-0 !bg-[#F7F7F7] !rounded-b-lg !border-0`}
				locale='es'
				inline
				readOnlyInput
				minDate={min}
				maxDate={max}
				yearNavigator={true}
				yearRange={`{${selectYear.year}:${selectYear.year}`}
				showOtherMonths={false}
				dateTemplate={(e) => dateTemplate(e)}
				onViewDateChange={(e) => {}}
				headerTemplate={() => templateHeader(index)}
			/>
		);
	};

	const returnFullRenderCalendar = () => {
		return (
			<div
				className='grid grid-cols-4 gap-1 2xl:w-[880px] xl:w-full lg-w-full md:w-full sm:w-full p-1'
				id='renderFullCalendar'>
				{monthsData.map((month, i) => {
					return renderFullCalendar(month.id);
				})}
			</div>
		);
	};

	const returnOneRenderCalendar = () => {
		let min = new Date(`01/01/${selectYear.year}`);
		let max = new Date(`01/01/${selectYear.year + 1}`);
		max.setDate(max.getDate() - 1);
		return (
			<div className='flex lg-w-full md:w-full sm:w-full p-1'>
				<Calendar
					key={forceUpdateKey}
					viewDate={new Date()}
					panelStyle={{ padding: '0px' }}
					panelClassName={`w-[${w}px] h-[${h}px] !p-0 !bg-[#F7F7F7] !rounded-b-lg !border-0`}
					className={`w-[${w}px] h-[${h}px] !p-0 !bg-[#F7F7F7] !rounded-b-lg !border-0`}
					locale='es'
					inline
					selectOtherMonths={false}
					minDate={min}
					maxDate={max}
					readOnlyInput
					yearRange={`${selectYear.year}:${selectYear.year}`}
					showOtherMonths={true}
					monthNavigator={true}
					yearNavigator={true}
					dateTemplate={(e) => dateTemplate(e)}
				/>
			</div>
		);
	};

	const [width, setWidth] = useState(window.innerWidth);

	const updateWidth = () => {
		setWidth(window.innerWidth);
	};

	return (
		<div className='w-full h-full'>
			{width > 1535 ? returnFullRenderCalendar() : returnOneRenderCalendar()}
		</div>
	);
};

GridContentCalendar.propTypes = {
	holidays: PropTypes.arrayOf(PropTypes.any).isRequired,
	selectYear: PropTypes.object.isRequired,
	bgHolidays: PropTypes.string.isRequired,
	bgCompanyDate: PropTypes.string.isRequired,

	setSelectDate: PropTypes.func.isRequired,
	openFormDialog: PropTypes.func.isRequired,
	onEditFunction: PropTypes.func.isRequired,
	onAdd: PropTypes.func.isRequired,
};
