import { Button } from 'primereact/button'
import React from 'react'

export const BaseButton = ({ type, onClick, label, iconPos, icon, disabled = false, ...props }) => {
    return (
        <Button
            disabled={disabled}
            type={type}
            onClick={onClick}
            label={label}
            iconPos={iconPos}
            icon={icon}
            {...props}
        />
    )
}
BaseButton.defaultProps = {
    type: 'button',
    iconPos: 'left',
    icon: null,
    label: '',
    onClick: () => { }
}