import React, {useState, useEffect } from 'react';
import { Dialog } from 'primereact/dialog';
import {TableCollaboratorsShift} from './TableCollaboratorsShift'

export const MassiveCollaboratorsShiftDialog =(
    props
) => {
    const [collaboratorsList, setCollaboratorsList] =useState([])
    const [width, setWidth] = useState(window.innerWidth);
    const updateWidth = () => {
		setWidth(window.innerWidth);
	};
    
  useEffect(() => {
    setCollaboratorsList(props.filtersList);
  }, [props.filtersList]);
    useEffect(() => {
        
		window.addEventListener('resize', updateWidth);
		return () => {
			window.removeEventListener('resize', updateWidth);
		};
	}, []);
    const renderTable = () =>{
        return (
            <TableCollaboratorsShift filtersList={collaboratorsList}/>
        )
    }
    
    const content = () => {
        return (
            <div className='flex flex-col w-full h-full space-y-2'>
                <p>
                    Para algunos colaboradores no se pudo asignar horario para la fecha de hoy debido a que ya tienen un horario asignado
                </p>
                {renderTable()}
            </div>
        )
    }
    return (
        <Dialog header={
            <p className='text-font-size-base text-[#5e5e5e] font-font-family-base font-bold '>
                {'Actualización masiva de horario'}
            </p>
        }
        visible={props.showModal}
        modal
        style={{
            width: width < 1000 ? '85vw' : width < 1564 ? ' 70vw' : '45vw',
            height: '70vh',
            position: 'relative',
        }}
        onHide={props.onHide}
        >
          {content()}
      </Dialog>
    )
}